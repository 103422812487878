<template>
<a
    class="sync-status" 
    :class="{ 'sec': syncPending, 'suc': syncSuccess, 'err': syncFailure, 'linkable': exposeURL }"
    v-show="isActive"
    :href="localExposeURL"
    target="_blank" 
    rel="noopener noreferrer"
>
    <CircleArrowIcon
      v-if="syncPending"
      class="icon"
    />
    <CheckBubbleIcon
      v-if="syncSuccess"
      class="icon"
    />
    <WarnTriangleIcon
      v-if="syncFailure"
      class="icon"
    />
    {{ label }}
</a>
</template>

<script>
import CircleArrowIcon from  '@/components/icons/CircleArrowIcon.vue'
import CheckBubbleIcon from  '@/components/icons/CheckBubbleIcon.vue'
import WarnTriangleIcon from  '@/components/icons/WarnTriangleIcon.vue'

export default {
  name: 'SyncStatusIndicator',
  props:{    
    isActive:{
      type: Boolean
    },
    status:{
      type: String
    },
    label:{
      type: String,
      required: true,
    },
    exposeURL:{
      type: String,
    }
  },
  computed:{
    syncPending(){
      return !this.status
    },
    syncSuccess(){
      return this.status === 'Success'
    },
    syncFailure(){
      return this.status === 'Failure'
    },
    localExposeURL(){ // Necessary to hide `href` if exposeURL is an empty string
      if (!this.exposeURL){
        return undefined
      }
      return this.exposeURL
    }
  },
  components:{
    CircleArrowIcon,
    CheckBubbleIcon,
    WarnTriangleIcon
  }
}
</script>

<style scoped>
.sync-status{
    margin: 0px 2px;
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 3px;
    text-decoration: none;
}
.sync-status.linkable{
    cursor: pointer;
}
.sync-status.sec{
    border: 1px solid var(--co-brd-sec);
    background-color: var(--co-bg-sec);
    color: var(--co-bg-sec-inv);
}
.sync-status.suc{
    border: 1px solid var(--co-suc-brd);
    background-color: var(--co-suc-bg);
    color: var(--co-suc-bg-inv);
}
.sync-status.err{
    border: 1px solid var(--co-err-brd);
    background-color: var(--co-err-bg);
    color: var(--co-err-bg-inv);
}
.sync-status .icon{
  margin-top: -2px;
}
</style>