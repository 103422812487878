<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Konten werden geladen...</h5>
    </div>
    <table
      id="bankAccounts"
      class="table table-sm table-striped"
      v-show="isLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th>Bez.</th>
          <th>IBAN</th>
          <th style="width:150px">Bank</th>
          <th style="width:125px">BIC</th>
          <th>Kontoinh.</th>
          <th style="width:50px" v-if="isAccountableContextGiven"></th>
          <th style="width:50px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="bankAccount in bankAccounts"
          :key="bankAccount.uuid"
        >
          <td
            label="Bez."
          >
            {{ bankAccount.name }}
          </td>
          <td
            label="IBAN"
          >
            {{ bankAccount.iban }}
          </td>
          <td
            label="Bank"
          >
            {{ bankAccount.bank_name }}
          </td>
          <td
            label="BIC"
          >
            {{ bankAccount.bic }}
          </td>
          <td
            label="Kontoinh."
          >
            {{ bankAccount.account_holder_name }}
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditBankAccount($event, bankAccount)"
            >
              Bearbeiten
            </div>
            <div
              class="btn-icon show-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditBankAccount($event, bankAccount)"
            >
              <PencilIcon />
            </div>
          </td>
          <td
              v-if="isAccountableContextGiven"
            >
              <div
                class="btn btn-warn hide-md"
                title="Entfernen"
                role="button"
                @click="triggerDetachBankAccount($event, bankAccount)"
              >
                Entfernen
              </div>
              <div
                class="btn-icon-warn show-md"
                title="Entfernen"
                role="button"
                @click="triggerDetachBankAccount($event, bankAccount)"
              >
                <XIcon />
              </div>
            </td>
        </tr>
        <tr class="proto" v-if="!isAccountableContextGiven">
          <td
            label="Bez."
          >
            <StringInput
              name="name" 
              label="Bez."
              class="form-control"
              placeholder="Kontenbezeichnung"
              v-model="protoFields.name.value"
              @valid:modelValue="protoFields.name.isValid = true"
              @invalid:modelValue="protoFields.name.isValid = false"
              @reset:modelValue="protoFields.name.isValid = null"
              mandatory
            />
          </td>
          <td
            label="IBAN"
          > 
            <StringInput
              name="iban" 
              label="IBAN"
              class="form-control"
              placeholder="DE123..."
              v-model="protoFields.iban.value"
              @valid:modelValue="protoFields.iban.isValid = true"
              @invalid:modelValue="protoFields.iban.isValid = false"
              @reset:modelValue="protoFields.iban.isValid = null"
              format="^[A-Z]{2}\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$"
              mandatory
            />
          </td>
          <td
            label="Bank"
          > 
            <StringInput
              name="bankName" 
              label="Bank"
              class="form-control"
              placeholder="Sparkasse"
              v-model="protoFields.bankName.value"
              @valid:modelValue="protoFields.bankName.isValid = true"
              @invalid:modelValue="protoFields.bankName.isValid = false"
              @reset:modelValue="protoFields.bankName.isValid = null"
            />
          </td>
          <td
            label="BIC"
          > 
            <StringInput
              name="bic" 
              label="BIC"
              class="form-control"
              placeholder="MALA..."
              v-model="protoFields.bic.value"
              @valid:modelValue="protoFields.bic.isValid = true"
              @invalid:modelValue="protoFields.bic.isValid = false"
              @reset:modelValue="protoFields.bic.isValid = null"
            />
          </td>
          <td
            label="Kontoinh."
          > 
            <StringInput
              name="accountHolderName" 
              label="Kontoinh."
              class="form-control"
              placeholder="Max Mustermann"
              v-model="protoFields.accountHolderName.value"
              @valid:modelValue="protoFields.accountHolderName.isValid = true"
              @invalid:modelValue="protoFields.accountHolderName.isValid = false"
              @reset:modelValue="protoFields.accountHolderName.isValid = null"
            />
          </td>
          <td>
            <button
              class="btn btn-suc hide-md"
              title="Erstellen"
              role="button"
              @click="triggerCreateBankAccount($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              Erstellen
            </button>
            <button
              class="btn-icon-suc show-md"
              title="Erstellen"
              @click="triggerCreateBankAccount($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              <PlusIcon />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BankAccount from '@/diaspora/models/bank-account.js'

import StringInput from  '@/components/inputs/StringInput.vue'

import XIcon from  '@/components/icons/XIcon.vue'
import PencilIcon from  '@/components/icons/PencilIcon.vue'
import PlusIcon from  '@/components/icons/PlusIcon.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

export default {
  name: 'BankAccountTable',
  emits: [
    'triggered:modelEdit',
    'triggered:modelDetach',
    'triggered:modelCreate'
  ],
  data(){
    return {
      messageStore: new MessageStore(),
      protoModel: new BankAccount(),
      areAllProtoFieldsValid: false,
    }
  },
  props:{
    bankAccounts:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    },
    isAccountableContextGiven:{
      type: Boolean,
    },
    contextType: {
      type: String,
      required: true
    },
    contextUUID: {
      type: String,
      required: true
    },
  },
  computed:{
    protoFields(){
      return this.protoModel.fields
    },
  },
  watch:{
    protoFields: {
      deep:true,
      handler(){
        this.areAllProtoFieldsValid = this.protoModel.areFieldsValid()
      }
    },
  },
  methods:{
    triggerEditBankAccount(e, bankAccount){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelEdit', bankAccount.uuid)
    },
    triggerDetachBankAccount(e, bankAccount){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelDetach', bankAccount.uuid)
    },
    triggerCreateBankAccount(e, bankAccount){
      if (e){
        e.preventDefault()
      }
        
      this.$emit('triggered:modelCreate', bankAccount, this.contextType, this.contextUUID)

      this.protoModel = new BankAccount()
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    PencilIcon,
    PlusIcon,
    XIcon,
    StringInput,
    FormMessages,
},
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>