<template>
  <div class="container">
    <h2>Immobilien-Einheiten</h2>
    <RealEstateUnitCollection
      :realEstateUUID="urlRealEstateUUID"
      :realEstateRenterUUID="urlRealEstateRenterUUID"
      :serviceProviderUUID="urlServiceProviderUUID"
      :isSubView="false"
    />
  </div>
</template>

<script>
import RealEstateUnitCollection from '@/components/collections/RealEstateUnitCollection.vue'

export default {
  name: 'RealEstateUnit',
  computed:{
    urlRealEstateUUID(){
      return this.$route.params.realEstateUUID
    },
    urlRealEstateRenterUUID(){
      return this.$route.params.realEstateRenterUUID
    },
    urlServiceProviderUUID(){
      return this.$route.params.serviceProviderUUID
    }
  },
  components:{
    RealEstateUnitCollection,
  }
}
</script>

<style>
</style>
