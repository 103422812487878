<template>
  <span class="input-container">
    <input
      type="text"
      :name="name"
      :placeholder="placeholder"
      :class="[ this.class, {'input-valid': isValid === true, 'input-invalid': isValid === false }]"
      v-model.lazy="localValue"
      :mandatory="mandatory"
      :disabled="disabled"
      :id="baseID + '-input'"
    >
    <ClockIcon
      class="input-placeholder-time" 
    />   
  </span>
</template>

<script>
import { services } from '@digiscape/js-core'

import ClockIcon from '@/components/icons/ClockIcon.vue'

export default {
  name: 'TimestampInput',
  data(){
    return {
      localValue: '',
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: String,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "...",
    },
    mandatory:{
      type: Boolean
    },
    id: {
      type: String
    },
    class: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue', 
    'reset:modelValue'
  ],
  computed:{
    baseID(){
      if (this.id){
        return this.id
      }
      return this.name
    }
  },
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (!value){
          this.localValue = ''
          return
        }
        this.localValue = services.$format.timestamp(value)
      }
    },
    localValue(){
      const validateErr = this.validateInput()
      if (validateErr != ""){
        this.isValid = false
        this.$emit('invalid:modelValue', validateErr)
        return
      }
      if (!this.localValue){
        this.isValid = null
        this.$emit('update:modelValue', this.localizedStringToTimestampString(this.localValue))
        this.$emit('reset:modelValue')
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localizedStringToTimestampString(this.localValue))
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  methods:{
    validateInput(){
      if (!this.localValue){
        return ""
      }
      const parsedTimestamp = services.$parse.timestamp(this.localValue)
      if (!this.isValidTimestamp(parsedTimestamp)){
        return "Das Feld '" + this.label +"' darf nur gültige Datumswerte enthalten."
      }
      return ""
    },
    localizedStringToTimestampString(localizedString){
      const parsedTimestamp = services.$parse.timestamp(localizedString)
      if (!this.isValidTimestamp(parsedTimestamp)){
        return ""
      }
      return parsedTimestamp.toISOString()
    },
    resetIsValid(){
      this.isValid = null
    },
    isValidTimestamp(parsedTimestamp){
      if (!parsedTimestamp){
        return false
      }
      if (parsedTimestamp.toString() == 'Invalid Date'){
        return false
      }
      if (parsedTimestamp.getYear() <= 1){
        return false
      }
      return true
    }
  },
  components:{
    ClockIcon
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>