import Message from '@/components/forms/messages/message.js'

class MessageStore{
    constructor(){
        this.messages = []
        this.messageTargets = {}
    }

    pending(contextCode = '', body, targetId = ''){
        if (targetId && targetId in this.messageTargets){
            const messageUUID = this.messageTargets[targetId];
            this.updateMessage(messageUUID, 'pending', contextCode, body)
            return messageUUID;
        }

        const messageUUID = this.newPending(contextCode, body)
        if (targetId){
            this.messageTargets[targetId] = messageUUID;
        }
        return messageUUID;
    }

    success(contextCode = '', body, targetId = ''){
        if (targetId && targetId in this.messageTargets){
            const messageUUID = this.messageTargets[targetId];
            this.updateMessage(messageUUID, 'success', contextCode, body)
            return messageUUID;
        }

        const messageUUID = this.newSuccess(contextCode, body)
        if (targetId){
            this.messageTargets[targetId] = messageUUID;
        }
        return messageUUID;
    }

    error(contextCode = '', body, targetId = ''){
        if (targetId && targetId in this.messageTargets){
            const messageUUID = this.messageTargets[targetId];
            this.updateMessage(messageUUID, 'error', contextCode, body)
            return messageUUID;
        }

        const messageUUID = this.newError(contextCode, body)
        if (targetId){
            this.messageTargets[targetId] = messageUUID;
        }
        return messageUUID;
    }

    flushTarget(targetId){
        if (targetId && targetId in this.messageTargets){
            const messageUUID = this.messageTargets[targetId];
            this.removeMessage(messageUUID)
        }
    }

    newPending(contextCode = '', body){
        const message = new Message('pending', contextCode, body);
        this.messages.push(message);
        return message.uuid;
    }

    newSuccess(contextCode = '', body){
        const message = new Message('success', contextCode, body);
        this.messages.push(message);
        window.setTimeout(this.removeMessage.bind(this, message.uuid), 1500);
        return message.uuid;
    }

    newError(contextCode = '', body){    
        const message = new Message('error', contextCode, body);
        this.messages.push(message);
        return message.uuid;
    }

    updateMessage(uuid, newType, newContextCode = '', newBody){
        for (let msgIdx = 0; msgIdx < this.messages.length; msgIdx++) {
            const message = this.messages[msgIdx];
            if (message.uuid != uuid){
                continue
            }
            message.type = newType;
            message.contextCode = newContextCode;
            message.body = newBody;
            if (newType == 'success') {                
                window.setTimeout(this.removeMessage.bind(this, message.uuid), 1500);
            }
            return true
        }
        return false
    }

    removeMessage(uuid){
        const newMessages = []
        for (let msgIdx = 0; msgIdx < this.messages.length; msgIdx++) {
            const message = this.messages[msgIdx];
            if (message.uuid == uuid){
                continue
            }
            newMessages.push(message)
        }
        this.messages = newMessages

        for (const [targetId, messageUUID] of Object.entries(this.messageTargets)){
            if (messageUUID == uuid){
                delete this.messageTargets[targetId]
            }
        }
    }

    flush(){
        this.messages = []
    }
}

export default MessageStore