import Field from '@/diaspora/fields/field.js'

class TimestampField extends Field{    
    constructor(fieldName = '', displayName = '', isMandatory = false, defaultValue = ''){
        super(fieldName, displayName, '', defaultValue, isMandatory)        

        if (defaultValue){
            this.isValid = true
        }
    }
}

export default TimestampField