<template>
  <div class="container">
    <br/>
    <h3>Einstellungen</h3>
    <CredentialsImmoscoutForm 
      class="col-12 col-md-8 col-xl-6"
    />
    <br/>
    <CredentialsBookooForm 
      class="col-12 col-md-8 col-xl-6"
    />
  </div>
</template>

<script>
import CredentialsImmoscoutForm from '@/components/forms/CredentialsImmoscoutForm.vue'
import CredentialsBookooForm from '@/components/forms/CredentialsBookooForm.vue'

export default {
  name: 'Dashboard',
  components: {
    CredentialsImmoscoutForm,
    CredentialsBookooForm
  }
}
</script>

<style>
</style>
