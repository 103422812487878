import { services } from '@digiscape/js-core'


async function createTechnicalInstallationMaintenance(technicalInstallationUUID, newTechnicalInstallationMaintenanceJSON){
    if (technicalInstallationUUID == ''){
        throw new Error('technicalInstallationUUID is empty')
    }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/technical-installation/"+technicalInstallationUUID+"/technical-installation-maintenance/create", newTechnicalInstallationMaintenanceJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readAllTechnicalInstallationMaintenances(technicalInstallationUUID){
    if (technicalInstallationUUID == ''){
        throw new Error('technicalInstallationUUID is empty')
    }
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/technical-installation/"+technicalInstallationUUID+"/technical-installation-maintenance/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_technical_installation_maintenances
    return responseBody
}


async function deleteTechnicalInstallationMaintenance(technicalInstallationMaintenanceUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/technical-installation-maintenance/" + technicalInstallationMaintenanceUUID)
}

export {
    createTechnicalInstallationMaintenance,
    readAllTechnicalInstallationMaintenances,
    deleteTechnicalInstallationMaintenance
}