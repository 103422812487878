<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLocalLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Techn. Anlagen werden geladen...</h5>
    </div>
    <div
      v-show="isLocalLoaded"
      class="row mb-1"
    >
      <h6>Filter</h6>
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="row mb-3">
            <FieldLabel
              for="filter-type"
              class="col-4 col-form-label"
              label="Typ"
            />
            <div class="col-8">
              <SelectAutocompleteInput
                :name="'filter-type'"
                label="Typ"
                class="form-control" 
                v-model="filterFields.type.value"
                @valid:modelValue="filterFields.type.isValid = true"
                @invalid:modelValue="filterFields.type.isValid = false"
                @reset:modelValue="filterFields.type.isValid = null"
                :options="sortedTechnicalInstallationTypeOptions"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4" v-if="contextType == 'RealEstate'">
          <div class="row mb-3">
            <FieldLabel
              for="filter-type"
              class="col-4 col-form-label"
              label="Einheit"
            />
            <div class="col-8">
              <SelectAutocompleteInput
                :name="'filter-unit'"
                label="Einheit"
                class="form-control" 
                v-model="filterFields.facilityUUID.value"
                :options="sortedRealEstateUnitOptions"
              />
            </div>
          </div>
        </div>
        <div class="col-1 col-xl-3 btn-icon-container">
          <button
            class="btn btn-warn form-control show-xl"
            type="button"
            @click="resetFilters"
          >
            Zurücksetzen
          </button>
          <button
            class="btn btn-icon-warn form-control hide-xl"
            type="button"
            @click="resetFilters"
          >
            <UndoArrowIcon />
          </button>
        </div>
      </div>
    </div>
    <table
      id="technical-installations"
      class="table table-sm table-striped"
      v-show="isLocalLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th style="width:110px">Typ</th>
          <th style="width:100px">Installiert</th>
          <th style="width:100px">Hersteller</th>
          <th style="width:100px">Modell</th>
          <th style="width:100px">Serien-Nr.</th>
          <th style="width:70px" v-if="contextType == 'RealEstate'">Einheit</th>
          <th style="width:40px">Wartbar</th>
          <th style="width:30px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="technicalInstallation in displayTechnicalInstallations"
          :key="technicalInstallation.uuid"
        >
          <td
            label="Typ"
          >
            {{ technicalInstallation.type }}
          </td>
          <td
            label="Installiert"
          >
            {{ formatDate(technicalInstallation.installation_date) }}
          </td>
          <td
            label="Hersteller"
          >
            {{ technicalInstallation.manufacturer }}
          </td>
          <td
            label="Modell"
          >
            {{ technicalInstallation.model }}
          </td>
          <td
            label="Serien-Nr."
          >
            {{ technicalInstallation.serial_number }}
          </td>
          <td
            v-if="contextType == 'RealEstate'"
            label="Einheit"
          >
            <span v-if="technicalInstallation.facility_type == 'RealEstateUnit'">{{ getRealEstateUnitName(technicalInstallation.facility_uuid) }}</span>
          </td>
          <td
            label="Wartbar"
          >
            <CheckBubbleIcon
              v-if="technicalInstallation.is_maintainable"
              class="table-icon"
            />
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditTechnicalInstallation($event, technicalInstallation)"
            >
              Bearbeiten
            </div>
            <div
              class="btn-icon show-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditTechnicalInstallation($event, technicalInstallation)"
            >
              <PencilIcon />
            </div>
          </td>
        </tr>
        <tr class="proto">
          <td
            label="Typ"
          > 
            <StringAutocompleteInput
              name="type"
              label="Typ"
              id="type"
              class="form-control" 
              v-model="protoFields.type.value"
              @valid:modelValue="protoFields.type.isValid = true"
              @invalid:modelValue="protoFields.type.isValid = false"
              @reset:modelValue="protoFields.type.isValid = null"
              :options="sortedTechnicalInstallationTypeOptions"
              mandatory
            />
          </td>
          <td
            label="Installiert"
          >
            <DateInput
              name="installationDate" 
              label="Installiert"
              class="form-control"
              placeholder="01.10.2023"
              v-model="protoFields.installationDate.value"
              @valid:modelValue="protoFields.installationDate.isValid = true"
              @invalid:modelValue="protoFields.installationDate.isValid = false"
              @reset:modelValue="protoFields.installationDate.isValid = null"
            />
          </td>
          <td
            label="Hersteller"
          >
            <StringInput
              name="manufacturer" 
              label="Hersteller"
              class="form-control"
              placeholder="Siemens"
              v-model="protoFields.manufacturer.value"
              @valid:modelValue="protoFields.manufacturer.isValid = true"
              @invalid:modelValue="protoFields.manufacturer.isValid = false"
              @reset:modelValue="protoFields.manufacturer.isValid = null"
              :maxLength="250"
            />
          </td>
          <td
            label="Modell"
          >
            <StringInput
              name="model" 
              label="Modell"
              class="form-control"
              placeholder="RX 512321"
              v-model="protoFields.model.value"
              @valid:modelValue="protoFields.model.isValid = true"
              @invalid:modelValue="protoFields.model.isValid = false"
              @reset:modelValue="protoFields.model.isValid = null"
              :maxLength="250"
            />
          </td>
          <td
            label="Serien-Nr."
          >
            <StringInput
              name="serialNumber" 
              label="Seriennummer"
              class="form-control"
              placeholder="512321-43259802"
              v-model="protoFields.serialNumber.value"
              @valid:modelValue="protoFields.serialNumber.isValid = true"
              @invalid:modelValue="protoFields.serialNumber.isValid = false"
              @reset:modelValue="protoFields.serialNumber.isValid = null"
              :maxLength="250"
            />
          </td>
          <td
            v-if="contextType == 'RealEstate'"
            label="Einheit"
          >
            <SelectAutocompleteInput
                name="protoRealEstateUnitUUID"
                label="Einheit"
                id="protoRealEstateUnitUUID"
                class="form-control" 
                v-model="protoRealEstateUnitUUID"
                :options="sortedRealEstateUnitOptions"
            />
          </td>
          <td
            label="Hauptz."
          >
            <CheckboxButtonInput
              id="isMaintainable-checkbox"
              name="isMaintainable"
              label="Ja"
              v-model="protoFields.isMaintainable.value"
              @valid:modelValue="protoFields.isMaintainable.isValid = true"
              @invalid:modelValue="protoFields.isMaintainable.isValid = false"
            />
          </td>
          <td>
            <button
              class="btn btn-suc hide-md"
              title="Erstellen"
              role="button"
              @click="triggerCreateTechnicalInstallation($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              Erstellen
            </button>
            <button
              class="btn-icon-suc show-md"
              title="Erstellen"
              @click="triggerCreateTechnicalInstallation($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              <PlusIcon />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import TechnicalInstallation from '@/diaspora/models/technical-installation.js'

import { readRelatedRealEstateUnits } from '@/diaspora/models/queries/real-estate-unit.js'

import FieldLabel from  '@/components/labels/FieldLabel.vue'

import StringInput from  '@/components/inputs/StringInput.vue'
import DateInput from  '@/components/inputs/DateInput.vue'
import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'
import StringAutocompleteInput from  '@/components/inputs/StringAutocompleteInput.vue'
import SelectAutocompleteInput from  '@/components/inputs/SelectAutocompleteInput.vue'

import PencilIcon from  '@/components/icons/PencilIcon.vue'
import PlusIcon from  '@/components/icons/PlusIcon.vue'
import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'
import CheckBubbleIcon from  '@/components/icons/CheckBubbleIcon.vue'

export default {
  name: 'TechnicalInstallationTable',
  emits: [
    'triggered:modelEdit',
    'triggered:modelCreate'
  ],
  data(){
    return {
      messageStore: new MessageStore(),
      filterModel: new TechnicalInstallation('RealEstateUnit'),
      protoModel: new TechnicalInstallation(),
      protoRealEstateUnitUUID: '',
      allRealEstateUnits: [],
      isUnitListLoaded: false,
      areAllProtoFieldsValid: false,
    }
  },
  props:{
    technicalInstallations:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    },
    contextType: {
      type: String,
      required: true
    },
    contextUUID: {
      type: String,
      required: true
    },
  },
  computed:{
    isLocalLoaded(){
      if (this.contextType != 'RealEstate'){
        return this.isLoaded
      }
      return this.isUnitListLoaded && this.isLoaded
    },
    filterFields(){
      return this.filterModel.fields
    },
    protoFields(){
      return this.protoModel.fields
    },
    displayTechnicalInstallations(){
      let technicalInstallationSubset = this.technicalInstallations
      technicalInstallationSubset = this.filterTechnicalInstallationsByFilterModel(technicalInstallationSubset)
      technicalInstallationSubset = this.sortTechnicalInstallationsByUnitAndInstallationDate(technicalInstallationSubset)
      return technicalInstallationSubset
    },
    sortedRealEstateUnits(){
      const sorted = this.cloneArray(this.allRealEstateUnits)
      return sorted.sort(this.realEstateUnitNameComparator)
    },
    allTechnicalInstallationTypeOptions(){
      const allTechnicalInstallationTypes = {}
      for (let optionIdx = 0; optionIdx < this.protoFields.type.options.length; optionIdx++) {
        const option = this.protoFields.type.options[optionIdx];
        allTechnicalInstallationTypes[option.value] = true
      }
      for (let installationIdx = 0; installationIdx < this.technicalInstallations.length; installationIdx++) {
        const installation = this.technicalInstallations[installationIdx];
        allTechnicalInstallationTypes[installation.type] = true
      }
      const allTechnicalInstallationTypeOptions = []
      for (const type in allTechnicalInstallationTypes) {
        const typeOption = {
          label: type, 
          value: type,
        }
        allTechnicalInstallationTypeOptions.push(typeOption)
      }
      return allTechnicalInstallationTypeOptions
    },
    sortedTechnicalInstallationTypeOptions(){
      const sorted = this.cloneArray(this.allTechnicalInstallationTypeOptions)
      return sorted.sort(this.optionLabelComparator)
    },
    sortedRealEstateUnitOptions(){
      const sortedUnitOptions = []
      for (let unitIdx = 0; unitIdx < this.sortedRealEstateUnits.length; unitIdx++) {
        const unit = this.sortedRealEstateUnits[unitIdx];
        const unitOption = {
          label: unit.name, 
          value: unit.uuid,
        }
        if (unitOption.label === '' || !unitOption.label){
          continue
        }
        sortedUnitOptions.push(unitOption)
      }
      return sortedUnitOptions
    },
  },
  watch:{
    protoFields: {
      deep:true,
      handler(){
        this.areAllProtoFieldsValid = this.protoModel.areFieldsValid()
      }
    },
    async contextUUID(newUUID){
      if (!newUUID){
        this.allRealEstateUnits = []
        return
      }
      await this.loadRealEstateUnitData()
    },
  },
  async mounted(){
    await this.loadRealEstateUnitData()
  },
  methods:{
    async loadRealEstateUnitData(){      
      this.isUnitListLoaded = false
      await Promise.all([
        this.loadAllRealEstateUnits(),
      ])
      this.isUnitListLoaded = true
    },
    async loadAllRealEstateUnits(){
      if (this.contextType != 'RealEstate') {
        return
      }
      this.allRealEstateUnits = await this.readRelatedRealEstateUnits()
    },
    async readRelatedRealEstateUnits(){
      if (!this.contextType || !this.contextUUID){
        services.$log.warn("MOC0134", "read all real estate units cannot be executed, invalid related uuid / type, skipping")
        return []
      }
      if (this.contextType != 'RealEstate') {
        services.$log.warn("MOC0135", "read all real estate units cannot be executed, invalid context type '"+this.contextType+"', skipping")
        return []
      }
      const messageTarget = `readRelatedRealEstateUnits`
      this.messageStore.flushTarget(messageTarget)

      let allRealEstateUnits = []
      try{
        allRealEstateUnits = await readRelatedRealEstateUnits(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0136", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return false
      }
      return allRealEstateUnits
    },
    isDisplayRealEstateUnit(unitUUID){
      for (let technicalInstallationIdx = 0; technicalInstallationIdx < this.technicalInstallations.length; technicalInstallationIdx++) {
        const displayTechnicalInstallation = this.technicalInstallations[technicalInstallationIdx];
        if (displayTechnicalInstallation.facility_type == 'RealEstateUnit' && displayTechnicalInstallation.facility_uuid === unitUUID){
          return true
        }
      }
      return false
    },
    getRealEstateUnitName(unitUUID){
      for (let unitIdx = 0; unitIdx < this.allRealEstateUnits.length; unitIdx++) {
        const unit = this.allRealEstateUnits[unitIdx];
        if (unit.uuid === unitUUID){
          return unit.name
        }
      }
      return ''
    },
    filterTechnicalInstallationsByFilterModel(technicalInstallationSubset){
      const filterModelJSON = this.filterModel.marshalJSON()
      if (!filterModelJSON){
        return []
      }
      const filteredTechnicalInstallations = technicalInstallationSubset.filter(function(installation){
        if (filterModelJSON.facility_uuid &&
            (installation.facility_type !== 'RealEstateUnit' ||
            filterModelJSON.facility_uuid !== installation.facility_uuid)
          ){
          return false
        }
        if (filterModelJSON.type && filterModelJSON.type !== installation.type){
          return false
        }
        return true
      })
      return filteredTechnicalInstallations
    },
    sortTechnicalInstallationsByUnitAndInstallationDate(technicalInstallationSubset){
      const sorted = this.cloneArray(technicalInstallationSubset)
      return sorted.sort(this.technicalInstallationRealEstateUnitNameComparator)
    },
    triggerEditTechnicalInstallation(e, technicalInstallation){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelEdit', technicalInstallation.uuid)
    },
    triggerCreateTechnicalInstallation(e, technicalInstallation){
      if (e){
        e.preventDefault()
      }
      if (this.protoRealEstateUnitUUID){
        this.$emit('triggered:modelCreate', technicalInstallation, 'RealEstateUnit', this.protoRealEstateUnitUUID)
      } else {
        this.$emit('triggered:modelCreate', technicalInstallation, this.contextType, this.contextUUID)
      }
      this.protoModel = new TechnicalInstallation()
      this.protoRealEstateUnitUUID = ''
    },
    technicalInstallationRealEstateUnitNameComparator(first, second){
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0
      
      if (first.facility_type == 'RealEstateUnit' && second.facility_type != 'RealEstateUnit'){
        return orderSecondBeforeFirst
      }
      if (first.facility_type != 'RealEstateUnit' && second.facility_type == 'RealEstateUnit'){
        return orderFirstBeforeSecond
      }
      if (first.facility_type != 'RealEstateUnit' && second.facility_type != 'RealEstateUnit'){
        return this.dateValueComparator(first.installation_date, second.installation_date)
      }

      const firstName = this.getRealEstateUnitName(first.facility_uuid)
      const secondName = this.getRealEstateUnitName(second.facility_uuid)
      const unitComparatorResult = this.nameValueComparator(firstName, secondName)
      if (unitComparatorResult != orderEqual){
        return unitComparatorResult
      }

      return this.dateValueComparator(first.installation_date, second.installation_date)
    },
    realEstateUnitNameComparator(first, second) {
      return this.nameValueComparator(first.name, second.name)
    },
    optionLabelComparator(first, second) {
      return this.nameValueComparator(first.label, second.label)
    },
    nameValueComparator(firstName, secondName) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!firstName){
        return orderFirstBeforeSecond
      }
      if (!secondName){
        return orderSecondBeforeFirst
      }
      if (firstName < secondName){
        return orderFirstBeforeSecond
      }
      if (firstName > secondName){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    dateValueComparator(firstDate, secondDate){
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0
      
      if (services.$parse.date(firstDate) > services.$parse.date(secondDate)){
        return orderFirstBeforeSecond
      }
      if (services.$parse.date(firstDate) < services.$parse.date(secondDate)){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    formatDate(value){
      return services.$format.date(value)
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    resetFilters(){
      this.filterModel = new TechnicalInstallation('RealEstateUnit')
    },
  },
  components:{
    PencilIcon,
    PlusIcon,
    UndoArrowIcon,
    CheckBubbleIcon,
    FieldLabel,
    StringInput,
    DateInput,
    CheckboxButtonInput,
    StringAutocompleteInput,
    SelectAutocompleteInput,
    FormMessages,
},
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>