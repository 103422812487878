import { services } from '@digiscape/js-core'

async function createServiceProvider(newServiceProviderJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstate'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/service-provider/create", newServiceProviderJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/service-provider/create", newServiceProviderJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'RealEstateOwner'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + relatedUUID+ "/service-provider/create", newServiceProviderJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/service-provider/create", newServiceProviderJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readAllServiceProviders(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/service-provider/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_service_providers
    return responseBody
}

async function readAllServiceProvidersByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstate'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/service-provider/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_service_providers
        return responseBody
    }
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/service-provider/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_service_providers
        return responseBody
    }
    if (relatedType === 'RealEstateOwner'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + relatedUUID+ "/service-provider/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_service_providers
        return responseBody
    }
    return []
}

async function searchServiceProviders(searchText){
    const requestBody = { "search_term": searchText }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/service-provider/search", requestBody)
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.result_service_providers
    return responseBody
}
async function readServiceProvider(serviceProviderUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + serviceProviderUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.service_provider
    return responseBody
}

async function saveServiceProviderField(serviceProviderUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: serviceProviderUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + serviceProviderUUID +"/field", reqBody)
    const responseBody = response.data.service_provider
    return responseBody
}

async function deleteServiceProvider(serviceProviderUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + serviceProviderUUID)
}

async function attachServiceProvider(relatedType, relatedUUID, serviceProviderUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + serviceProviderUUID + "/attach", requestBody)
}

async function detachServiceProvider(relatedType, relatedUUID, serviceProviderUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + serviceProviderUUID + "/detach", requestBody)
}

export {
    createServiceProvider,
    readAllServiceProviders,
    readAllServiceProvidersByRelated,
    searchServiceProviders,
    readServiceProvider,
    saveServiceProviderField,
    deleteServiceProvider,
    attachServiceProvider,
    detachServiceProvider,
}