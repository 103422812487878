<template>
    <label 
        v-html="typeHTML"
    >
    </label>
</template>

<script>
export default {
  name: 'UnitLabel',
  props:{
      type:{
        type: String
      }
  },  
  computed:{
    typeHTML(){
      if (!this.type){
        return ''
      }
      if (this.type === 'sqm'){
        return 'm<sup>2</sup>'
      }
      if (this.type === 'cum'){
        return 'm<sup>3</sup>'
      }
      if (this.type === 'eur'){
        return '€'
      }
      if (this.type === 'usd'){
        return '$'
      }
      if (this.type === 'prc'){
        return '%'
      }
      if (this.type === 'ltr'){
        return 'l'
      }
      if (this.type === 'mjo'){
        return 'MJ'
      }
      if (this.type === 'kwh'){
        return 'kWh'
      }
      return ''
    }
  }
}
</script>