<template>
  <div class="container">
    <UserCreationForm
      v-if="currentComponent == 'UserCreationForm'"
      @success:modelCreation="showUserManagementTable"
      @cancelled:modelCreation="showUserManagementTable"
    />
    <button
      class="btn btn-brand form-control"
      type="button"
      v-if="currentComponent == 'UserManagementTable'"
      @click="showUserCreationForm"
    >
      Benutzer erstellen
    </button>
    <UserManagementTable
      v-if="currentComponent == 'UserManagementTable'"
      @triggered:modelEdit="showUserEditForm"
    />
    <UserEditForm
      v-if="currentComponent == 'UserEditForm'"
      @success:modelEdit="showUserManagementTable"
      @cancelled:modelEdit="showUserManagementTable"
      :user="editUser"
    />
  </div>
</template>

<script>
import UserManagementTable from '@/components/tables/UserManagementTable.vue'

import UserCreationForm from '@/components/forms/UserCreationForm.vue'
import UserEditForm from '@/components/forms/UserEditForm.vue'

export default {
  name: 'UserManagementCollection',
  data(){
    return {
      currentComponent: 'UserManagementTable',
      editUser: null,
    }
  },
  methods:{
    showUserCreationForm(){
      this.currentComponent = 'UserCreationForm'
    },
    showUserManagementTable(){
      this.currentComponent = 'UserManagementTable'
    },
    showUserEditForm(user){
      this.editUser = user
      this.currentComponent = 'UserEditForm'
    }
  },
  components:{
    UserManagementTable,
    UserCreationForm,
    UserEditForm
  }
}
</script>

<style>
</style>
