<template>
  <div id="meter-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Der Zähler wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 style="display:flex">
        Zähler - <span class="text-limit-3 fst-italic txt-sec">{{ localMeter.number }}</span>
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteMeter"
          >
            Löschen
          </button>
        </div>
      </div>
      <div class="row">
        <div 
          class="col-12"
        >
          <MeterFields
            :meter="localMeter"
            @update:modelValue="updateLocalMeter"
            @update:fieldValue="saveMeterField"
            @error:modelFields="modelFieldsErrorHandler"
          />
          <h5>Zählerstände</h5>
          <MeterReadingTable
            id="meter-readings"
            :meterType="localMeterType"
            :meterReadings="sortedMeterReadings"
            :isLoaded="isLoaded"
            @triggered:modelDelete="deleteMeterReading"
            @triggered:modelCreate="createMeterReading"
          />
          <div class="row mb-3 gx-1">
            <div class="col-6">
                <button
                class="btn btn-brand form-control"
                type="button"
                @click="cancelEdit"
                >
                  Abbrechen
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import { readMeter, saveMeterField, deleteMeter } from '@/diaspora/models/queries/meter.js'
import { readAllMeterReadings, createMeterReading, deleteMeterReading } from '@/diaspora/models/queries/meter-reading.js'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import MeterFields from  '@/components/forms/MeterFields.vue'
import MeterReadingTable from  '@/components/tables/MeterReadingTable.vue'

import Meter from '@/diaspora/models/meter.js'
import MeterReading from '@/diaspora/models/meter-reading.js'

export default {
  name: 'MeterEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
  ],
  data(){
    return {
      localMeter: {},
      localMeterReadings: [],
      messageStore: new MessageStore(),
      isLoaded: false,
      shortcutHandlers: {
        "ESCAPE": this.cancelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  props:{
    meterUUID:{
      type: String,
      required: true
    },
  },
  computed:{    
    localMeterIdentifier(){
      let identifier = this.localMeter.number
      if (identifier) {
        return identifier
      }
      return this.meterUUID
    },
    localMeterType(){
      if (this.localMeter.type){
        return this.localMeter.type
      }
      return ''
    },
    sortedMeterReadings(){
      const sorted = this.cloneArray(this.localMeterReadings)
      return sorted.sort(this.meterReadingReadingAtComparator)
    }, 
  },
  async mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadMeter.bind(this))

    await this.reloadMeter()
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async meterUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadMeter()
    },
  },
  methods:{
    async reloadMeter(){
      this.isLoaded = false
      await this.loadMeter(this.meterUUID)
      this.isLoaded = true
    },
    async loadMeter(meterUUID){
      await Promise.all([
        this.readMeter(meterUUID),
        this.readAllMeterReadings(meterUUID),
      ])
    },
    async readMeter(meterUUID){
      const messageTarget = `readMeter`
      this.messageStore.flushTarget(messageTarget)

      let meter = {}
      try{
        meter = await readMeter(meterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0116", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localMeter = meter
      return true
    },
    async readAllMeterReadings(meterUUID){
      const messageTarget = `readAllMeterReadings`
      this.messageStore.flushTarget(messageTarget)

      let meterReadings = []
      try{
        meterReadings = await readAllMeterReadings(meterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0127", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localMeterReadings = meterReadings
      return true
    },
    async deleteMeter(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `deleteMeter.${this.meterUUID}`
      this.messageStore.flushTarget(messageTarget)

      if (!this.meterUUID){
        this.messageStore.error("MOC0117", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0118", "delete meter cannot be executed, invalid uuid")
        return
      }
      const identifier = this.localMeterIdentifier
      const isConfirmed = confirm("Sicher, dass der Zähler '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Der Zähler '"+ identifier +"' wird gelöscht...", messageTarget)

      try{
        await deleteMeter(this.meterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0119", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Zähler '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },
    async saveMeterField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.meterUUID){
        this.messageStore.error("MOC0120", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0121", "delete real estate cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Zähler-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let meter = {}
      try{
        meter = await saveMeterField(this.meterUUID, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0122", requestErr)
        this.messageStore.error(parsedError.code, `Zähler-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Zähler-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new Meter()
      saved.unmarshalJSON(meter)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localMeter[jsonFieldName] = savedValue
      }
    },
    async createMeterReading(meterJSON){
      const messageTarget = `createMeterReading`
      this.messageStore.flushTarget(messageTarget)

      if (!this.meterUUID){
        this.messageStore.error("MOC0132", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0133", "create meter reading cannot be executed, invalid meter uuid")
        return
      }

      this.messageStore.pending('', 'Erstelle einen neuen Zählerstand...', messageTarget)

      try{
        await createMeterReading(this.meterUUID, meterJSON)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0128", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Zählerstand erfolgreich erstellt.", messageTarget)
      
      await this.reloadMeter()
    },
    async deleteMeterReading(meterReadingJSON){
      const messageTarget = `deleteMeterReading`
      this.messageStore.flushTarget(messageTarget)

      if (!meterReadingJSON.uuid){
        this.messageStore.error("MOC0129", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0130", "delete meter reading cannot be executed, invalid uuid")
        return
      }

      const meterReadingMod = new MeterReading()
      meterReadingMod.unmarshalJSON(meterReadingJSON)
      const identifier = meterReadingMod.getIdentifierString()
      const isConfirmed = confirm("Sicher, dass der Zählerstand '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Der Zählerstand vom "+ identifier +" wird gelöscht...", messageTarget)

      try{
        await deleteMeterReading(meterReadingJSON.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0131", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Zählerstand vom "+identifier+" wurde erfolgreich gelöscht.", messageTarget)
      await this.reloadMeter()
    },
    updateLocalMeter(meter){
      if (JSON.stringify(this.localMeter) === JSON.stringify(meter)){
        return
      }
      this.localMeter = meter
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    meterReadingReadingAtComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      const firstReadingAt = services.$parse.timestamp(first.reading_at)
      if (!firstReadingAt || firstReadingAt.getFullYear() < 2000){
        return orderFirstBeforeSecond
      }
      const secondReadingAt = services.$parse.timestamp(second.reading_at)
      if (!secondReadingAt || secondReadingAt.getFullYear() < 2000){
        return orderSecondBeforeFirst
      }
      if (firstReadingAt < secondReadingAt){
        return orderFirstBeforeSecond
      }
      if (firstReadingAt > secondReadingAt){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }      
      this.$emit('cancelled:modelEdit')
    },
  },
  components:{
    MeterFields,
    MeterReadingTable,
    FormMessages,
  }
}
</script>