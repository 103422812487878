<template>
    <label :class="[ validationState, { 'bold': mandatory }]">
        {{ label }}
        <span v-if="mandatory">*</span>
    </label>
</template>


<script>
export default {
  name: 'FieldLabel',
  props:{
      label:{
        type: String,
        required: true,
      },
      mandatory:{
        type: Boolean,
      },
      validationState:{
        type: String,
      }
  },
}
</script>