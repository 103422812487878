<template>
  <div class="container">
    <h2>Immobilienanzeigen</h2>
    <RealEstateListingCollection 
      :prefilterFields="{ publishStatus: [ 'Active', 'Draft'] }"
    />
  </div>
</template>

<script>
import RealEstateListingCollection from '@/components/collections/RealEstateListingCollection.vue'

export default {
  name: 'RealEstateListingLive',
  components:{
    RealEstateListingCollection,
  }
}
</script>

<style>
</style>
