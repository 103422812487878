<template>
  <div class="container">
    <div class="row gx-2">
      <h2>Benutzerverwaltung</h2>
      <UserProfileForm
        class="col-10"
        :class="[ isUserAllowedEditUsers ? 'col-lg-4' : 'col-lg-6' ]"
      />
      <UserManagementCollection
        class="col-12 col-lg-7"
        v-if="isUserAllowedEditUsers"
      />
    </div>
  </div>
</template>

<script>
import UserManagementCollection from '@/components/collections/UserManagementCollection.vue'
import UserProfileForm from '@/components/forms/UserProfileForm.vue'

export default {
  name: 'UserProfile',
  computed: {
    isUserAllowedEditUsers(){
      return this.$store.getters.isUserAllowedEditUsers
    }
  },
  components:{
    UserProfileForm,
    UserManagementCollection
  }
}
</script>

<style>
</style>
