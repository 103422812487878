import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import EmailField from '@/diaspora/fields/email_field.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class ServiceProvider extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            tenantUUID:             new UUIDField('tenantUUID', 'Kunden-UUID'),
            department:             new StringField('department', 'Abteilung', false, [
                new FieldOption('Alle',         'Alle',         true),
                new FieldOption('Vermarktung',  'Vermarktung'),
                new FieldOption('Verwaltung',   'Verwaltung'),
            ]),
            name:                   new StringField('name', 'Bezeichnung', true),
            type:                   new StringField('type', 'Typ', true, [
                new FieldOption('Makler',           'Makler'),
                new FieldOption('Hausverwaltung',   'Hausverwaltung'),
                new FieldOption('Architekt',        'Architekt'),
                new FieldOption('Statiker',         'Statiker'),
                new FieldOption('Energieberater',   'Energieberater'),
                new FieldOption('Dachdecker',       'Dachdecker'),
                new FieldOption('Gerüstbauer',      'Gerüstbauer'),
                new FieldOption('Heizungsbauer',    'Heizungsbauer'),
                new FieldOption('Trockenbauer',     'Trockenbauer'),
                new FieldOption('Elektriker',       'Elektriker'),
                new FieldOption('Maler',            'Maler'),
                new FieldOption('Installateur',     'Installateur'),
                new FieldOption('Rohrreiniger',     'Rohrreiniger'),
                new FieldOption('Gartenbauer',      'Gartenbauer'),
                new FieldOption('Gebäudereiniger',  'Gebäudereiniger'),
                new FieldOption('Schornsteinfeger', 'Schornsteinfeger'),
                new FieldOption('Hausmeister',      'Hausmeister'),
                new FieldOption('Rechtsanwalt',     'Rechtsanwalt'),
                new FieldOption('Notar',            'Notar'),
                new FieldOption('Bauamt',           'Bauamt'),
            ]),
            salutation:             new StringField('salutation', 'Anrede', false, [
                new FieldOption('Herr',  'Herr'),
                new FieldOption('Frau',  'Frau'),
            ]),
            firstName:              new StringField('firstName', 'Vorname'),
            lastName:               new StringField('lastName', 'Nachname'),
            streetName:             new StringField('streetName', 'Straße'),
            streetNumber:           new StringField('streetNumber', 'Haus-Nr'),
            zipCode:                new StringField('zipCode', 'PLZ'),
            city:                   new StringField('city', 'Ort'),
            email:                  new EmailField('email', 'E-Mail'),
            phoneLandline:          new StringField('phoneLandline', 'Telefon-Nr (Festnetz)'),
            phoneMobile:            new StringField('phoneMobile', 'Telefon-Nr (Mobil)'),
            companyName:            new StringField('companyName', 'Unternehmen'),
            companyEmail:           new StringField('companyEmail', 'E-Mail'),
            companyPhoneLandline:   new StringField('companyPhoneLandline', 'Telefon-Nr (Festnetz)'),
            companyPhoneMobile:     new StringField('companyPhoneMobile', 'Telefon-Nr (Mobil)'),
            companyStreetName:      new StringField('companyStreetName', 'Straße'),
            companyStreetNumber:    new StringField('companyStreetNumber', 'Haus-Nr'),
            companyZipCode:         new StringField('companyZipCode', 'PLZ'),
            companyCity:            new StringField('companyCity', 'Ort'),
        }
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default ServiceProvider