<template>
    <fieldset>
      <section
        :id="id+'-fields-main'" 
      >
        <h5
          class="collapsable"
          data-bs-toggle="collapse" 
          :data-bs-target="`#${id}-fields-main-body`"
          :aria-controls="`#${id}-fields-main-body`"
          aria-expanded="true"
        >
          Dienstleister
        </h5>
        <div
          :id="id+'-fields-main-body'"
          class="collapse show"
        >
          <div class="row mb-3">
              <FieldLabel
                :for="id+'-type'"
                class="col-sm-4 col-form-label"
                label="Abteilung"
                :validationState="serviceProviderModel.getFieldValidityIndicatorClass('department')"
              />
              <div class="col-sm-8">
                  <RadioButtonInput
                    :name="id+'-department'" 
                    label="Abteilung"
                    :id="id+'-department'"
                    v-model="fields.department.value"
                    @set:modelValue="fields.department.isValid = true"
                    :options="fields.department.options"
                  />
              </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-type'"
              class="col-sm-4 col-form-label"
              label="Typ"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('type')"
              mandatory
            />
            <div class="col-sm-8">     
                <StringAutocompleteInput
                  name="type"
                  label="Typ"
                  id="type"
                  class="form-control" 
                  v-model="fields.type.value"
                  @valid:modelValue="fields.type.isValid = true"
                  @invalid:modelValue="fields.type.isValid = false"
                  @reset:modelValue="fields.type.isValid = null"
                  :options="fields.type.options"
                  mandatory
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-name'"
              class="col-sm-4 col-form-label"
              label="Bezeichnung"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('name')"
              mandatory
            />
            <div class="col-sm-8">            
              <StringInput
                :name="id+'-name'"
                label="Bezeichnung"
                class="form-control"
                placeholder="Mustermann"
                v-model="fields.name.value"
                @valid:modelValue="fields.name.isValid = true"
                @invalid:modelValue="fields.name.isValid = false"
                @reset:modelValue="fields.name.isValid = null"
                mandatory
              />
            </div>
          </div>
        </div>
      </section>
      <section
        :id="id+'-fields-contact'" 
      >
        <h5
          class="collapsable"
          data-bs-toggle="collapse" 
          :data-bs-target="`#${id}-fields-contact-body`"
          :aria-controls="`#${id}-fields-contact-body`"
          aria-expanded="true"
        >
          Ansprechpartner
        </h5>
        <div
          :id="id+'-fields-contact-body'"
          class="collapse show"
        >
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-salutation'"
              class="col-sm-4 col-form-label"
              label="Anrede"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('salutation')"
            />
            <div class="col-sm-8">
              <SelectInput
                name="salutation" 
                label="Anrede"
                class="form-control" 
                v-model="fields.salutation.value"
                @valid:modelValue="fields.salutation.isValid = true"
                @invalid:modelValue="fields.salutation.isValid = false"
                @reset:modelValue="fields.salutation.isValid = null"
                :options="fields.salutation.options"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-firstName'"
              class="col-sm-4 col-form-label"
              label="Vorname"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('firstName')"
            />
            <div class="col-sm-8">
              <StringInput
                name="firstName" 
                label="Vorname"
                placeholder="Max"
                class="form-control" 
                v-model="fields.firstName.value"
                @valid:modelValue="fields.firstName.isValid = true"
                @invalid:modelValue="fields.firstName.isValid = false"
                @reset:modelValue="fields.firstName.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-lastName'"
              class="col-sm-4 col-form-label"
              label="Nachname"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('lastName')"
            />
            <div class="col-sm-8">
              <StringInput
                name="lastName" 
                label="Nachname"
                placeholder="Mustermann"
                class="form-control" 
                v-model="fields.lastName.value"
                @valid:modelValue="fields.lastName.isValid = true"
                @invalid:modelValue="fields.lastName.isValid = false"
                @reset:modelValue="fields.lastName.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-email'"
              class="col-sm-4 col-form-label"
              label="E-Mail"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('email')"
            />
            <div class="col-sm-8">
              <StringInput
                name="email" 
                label="E-Mail"
                placeholder="info@mustermann.de"
                class="form-control" 
                v-model="fields.email.value"
                @valid:modelValue="fields.email.isValid = true"
                @invalid:modelValue="fields.email.isValid = false"
                @reset:modelValue="fields.email.isValid = null"
                format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-phoneLandline'"
              class="col-sm-4 col-form-label"
              label="Telefon (Festnetz)"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('phoneLandline')"
            />
            <div class="col-sm-8">
              <StringInput
                  name="phoneLandline" 
                  label="Telefon (Festnetz)"
                  placeholder="0631 1234 5678"
                  class="form-control" 
                  v-model="fields.phoneLandline.value"
                  @valid:modelValue="fields.phoneLandline.isValid = true"
                  @invalid:modelValue="fields.phoneLandline.isValid = false"
                  @reset:modelValue="fields.phoneLandline.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-phoneMobile'"
              class="col-sm-4 col-form-label"
              label="Telefon (Mobil)"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('phoneMobile')"
            />
            <div class="col-sm-8">
              <StringInput
                  name="phoneMobile" 
                  label="Telefon (Mobil)"
                  placeholder="01743 1234 5678"
                  class="form-control" 
                  v-model="fields.phoneMobile.value"
                  @valid:modelValue="fields.phoneMobile.isValid = true"
                  @invalid:modelValue="fields.phoneMobile.isValid = false"
                  @reset:modelValue="fields.phoneMobile.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-streetName'"
              class="col-sm-4 col-form-label"
              label="Straße"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('streetName')"
            />  
            <div class="col-sm-8">
              <StringInput
                :name="id+'-streetName'"
                label="Straße"
                class="form-control"
                placeholder="Musterstr."
                v-model="fields.streetName.value"
                @valid:modelValue="fields.streetName.isValid = true"
                @invalid:modelValue="fields.streetName.isValid = false"
                @reset:modelValue="fields.streetName.isValid = null"
                :min-length="3"
              />
            </div>
        </div>
        <div class="row mb-3">
            <FieldLabel
                :for="id+'-streetNumber'"
                class="col-sm-4 col-form-label"
                label="Haus-Nr."
                :validationState="serviceProviderModel.getFieldValidityIndicatorClass('streetNumber')"
            />
            <div class="col-sm-8">
                <StringInput
                    :name="id+'-streetNumber'"
                    label="Haus-Nr."
                    class="form-control"
                    placeholder="2b"
                    v-model="fields.streetNumber.value"
                    @valid:modelValue="fields.streetNumber.isValid = true"
                    @invalid:modelValue="fields.streetNumber.isValid = false"
                    @reset:modelValue="fields.streetNumber.isValid = null"
                    :min-length="1"
                />
            </div>
        </div>
        <div class="row mb-3">                
            <FieldLabel
                :for="id+'-zipCode'"
                class="col-sm-4 col-form-label"
                label="PLZ"
                :validationState="serviceProviderModel.getFieldValidityIndicatorClass('zipCode')"
            />
            <div class="col-sm-8">
                <StringInput
                    :name="id+'-zipCode'"
                    label="PLZ"
                    class="form-control"
                    placeholder="85325"
                    v-model="fields.zipCode.value"
                    @valid:modelValue="fields.zipCode.isValid = true"
                    @invalid:modelValue="fields.zipCode.isValid = false"
                    @reset:modelValue="fields.zipCode.isValid = null"
                    :min-length="3"
                    :max-length="5"
                />
            </div>
        </div>
        <div class="row mb-3">
            <FieldLabel
                :for="id+'-city'"
                class="col-sm-4 col-form-label"
                label="Ort"
                :validationState="serviceProviderModel.getFieldValidityIndicatorClass('city')"
            />
            <div class="col-sm-8">
                <StringInput
                    :name="id+'-city'"
                    label="Ort"
                    class="form-control"
                    placeholder="Musterstadt"
                    v-model="fields.city.value"
                    @valid:modelValue="fields.city.isValid = true"
                    @invalid:modelValue="fields.city.isValid = false"
                    @reset:modelValue="fields.city.isValid = null"
                    :min-length="3"
                />
            </div>
        </div>
        </div>
      </section>
      <section
        :id="id+'-fields-company'" 
      >
        <h5
          class="collapsable"
          data-bs-toggle="collapse" 
          :data-bs-target="`#${id}-fields-company-body`"
          :aria-controls="`#${id}-fields-company-body`"
          aria-expanded="true"
        >
          Unternehmen
        </h5>
        <div
          :id="id+'-fields-company-body'"
          class="collapse show"
        >
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-companyName'"
              class="col-sm-4 col-form-label"
              label="Name"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyName')"
            />
            <div class="col-sm-8">            
              <StringInput
                :name="id+'-companyName'"
                label="Name"
                class="form-control"
                placeholder="Muster GmbH"
                v-model="fields.companyName.value"
                @valid:modelValue="fields.companyName.isValid = true"
                @invalid:modelValue="fields.companyName.isValid = false"
                @reset:modelValue="fields.companyName.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-companyEmail'"
              class="col-sm-4 col-form-label"
              label="E-Mail"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyEmail')"
            />
            <div class="col-sm-8">
              <StringInput
                name="companyEmail" 
                label="E-Mail"
                placeholder="info@mustermann.de"
                class="form-control" 
                v-model="fields.companyEmail.value"
                @valid:modelValue="fields.companyEmail.isValid = true"
                @invalid:modelValue="fields.companyEmail.isValid = false"
                @reset:modelValue="fields.companyEmail.isValid = null"
                format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-companyPhoneLandline'"
              class="col-sm-4 col-form-label"
              label="Telefon (Festnetz)"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyPhoneLandline')"
            />
            <div class="col-sm-8">
              <StringInput
                  name="companyPhoneLandline" 
                  label="Telefon (Festnetz)"
                  placeholder="0631 1234 5678"
                  class="form-control" 
                  v-model="fields.companyPhoneLandline.value"
                  @valid:modelValue="fields.companyPhoneLandline.isValid = true"
                  @invalid:modelValue="fields.companyPhoneLandline.isValid = false"
                  @reset:modelValue="fields.companyPhoneLandline.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-companyPhoneMobile'"
              class="col-sm-4 col-form-label"
              label="Telefon (Mobil)"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyPhoneMobile')"
            />
            <div class="col-sm-8">
              <StringInput
                  name="companyPhoneMobile" 
                  label="Telefon (Mobil)"
                  placeholder="01743 1234 5678"
                  class="form-control" 
                  v-model="fields.companyPhoneMobile.value"
                  @valid:modelValue="fields.companyPhoneMobile.isValid = true"
                  @invalid:modelValue="fields.companyPhoneMobile.isValid = false"
                  @reset:modelValue="fields.companyPhoneMobile.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-companyStreetName'"
              class="col-sm-4 col-form-label"
              label="Straße"
              :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyStreetName')"
            />  
            <div class="col-sm-8">
              <StringInput
                :name="id+'-companyStreetName'"
                label="Straße"
                class="form-control"
                placeholder="Musterstr."
                v-model="fields.companyStreetName.value"
                @valid:modelValue="fields.companyStreetName.isValid = true"
                @invalid:modelValue="fields.companyStreetName.isValid = false"
                @reset:modelValue="fields.companyStreetName.isValid = null"
                :min-length="3"
              />
            </div>
        </div>
        <div class="row mb-3">
            <FieldLabel
                :for="id+'-companyStreetNumber'"
                class="col-sm-4 col-form-label"
                label="Haus-Nr."
                :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyStreetNumber')"
            />
            <div class="col-sm-8">
                <StringInput
                    :name="id+'-companyStreetNumber'"
                    label="Haus-Nr."
                    class="form-control"
                    placeholder="2b"
                    v-model="fields.companyStreetNumber.value"
                    @valid:modelValue="fields.companyStreetNumber.isValid = true"
                    @invalid:modelValue="fields.companyStreetNumber.isValid = false"
                    @reset:modelValue="fields.companyStreetNumber.isValid = null"
                    :min-length="1"
                />
            </div>
        </div>
        <div class="row mb-3">                
            <FieldLabel
                :for="id+'-companyZipCode'"
                class="col-sm-4 col-form-label"
                label="PLZ"
                :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyZipCode')"
            />
            <div class="col-sm-8">
                <StringInput
                    :name="id+'-companyZipCode'"
                    label="PLZ"
                    class="form-control"
                    placeholder="85325"
                    v-model="fields.companyZipCode.value"
                    @valid:modelValue="fields.companyZipCode.isValid = true"
                    @invalid:modelValue="fields.companyZipCode.isValid = false"
                    @reset:modelValue="fields.companyZipCode.isValid = null"
                    :min-length="3"
                    :max-length="5"
                />
            </div>
          </div>
          <div class="row mb-3">
              <FieldLabel
                  :for="id+'-companyCity'"
                  class="col-sm-4 col-form-label"
                  label="Ort"
                  :validationState="serviceProviderModel.getFieldValidityIndicatorClass('companyCity')"
              />
              <div class="col-sm-8">
                  <StringInput
                      :name="id+'-companyCity'"
                      label="Ort"
                      class="form-control"
                      placeholder="Musterstadt"
                      v-model="fields.companyCity.value"
                      @valid:modelValue="fields.companyCity.isValid = true"
                      @invalid:modelValue="fields.companyCity.isValid = false"
                      @reset:modelValue="fields.companyCity.isValid = null"
                      :min-length="3"
                  />
              </div>
          </div>
        </div>
      </section>
    </fieldset>
  </template>
  
  <script>
  import FieldLabel from  '@/components/labels/FieldLabel.vue'
  import StringAutocompleteInput from  '@/components/inputs/StringAutocompleteInput.vue'
  import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
  import StringInput from  '@/components/inputs/StringInput.vue'
  import SelectInput from  '@/components/inputs/SelectInput.vue'
  
  import ServiceProvider from '@/diaspora/models/service-provider.js'
  
  export default {
    name: 'ServiceProviderFields',
    emits: [
      'update:modelValue', 
      'update:fieldValue', 
      'valid:modelFields', 
      'invalid:modelFields',
      'error:modelFields'
    ],
    data(){
      return {
        serviceProviderModel: new ServiceProvider(),
      }
    },
    props:{
      id: {
        type: String,
      },
      serviceProvider:{
        type: Object
      },
    },
    async created(){
      if (this.serviceProvider){
          this.serviceProviderModel.unmarshalJSON(this.serviceProvider)
      }
      this.emitModelFieldValidity()
      
      this.serviceProviderModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
    },
    computed:{
      fields(){
        return this.serviceProviderModel.fields
      },
    },
    watch:{
      fields: {
        deep:true,
        handler(){
            this.emitModelFieldValidity()
            this.$emit('update:modelValue', this.serviceProviderModel.marshalJSON())
        }
      },
      serviceProvider: {
          deep: true,
          handler(){
              if (this.serviceProvider){
                  this.serviceProviderModel.unmarshalJSON(this.serviceProvider)
              }
          }
      },
    },
    methods:{
      async emitUpdateFieldValue(fieldName, oldValue, newValue){
        await this.$nextTick()      
        const isValid = this.fields[fieldName].isValid;
        const fieldLabel = this.fields[fieldName].displayName;
        this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
      },
      emitModelFieldValidity(){        
        const areAllFieldsValid = this.serviceProviderModel.areFieldsValid()
        if (areAllFieldsValid === true) {
          this.$emit('valid:modelFields')
          return
        }
        this.$emit('invalid:modelFields')
      },
    },
    components:{
      FieldLabel,
      StringAutocompleteInput,
      RadioButtonInput,
      StringInput,
      SelectInput,
    }
  }
  </script>