<template>
    <fieldset
        ref="root"
        :class="{'mode-list': isDisplayModeList, 'mode-grid': isDisplayModeGrid }"
    >
        <nav
            v-if="isDisplayModeGrid"
            :id="id+'fields-nav'"
            class="grid-fields-nav"
        >
            <div
                v-for="fieldGroup in displayModeGridFieldGroups"
                :id="'field-group-'+fieldGroup.id"
                :key="fieldGroup.id"
                class="flow-card hl tiny btn centered"
                :class="[
                    { active: selectedFieldGroup == fieldGroup.name }, 
                    getGridFieldGroupValidityClassBackground(fieldGroup),
                    getGridFieldGroupValidityClassBorder(fieldGroup),
                ]"
                @click="selectedFieldGroup = fieldGroup.name"
            >
                <div class="body">
                    <span class="btn-text">{{ fieldGroup.name }}</span>
                    <CheckBubbleFilledIcon
                        v-if="getGridFieldGroupValidity(fieldGroup) == 'success'"
                        class="success"
                    />
                    <WarnTriangleFilledIcon
                        v-if="getGridFieldGroupValidity(fieldGroup) == 'error'"
                        class="error"
                    />
                </div>
            </div>
        </nav>
        <section
            v-show="!isDisplayModeGrid || selectedFieldGroup == 'Objektdaten'"
            :id="id+'-fields-main'" 
        >
            <h5
                class="collapsable"
                data-bs-toggle="collapse" 
                :data-bs-target="`#${id}-fields-main-body`"
                :aria-controls="`#${id}-fields-main-body`"
                aria-expanded="true"
            >
                Objektdaten
            </h5>
            <div
                :id="id+'-fields-main-body'"
                class="collapse show"
            >
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-type'"
                        class="col-sm-4 col-form-label"
                        label="Typ"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('type')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <RadioButtonInput
                            :name="id+'-type'" 
                            label="Typ"
                            :id="id+'-type'"
                            v-model="fields.type.value"
                            @set:modelValue="fields.type.isValid = true"
                            :options="fields.type.options"
                            :disabled="isSyncedToAnyChannel"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-subType'"
                        class="col-sm-4 col-form-label"
                        label="Zusatztyp"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('subType')"
                    />
                    <div class="col-sm-8">            
                        <SelectInput
                            :name="id+'-subType'"
                            label="Zusatztyp"
                            :id="id+'-subType'"
                            class="form-control" 
                            v-model="fields.subType.value"
                            @valid:modelValue="fields.subType.isValid = true"
                            @invalid:modelValue="fields.subType.isValid = false"
                            @reset:modelValue="fields.subType.isValid = null"
                            :options="fields.subType.options"
                            :optionGroup="fields.type.value"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-contractType'"
                        class="col-sm-4 col-form-label"
                        label="Vermarktung"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('contractType')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <RadioButtonInput
                            :name="id+'-contractType'"
                            label="Typ"
                            :id="id+'-contractType'"
                            v-model="fields.contractType.value"
                            @set:modelValue="fields.contractType.isValid = true"
                            :options="fields.contractType.options"
                            :disabled="isSyncedToAnyChannel"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-commercialUse'"
                        class="col-sm-4 col-form-label"
                        label="Gewerbl. Nutzung"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('commercialUse')"
                    />
                    <div class="col-sm-8">
                        <RadioButtonInput
                            :name="id+'-commercialUse'"
                            label="Gewerbl. Nutzung"
                            :id="id+'-contractType'"
                            v-model="fields.commercialUse.value"
                            :options="fields.commercialUse.labels"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section
            v-show="!isDisplayModeGrid || selectedFieldGroup == 'Objektdaten'"
            :id="id+'-fields-address'"
        >
            <h5
                class="collapsable"
                data-bs-toggle="collapse" 
                :data-bs-target="`#${id}-fields-address-body`"
                :aria-controls="`#${id}-fields-address-body`"
                aria-expanded="true"
            >
                Adresse
            </h5>
            <div
                :id="id+'-fields-address-body'"
                class="collapse show"
            >
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-streetName'"
                        class="col-sm-4 col-form-label"
                        label="Straße"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('streetName')"
                        mandatory
                    />  
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-streetName'"
                            label="Straße"
                            class="form-control"
                            placeholder="Musterstr."
                            v-model="fields.streetName.value"
                            @valid:modelValue="fields.streetName.isValid = true"
                            @invalid:modelValue="fields.streetName.isValid = false"
                            @reset:modelValue="fields.streetName.isValid = null"
                            :min-length="3"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-streetNumber'"
                        class="col-sm-4 col-form-label"
                        label="Haus-Nr."
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('streetNumber')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-streetNumber'"
                            label="Haus-Nr."
                            class="form-control"
                            placeholder="2b"
                            v-model="fields.streetNumber.value"
                            @valid:modelValue="fields.streetNumber.isValid = true"
                            @invalid:modelValue="fields.streetNumber.isValid = false"
                            @reset:modelValue="fields.streetNumber.isValid = null"
                            :min-length="1"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">                
                    <FieldLabel
                        :for="id+'-zipCode'"
                        class="col-sm-4 col-form-label"
                        label="PLZ"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('zipCode')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-zipCode'"
                            label="PLZ"
                            class="form-control"
                            placeholder="85325"
                            v-model="fields.zipCode.value"
                            @valid:modelValue="fields.zipCode.isValid = true"
                            @invalid:modelValue="fields.zipCode.isValid = false"
                            @reset:modelValue="fields.zipCode.isValid = null"
                            :min-length="3"
                            :max-length="5"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-city'"
                        class="col-sm-4 col-form-label"
                        label="Ort"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('city')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-city'"
                            label="Ort"
                            class="form-control"
                            placeholder="Musterstadt"
                            v-model="fields.city.value"
                            @valid:modelValue="fields.city.isValid = true"
                            @invalid:modelValue="fields.city.isValid = false"
                            @reset:modelValue="fields.city.isValid = null"
                            :min-length="3"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3" v-if="fields.channelImmoscout24.value">
                    <FieldLabel
                        :for="id+'-immoscout24ShowAddress'"
                        class="col-sm-4 col-form-label"
                        label="Adresse veröff."
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('immoscout24ShowAddress')"
                    />
                    <div class="col-sm-8 input-container">
                        <RadioButtonInput
                            :name="id+'-immoscout24ShowAddress'"
                            label="Adresse veröffentlichen"
                            :id="id+'-immoscout24ShowAddress'"
                            v-model="fields.immoscout24ShowAddress.value"
                            :options="fields.immoscout24ShowAddress.labels"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section
            v-show="!isDisplayModeGrid || selectedFieldGroup == 'Anzeige'"
            :id="id+'-fields-listing'"
        >
            <h5
                class="collapsable"
                data-bs-toggle="collapse" 
                :data-bs-target="`#${id}-fields-listing-body`"
                :aria-controls="`#${id}-fields-listing-body`"
                aria-expanded="true"
            >
                Anzeige
            </h5>
            <div
                :id="id+'-fields-listing-body'"
                class="collapse show"
            >
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-title'"
                        class="col-sm-4 col-form-label"
                        label="Titel"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('title')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-title'"
                            label="Titel"
                            class="form-control"
                            placeholder="Wohnung Saniert..."
                            v-model="fields.title.value"
                            @valid:modelValue="fields.title.isValid = true"
                            @invalid:modelValue="fields.title.isValid = false"
                            @reset:modelValue="fields.title.isValid = null"
                            :maxLength="100"
                            resizable
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-contactUserUUID'"
                        class="col-sm-4 col-form-label"
                        label="Kontaktperson"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('contactUserUUID')"
                    />           
                    <div class="col-sm-8">
                        <SelectInput
                            :name="id+'-contactUserUUID'"
                            label="Kontaktperson"
                            class="form-control" 
                            v-model="fields.contactUserUUID.value"
                            @valid:modelValue="fields.contactUserUUID.isValid = true"
                            @invalid:modelValue="fields.contactUserUUID.isValid = false"
                            @reset:modelValue="fields.contactUserUUID.isValid = null"
                            :options="fields.contactUserUUID.options"
                            mandatory
                        />
                    </div>
                </div>
                <section :id="id+'-fields-price'">
                    <h6
                        class="collapsable"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-price-body`"
                        :aria-controls="`#${id}-fields-price-body`"
                        aria-expanded="true"
                    >
                        Ein- / Ausgaben
                    </h6>
                    <div
                        :id="id+'-fields-price-body'"
                        class="collapse show"
                    >
                        <div class="row mb-3" v-show="isContractTypeSale">
                            <FieldLabel
                                :for="id+'-purchasePrice'"
                                class="col-sm-4 col-form-label"
                                label="Kaufpreis"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('purchasePrice')"
                                mandatory
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-purchasePrice'"
                                    label="Kaufpreis"
                                    class="form-control"
                                    placeholder="203.325,50"
                                    v-model="fields.purchasePrice.value"
                                    @valid:modelValue="fields.purchasePrice.isValid = true"
                                    @invalid:modelValue="fields.purchasePrice.isValid = false"
                                    @reset:modelValue="fields.purchasePrice.isValid = null"
                                    mandatory
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isContractTypeSale">
                            <FieldLabel
                                :for="id+'-monthlyRentIncome'"
                                class="col-sm-4 col-form-label"
                                label="Mieteinnahmen"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyRentIncome')"
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-monthlyRentIncome'"
                                    label="Mieteinnahmen"
                                    class="form-control"
                                    placeholder="650,00"
                                    v-model="fields.monthlyRentIncome.value"
                                    @valid:modelValue="fields.monthlyRentIncome.isValid = true"
                                    @invalid:modelValue="fields.monthlyRentIncome.isValid = false"
                                    @reset:modelValue="fields.monthlyRentIncome.isValid = null"
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isContractTypeSale && isRealEstateListingTypeApartment">
                            <FieldLabel
                                :for="id+'-monthlyHoaFee'"
                                class="col-sm-4 col-form-label"
                                label="Hausgeld"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyHoaFee')"
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-monthlyHoaFee'"
                                    label="Hausgeld"
                                    class="form-control"
                                    placeholder="200,00"
                                    v-model="fields.monthlyHoaFee.value"
                                    @valid:modelValue="fields.monthlyHoaFee.isValid = true"
                                    @invalid:modelValue="fields.monthlyHoaFee.isValid = false"
                                    @reset:modelValue="fields.monthlyHoaFee.isValid = null"
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isContractTypeRental">
                            <FieldLabel
                                :for="id+'-monthlyRentCold'"
                                class="col-sm-4 col-form-label"
                                label="Kaltmiete"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyRentCold')"
                                mandatory
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-monthlyRentCold'"
                                    label="Kaltmiete"
                                    class="form-control"
                                    placeholder="755,50"
                                    v-model="fields.monthlyRentCold.value"
                                    @valid:modelValue="fields.monthlyRentCold.isValid = true"
                                    @invalid:modelValue="fields.monthlyRentCold.isValid = false"
                                    @reset:modelValue="fields.monthlyRentCold.isValid = null"
                                    mandatory
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isContractTypeRental">
                            <FieldLabel
                                :for="id+'-monthlyRentTotal'"
                                class="col-sm-4 col-form-label"
                                label="Gesamtmiete"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyRentTotal')"
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-monthlyRentTotal'"
                                    label="Gesamtmiete"
                                    class="form-control"
                                    placeholder="955,50"
                                    v-model="fields.monthlyRentTotal.value"
                                    @valid:modelValue="fields.monthlyRentTotal.isValid = true"
                                    @invalid:modelValue="fields.monthlyRentTotal.isValid = false"
                                    @reset:modelValue="fields.monthlyRentTotal.isValid = null"
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-monthlyUtilities'"
                                class="col-sm-4 col-form-label"
                                label="Nebenkosten"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyUtilities')"
                            />
                            <div class="col-sm-8 input-container">
                            <FloatInput
                                :name="id+'-monthlyUtilities'"
                                label="Nebenkosten"
                                class="form-control"
                                placeholder="200,00"
                                v-model="fields.monthlyUtilities.value"
                                @valid:modelValue="fields.monthlyUtilities.isValid = true"
                                @invalid:modelValue="fields.monthlyUtilities.isValid = false"
                                @reset:modelValue="fields.monthlyUtilities.isValid = null"
                            />
                            <UnitLabel
                                class="input-placeholder-unit"
                                type="eur"
                            />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isContractTypeRental">
                            <FieldLabel
                                :for="id+'-monthlyHeating'"
                                class="col-sm-4 col-form-label"
                                label="Heizkosten"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyHeating')"
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-monthlyHeating'"
                                    label="Heizkosten"
                                    class="form-control"
                                    placeholder="200,00"
                                    v-model="fields.monthlyHeating.value"
                                    @valid:modelValue="fields.monthlyHeating.isValid = true"
                                    @invalid:modelValue="fields.monthlyHeating.isValid = false"
                                    @reset:modelValue="fields.monthlyHeating.isValid = null"
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isContractTypeRental">
                            <FieldLabel
                                :for="id+'-monthlyHeatingInUtilities'"
                                class="col-sm-4 col-form-label"
                                label="Heizk. in Nebenk."
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyHeatingInUtilities')"
                            />
                            <div class="col-sm-8">
                            <RadioButtonInput
                                :name="id+'-monthlyHeatingInUtilities'"
                                label="Heizk. in Nebenk."
                                :id="id+'-monthlyHeatingInUtilities'"
                                v-model="fields.monthlyHeatingInUtilities.value"
                                :options="monthlyHeatingInUtilitiesOptions"
                                small
                            />
                            </div>
                        </div>
                    </div>
                </section>            
                <section :id="id+'-fields-area'">
                    <h6
                        class="collapsable"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-area-body`"
                        :aria-controls="`#${id}-fields-area-body`"
                        aria-expanded="true"
                    >
                        Flächen
                    </h6>
                    <div
                        :id="id+'-fields-area-body'"
                        class="collapse show"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-spaceLiving'"
                                class="col-sm-4 col-form-label"
                                label="Wohnfläche"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('spaceLiving')"
                                mandatory
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-spaceLiving'"
                                    label="Wohnfläche"
                                    class="form-control"
                                    placeholder="80,00"
                                    v-model="fields.spaceLiving.value"
                                    @valid:modelValue="fields.spaceLiving.isValid = true"
                                    @invalid:modelValue="fields.spaceLiving.isValid = false"
                                    @reset:modelValue="fields.spaceLiving.isValid = null"
                                    mandatory
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="sqm"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-show="isRealEstateListingTypeHouse">
                            <FieldLabel
                                :for="id+'-spaceProperty'"
                                class="col-sm-4 col-form-label"
                                label="Grundstücksfl."
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('spaceProperty')"
                                mandatory
                            />
                            <div class="col-sm-8 input-container">
                            <FloatInput
                                :name="id+'-spaceProperty'"
                                label="Grundstücksfl."
                                class="form-control"
                                placeholder="125,00"
                                v-model="fields.spaceProperty.value"
                                @valid:modelValue="fields.spaceProperty.isValid = true"
                                @invalid:modelValue="fields.spaceProperty.isValid = false"
                                @reset:modelValue="fields.spaceProperty.isValid = null"
                            />
                            <UnitLabel
                                class="input-placeholder-unit"
                                type="sqm"
                            />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-spaceUsable'"
                                class="col-sm-4 col-form-label"
                                label="Nutzfläche"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('spaceUsable')"
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-spaceUsable'"
                                    label="Nutzfläche"
                                    class="form-control"
                                    placeholder="25,00"
                                    v-model="fields.spaceUsable.value"
                                    @valid:modelValue="fields.spaceUsable.isValid = true"
                                    @invalid:modelValue="fields.spaceUsable.isValid = false"
                                    @reset:modelValue="fields.spaceUsable.isValid = null"
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="sqm"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section :id="id+'-fields-details'">
                    <h6
                        class="collapsable"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-details-body`"
                        :aria-controls="`#${id}-fields-details-body`"
                        aria-expanded="true"
                    >
                        Details
                    </h6>
                    <div
                        :id="id+'-fields-details-body'"
                        class="collapse show"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-availability'"
                                class="col-sm-4 col-form-label"
                                label="Verfügbar ab"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('availability')"
                            />
                            <div class="col-sm-8">
                                <StringInput
                                    :name="id+'-availability'"
                                    label="Verfügbar ab"
                                    class="form-control"
                                    placeholder="sofort"
                                    v-model="fields.availability.value"
                                    @valid:modelValue="fields.availability.isValid = true"
                                    @invalid:modelValue="fields.availability.isValid = false"
                                    @reset:modelValue="fields.availability.isValid = null"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-description'"
                                class="col-sm-4 col-form-label"
                                label="Beschreibung"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('description')"
                            />
                            <div class="col-sm-8">
                            <StringInput
                                :name="id+'-description'"
                                label="Beschreibung"
                                class="form-control"
                                placeholder="Außergewöhnliches Einfamilienhaus..."
                                v-model="fields.description.value"
                                @valid:modelValue="fields.description.isValid = true"
                                @invalid:modelValue="fields.description.isValid = false"
                                @reset:modelValue="fields.description.isValid = null"
                                textArea
                            />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-descriptionFurnishings'"
                                class="col-sm-4 col-form-label"
                                label="Ausstattung"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('descriptionFurnishings')"
                            />
                            <div class="col-sm-8">
                                <StringInput
                                    :name="id+'-descriptionFurnishings'"
                                    label="Ausstattung"
                                    class="form-control"
                                    placeholder="Garage, Stellplatz"
                                    v-model="fields.descriptionFurnishings.value"
                                    @valid:modelValue="fields.descriptionFurnishings.isValid = true"
                                    @invalid:modelValue="fields.descriptionFurnishings.isValid = false"
                                    @reset:modelValue="fields.descriptionFurnishings.isValid = null"
                                    textArea
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-descriptionLocation'"
                                class="col-sm-4 col-form-label"
                                label="Lage"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('descriptionLocation')"
                            />
                            <div class="col-sm-8">
                            <StringInput
                                :name="id+'-descriptionLocation'"
                                label="Lage"
                                class="form-control"
                                placeholder="Zentrale Lage Nähe Zentrum..."
                                v-model="fields.descriptionLocation.value"
                                @valid:modelValue="fields.descriptionLocation.isValid = true"
                                @invalid:modelValue="fields.descriptionLocation.isValid = false"
                                @reset:modelValue="fields.descriptionLocation.isValid = null"
                                textArea
                            />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-descriptionOther'"
                                class="col-sm-4 col-form-label"
                                label="Sonstiges"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('descriptionOther')"
                            /> 
                            <div class="col-sm-8">
                            <StringInput
                                :name="id+'-descriptionOther'"
                                label="Sonstiges"
                                class="form-control"
                                placeholder="Vielen Dank für Ihr Interesse..."
                                v-model="fields.descriptionOther.value"
                                @valid:modelValue="fields.descriptionOther.isValid = true"
                                @invalid:modelValue="fields.descriptionOther.isValid = false"
                                @reset:modelValue="fields.descriptionOther.isValid = null"
                                textArea
                            />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-deposit'"
                                class="col-sm-4 col-form-label"
                                label="Kaution"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('deposit')"
                            /> 
                            <div class="col-sm-8">
                            <StringInput
                                :name="id+'-deposit'"
                                label="Kaution"
                                class="form-control"
                                placeholder="Drei Monatsmieten..."
                                v-model="fields.deposit.value"
                                @valid:modelValue="fields.deposit.isValid = true"
                                @invalid:modelValue="fields.deposit.isValid = false"
                                @reset:modelValue="fields.deposit.isValid = null"
                                :maxLength="50"
                            />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <section
            v-show="!isDisplayModeGrid || selectedFieldGroup == 'Details'"
            :id="id+'-fields-misc'"
        >
            <h5
                class="collapsable"
                data-bs-toggle="collapse" 
                :data-bs-target="`#${id}-fields-misc-body`"
                :aria-controls="`#${id}-fields-misc-body`"
                :aria-expanded="isDisplayModeGrid"
            >
                Sonstiges
            </h5>
            <div
                :id="id+'-fields-misc-body'"
                class="collapse show"
            >
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-numberTotalRooms'"
                        class="col-sm-4 col-form-label"
                        label="Anz. Zimmer"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('numberTotalRooms')"
                        mandatory
                    />
                    <div class="col-sm-8">
                        <IntegerInput
                            :name="id+'-numberTotalRooms'"
                            label="Anz. Zimmer"
                            class="form-control"
                            placeholder="5"
                            v-model="fields.numberTotalRooms.value"
                            @valid:modelValue="fields.numberTotalRooms.isValid = true"
                            @invalid:modelValue="fields.numberTotalRooms.isValid = false"
                            @reset:modelValue="fields.numberTotalRooms.isValid = null"
                            mandatory
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-numberBathrooms'"
                        class="col-sm-4 col-form-label"
                        label="Anz. Badezimmer"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('numberBathrooms')"
                    />
                    <div class="col-sm-8">
                        <IntegerInput
                            :name="id+'-numberBathrooms'"
                            label="Anz. Badezimmer"
                            class="form-control"
                            placeholder="2"
                            v-model="fields.numberBathrooms.value"
                            @valid:modelValue="fields.numberBathrooms.isValid = true"
                            @invalid:modelValue="fields.numberBathrooms.isValid = false"
                            @reset:modelValue="fields.numberBathrooms.isValid = null"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-numberBedrooms'"
                        class="col-sm-4 col-form-label"
                        label="Anz. Schlafzimmer"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('numberBedrooms')"
                    />
                    <div class="col-sm-8">
                        <IntegerInput
                            :name="id+'-numberBedrooms'"
                            label="Anz. Schlafzimmer"
                            class="form-control" 
                            placeholder="2"
                            v-model="fields.numberBedrooms.value"
                            @valid:modelValue="fields.numberBedrooms.isValid = true"
                            @invalid:modelValue="fields.numberBedrooms.isValid = false"
                            @reset:modelValue="fields.numberBedrooms.isValid = null"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-numberFloors'"
                        class="col-sm-4 col-form-label"
                        label="Anz. Etagen"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('numberFloors')"
                    />
                    <div class="col-sm-8 input-container">
                        <IntegerInput
                            :name="id+'-numberFloors'"
                            label="Anz. Etagen"
                            class="form-control"
                            placeholder="4"
                            v-model="fields.numberFloors.value"
                            @valid:modelValue="fields.numberFloors.isValid = true"
                            @invalid:modelValue="fields.numberFloors.isValid = false"
                            @reset:modelValue="fields.numberFloors.isValid = null"
                        />
                    </div>
                </div>
                <div class="row mb-3" v-if="isRealEstateListingTypeApartment">
                    <FieldLabel
                        :for="id+'-floor'"
                        class="col-sm-4 col-form-label"
                        label="Etage"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('floor')"
                    />
                    <div class="col-sm-8 input-container">
                        <IntegerInput
                            :name="id+'-floor'"
                            label="Etage"
                            class="form-control"
                            placeholder="2"
                            v-model="fields.floor.value"
                            @valid:modelValue="fields.floor.isValid = true"
                            @invalid:modelValue="fields.floor.isValid = false"
                            @reset:modelValue="fields.floor.isValid = null"
                        />
                    </div>
                </div>            
                <section :id="id+'-fields-furnishings'">
                    <h6
                        class="collapsable"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-furnishings-body`"
                        :aria-controls="`#${id}-fields-furnishings-body`"
                        aria-expanded="true"
                    >
                        Ausstattung
                    </h6>
                    <div
                        :id="id+'-fields-furnishings-body'"
                        class="collapse show"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-condition'"
                                class="col-sm-4 col-form-label"
                                label="Zustand"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('condition')"
                            />
                            <div class="col-sm-8">
                                <SelectInput
                                    :name="id+'-condition'"
                                    label="Zustand"
                                    class="form-control" 
                                    v-model="fields.condition.value"
                                    @valid:modelValue="fields.condition.isValid = true"
                                    @invalid:modelValue="fields.condition.isValid = false"
                                    @reset:modelValue="fields.condition.isValid = null"
                                    :options="fields.condition.options"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-furnishingsQuality'"
                                class="col-sm-4 col-form-label"
                                label="Qualität d. Ausstattung"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('furnishingsQuality')"
                            />
                            <div class="col-sm-8">            
                                <SelectInput
                                    :name="id+'-furnishingsQuality'"
                                    label="Qualität d. Ausstattung"
                                    :id="id+'-furnishingsQuality'"
                                    class="form-control" 
                                    v-model="fields.furnishingsQuality.value"
                                    @valid:modelValue="fields.furnishingsQuality.isValid = true"
                                    @invalid:modelValue="fields.furnishingsQuality.isValid = false"
                                    @reset:modelValue="fields.furnishingsQuality.isValid = null"
                                    :options="fields.furnishingsQuality.options"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-petsAllowed'"
                                class="col-sm-4 col-form-label"
                                label="Haustiere erlaubt"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('petsAllowed')"
                            />
                            <div class="col-sm-8">            
                                <SelectInput
                                    :name="id+'-petsAllowed'"
                                    label="Haustiere erlaubt"
                                    :id="id+'-petsAllowed'"
                                    class="form-control"
                                    v-model="fields.petsAllowed.value"
                                    @valid:modelValue="fields.petsAllowed.isValid = true"
                                    @invalid:modelValue="fields.petsAllowed.isValid = false"
                                    @reset:modelValue="fields.petsAllowed.isValid = null"
                                    :options="fields.petsAllowed.options"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <div>
                                    <CheckboxInput
                                        :id="id+'-withBasement-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withBasement.value"
                                        @valid:modelValue="fields.withBasement.isValid = true"
                                        @invalid:modelValue="fields.withBasement.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withBasement-text'"
                                        :for="id+'-withBasement-checkbox'"
                                        class="form-check-label"
                                        label="Keller"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withBasement')"
                                    />
                                </div>
                                <div>
                                    <CheckboxInput
                                        :id="id+'-withGuestBathroom-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withGuestBathroom.value"
                                        @valid:modelValue="fields.withGuestBathroom.isValid = true"
                                        @invalid:modelValue="fields.withGuestBathroom.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withGuestBathroom-text'"
                                        :for="id+'-withGuestBathroom-checkbox'"
                                        class="form-check-label"
                                        label="Gäste-WC"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withGuestBathroom')"
                                    />       
                                </div>
                                <div>
                                    <CheckboxInput
                                        :id="id+'-withWheelchairAccess-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withWheelchairAccess.value"
                                        @valid:modelValue="fields.withWheelchairAccess.isValid = true"
                                        @invalid:modelValue="fields.withWheelchairAccess.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withWheelchairAccess-text'"
                                        :for="id+'-withWheelchairAccess-checkbox'"
                                        class="form-check-label"
                                        label="Stufenloser Zugang"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withWheelchairAccess')"
                                    />
                                </div>
                                <div v-if="isRealEstateListingTypeApartment"> 
                                    <CheckboxInput
                                        :id="id+'-withBalcony-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withBalcony.value"
                                        @valid:modelValue="fields.withBalcony.isValid = true"
                                        @invalid:modelValue="fields.withBalcony.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withBalcony-text'"
                                        :for="id+'-withBalcony-checkbox'"
                                        class="form-check-label"
                                        label="Balkon/Terrasse"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withBalcony')"
                                    />
                                </div>
                                <div v-if="isRealEstateListingTypeApartment"> 
                                    <CheckboxInput
                                        :id="id+'-withBuiltInKitchen-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withBuiltInKitchen.value"
                                        @valid:modelValue="fields.withBuiltInKitchen.isValid = true"
                                        @invalid:modelValue="fields.withBuiltInKitchen.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withBuiltInKitchen-text'"
                                        :for="id+'-withBuiltInKitchen-checkbox'"
                                        class="form-check-label"
                                        label="Einbauküche"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withBuiltInKitchen')"
                                    />
                                </div>
                                <div v-if="isRealEstateListingTypeApartment"> 
                                    <CheckboxInput
                                        :id="id+'-withElevator-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withElevator.value"
                                        @valid:modelValue="fields.withElevator.isValid = true"
                                        @invalid:modelValue="fields.withElevator.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withElevator-text'"
                                        :for="id+'-withElevator-checkbox'"
                                        class="form-check-label"
                                        label="Personenaufzug"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withElevator')"
                                    />
                                </div>
                            </div>
                            <div class="col">
                                <div v-if="isContractTypeSale">
                                    <CheckboxInput
                                        :id="id+'-withMonumentProtection-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withMonumentProtection.value"
                                        @valid:modelValue="fields.withMonumentProtection.isValid = true"
                                        @invalid:modelValue="fields.withMonumentProtection.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withMonumentProtection-text'"
                                        :for="id+'-withMonumentProtection-checkbox'"
                                        class="form-check-label"
                                        label="Denkmalschutz"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withMonumentProtection')"
                                    />
                                </div>
                                <div v-if="isContractTypeSale">
                                    <CheckboxInput
                                        :id="id+'-alreadyRented-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.alreadyRented.value"
                                        @valid:modelValue="fields.alreadyRented.isValid = true"
                                        @invalid:modelValue="fields.alreadyRented.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-alreadyRented-text'"
                                        :for="id+'-alreadyRented-checkbox'"
                                        class="form-check-label"
                                        label="Vermietet"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('alreadyRented')"
                                    />
                                </div>
                                <div v-if="isContractTypeSale">
                                    <CheckboxInput
                                        :id="id+'-suitableForVacationHome-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.suitableForVacationHome.value"
                                        @valid:modelValue="fields.suitableForVacationHome.isValid = true"
                                        @invalid:modelValue="fields.suitableForVacationHome.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-suitableForVacationHome-text'"
                                        :for="id+'-suitableForVacationHome-checkbox'"
                                        class="form-check-label"
                                        label="Als Ferienhaus geeignet"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('suitableForVacationHome')"
                                    />
                                </div>
                                <div v-if="isRealEstateListingTypeApartment"> 
                                    <CheckboxInput
                                        :id="id+'-withGardenUsage-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withGardenUsage.value"
                                        @valid:modelValue="fields.withGardenUsage.isValid = true"
                                        @invalid:modelValue="fields.withGardenUsage.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withGardenUsage-text'"
                                        :for="id+'-withGardenUsage-checkbox'"
                                        class="form-check-label"
                                        label="Garten/-mitbenutzung"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withGardenUsage')"
                                    />
                                </div>
                                <div v-if="isRealEstateListingTypeApartment"> 
                                    <CheckboxInput
                                        :id="id+'-withSecondarySuite-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.withSecondarySuite.value"
                                        @valid:modelValue="fields.withSecondarySuite.isValid = true"
                                        @invalid:modelValue="fields.withSecondarySuite.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-withSecondarySuite-text'"
                                        :for="id+'-withSecondarySuite-checkbox'"
                                        class="form-check-label"
                                        label="Einliegerwohnung"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('withSecondarySuite')"
                                    />
                                </div>            
                                <div v-if="isRealEstateListingTypeApartment"> 
                                    <CheckboxInput
                                        :id="id+'-suitableForSharedApartment-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.suitableForSharedApartment.value"
                                        @valid:modelValue="fields.suitableForSharedApartment.isValid = true"
                                        @invalid:modelValue="fields.suitableForSharedApartment.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-suitableForSharedApartment-text'"
                                        :for="id+'-suitableForSharedApartment-checkbox'"
                                        class="form-check-label"
                                        label="WG-geeignet"
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('suitableForSharedApartment')"
                                    />
                                </div>
                                <div v-if="isContractTypeRental">
                                    <CheckboxInput
                                        :id="id+'-eligibilityCertificateRequired-checkbox'"
                                        class="form-check-input mx-2"
                                        v-model="fields.eligibilityCertificateRequired.value"
                                        @valid:modelValue="fields.eligibilityCertificateRequired.isValid = true"
                                        @invalid:modelValue="fields.eligibilityCertificateRequired.isValid = false"
                                    />
                                    <FieldLabel
                                        :id="id+'-eligibilityCertificateRequired-text'"
                                        :for="id+'-eligibilityCertificateRequired-checkbox'"
                                        class="form-check-label"
                                        label="Wohnber.-Schein erf."
                                        :validationState="realEstateModel.getFieldValidityIndicatorClass('eligibilityCertificateRequired')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section :id="id+'-fields-construction'">
                    <h6
                        class="collapsable"
                        :class="{ collapsed: !isDisplayModeGrid }"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-construction-body`"
                        :aria-controls="`#${id}-fields-construction-body`"
                        :aria-expanded="isDisplayModeGrid"
                    >
                        Bau / Sanierung
                    </h6>
                    <div
                        :id="id+'-fields-construction-body'"
                        class="collapse"
                        :class="{ show: isDisplayModeGrid }"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-yearOfConstruction'"
                                class="col-sm-4 col-form-label"
                                label="Baujahr"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('yearOfConstruction')"
                            />
                            <div class="col-sm-8">
                                <IntegerInput
                                    :name="id+'-yearOfConstruction'"
                                    label="Baujahr"
                                    class="form-control" 
                                    placeholder="2005"
                                    v-model="fields.yearOfConstruction.value"
                                    @valid:modelValue="fields.yearOfConstruction.isValid = true"
                                    @invalid:modelValue="fields.yearOfConstruction.isValid = false"
                                    @reset:modelValue="fields.yearOfConstruction.isValid = null"
                                    :min-length="4"
                                    :max-length="4"
                                    :disabled="fields.immoscout24ConstructionYearUnknown.value"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-if="fields.channelImmoscout24.value">
                            <FieldLabel
                                :for="id+'-immoscout24ConstructionYearUnknown'"
                                class="col-sm-4 col-form-label"
                                label="Baujahr unbekannt"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('immoscout24ConstructionYearUnknown')"
                            />
                            <div class="col-sm-8 input-container">
                                <RadioButtonInput
                                    :name="id+'-immoscout24ConstructionYearUnknown'"
                                    label="Baujahr unbekannt"
                                    :id="id+'-immoscout24ConstructionYearUnknown'"
                                    v-model="fields.immoscout24ConstructionYearUnknown.value"
                                    :options="fields.immoscout24ConstructionYearUnknown.labels"
                                    :disabled="contractYearValue > 0"
                                />
                            </div>
                        </div>
                        <div class="row mb-3" v-if="isRealEstateListingTypeHouse && isContractTypeSale">
                            <FieldLabel
                                :for="id+'-phaseOfConstruction'"
                                class="col-sm-4 col-form-label"
                                label="Bauphase"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('phaseOfConstruction')"
                            />
                            <div class="col-sm-8">
                                <SelectInput
                                    :name="id+'-phaseOfConstruction'"
                                    label="Bauphase"
                                    class="form-control" 
                                    v-model="fields.phaseOfConstruction.value"
                                    @valid:modelValue="fields.phaseOfConstruction.isValid = true"
                                    @invalid:modelValue="fields.phaseOfConstruction.isValid = false"
                                    @reset:modelValue="fields.phaseOfConstruction.isValid = null"
                                    :options="fields.phaseOfConstruction.options"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-lastRenovationYear'"
                                class="col-sm-4 col-form-label"
                                label="Saniert"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('lastRenovationYear')"
                            />
                            <div class="col-sm-8">
                                <IntegerInput
                                    :name="id+'-lastRenovationYear'"
                                    label="Saniert"
                                    class="form-control" 
                                    placeholder="2010"
                                    v-model="fields.lastRenovationYear.value"
                                    @valid:modelValue="fields.lastRenovationYear.isValid = true"
                                    @invalid:modelValue="fields.lastRenovationYear.isValid = false"
                                    @reset:modelValue="fields.lastRenovationYear.isValid = null"
                                    :min-length="4"
                                    :max-length="4"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h6                
                        class="collapsable"
                        :class="{ collapsed: !isDisplayModeGrid }"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-parking-body`"
                        :aria-controls="`#${id}-fields-parking-body`"
                        :aria-expanded="isDisplayModeGrid"
                    >
                        Stellplätze / Garagen
                    </h6>
                    <div                
                        :id="id+'-fields-parking-body'"
                        class="collapse"
                        :class="{ show: isDisplayModeGrid }"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-numberGarages'"
                                class="col-sm-4 col-form-label"
                                label="Anz. Stellplätze"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('numberGarages')"
                            />
                            <div class="col-sm-8">
                                <IntegerInput
                                    :name="id+'-numberGarages'"
                                    label="Anz. Garagen / Stellpl."
                                    class="form-control"
                                    placeholder="1"
                                    v-model="fields.numberGarages.value"
                                    @valid:modelValue="fields.numberGarages.isValid = true"
                                    @invalid:modelValue="fields.numberGarages.isValid = false"
                                    @reset:modelValue="fields.numberGarages.isValid = null"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-garageType'"
                                class="col-sm-4 col-form-label"
                                label="Stellplatz"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('garageType')"
                            />
                            <div class="col-sm-8">
                            <SelectInput
                                :name="id+'-garageType'"
                                label="Garage / Stellpl."
                                class="form-control" 
                                v-model="fields.garageType.value"
                                @valid:modelValue="fields.garageType.isValid = true"
                                @invalid:modelValue="fields.garageType.isValid = false"
                                @reset:modelValue="fields.garageType.isValid = null"
                                :options="fields.garageType.options"
                            />
                            </div>
                        </div>    
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-monthlyRentGarage'"
                                class="col-sm-4 col-form-label"
                                label="Stellplatzmiete"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('monthlyRentGarage')"
                            />
                            <div class="col-sm-8 input-container">
                                <FloatInput
                                    :name="id+'-monthlyRentGarage'"
                                    label="Stellplatzmiete"
                                    class="form-control"
                                    placeholder="29,50"
                                    v-model="fields.monthlyRentGarage.value"
                                    @valid:modelValue="fields.monthlyRentGarage.isValid = true"
                                    @invalid:modelValue="fields.monthlyRentGarage.isValid = false"
                                    @reset:modelValue="fields.monthlyRentGarage.isValid = null"
                                />
                                <UnitLabel
                                    class="input-placeholder-unit"
                                    type="eur"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section :id="id+'-fields-energy'">
                    <h6                    
                        class="collapsable"
                        :class="{ collapsed: !isDisplayModeGrid }"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-energy-body`"
                        :aria-controls="`#${id}-fields-energy-body`"
                        :aria-expanded="isDisplayModeGrid"
                    >
                        Energie / Heizung
                    </h6>
                    <div
                        :id="id+'-fields-energy-body'"
                        class="collapse"
                        :class="{ show: isDisplayModeGrid }"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-energyCertificateAvailability'"
                                class="col-sm-4 col-form-label"
                                label="Energieausweis Verf."
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('energyCertificateAvailability')"
                            />
                            <div class="col-sm-8">
                                <SelectInput
                                    :name="id+'-energyCertificateAvailability'"
                                    label="Energieausweis Verf."
                                    class="form-control" 
                                    v-model="fields.energyCertificateAvailability.value"
                                    @valid:modelValue="fields.energyCertificateAvailability.isValid = true"
                                    @invalid:modelValue="fields.energyCertificateAvailability.isValid = false"
                                    @reset:modelValue="fields.energyCertificateAvailability.isValid = null"
                                    :options="fields.energyCertificateAvailability.options"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-energySource'"
                                class="col-sm-4 col-form-label"
                                label="Energieträger"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('energySource')"
                            /> 
                            <div class="col-sm-8">
                                <SelectInput
                                    :name="id+'-energySource'"
                                    label="Energieträger"
                                    class="form-control" 
                                    v-model="fields.energySource.value"
                                    @valid:modelValue="fields.energySource.isValid = true"
                                    @invalid:modelValue="fields.energySource.isValid = false"
                                    @reset:modelValue="fields.energySource.isValid = null"
                                    :options="fields.energySource.options"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-heatingType'"
                                class="col-sm-4 col-form-label"
                                label="Heizungsart"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('heatingType')"
                            /> 
                            <div class="col-sm-8">
                                <SelectInput
                                    :name="id+'-heatingType'"
                                    label="Heizungsart"
                                    class="form-control" 
                                    v-model="fields.heatingType.value"
                                    @valid:modelValue="fields.heatingType.isValid = true"
                                    @invalid:modelValue="fields.heatingType.isValid = false"
                                    @reset:modelValue="fields.heatingType.isValid = null"
                                    :options="fields.heatingType.options"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section :id="id+'-fields-commission'">
                    <h6
                        class="collapsable"
                        :class="{ collapsed: !isDisplayModeGrid }"
                        data-bs-toggle="collapse" 
                        :data-bs-target="`#${id}-fields-commission-body`"
                        :aria-controls="`#${id}-fields-commission-body`"
                        :aria-expanded="isDisplayModeGrid"
                    >
                        Makler-Provision
                    </h6>
                    <div
                        :id="id+'-fields-commission-body'"
                        class="collapse"
                        :class="{ show: isDisplayModeGrid }"
                    >
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-commissionAmountGross'"
                                class="col-sm-4 col-form-label"
                                label="Provision"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('commissionAmountGross')"
                            /> 
                            <div class="col-sm-8 input-container">            
                                <StringInput
                                    :name="id+'-commissionAmountGross'"
                                    label="Provision"
                                    class="form-control"
                                    placeholder="2,38 %"
                                    v-model="fields.commissionAmountGross.value"
                                    @valid:modelValue="fields.commissionAmountGross.isValid = true"
                                    @invalid:modelValue="fields.commissionAmountGross.isValid = false"
                                    @reset:modelValue="fields.commissionAmountGross.isValid = null"
                                    :maxLength="100"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <FieldLabel
                                :for="id+'-commissionNote'"
                                class="col-sm-4 col-form-label"
                                label="Hinweis"
                                :validationState="realEstateModel.getFieldValidityIndicatorClass('commissionNote')"
                            /> 
                            <div class="col-sm-8">
                                <StringInput
                                    :name="id+'-commissionNote'"
                                    label="Hinweis"
                                    class="form-control"
                                    placeholder="Sofort fällig..."
                                    v-model="fields.commissionNote.value"
                                    @valid:modelValue="fields.commissionNote.isValid = true"
                                    @invalid:modelValue="fields.commissionNote.isValid = false"
                                    @reset:modelValue="fields.commissionNote.isValid = null"
                                    :maxLength="500"
                                    textArea
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <section
            v-show="!isDisplayModeGrid || selectedFieldGroup == 'Kanäle'"
            :id="id+'-fields-immoscout24'"
        >
            <h5
                class="collapsable"
                :class="{ collapsed: !isDisplayModeGrid }"
                data-bs-toggle="collapse" 
                :data-bs-target="`#${id}-fields-immoscout24-body`"
                :aria-controls="`#${id}-fields-immoscout24-body`"
                :aria-expanded="isDisplayModeGrid"
            >
                Immoscout24
            </h5>
            <div
                :id="id+'-fields-immoscout24-body'"
                class="collapse"
                :class="{ show: isDisplayModeGrid }"
            >
                <div class="row mb-3" v-if="isUserAllowedEditExternalIDs">
                    <FieldLabel
                        :for="id+'-immoscout24ID'"
                        class="col-sm-4 col-form-label"
                        label="ID"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('immoscout24ID')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-immoscout24ID'"
                            label="ID (Immoscout24)"
                            class="form-control"
                            placeholder="1984..."
                            v-model="fields.immoscout24ID.value"
                            @valid:modelValue="fields.immoscout24ID.isValid = true"
                            @invalid:modelValue="fields.immoscout24ID.isValid = false"
                            @reset:modelValue="fields.immoscout24ID.isValid = null"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-immoscout24ExternalID'"
                        class="col-sm-4 col-form-label"
                        label="Objekt-ID"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('immoscout24ExternalID')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-immoscout24ExternalID'"
                            label="Objekt-ID"
                            class="form-control"
                            placeholder="2022..."
                            v-model="fields.immoscout24ExternalID.value"
                            @valid:modelValue="fields.immoscout24ExternalID.isValid = true"
                            @invalid:modelValue="fields.immoscout24ExternalID.isValid = false"
                            @reset:modelValue="fields.immoscout24ExternalID.isValid = null"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-immoscout24GroupID'"
                        class="col-sm-4 col-form-label"
                        label="Gruppen-ID"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('immoscout24GroupID')"
                    />
                    <div class="col-sm-8">
                        <IntegerInput
                            :name="id+'-immoscout24GroupID'"
                            label="Gruppen-ID"
                            class="form-control"
                            placeholder="1"
                            v-model="fields.immoscout24GroupID.value"
                            @valid:modelValue="fields.immoscout24GroupID.isValid = true"
                            @invalid:modelValue="fields.immoscout24GroupID.isValid = false"
                            @reset:modelValue="fields.immoscout24GroupID.isValid = null"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section
            v-show="!isDisplayModeGrid || selectedFieldGroup == 'Kanäle'"
            :id="id+'-fields-bookoo'"
        >
            <h5
                :id="id+'-fields-bookoo-control'"
                class="collapsable"
                :class="{ collapsed: !isDisplayModeGrid }"
                data-bs-toggle="collapse" 
                :data-bs-target="`#${id}-fields-bookoo-body`"
                :aria-controls="`#${id}-fields-bookoo-body`"
                :aria-expanded="isDisplayModeGrid"
            >
                Bookoo
            </h5>
            <div            
                :id="id+'-fields-bookoo-body'"
                class="collapse"
                :class="{ show: isDisplayModeGrid }"
            >
                <div class="row mb-3" v-if="isUserAllowedEditExternalIDs">
                    <FieldLabel
                        :for="id+'-bookooID'"
                        class="col-sm-4 col-form-label"
                        label="ID"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooID')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooID'"
                            label="ID (Bookoo)"
                            class="form-control"
                            placeholder="810..."
                            v-model="fields.bookooID.value"
                            @valid:modelValue="fields.bookooID.isValid = true"
                            @invalid:modelValue="fields.bookooID.isValid = false"
                            @reset:modelValue="fields.bookooID.isValid = null"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-bookooTitle'"
                        class="col-sm-4 col-form-label"
                        :class="{ 'bold':isChannelBookooActive }"
                        label="Titel"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooTitle')"
                        :mandatory="isChannelBookooActive"
                    /> 
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooTitle'" 
                            label="Titel (Bookoo)"
                            class="form-control"
                            placeholder="Newly renovated..."
                            v-model="fields.bookooTitle.value"
                            @valid:modelValue="fields.bookooTitle.isValid = true"
                            @invalid:modelValue="fields.bookooTitle.isValid = false"
                            @reset:modelValue="fields.bookooTitle.isValid = null"
                            :mandatory="isChannelBookooActive"
                            resizable
                            :minLength="5"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <FieldLabel
                        :for="id+'-bookooDescriptionMode'"
                        class="col-sm-4 col-form-label"
                        label="Beschr.-Modus"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionMode')"
                    />   
                    <div class="col-sm-8">
                        <RadioButtonInput
                            :name="id+'-bookooDescriptionMode'" 
                            label="Beschr.-Modus"
                            :id="id+'-bookooDescriptionMode'"
                            v-model="fields.bookooDescriptionMode.value"
                            :options="fields.bookooDescriptionMode.options"
                        />
                    </div>
                </div>
                <div class="row mb-3" v-if="showBookooDescription" v-show="!isBookooEditModeManualOverride">
                    <FieldLabel
                        :for="id+'-bookooDescriptionFreeText'"
                        class="col-sm-4 col-form-label"
                        label="Freitext-Beschr."
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionFreeText')"
                    />   
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescriptionFreeText'"
                            label="Freitext-Beschr."
                            class="form-control"
                            placeholder="Newly renovated..."
                            v-model="fields.bookooDescriptionFreeText.value"
                            @valid:modelValue="fields.bookooDescriptionFreeText.isValid = true"
                            @invalid:modelValue="fields.bookooDescriptionFreeText.isValid = false"
                            @reset:modelValue="fields.bookooDescriptionFreeText.isValid = null"
                            textArea
                        />
                    </div>
                </div>   
                <div class="row mb-3" v-if="showBookooDescription" v-show="!isBookooEditModeManualOverride">
                    <FieldLabel
                        :for="id+'-bookooDescriptionAvailability'"
                        class="col-sm-4 col-form-label"
                        label="Verfügbar ab"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionAvailability')"
                    />   
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescriptionAvailability'"
                            label="Verfügbar ab"
                            class="form-control"
                            placeholder="Now"
                            v-model="fields.bookooDescriptionAvailability.value"
                            @valid:modelValue="fields.bookooDescriptionAvailability.isValid = true"
                            @invalid:modelValue="fields.bookooDescriptionAvailability.isValid = false"
                            @reset:modelValue="fields.bookooDescriptionAvailability.isValid = null"
                            :max-length="50"
                        />
                    </div>
                </div> 
                <div class="row mb-3" v-if="isContractTypeRental && showBookooDescription" v-show="!isBookooEditModeManualOverride">
                    <FieldLabel
                        :for="id+'-bookooDescriptionPetsAllowed'"
                        class="col-sm-4 col-form-label"
                        label="Haustiere erlaubt"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionPetsAllowed')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescriptionPetsAllowed'"
                            label="Haustiere erlaubt"
                            class="form-control"
                            placeholder="Subject to approval"
                            v-model="fields.bookooDescriptionPetsAllowed.value"
                            @valid:modelValue="fields.bookooDescriptionPetsAllowed.isValid = true"
                            @invalid:modelValue="fields.bookooDescriptionPetsAllowed.isValid = false"
                            @reset:modelValue="fields.bookooDescriptionPetsAllowed.isValid = null"
                            :max-length="50"
                        />
                    </div>
                </div>
                <div class="row mb-3" v-if="showBookooDescription" v-show="!isBookooEditModeManualOverride">
                    <FieldLabel
                        :for="id+'-bookooDescriptionCommissionNote'"
                        class="col-sm-4 col-form-label"
                        label="Provision"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionCommissionNote')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescriptionCommissionNote'"
                            label="Provision"
                            class="form-control"
                            placeholder="No"
                            v-model="fields.bookooDescriptionCommissionNote.value"
                            @valid:modelValue="fields.bookooDescriptionCommissionNote.isValid = true"
                            @invalid:modelValue="fields.bookooDescriptionCommissionNote.isValid = false"
                            @reset:modelValue="fields.bookooDescriptionCommissionNote.isValid = null"
                            :max-length="100"
                        />
                    </div>
                </div>
                <div class="row mb-3" v-show="!isBookooEditModeManualOverride">
                    <FieldLabel
                        :for="id+'-bookooDescriptionDistancesText'"
                        class="col-sm-4 col-form-label"
                        label="Fahrzeiten"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionDistancesText')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescriptionDistancesText'"
                            label="Fahrzeiten"
                            class="form-control"
                            placeholder="15 minutes to Ramstein airbase"
                            v-model="fields.bookooDescriptionDistancesText.value"
                            @valid:modelValue="fields.bookooDescriptionDistancesText.isValid = true"
                            @invalid:modelValue="fields.bookooDescriptionDistancesText.isValid = false"
                            @reset:modelValue="fields.bookooDescriptionDistancesText.isValid = null"
                            resizable
                        />
                    </div>
                </div> 
                <div class="row mb-3" v-if="showBookooDescription" v-show="!isBookooEditModeManualOverride">
                    <FieldLabel
                        :for="id+'-bookooDescriptionFooterText'"
                        class="col-sm-4 col-form-label"
                        label="Fußzeile"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescriptionFooterText')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescriptionFooterText'"
                            label="Fußzeile"
                            class="form-control"
                            placeholder="200 €"
                            v-model="fields.bookooDescriptionFooterText.value"
                            @valid:modelValue="fields.bookooDescriptionFooterText.isValid = true"
                            @invalid:modelValue="fields.bookooDescriptionFooterText.isValid = false"
                            @reset:modelValue="fields.bookooDescriptionFooterText.isValid = null"
                            textArea
                        />
                    </div>
                </div> 
                <div class="row mb-3" v-if="showBookooDescription">
                    <FieldLabel
                        :for="id+'-bookooDescription'"
                        class="col-sm-4 col-form-label"
                        label="Anzeige-Text"
                        :validationState="realEstateModel.getFieldValidityIndicatorClass('bookooDescription')"
                    />
                    <div class="col-sm-8">
                        <StringInput
                            :name="id+'-bookooDescription'"
                            label="Finale Beschreibung"
                            class="form-control"
                            placeholder="Newly renovated..."
                            v-model="fields.bookooDescription.value"
                            @valid:modelValue="fields.bookooDescription.isValid = true"
                            @invalid:modelValue="fields.bookooDescription.isValid = false"
                            @reset:modelValue="fields.bookooDescription.isValid = null"
                            :disabled="!isBookooEditModeManualOverride"
                            textArea
                            :numRows="17"
                        />
                    </div>
                </div>
            </div>
        </section>
    </fieldset>
</template>

<script>
import { services } from '@digiscape/js-core'

import FieldOption  from '@/diaspora/fields/field_option.js'
import FieldLabel from  '@/components/labels/FieldLabel.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import IntegerInput from  '@/components/inputs/IntegerInput.vue'
import FloatInput from  '@/components/inputs/FloatInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'
import CheckboxInput from  '@/components/inputs/CheckboxInput.vue'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import UnitLabel from  '@/components/labels/UnitLabel.vue'

import CheckBubbleFilledIcon from  '@/components/icons/CheckBubbleFilledIcon.vue'
import WarnTriangleFilledIcon from  '@/components/icons/WarnTriangleFilledIcon.vue'

import RealEstateListing from '@/diaspora/models/real-estate-listing.js'

import SwipeManager from '@/events/swipe-manager.js'

export default {
  name: 'RealEstateListingFields',
  emits: [
    'update:modelValue', 
    'update:fieldValue', 
    'valid:modelFields', 
    'invalid:modelFields',
    'error:modelFields'
  ],
  data(){
    return {
      realEstateModel: new RealEstateListing(),   
      displayModeGridFieldGroups: [
            {
                id: "main", 
                name: "Objektdaten",
                fields: [
                    'type',
                    'subType',
                    'contractType',
                    'commercialUse',
                    'streetName',
                    'streetNumber',
                    'zipCode',
                    'city',
                    'immoscout24ShowAddress'
                ],
            },
            {
                id: "listing", 
                name: "Anzeige",
                fields: [
                    'title',
                    'contactUserUUID',
                    'purchasePrice',
                    'monthlyRentIncome',
                    'monthlyHoaFee',
                    'monthlyRentCold',
                    'monthlyRentTotal',
                    'monthlyUtilities',
                    'monthlyHeating',
                    'monthlyHeatingInUtilities',
                    'spaceLiving',
                    'spaceProperty',
                    'spaceUsable',
                    'availability',
                    'description',
                    'descriptionFurnishings',
                    'descriptionLocation',
                    'descriptionOther',
                    'deposit'
                ],
            },
            {
                id: "details", 
                name: "Details",
                fields: [
                    'numberTotalRooms',
                    'numberBathrooms',
                    'numberBedrooms',
                    'numberFloors',
                    'floor',
                    'condition',
                    'furnishingsQuality',
                    'petsAllowed',
                    'withBasement',
                    'withGuestBathroom',
                    'withWheelchairAccess',
                    'withBalcony',
                    'withBuiltInKitchen',
                    'withElevator',
                    'withMonumentProtection',
                    'alreadyRented',
                    'suitableForVacationHome',
                    'withGardenUsage',
                    'withSecondarySuite',
                    'suitableForSharedApartment',
                    'eligibilityCertificateRequired',
                    'yearOfConstruction',
                    'immoscout24ConstructionYearUnknown',
                    'phaseOfConstruction',
                    'lastRenovationYear',
                    'numberGarages',
                    'garageType',
                    'monthlyRentGarage',
                    'energyCertificateAvailability',
                    'energySource',
                    'heatingType',
                    'commissionAmountGross',
                    'commissionNote'
                ],
            },
            {
                id: "channels", 
                name: "Kanäle",
                fields: [
                    'immoscout24ID',
                    'immoscout24ExternalID',
                    'immoscout24GroupID',
                    'bookooID',
                    'bookooTitle',
                    'bookooDescriptionMode',
                    'bookooDescriptionFreeText',
                    'bookooDescriptionAvailability',
                    'bookooDescriptionPetsAllowed',
                    'bookooDescriptionCommissionNote',
                    'bookooDescriptionDistancesText',
                    'bookooDescriptionFooterText',
                    'bookooDescription',
                ],
            },
        ],
        selectedFieldGroup: 'Objektdaten',
        swipeManager: new SwipeManager(),
    }
  },
  props:{
    id: {
      type: String,
    },
    realEstate:{
      type: Object
    },
    showBookooDescription:{
        type: Boolean,
        default: true,
    },
    displayMode:{
        type: String,
        default: 'List',
    }
  },
  async created(){
    if (this.realEstate){
        this.realEstateModel.unmarshalJSON(this.realEstate)
    }
    await this.readAllUsers()
    this.emitModelFieldValidity()
    
    this.realEstateModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)

    if (this.displayMode == "Grid"){
        const rootElement = this.$refs.root;
        this.swipeManager = new SwipeManager(rootElement, this.selectPrevFieldGroup.bind(this), this.selectNextFieldGroup.bind(this), 100);
    }
  },
  computed:{
    prevFieldGroupName(){
        if (!this.isDisplayModeGrid){
            return ''
        }
        let prevFieldGroupName = ''
        for (let groupIdx = 0; groupIdx < this.displayModeGridFieldGroups.length; groupIdx++) {
            const fieldGroup = this.displayModeGridFieldGroups[groupIdx];
            if (fieldGroup.name == this.selectedFieldGroup){
                return prevFieldGroupName
            }
            prevFieldGroupName = fieldGroup.name;
        }
        return ''
    },
    nextFieldGroupName(){
        if (!this.isDisplayModeGrid){
            return ''
        }
        let prevFieldGroupName = ''
        for (let groupIdx = 0; groupIdx < this.displayModeGridFieldGroups.length; groupIdx++) {
            const fieldGroup = this.displayModeGridFieldGroups[groupIdx];
            if (prevFieldGroupName == this.selectedFieldGroup){
                return fieldGroup.name;
            }
            prevFieldGroupName = fieldGroup.name;
        }
        return ''
    },
    isDisplayModeList(){
      return this.displayMode === 'List'
    },    
    isDisplayModeGrid(){
      return this.displayMode === 'Grid'
    },
    fields(){
      return this.realEstateModel.fields
    },
    isSyncedToAnyChannel(){
        if (this.fields.bookooID.value){
            return true
        }
        if (this.fields.immoscout24ID.value){
            return true
        }
        return false
    },
    isContractTypeSale(){
      return this.fields.contractType.value === 'Verkauf'
    },
    isContractTypeRental(){
      return this.fields.contractType.value === 'Vermietung'
    },
    isRealEstateListingTypeApartment(){
      return this.fields.type.value === 'Wohnung'
    },
    isRealEstateListingTypeHouse(){
      return this.fields.type.value === 'Haus'
    },
    isBookooEditModeAutoGenerate(){
        return this.fields.bookooDescriptionMode.value === 'AutoGenerate'
    },
    isBookooEditModeManualOverride(){
        return this.fields.bookooDescriptionMode.value === 'ManualOverride'
    },
    isChannelBookooActive(){
        return this.fields.channelBookoo.value
    }, 
    isUserAllowedEditExternalIDs(){
        return this.$store.getters.isUserAllowedEditExternalIDs
    },
    monthlyHeatingInUtilitiesOptions(){
        if (this.fields.monthlyHeating.value == 0.0){
            return this.fields.monthlyHeatingInUtilities.options
        }
        return [
            new FieldOption('Nein', 'Nein', true),
            new FieldOption('Ja',   'Ja'),
        ]
    },
    contractYearValue(){
        return this.fields.yearOfConstruction.value
    }
  },
  watch:{
    fields: {
      deep:true,
      handler(){
          this.emitModelFieldValidity()
          this.$emit('update:modelValue', this.realEstateModel.marshalJSON())
      }
    },
    realEstate: {
        deep: true,
        handler(){
            if (this.realEstate){
                this.realEstateModel.unmarshalJSON(this.realEstate)
            }
        }
    },
    isContractTypeSale(){
      this.resetContractTypeSpecificFields()
    },
    isContractTypeRental(){
      this.resetContractTypeSpecificFields()
    },
    isRealEstateListingTypeApartment(){
      this.resetRealEstateListingTypeSpecificFields()
    },
    isRealEstateListingTypeHouse(){
      this.resetRealEstateListingTypeSpecificFields()
    },
    contractYearValue(){
        this.fields.immoscout24ConstructionYearUnknown.value = false
    },
    isChannelBookooActive(){
        if (this.isChannelBookooActive){
            this.fields.bookooTitle.isMandatory = true
        } else {
            this.fields.bookooTitle.isMandatory = false
        }
    }
  },
  methods:{
    async emitUpdateFieldValue(fieldName, oldValue, newValue){
        await this.$nextTick()      
        const isValid = this.fields[fieldName].isValid;
        const fieldLabel = this.fields[fieldName].displayName;
        this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
    },
    emitModelFieldValidity(){        
        const areAllFieldsValid = this.realEstateModel.areFieldsValid()
        if (areAllFieldsValid === true) {
            this.$emit('valid:modelFields')
            return
        }
        this.$emit('invalid:modelFields')
    },
    resetContractTypeSpecificFields(){
      if (this.isSyncedToAnyChannel){
        return
      }
      if (this.isContractTypeRental){
        this.fields.purchasePrice.value = 0.0
        return
      }
      this.fields.monthlyRentCold.value = 0.0
      this.fields.monthlyRentIncome.value = 0.0
      this.fields.monthlyRentTotal.value = 0.0
      this.fields.monthlyHoaFee.value = 0.0
      this.fields.monthlyHeating.value = 0.0
      this.fields.monthlyHeatingInUtilities.value = false
    },
    resetRealEstateListingTypeSpecificFields(){
      if (this.isSyncedToAnyChannel){
        return
      }
      if (this.isRealEstateListingTypeHouse){
        this.fields.monthlyHoaFee.value = 0.0
        return
      }
      this.fields.spaceProperty.value = 0.0
    },
    async readAllUsers(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/user/all", { 'role': ['user-marketing', 'manager'] })
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0013", requestErr)
        this.$emit('error:modelFields', parsedError.code, parsedError.message)
        return false
      }
      const userOptions = this.parseUserSelectInputOptions(response.data.all_users)
      this.fields.contactUserUUID.options = userOptions
      return true
    },
    parseUserSelectInputOptions(allUsers){
      if (!allUsers){
        return []
      }
      const userOptions = []
      allUsers.forEach(user => {
        if (!user.first_name || !user.last_name){
          return
        }
        userOptions.push({ "label": user.first_name + " " + user.last_name, "value": user.uuid })
      })
      return userOptions
    },
    selectPrevFieldGroup(){
        if (!this.prevFieldGroupName){
            return
        }
        this.selectedFieldGroup = this.prevFieldGroupName
    },
    selectNextFieldGroup(){
        if (!this.nextFieldGroupName){
            return
        }
        this.selectedFieldGroup = this.nextFieldGroupName
    },
    getGridFieldGroupValidityClassBackground(fieldGroup){
        const validity = this.getGridFieldGroupValidity(fieldGroup);
        if (validity == 'success'){
            return 'bg-suc'
        }
        if (validity == 'error'){
            return 'bg-err'
        }
        return ''
    },
    getGridFieldGroupValidityClassBorder(fieldGroup){
        const validity = this.getGridFieldGroupValidity(fieldGroup);
        if (validity == 'success'){
            return 'brd-suc'
        }
        if (validity == 'error'){
            return 'brd-err'
        }
        return 'brd-sec'
    },
    getGridFieldGroupValidity(fieldGroup){
        if (!fieldGroup || !fieldGroup.name){
            return ''
        }
        let hasSuccess = false;
        for (let fieldIdx = 0; fieldIdx < fieldGroup.fields.length; fieldIdx++) {
            const fieldName = fieldGroup.fields[fieldIdx];
            const fieldValidity = this.realEstateModel.getFieldValidityIndicatorClass(fieldName)
            if (fieldValidity == 'error'){
                return 'error'
            }
            if (fieldValidity == 'success'){
                hasSuccess = true;
            }
        }
        if (!hasSuccess){
            return ''
        }
        return 'success'
    }
  },
  components:{
    FieldLabel,
    StringInput,
    IntegerInput,
    FloatInput,
    SelectInput,
    CheckboxInput,
    RadioButtonInput,
    UnitLabel,
    WarnTriangleFilledIcon,
    CheckBubbleFilledIcon,
  }
}
</script>

<style scoped>
.grid-fields-nav{
    background-color: var(--co-bg);
}
#real-estate-fields .form-group{
  margin-top:2em;
}
#real-estate-fields .btn{
  margin-top:4em;
}
#real-estate-fields .form-group label{
  display: block;
  text-align: left;
  margin: 5px 0px;
}
</style>
