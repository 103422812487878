<template>
    <fieldset>
      <section
        :id="id+'-fields-main'" 
      >
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-type'"
              class="col-sm-4 col-form-label"
              label="Typ"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('type')"
              mandatory
          />
          <div class="col-sm-8">
              <StringAutocompleteInput
                  name="type"
                  label="Typ"
                  id="type"
                  class="form-control" 
                  v-model="fields.type.value"
                  @valid:modelValue="fields.type.isValid = true"
                  @invalid:modelValue="fields.type.isValid = false"
                  @reset:modelValue="fields.type.isValid = null"
                  :options="fields.type.options"
                  mandatory
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-installationDate'"
              class="col-sm-4 col-form-label"
              label="Installiert am"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('installationDate')"
          />
          <div class="col-sm-8">
            <DateInput
              name="installationDate" 
              label="Installiert am"
              class="form-control"
              placeholder="01.10.2023"
              v-model="fields.installationDate.value"
              @valid:modelValue="fields.installationDate.isValid = true"
              @invalid:modelValue="fields.installationDate.isValid = false"
              @reset:modelValue="fields.installationDate.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-isMaintainable'"
            class="col-sm-4 col-form-label"
            label="Wartbar"
            validationState="success"
          />
          <div class="col-sm-8">
              <RadioButtonInput
                :name="id+'-isMaintainable'" 
                label="Wartbar"
                :id="id+'-isMaintainable'"
                v-model="fields.isMaintainable.value"
                :options="fields.isMaintainable.labels"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-maintenanceInterval'"
              class="col-sm-4 col-form-label"
              label="Wartungsintervall"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('maintenanceInterval')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="maintenanceInterval" 
                  label="Wartungsintervall"
                  class="form-control"
                  placeholder="Halbjährlich"
                  v-model="fields.maintenanceInterval.value"
                  @valid:modelValue="fields.maintenanceInterval.isValid = true"
                  @invalid:modelValue="fields.maintenanceInterval.isValid = false"
                  @reset:modelValue="fields.maintenanceInterval.isValid = null"
                  :maxLength="250"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-manufacturer'"
              class="col-sm-4 col-form-label"
              label="Hersteller"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('manufacturer')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="manufacturer" 
                  label="Hersteller"
                  class="form-control"
                  placeholder="Siemens"
                  v-model="fields.manufacturer.value"
                  @valid:modelValue="fields.manufacturer.isValid = true"
                  @invalid:modelValue="fields.manufacturer.isValid = false"
                  @reset:modelValue="fields.manufacturer.isValid = null"
                  :maxLength="250"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-model'"
              class="col-sm-4 col-form-label"
              label="Modell"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('model')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="model" 
                  label="Modell"
                  class="form-control"
                  placeholder="RX 512321"
                  v-model="fields.model.value"
                  @valid:modelValue="fields.model.isValid = true"
                  @invalid:modelValue="fields.model.isValid = false"
                  @reset:modelValue="fields.model.isValid = null"
                  :maxLength="250"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-serialNumber'"
              class="col-sm-4 col-form-label"
              label="Seriennummer"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('serialNumber')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="serialNumber" 
                  label="Seriennummer"
                  class="form-control"
                  placeholder="512321-43259802"
                  v-model="fields.serialNumber.value"
                  @valid:modelValue="fields.serialNumber.isValid = true"
                  @invalid:modelValue="fields.serialNumber.isValid = false"
                  @reset:modelValue="fields.serialNumber.isValid = null"
                  :maxLength="250"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-description'"
              class="col-sm-4 col-form-label"
              label="Beschreibung"
              :validationState="technicalInstallationModel.getFieldValidityIndicatorClass('description')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="description" 
                  label="Beschreibung"
                  class="form-control"
                  placeholder="Die Anlage ist..."
                  v-model="fields.description.value"
                  @valid:modelValue="fields.description.isValid = true"
                  @invalid:modelValue="fields.description.isValid = false"
                  @reset:modelValue="fields.description.isValid = null"
                  :maxLength="250"
                  textArea
              />
          </div>
        </div>
    </section>
    </fieldset>
  </template>
  
  <script>
  import FieldLabel from  '@/components/labels/FieldLabel.vue'
  import StringInput from  '@/components/inputs/StringInput.vue'
  import StringAutocompleteInput from  '@/components/inputs/StringAutocompleteInput.vue'
  import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
  import DateInput from  '@/components/inputs/DateInput.vue'
  
  import TechnicalInstallation from '@/diaspora/models/technical-installation.js'
  
  export default {
    name: 'TechnicalInstallationFields',
    emits: [
      'update:modelValue', 
      'update:fieldValue', 
      'valid:modelFields', 
      'invalid:modelFields',
      'error:modelFields'
    ],
    data(){
      return {
        technicalInstallationModel: new TechnicalInstallation(),
      }
    },
    props:{
      id: {
        type: String,
      },
      technicalInstallation:{
        type: Object
      },
    },
    async created(){
      if (this.technicalInstallation){
          this.technicalInstallationModel.unmarshalJSON(this.technicalInstallation)
      }
      this.emitModelFieldValidity()
      
      this.technicalInstallationModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
    },
    computed:{
      fields(){
        return this.technicalInstallationModel.fields
      },
    },
    watch:{
      fields: {
        deep:true,
        handler(){
            this.emitModelFieldValidity()
            this.$emit('update:modelValue', this.technicalInstallationModel.marshalJSON())
        }
      },
      technicalInstallation: {
          deep: true,
          handler(){
              if (this.technicalInstallation){
                  this.technicalInstallationModel.unmarshalJSON(this.technicalInstallation)
              }
          }
      },
    },
    methods:{
      async emitUpdateFieldValue(fieldName, oldValue, newValue){
        await this.$nextTick()      
        const isValid = this.fields[fieldName].isValid;
        const fieldLabel = this.fields[fieldName].displayName;
        this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
      },
      emitModelFieldValidity(){        
        const areAllFieldsValid = this.technicalInstallationModel.areFieldsValid()
        if (areAllFieldsValid === true) {
          this.$emit('valid:modelFields')
          return
        }
        this.$emit('invalid:modelFields')
      },
    },
    components:{
      FieldLabel,
      StringInput,
      StringAutocompleteInput,
      RadioButtonInput,
      DateInput,
    }
  }
  </script>