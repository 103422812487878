class IdleManager{

    constructor(idleIntervalMs = 60000, idleAction = () => {}) {
        this.idleIntervalMs = idleIntervalMs;
        this.idleAction = idleAction;

        const actionExecutor = this.executeIdleAction.bind(this)

        this.timer = setTimeout(actionExecutor)

        const resetExecutor = this.resetTimer.bind(this)
        
        const windowEvents = ['onload']
        windowEvents.forEach(function(eventName){
            window.addEventListener(eventName, resetExecutor, true)
        })

        const documentEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']
        documentEvents.forEach(function(eventName){
            document.addEventListener(eventName, resetExecutor, true)
        })
    }

    resetTimer(){
        clearTimeout(this.timer)
        this.timer = setTimeout(this.executeIdleAction.bind(this), this.idleIntervalMs)
    }

    executeIdleAction(){
        this.idleAction()
        this.resetTimer()
    }
}

export default IdleManager