<template>
    <div id="service-provider-edit">
      <FormMessages
        :allMessages="messageStore.messages"
      />
      <div
        v-show="!isLoaded"
        class="m-1"
      >
        <div class="spinner-grow spinner-lg co-hl" role="status"></div>
        <h4 class="spinner-label spinner-label-lg co-sec">Der Dienstleister wird geladen...</h4>
      </div>
      <div
        v-show="isLoaded"
      >
        <h5 style="display:flex" v-if="contextType === 'RealEstate'">
          <span style="width: 125px;">Objekt</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
        </h5>
        <h5 style="display:flex" v-if="contextType === 'RealEstateUnit'">
          <span style="width: 125px;">Einheit</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
        </h5>
        <h5 style="display:flex" v-if="contextType === 'RealEstateOwner'">
          <span style="width: 125px;">Eigentümer</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
        </h5>
        <h5 style="display:flex">
          <span style="width: 125px;">Dienstleister</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localServiceProviderIdentifier }}</span>
        </h5>
        <div class="row mb-3 gx-1 gy-2">
          <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
            <button
              class="btn btn-sec form-control"
              type="button"
              @click="cancelEdit"
            >
              Zurück
            </button>
          </div>
          <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
            <button
              class="btn btn-warn form-control"
              type="button"
              @click="deleteServiceProvider"
            >
              Löschen
            </button>
          </div>
          <div class="col-6 col-sm-4 col-lg-2 col-xl-1 center-txt">
            <span
              class="btn btn-icon form-control"
              @click="prevModelEdit"
              :class="{ 'disabled': !prevModelUUID }"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
            </span>
            <span
              class="btn btn-icon form-control mx-1"
              @click="nextModelEdit"
              :class="{ 'disabled': !nextModelUUID }"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-2 column-brd-right">
            <div class="row mb-3 sticky-top">
              <div
                class="col-sm-12 centered mb-1"
              >  
                <CheckboxButtonInput
                  name="subViewRealEstates"
                  label="Objekte"
                  :modelValue="currentSubView === 'Objekte'"
                  @click="toggleSubView($event, 'Objekte')"
                />
              </div>
              <div
                class="col-sm-12 centered mb-1"
              >  
                <CheckboxButtonInput
                  name="subViewRealEstateUnits"
                  label="Einheiten"
                  :modelValue="currentSubView === 'Einheiten'"
                  @click="toggleSubView($event, 'Einheiten')"
                />
              </div>
              <div
                class="col-sm-12 centered mb-1"
              >  
                <CheckboxButtonInput
                  name="subViewRealEstateOwners"
                  label="Eigentümer"
                  :modelValue="currentSubView === 'Eigentümer'"
                  @click="toggleSubView($event, 'Eigentümer')"
                />
              </div>
              <div
                class="col-sm-12 centered mb-1"
              >  
                <CheckboxButtonInput
                  name="subViewBankAccounts"
                  label="Konten"
                  :modelValue="currentSubView === 'Konten'"
                  @click="toggleSubView($event, 'Konten')"
                />
              </div>
            </div>
          </div>
          <div 
            class="col-12 col-lg-10"
          >
            <div
              v-show="currentSubView === ''"
            >
              <ServiceProviderFields
                :serviceProvider="localServiceProvider"
                @update:modelValue="updateLocalServiceProvider"
                @update:fieldValue="saveServiceProviderField"
                @error:modelFields="modelFieldsErrorHandler"
              />
              <div class="row mb-3 gx-1">
                <div class="col-6">
                  <button
                    class="btn btn-brand form-control"
                    type="button"
                    @click="cancelEdit"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="currentSubView === 'Objekte'"
            >
              <RealEstateCollection
                :serviceProviderUUID="serviceProviderUUID"
                :isSubView="true"
              />
            </div>
            <div
              v-if="currentSubView === 'Einheiten'"
            >
              <RealEstateUnitCollection
                :serviceProviderUUID="serviceProviderUUID"
                :isSubView="true"
              />
            </div>
            <div
              v-if="currentSubView === 'Eigentümer'"
            >
              <RealEstateOwnerCollection
                :serviceProviderUUID="serviceProviderUUID"
                :isSubView="true"
              />
            </div>
            <div
              v-if="currentSubView === 'Konten'"
            >
              <BankAccountCollection
                :serviceProviderUUID="serviceProviderUUID"
                :isSubView="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { services } from '@digiscape/js-core'
  
  import IdleManager from '@/events/idle-manager.js'
  import ShortcutManager from '@/events/shortcut-manager.js'
  
  import { readRealEstate } from '@/diaspora/models/queries/real-estate.js'
  import { readRealEstateUnit } from '@/diaspora/models/queries/real-estate-unit.js'
  import { readRealEstateOwner } from '@/diaspora/models/queries/real-estate-owner.js'
  import { readServiceProvider, saveServiceProviderField, deleteServiceProvider } from '@/diaspora/models/queries/service-provider.js'
  
  import FormMessages from '@/components/forms/messages/FormMessages.vue'
  import MessageStore from '@/components/forms/messages/message-store.js'

  import BankAccountCollection from '@/components/collections/BankAccountCollection.vue'
  
  import ServiceProviderFields from '@/components/forms/ServiceProviderFields.vue'
  
  import ServiceProvider from '@/diaspora/models/service-provider.js'
  
  import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'
  
  export default {
    name: 'ServiceProviderEditForm',
    emits: [
      'success:modelEdit',
      'success:modelDelete',
      'cancelled:modelEdit',
      'navigate:model',
    ],
    data(){
      return {
        currentSubView: "",
        localServiceProvider: {},
        localContextModel: {},
        messageStore: new MessageStore(),
        isLoaded: false,
        isSubViewLoaded: {
          '': true,
        },
        shortcutHandlers: {
          "ESCAPE": this.cancelEditShortcut.bind(this),
          "CTRL + ARROWLEFT": this.prevModelEditShortcut.bind(this),
          "CTRL + ARROWRIGHT": this.nextModelEditShortcut.bind(this),
        },
        idleManager: new IdleManager(),
        shortcutManager: new ShortcutManager(),
      }
    },
    props:{
      serviceProviderUUID:{
        type: String,
        required: true
      },
      prevModelUUID:{
        type: String
      },
      nextModelUUID:{
        type: String
      },
      contextType:{
        type: String
      },
      contextUUID:{
        type: String
      }
    },
    computed:{
      localServiceProviderIdentifier(){
        let identifier = this.getServiceProviderNameLine()
        if (identifier) {
          return identifier
        }
        return this.localServiceProvider.uuid
      },
      localContextModelIdentifier(){
        if (!this.contextType || !this.contextUUID){
          return ""
        }
        let identifier = this.getPersonCompanyModelNameLine()
        if (identifier && this.contextType === 'RealEstateOwner'){
          return identifier
        }
        identifier = this.getContextModelAddressLine()
        if (identifier && this.contextType === 'RealEstate') {
          return identifier
        }
        if (identifier && this.contextType === 'RealEstateUnit') {
          return identifier + " (" + this.localContextModel.name + ")"
        }
        return this.localContextModel.uuid
      },
    },
    async mounted(){    
      this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
      this.shortcutManager.listen()
  
      this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadServiceProvider.bind(this))
  
      await this.reloadServiceProvider()
    },
    beforeCreate(){
      this.$options.components.RealEstateCollection = require('../collections/RealEstateCollection.vue').default
      this.$options.components.RealEstateUnitCollection = require('../collections/RealEstateUnitCollection.vue').default
      this.$options.components.RealEstateOwnerCollection = require('../collections/RealEstateOwnerCollection.vue').default
    },
    beforeUnmount(){
      this.shortcutManager.destroy()
    },
    watch:{
      async serviceProviderUUID() {
        if (!this.isLoaded){
          return
        }      
        await this.reloadServiceProvider()
      },
    },
    methods:{
      async reloadServiceProvider(){
        this.isLoaded = false
        
        await Promise.all([
          this.loadServiceProvider(this.serviceProviderUUID),
          this.loadRelatedContextModel(this.contextType, this.contextUUID),
        ])
        this.isLoaded = true
      },
      async loadServiceProvider(serviceProviderUUID){
        await Promise.all([
          this.readServiceProvider(serviceProviderUUID),
        ])
      },
      async loadRelatedContextModel(contextType, contextUUID){
        if (!contextType || !contextUUID){
          return
        }
        if (contextType === 'RealEstate'){
          await this.readRealEstate(contextUUID)
          return
        }
        if (contextType === 'RealEstateUnit'){
          await this.readRealEstateUnit(contextUUID)
          return
        }
        if (contextType === 'RealEstateOwner'){
          await this.readRealEstateOwner(contextUUID)
          return
        }
      },
      async readServiceProvider(serviceProviderUUID){
        const messageTarget = `readServiceProvider`
        this.messageStore.flushTarget(messageTarget)
  
        let serviceProvider = {}
        try{
          serviceProvider = await readServiceProvider(serviceProviderUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0147", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return false
        }
  
        this.localServiceProvider = serviceProvider
        return true
      },
      async readRealEstate(realEstateUUID){
        const messageTarget = `readRealEstate`
        this.messageStore.flushTarget(messageTarget)
  
        let realEstate = {}
        try{
          realEstate = await readRealEstate(realEstateUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0148", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return false
        }
  
        this.localContextModel = realEstate
        return true
      },
      async readRealEstateUnit(realEstateUnitUUID){
        const messageTarget = `readRealEstateUnit`
        this.messageStore.flushTarget(messageTarget)
  
        let realEstateUnit = {}
        try{
          realEstateUnit = await readRealEstateUnit(realEstateUnitUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0157", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return false
        }
  
        this.localContextModel = realEstateUnit
        return true
      },
      async readRealEstateOwner(realEstateOwnerUUID){
        const messageTarget = `readRealEstateOwner`
        this.messageStore.flushTarget(messageTarget)
  
        let realEstateOwner = {}
        try{
          realEstateOwner = await readRealEstateOwner(realEstateOwnerUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0158", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return false
        }
  
        this.localContextModel = realEstateOwner
        return true
      },
      async deleteServiceProvider(e){
        if (e){
          e.preventDefault()
        }
  
        const messageTarget = `deleteServiceProvider.${this.localServiceProvider.uuid}`
        this.messageStore.flushTarget(messageTarget)
  
        if (!this.localServiceProvider.uuid){
          this.messageStore.error("MOC0149", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
          services.$log.fatal("MOC0150", "delete real estate owner cannot be executed, invalid uuid")
          return
        }
        const identifier = this.localServiceProviderIdentifier
        const isConfirmed = confirm("Sicher, dass der Dienstleister '"+ identifier +"' gelöscht werden soll?")
        if (!isConfirmed){
          return
        }
        this.messageStore.pending('', "Der Dienstleister '"+ identifier +"' wird gelöscht...", messageTarget)
  
        try{
          await deleteServiceProvider(this.localServiceProvider.uuid)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0151", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Dienstleister '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
        await services.$sleep.seconds(1)
        this.$emit('success:modelDelete')
      },
      async saveServiceProviderField(fieldName, fieldLabel, oldValue, newValue){
        if (!this.isLoaded){
          return
        }
        if (!this.localServiceProvider.uuid){
          this.messageStore.error("MOC0152", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
          services.$log.fatal("MOC0153", "delete real estate cannot be executed, invalid uuid")
          return
        }
        this.messageStore.pending('', `Dienstleister-Feld '${fieldLabel}' wird gespeichert...`, fieldName)
  
        let serviceProvider = {}
        try{
          serviceProvider = await saveServiceProviderField(this.localServiceProvider.uuid, fieldName, oldValue, newValue)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0154", requestErr)
          this.messageStore.error(parsedError.code, `Dienstleister-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
          return
        }
        this.messageStore.success('', `Dienstleister-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
        this.$emit('success:modelEdit')
        
        await this.$nextTick()
  
        const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
        const saved = new ServiceProvider()
        saved.unmarshalJSON(serviceProvider)
        const savedValue = saved.fields[fieldName].value
  
        if (savedValue != newValue){
          this.localServiceProvider[jsonFieldName] = savedValue
        }
      },
      async toggleSubView(e, selectedSubView){
        if (e){
          e.preventDefault()
        }
        if (this.currentSubView == selectedSubView){
          this.currentSubView = ''
          return
        }
        this.currentSubView = selectedSubView
      },
      getServiceProviderNameLine(){
        let namePieces = []
        if (this.localServiceProvider.name){
          namePieces.push(this.localServiceProvider.name)
          namePieces.push(" / ")
        }
        if (this.localServiceProvider.salutation){
          namePieces.push(this.localServiceProvider.salutation)
        }
        if (this.localServiceProvider.first_name){
          namePieces.push(this.localServiceProvider.first_name)
        }
        if (this.localServiceProvider.last_name){
          namePieces.push(this.localServiceProvider.last_name)
        }
        if (namePieces.length > 2 && this.localServiceProvider.company_name){
          namePieces.push(" / ")
        }
        if (this.localServiceProvider.company_name){
          namePieces.push(this.localServiceProvider.company_name)
        }
  
        return namePieces.join(" ")
      },
      getPersonCompanyModelNameLine(){
        let namePieces = []
        if (this.localContextModel.name){
          namePieces.push(this.localContextModel.name)
          namePieces.push(" / ")
        }
        if (this.localContextModel.salutation){
          namePieces.push(this.localContextModel.salutation)
        }
        if (this.localContextModel.first_name){
          namePieces.push(this.localContextModel.first_name)
        }
        if (this.localContextModel.last_name){
          namePieces.push(this.localContextModel.last_name)
        }
        if (namePieces.length > 2 && this.localContextModel.company_name){
          namePieces.push(" / ")
        }
        if (this.localContextModel.company_name){
          namePieces.push(this.localContextModel.company_name)
        }

        return namePieces.join(" ")
      },
      getContextModelAddressLine(){
        let addrPieces = []
        if (this.localContextModel.street_name){
          addrPieces.push(this.localContextModel.street_name)
        }
        if (this.localContextModel.street_number){
          addrPieces.push(this.localContextModel.street_number)
        }
        if (this.localContextModel.zip_code){
          addrPieces.push(this.localContextModel.zip_code)
        }
        if (this.localContextModel.city){
          addrPieces.push(this.localContextModel.city)
        }
        return addrPieces.join(" ")
      },
      updateLocalServiceProvider(serviceProvider){
        if (JSON.stringify(this.localServiceProvider) === JSON.stringify(serviceProvider)){
          return
        }
        this.localServiceProvider = serviceProvider
      },
      modelFieldsErrorHandler(code, message){
        const messageTarget = `modelFieldsErrorHandler`
        this.messageStore.flushTarget(messageTarget)
        this.messageStore.error(code, message, messageTarget)
      },
      cancelEditShortcut(e){
        if (e){
          e.preventDefault()
        }
        this.cancelEdit(e)
      },
      cancelEdit(e){
        if (e){
          e.preventDefault()
        }      
        this.$emit('cancelled:modelEdit')
      },
      prevModelEditShortcut(e){
        if (e){
          e.preventDefault()
        }
        this.prevModelEdit()
      },
      prevModelEdit(e){
        if (e){
          e.preventDefault()
        }
        if (!this.prevModelUUID){
          return
        }      
        this.$emit('navigate:model', this.prevModelUUID)
      },
      nextModelEditShortcut(e){
        if (e){
          e.preventDefault()
        }
        this.nextModelEdit()
      },
      nextModelEdit(e){
        if (e){
          e.preventDefault()
        }
        if (!this.nextModelUUID){
          return
        }      
        this.$emit('navigate:model', this.nextModelUUID)
      }, 
    },
    components:{
      ServiceProviderFields,
      CheckboxButtonInput,
      FormMessages,
      BankAccountCollection,
    }
  }
  </script>