<template>
  <input   
    type="checkbox" 
    :name="name" 
    :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
    v-model.lazy="localValue"
  >
</template>

<script>
export default {
  name: 'CheckboxInput',
  data(){
    return {
      localValue: false,
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: Boolean,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    mandatory:{
      type: Boolean
    }
  },
  emits: ['update:modelValue', 'invalid:modelValue', 'valid:modelValue'],
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (!value){
          this.localValue = false
          return
        }
        this.localValue = value
      }
    },
    localValue(){
      if (this.mandatory === true && this.localValue === false){
        this.isValid = false
        this.$emit('invalid:modelValue', "Das Feld '" + this.label +"' ist ein Pflichtfeld und muss bestätigt werden.")
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  methods:{
    resetIsValid(){
      this.isValid = null
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid var(--co-suc-brd, green);
  background-color: var(--co-suc-bg, lightgreen);
}
.input-invalid{
  border: 1px solid var(--co-err-brd, red);  
  background-color: var(--co-err-bg, lightpink);
}
</style>