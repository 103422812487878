<template>
  <span class="input-container">
      <input
        type="text"
        :name="name"
        :placeholder="placeholder"
        :class="[ this.class, {'input-valid': isValid === true, 'input-invalid': isValid === false }]"
        v-model.lazy="localValue"
        :mandatory="mandatory"
        :disabled="disabled"
        :id="baseID + '-input'"
      >
      <CalendarIcon
        class="input-placeholder-date" 
      />        
  </span>
</template>

<script>
import { services } from '@digiscape/js-core'

import CalendarIcon from '@/components/icons/CalendarIcon.vue'

export default {
  name: 'DateInput',
  data(){
    return {
      localValue: '',
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: String,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "...",
    },
    mandatory:{
      type: Boolean
    },
    id: {
      type: String
    },
    class: {
      type: String
    },
    disabled: {
      type: Boolean
    },
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue', 
    'reset:modelValue'
  ],
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (!value){
          this.localValue = ''
          return
        }
        this.localValue = services.$format.date(value)
      }
    },
    localValue(localValue){
      const validateErr = this.validateInput()
      if (validateErr != ""){
        this.isValid = false
        this.$emit('invalid:modelValue', validateErr)
        return
      }
      if (!localValue){
        this.isValid = null
        this.$emit('update:modelValue', this.localizedStringToDateString(localValue))
        this.$emit('reset:modelValue')
        return
      }
      if (this.isIsoDateStringEqualToFormatted(this.modelValue, localValue)){
        this.isValid = true
        this.$emit('valid:modelValue')
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localizedStringToDateString(localValue))
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  computed:{
    baseID(){
      if (this.id){
        return this.id
      }
      return this.name
    }
  },
  methods:{
    validateInput(){
      if (!this.localValue){
        return ""
      }
      const parsedTime = services.$time.parse(this.localValue)
      if (services.$time.isZero(parsedTime)){
        return "Das Feld '" + this.label +"' darf nur gültige Datumswerte enthalten."
      }
      return ""
    },
    localizedStringToDateString(localizedString){
      if (localizedString == ''){
        return ''
      }
      const parsedTime = services.$time.parse(localizedString)
      return parsedTime.toStringUTC()
    },
    resetIsValid(){
      this.isValid = null
    },
    isIsoDateStringEqualToFormatted(isoDateStr, localDateStr){
      const isoTime = services.$time.parse(isoDateStr)
      const localTime = services.$time.parse(localDateStr)
      return services.$time.isEqual(isoTime, localTime)
    },
  },
  components:{
    CalendarIcon
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>