<template>
    <fieldset>
      <section
        :id="id+'-fields-main'" 
      >
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-name'"
              class="col-sm-4 col-form-label"
              label="Bezeichnung"
              :validationState="bankAccountModel.getFieldValidityIndicatorClass('name')"
              mandatory
          />
          <div class="col-sm-8">
              <StringInput
                  name="name" 
                  label="Bezeichnung"
                  class="form-control"
                  placeholder="Kontenbezeichnung"
                  v-model="fields.name.value"
                  @valid:modelValue="fields.name.isValid = true"
                  @invalid:modelValue="fields.name.isValid = false"
                  @reset:modelValue="fields.name.isValid = null"
                  mandatory
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-iban'"
              class="col-sm-4 col-form-label"
              label="IBAN"
              :validationState="bankAccountModel.getFieldValidityIndicatorClass('iban')"
              mandatory
          />
          <div class="col-sm-8">
              <StringInput
                  name="iban" 
                  label="IBAN"
                  class="form-control"
                  placeholder="DE123..."
                  v-model="fields.iban.value"
                  @valid:modelValue="fields.iban.isValid = true"
                  @invalid:modelValue="fields.iban.isValid = false"
                  @reset:modelValue="fields.iban.isValid = null"
                  format="^[A-Z]{2}\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$"
                  mandatory
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-bankName'"
              class="col-sm-4 col-form-label"
              label="Bank"
              :validationState="bankAccountModel.getFieldValidityIndicatorClass('bankName')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="bankName" 
                  label="Bank"
                  class="form-control"
                  placeholder="Sparkasse"
                  v-model="fields.bankName.value"
                  @valid:modelValue="fields.bankName.isValid = true"
                  @invalid:modelValue="fields.bankName.isValid = false"
                  @reset:modelValue="fields.bankName.isValid = null"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-bic'"
              class="col-sm-4 col-form-label"
              label="BIC"
              :validationState="bankAccountModel.getFieldValidityIndicatorClass('bic')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="bic" 
                  label="BIC"
                  class="form-control"
                  placeholder="MALA..."
                  v-model="fields.bic.value"
                  @valid:modelValue="fields.bic.isValid = true"
                  @invalid:modelValue="fields.bic.isValid = false"
                  @reset:modelValue="fields.bic.isValid = null"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-accountHolderName'"
              class="col-sm-4 col-form-label"
              label="Kontoinhaber"
              :validationState="bankAccountModel.getFieldValidityIndicatorClass('accountHolderName')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="accountHolderName" 
                  label="Kontoinhaber"
                  class="form-control"
                  placeholder="Max Mustermann"
                  v-model="fields.accountHolderName.value"
                  @valid:modelValue="fields.accountHolderName.isValid = true"
                  @invalid:modelValue="fields.accountHolderName.isValid = false"
                  @reset:modelValue="fields.accountHolderName.isValid = null"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-notes'"
              class="col-sm-4 col-form-label"
              label="Notizen"
              :validationState="bankAccountModel.getFieldValidityIndicatorClass('notes')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="notes" 
                  label="Notizen"
                  class="form-control"
                  placeholder="Das Konto ist..."
                  v-model="fields.notes.value"
                  @valid:modelValue="fields.notes.isValid = true"
                  @invalid:modelValue="fields.notes.isValid = false"
                  @reset:modelValue="fields.notes.isValid = null"
                  textArea
              />
          </div>
        </div>
      </section>
    </fieldset>
  </template>
  
  <script>
  import FieldLabel from  '@/components/labels/FieldLabel.vue'
  import StringInput from  '@/components/inputs/StringInput.vue'
  
  import BankAccount from '@/diaspora/models/bank-account.js'
  
  export default {
    name: 'BankAccountFields',
    emits: [
      'update:modelValue', 
      'update:fieldValue', 
      'valid:modelFields', 
      'invalid:modelFields',
      'error:modelFields'
    ],
    data(){
      return {
        bankAccountModel: new BankAccount(),
      }
    },
    props:{
      id: {
        type: String,
      },
      bankAccount:{
        type: Object
      },
    },
    async created(){
      if (this.bankAccount){
          this.bankAccountModel.unmarshalJSON(this.bankAccount)
      }
      this.emitModelFieldValidity()
      
      this.bankAccountModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
    },
    computed:{
      fields(){
        return this.bankAccountModel.fields
      },
    },
    watch:{
      fields: {
        deep:true,
        handler(){
            this.emitModelFieldValidity()
            this.$emit('update:modelValue', this.bankAccountModel.marshalJSON())
        }
      },
      bankAccount: {
          deep: true,
          handler(){
              if (this.bankAccount){
                  this.bankAccountModel.unmarshalJSON(this.bankAccount)
              }
          }
      },
    },
    methods:{
      async emitUpdateFieldValue(fieldName, oldValue, newValue){
        await this.$nextTick()      
        const isValid = this.fields[fieldName].isValid;
        const fieldLabel = this.fields[fieldName].displayName;
        this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
      },
      emitModelFieldValidity(){        
        const areAllFieldsValid = this.bankAccountModel.areFieldsValid()
        if (areAllFieldsValid === true) {
          this.$emit('valid:modelFields')
          return
        }
        this.$emit('invalid:modelFields')
      },
    },
    components:{
      FieldLabel,
      StringInput,
    }
  }
  </script>