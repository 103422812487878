<template>
  <div id="real-estate-unit-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Die Einheit wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 style="display:flex" v-if="contextType === 'RealEstateRenter'">
        <span style="width: 150px;">Mieter</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
      </h5>
      <h5 style="display:flex" v-if="contextType === 'ServiceProvider'">
        <span style="width: 150px;">Dienstleister</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
      </h5>
      <h5 style="display:flex">
        <span style="width: 150px;">Einheit</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">
          {{ localRealEstateUnit.zip_code }} {{ localRealEstateUnit.city }}, 
          <span class="text-limit-2">{{ localRealEstateUnit.street_name }}</span>
          {{ localRealEstateUnit.street_number }}
          (<span class="text-limit-2">{{ localRealEstateUnit.name }}</span>)
        </span>
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteRealEstateUnit"
          >
            Löschen
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1 center-txt">
          <span
            class="btn btn-icon form-control"
            @click="prevModelEdit"
            :class="{ 'disabled': !prevModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </span>
          <span
            class="btn btn-icon form-control mx-1"
            @click="nextModelEdit"
            :class="{ 'disabled': !nextModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-2 column-brd-right">
          <div class="row mb-3 sticky-top">  
            <div
              class="col-sm-12 centered mb-1"
            >  
              <button
                class="btn btn-sec form-control"
                style="max-width: 150px;"
                type="button"
                @click="navigateToRealEstate"
              >
                Zum Objekt
              </button>
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >
              <CheckboxButtonInput
                name="subViewRealEstateRenters"
                label="Mieter"
                :modelValue="currentSubView === 'Mieter'"
                @click="toggleSubView($event, 'Mieter')"
              />
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >
              <CheckboxButtonInput
                name="subViewMeters"
                label="Zähler"
                :modelValue="currentSubView === 'Zähler'"
                @click="toggleSubView($event, 'Zähler')"
              />
            </div>  
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewTechnicalInstallations"
                label="Techn. Anlagen"
                :modelValue="currentSubView === 'Techn. Anlagen'"
                @click="toggleSubView($event, 'Techn. Anlagen')"
              />
            </div>   
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewServiceProviders"
                label="Dienstleister"
                :modelValue="currentSubView === 'Dienstleister'"
                @click="toggleSubView($event, 'Dienstleister')"
              />
            </div>         
          </div>
        </div>
        <div 
          class="col-12 col-lg-10"
        >
          <div
            v-show="currentSubView === ''"
          >
            <RealEstateUnitFields
              :realEstateUnit="localRealEstateUnit"
              @update:modelValue="updateLocalRealEstateUnit"
              @update:fieldValue="saveRealEstateUnitField"
              @error:modelFields="modelFieldsErrorHandler"
            />
            <RentalContractFields
              v-if="contextType === 'RealEstateRenter'"
              :rentalContract="localRentalContract"
              @update:modelValue="updateLocalRentalContract"
              @update:fieldValue="saveRentalContractField"
              @error:modelFields="modelFieldsErrorHandler"
            />
            <div class="row mb-3 gx-1">
              <div class="col-6">
                <button
                  class="btn btn-brand form-control"
                  type="button"
                  @click="cancelEdit"
                >
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="currentSubView === 'Mieter'"
          >
            <RealEstateRenterCollection
              :realEstateUnitUUID="realEstateUnitUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Zähler'"
          >
            <MeterCollection
              :realEstateUnitUUID="realEstateUnitUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Techn. Anlagen'"
          >
            <TechnicalInstallationCollection
              :realEstateUnitUUID="realEstateUnitUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Dienstleister'"
          >
            <ServiceProviderCollection
              :realEstateUnitUUID="realEstateUnitUUID"
              :isSubView="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import { readRealEstateUnit, saveRealEstateUnitField, deleteRealEstateUnit } from '@/diaspora/models/queries/real-estate-unit.js'
import { readServiceProvider } from '@/diaspora/models/queries/service-provider.js'
import { readRealEstateRenter } from '@/diaspora/models/queries/real-estate-renter.js'
import { readRentalContractByRelated, saveRentalContractField } from '@/diaspora/models/queries/rental-contract.js'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import MeterCollection                  from  '@/components/collections/MeterCollection.vue'
import TechnicalInstallationCollection  from  '@/components/collections/TechnicalInstallationCollection.vue'

import RealEstateUnitFields from  '@/components/forms/RealEstateUnitFields.vue'
import RentalContractFields from '@/components/forms/RentalContractFields.vue'

import RealEstateUnit from '@/diaspora/models/real-estate-unit.js'
import RentalContract from '@/diaspora/models/rental-contract.js'

import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'

export default {
  name: 'RealEstateUnitEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
    'navigate:model',
  ],
  data(){
    return {
      currentSubView: "",
      localRealEstateUnit: {},
      localContextModel: {},
      localRentalContract: {},
      messageStore: new MessageStore(),
      isLoaded: false,
      isSubViewLoaded: {
        '': true,
      },
      shortcutHandlers: {
        "ESCAPE": this.cancelEditShortcut.bind(this),
        "CTRL + ARROWLEFT": this.prevModelEditShortcut.bind(this),
        "CTRL + ARROWRIGHT": this.nextModelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  props:{
    realEstateUnitUUID:{
      type: String,
      required: true
    },
    prevModelUUID:{
      type: String
    },
    nextModelUUID:{
      type: String
    },
    contextType:{
      type: String
    },
    contextUUID:{
      type: String
    }
  },
  computed:{
    localRealEstateUnitIdentifier(){
      let identifier = this.getRealEstateUnitAddressLine()
      if (identifier) {
        return identifier
      }
      identifier = this.localRealEstateUnit.short_code
      if (identifier) {
        return identifier
      }
      return this.localRealEstateUnit.uuid
    },
    localContextModelIdentifier(){
      if (!this.contextType || !this.contextUUID){
        return ""
      }
      let identifier = this.getPersonCompanyModelNameLine()
      if (identifier && this.contextType === 'ServiceProvider'){
        return identifier
      }
      identifier = this.getPersonModelNameLine()
      if (identifier && this.contextType === 'RealEstateRenter') {
        return identifier
      }
      return this.localContextModel.uuid
    },
    realEstateUUID(){
      return this.localRealEstateUnit.real_estate_uuid
    }
  },
  async mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadRealEstateUnit.bind(this))

    await this.reloadRealEstateUnit()
  },
  beforeCreate(){
    this.$options.components.RealEstateRenterCollection = require('../collections/RealEstateRenterCollection.vue').default
    this.$options.components.ServiceProviderCollection = require('../collections/ServiceProviderCollection.vue').default
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async realEstateUnitUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadRealEstateUnit()
    },
  },
  methods:{
    async reloadRealEstateUnit(){
      this.isLoaded = false
      await Promise.all([
        this.loadRealEstateUnit(this.realEstateUnitUUID),
        this.loadRelatedContextModel(this.contextType, this.contextUUID),

      ])
      this.isLoaded = true
    },
    async loadRealEstateUnit(realEstateUnitUUID){
      await Promise.all([
        this.readRealEstateUnit(realEstateUnitUUID),
        this.loadRelatedContextModel(realEstateUnitUUID, this.contextType, this.contextUUID),
      ])
    },
    async loadRelatedContextModel(realEstateUnitUUID, contextType, contextUUID){
      if (!contextType || !contextUUID){
        return
      }
      if (contextType === 'RealEstateRenter'){
        await Promise.all([
          this.readRealEstateRenter(contextUUID),
          this.readRentalContract(realEstateUnitUUID, contextUUID),
        ])
        return
      }
      if (contextType === 'ServiceProvider'){
        await this.readServiceProvider(contextUUID)
        return
      }
    },
    async readRealEstateUnit(realEstateUnitUUID){
      const messageTarget = `readRealEstateUnit`
      this.messageStore.flushTarget(messageTarget)

      let realEstateUnit = {}
      try{
        realEstateUnit = await readRealEstateUnit(realEstateUnitUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0074", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localRealEstateUnit = realEstateUnit
      return true
    },
    async readRealEstateRenter(realEstateRenterUUID){
      const messageTarget = `readRealEstateRenter`
      this.messageStore.flushTarget(messageTarget)

      let realEstateRenter = {}
      try{
        realEstateRenter = await readRealEstateRenter(realEstateRenterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0114", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localContextModel = realEstateRenter
      return true
    },
    async readServiceProvider(serviceProviderUUID){
      const messageTarget = `readServiceProvider`
      this.messageStore.flushTarget(messageTarget)

      let serviceProvider = {}
      try{
        serviceProvider = await readServiceProvider(serviceProviderUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0162", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localContextModel = serviceProvider
      return true
    },
    async readRentalContract(realEstateUnitUUID, realEstateRenterUUID){
      const messageTarget = `readRentalContract`
      this.messageStore.flushTarget(messageTarget)

      let rentalContract = {}
      try{
        rentalContract = await readRentalContractByRelated(realEstateUnitUUID, realEstateRenterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0107", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localRentalContract = rentalContract
      return true
    },
    async deleteRealEstateUnit(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `deleteRealEstateUnit.${this.localRealEstateUnit.uuid}`
      this.messageStore.flushTarget(messageTarget)

      if (!this.localRealEstateUnit.uuid){
        this.messageStore.error("MOC0075", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0076", "delete real estate unit cannot be executed, invalid uuid")
        return
      }
      const identifier = this.localRealEstateUnitIdentifier
      const isConfirmed = confirm("Sicher, dass die Einheit '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Die Einheit '"+ identifier +"' wird gelöscht...", messageTarget)

      try{
        await deleteRealEstateUnit(this.localRealEstateUnit.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0077", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Einheit '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },
    async saveRealEstateUnitField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.localRealEstateUnit.uuid){
        this.messageStore.error("MOC0078", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0079", "delete real estate cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Einheit-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let realEstateUnit = {}
      try{
        realEstateUnit = await saveRealEstateUnitField(this.localRealEstateUnit.uuid, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0080", requestErr)
        this.messageStore.error(parsedError.code, `Einheit-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Einheit-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new RealEstateUnit()
      saved.unmarshalJSON(realEstateUnit)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localRealEstateUnit[jsonFieldName] = savedValue
      }
    },
    async saveRentalContractField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.localRentalContract.uuid){
        this.messageStore.error("MOC0104", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0105", "save rental contract field cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Vertrags-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let rentalContract = {}
      try{
        rentalContract = await saveRentalContractField(this.localRentalContract.uuid, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0108", requestErr)
        this.messageStore.error(parsedError.code, `Mieter-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Vertrags-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new RentalContract()
      saved.unmarshalJSON(rentalContract)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localRentalContract[jsonFieldName] = savedValue
      }
    },
    navigateToRealEstate(e){
      if (e){
        e.preventDefault()
      }
      if (!this.realEstateUUID){
        this.messageStore.error("MOC0081", "Etwas ist schiefgelaufen. Das zugehörige Objekt konnte nicht bestimmt werden.")
        return
      }

      const newRoute = '/real-estate/'+this.realEstateUUID
      this.$router.push(newRoute)
    },
    async toggleSubView(e, selectedSubView){
      if (e){
        e.preventDefault()
      }
      if (this.currentSubView == selectedSubView){
        this.currentSubView = ''
        return
      }
      this.currentSubView = selectedSubView
    },
    getPersonCompanyModelNameLine(){
      let namePieces = []
      if (this.localContextModel.name){
        namePieces.push(this.localContextModel.name)
        namePieces.push(" / ")
      }
      if (this.localContextModel.salutation){
        namePieces.push(this.localContextModel.salutation)
      }
      if (this.localContextModel.first_name){
        namePieces.push(this.localContextModel.first_name)
      }
      if (this.localContextModel.last_name){
        namePieces.push(this.localContextModel.last_name)
      }
      if (namePieces.length > 2 && this.localContextModel.company_name){
        namePieces.push(" / ")
      }
      if (this.localContextModel.company_name){
        namePieces.push(this.localContextModel.company_name)
      }

      return namePieces.join(" ")
    },
    getPersonModelNameLine(){
      let namePieces = []
      if (this.localContextModel.salutation){
        namePieces.push(this.localContextModel.salutation)
      }
      if (this.localContextModel.first_name){
        namePieces.push(this.localContextModel.first_name)
      }
      if (this.localContextModel.last_name){
        namePieces.push(this.localContextModel.last_name)
      }
      return namePieces.join(" ")
    },
    getRealEstateUnitAddressLine(){
      let addrPieces = []
      if (this.localRealEstateUnit.street_name){
        addrPieces.push(this.localRealEstateUnit.street_name)
      }
      if (this.localRealEstateUnit.street_number){
        addrPieces.push(this.localRealEstateUnit.street_number)
      }
      if (this.localRealEstateUnit.zip_code){
        addrPieces.push(this.localRealEstateUnit.zip_code)
      }
      if (this.localRealEstateUnit.city){
        addrPieces.push(this.localRealEstateUnit.city)
      }
      return addrPieces.join(" ")
    },
    updateLocalRealEstateUnit(realEstateUnit){
      if (JSON.stringify(this.localRealEstateUnit) === JSON.stringify(realEstateUnit)){
        return
      }
      this.localRealEstateUnit = realEstateUnit
    },
    updateLocalRentalContract(rentalContract){
      if (JSON.stringify(this.localRentalContract) === JSON.stringify(rentalContract)){
        return
      }
      this.localRentalContract = rentalContract
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    realEstateUnitNameComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.name){
        return orderFirstBeforeSecond
      }
      if (!second.name){
        return orderSecondBeforeFirst
      }
      if (first.name < second.name){
        return orderFirstBeforeSecond
      }
      if (first.name > second.name){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }      
      this.$emit('cancelled:modelEdit', this.realEstateUUID)
    },
    prevModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.prevModelEdit()
    },
    prevModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.prevModelUUID){
        return
      }      
      this.$emit('navigate:model', this.prevModelUUID)
    },
    nextModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.nextModelEdit()
    },
    nextModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.nextModelUUID){
        return
      }      
      this.$emit('navigate:model', this.nextModelUUID)
    }, 
  },
  components:{
    MeterCollection,
    TechnicalInstallationCollection,
    RealEstateUnitFields,
    RentalContractFields,
    FormMessages,
    CheckboxButtonInput,
  }
}
</script>