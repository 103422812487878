import Field from '@/diaspora/fields/field.js'

class FloatField extends Field{    
    constructor(fieldName = '', displayName = '', isMandatory = false, options = []){
        super(fieldName, displayName, 0.0, 0.0, isMandatory)

        this.options = options
        for (let optionIdx = 0; optionIdx < options.length; optionIdx++) {
            const option = options[optionIdx];
            if (option.default == true){
                this.value = option.value
                break;
            }
        }
    }
}


export default FloatField