<template>
  <div id="user-creation">
    <h4>Mein Profil</h4>
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <form>
        <h5>Anmeldedaten</h5>      
        <div class="row mb-3">
            <label for="email" class="col-sm-4 col-form-label">E-Mail Adresse</label>  
            <div class="col-sm-8">
              <StringInput
                  name="email" 
                  label="E-Mail"
                  class="form-control"
                  placeholder="max@mustermann.de"
                  v-model="fields.email.value"
                  @valid:modelValue="fields.email.isValid = true"
                  @invalid:modelValue="fields.email.isValid = false"
                  @reset:modelValue="fields.email.isValid = null"
                  :min-length="3"
                  format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
                  disabled
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="password" class="col-sm-4 col-form-label">Passwort</label>
            <div class="col-sm-8">
              <PasswordInput
                  name="password" 
                  label="Passwort"
                  class="form-control" 
                  v-model="fields.password.value"
                  @valid:modelValue="fields.password.isValid = true"
                  @invalid:modelValue="fields.password.isValid = false"
                  @reset:modelValue="fields.password.isValid = null"
                  :min-length="3"
              />
            </div>
        </div>
        <br/>
        <h5>Öffentliche Kontaktdaten</h5>   
        <div class="row mb-3">
            <label for="salutation" class="col-sm-4 col-form-label">Anrede</label>
            <div class="col-sm-8">
              <SelectInput
                  name="salutation" 
                  label="Anrede"
                  class="form-control" 
                  v-model="fields.salutation.value"
                  @valid:modelValue="fields.salutation.isValid = true"
                  @invalid:modelValue="fields.salutation.isValid = false"
                  @reset:modelValue="fields.salutation.isValid = null"
                  :options="fields.salutation.options"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="firstName" class="col-sm-4 col-form-label">Vorname</label>
            <div class="col-sm-8">
              <StringInput
                  name="firstName" 
                  label="Vorname"
                  placeholder="Max"
                  class="form-control" 
                  v-model="fields.firstName.value"
                  @valid:modelValue="fields.firstName.isValid = true"
                  @invalid:modelValue="fields.firstName.isValid = false"
                  @reset:modelValue="fields.firstName.isValid = null"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="lastName" class="col-sm-4 col-form-label">Nachname</label>
            <div class="col-sm-8">
              <StringInput
                  name="lastName" 
                  label="Nachname"
                  placeholder="Mustermann"
                  class="form-control" 
                  v-model="fields.lastName.value"
                  @valid:modelValue="fields.lastName.isValid = true"
                  @invalid:modelValue="fields.lastName.isValid = false"
                  @reset:modelValue="fields.lastName.isValid = null"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="publicEmail" class="col-sm-4 col-form-label">E-Mail Adresse</label>
            <div class="col-sm-8">
              <StringInput
                  name="publicEmail" 
                  label="E-Mail Adresse"
                  placeholder="info@mustermann.de"
                  class="form-control" 
                  v-model="fields.publicEmail.value"
                  @valid:modelValue="fields.publicEmail.isValid = true"
                  @invalid:modelValue="fields.publicEmail.isValid = false"
                  @reset:modelValue="fields.publicEmail.isValid = null"
                  format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="phoneLandline" class="col-sm-4 col-form-label">Telefon (Festnetz)</label>
            <div class="col-sm-8">
              <StringInput
                  name="phoneLandline" 
                  label="Telefon (Festnetz)"
                  placeholder="0631 1234 5678"
                  class="form-control" 
                  v-model="fields.phoneLandline.value"
                  @valid:modelValue="fields.phoneLandline.isValid = true"
                  @invalid:modelValue="fields.phoneLandline.isValid = false"
                  @reset:modelValue="fields.phoneLandline.isValid = null"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="phoneMobile" class="col-sm-4 col-form-label">Telefon (Mobil)</label>
            <div class="col-sm-8">
              <StringInput
                  name="phoneMobile" 
                  label="Telefon (Mobil)"
                  placeholder="01743 1234 5678"
                  class="form-control" 
                  v-model="fields.phoneMobile.value"
                  @valid:modelValue="fields.phoneMobile.isValid = true"
                  @invalid:modelValue="fields.phoneMobile.isValid = false"
                  @reset:modelValue="fields.phoneMobile.isValid = null"
              />
            </div>
        </div>
        <div class="row mb-3">
          <button
            class="btn btn-brand form-control"
            type="button"
            @click="saveUser"
            :disabled="!areAllFieldsValid"
          >
            Speichern
          </button>
        </div>
    </form>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import StringInput from  '@/components/inputs/StringInput.vue'
import PasswordInput from  '@/components/inputs/PasswordInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'

import FormMessages from '@/components/forms/features/form-messages.js'

import User from '@/diaspora/models/user.js'

export default {
  name: 'UserProfileForm',
  data(){
    return {
      areAllFieldsValid: false, 
      userModel: new User(),
      messages: new FormMessages()
    }
  },
  async mounted(){
    await this.readUser()
  },
  computed:{
    fields(){
      return this.userModel.fields
    },
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    }
  },
  watch:{    
    fields: {
      deep:true,
      handler(){
        if (this.fields.role.value != ""){
          this.fields.role.isValid = true
        }
        this.areAllFieldsValid = this.userModel.areFieldsValid()
      }
    }
  },
  methods:{
    async readUser(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/profile")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0002", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.user
      this.userModel.unmarshalJSON(responseBody)
    },
    async saveUser(e){
      e.preventDefault()

      services.$log.info("saveUser", "clicked!")
      this.messages.reset()

      const userData = this.userModel.marshalJSON()

      try{
        await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/profile/save", userData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0003", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      this.messages.success("Benutzer erfolgreich gespeichert")
    }
  },
  components:{
    StringInput,
    PasswordInput,
    SelectInput
  }
}
</script>

<style scoped>
#user-fields .form-group{
  margin-top:2em;
}
#user-fields .btn{
  margin-top:4em;
}
#user-fields .form-group label{
  display: block;
  text-align: left;
  margin: 5px 0px;
}
</style>
