import { services } from '@digiscape/js-core'

async function createRealEstateOwner(newRealEstateOwnerJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstate'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/real-estate-owner/create", newRealEstateOwnerJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'ServiceProvider'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + relatedUUID+ "/real-estate-owner/create", newRealEstateOwnerJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/create", newRealEstateOwnerJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readAllRealEstateOwners(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_real_estate_owners
    return responseBody
}

async function readAllRealEstateOwnersByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstate'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/real-estate-owner/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estate_owners
        return responseBody
    }
    if (relatedType === 'ServiceProvider'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + relatedUUID+ "/real-estate-owner/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estate_owners
        return responseBody
    }
    return []
}

async function searchRealEstateOwners(searchText){
    const requestBody = { "search_term": searchText }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/search", requestBody)
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.result_real_estate_owners
    return responseBody
}
async function readRealEstateOwner(realEstateOwnerUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + realEstateOwnerUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.real_estate_owner
    return responseBody
}

async function saveRealEstateOwnerField(realEstateOwnerUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: realEstateOwnerUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + realEstateOwnerUUID +"/field", reqBody)
    const responseBody = response.data.real_estate_owner
    return responseBody
}

async function deleteRealEstateOwner(realEstateOwnerUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + realEstateOwnerUUID)
}

async function attachRealEstateOwner(relatedType, relatedUUID, realEstateOwnerUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + realEstateOwnerUUID + "/attach", requestBody)
}

async function detachRealEstateOwner(relatedType, relatedUUID, realEstateOwnerUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + realEstateOwnerUUID + "/detach", requestBody)
}

export {
    createRealEstateOwner,
    readAllRealEstateOwners,
    readAllRealEstateOwnersByRelated,
    searchRealEstateOwners,
    readRealEstateOwner,
    saveRealEstateOwnerField,
    deleteRealEstateOwner,
    attachRealEstateOwner,
    detachRealEstateOwner,
}