<template>
  <fieldset  
    :class="{ 'checkbox-button-container': true, 'centered': centered }"
  >
    <span
      :class="{ 'checkbox-button': true, 'hide-sm': showResponsiveLabels }"
    >
      <input
        type="checkbox"
        class="btn-check" 
        :name="name"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
        :id="id + '-' + label" 
        autocomplete="off"
        v-model="localValue"
        :checked="localValue"
      >
      <label
        class="btn"
        :class="{
          'btn-suc-outline': buttonStyle === 'suc',
          'btn-brand-outline': buttonStyle !== 'suc'
        }"
        :for="id + '-' + label"
      >
        {{ label }}
      </label>
    </span>
    <span
      :class="{
          'checkbox-button': true, 
          'show-sm': true,
          'hide-md': showLabelMedium,
          'hide-lg': !showLabelMedium && showLabelLarge,
          'hide-xl': !showLabelMedium && !showLabelLarge && showLabelExtraLarge,
          'hide-xxl': !showLabelMedium && !showLabelLarge && !showLabelExtraLarge && showLabelExtraExtraLarge,
        }"
        v-if="showLabelSmall"
    >
      <input
        type="checkbox"
        class="btn-check" 
        :name="name"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
        :id="id + '-sm-' + label" 
        autocomplete="off"
        v-model="localValue"
      >
      <label
        class="btn btn-suc-outline"
        :class="{
          'btn-suc-outline': buttonStyle === 'suc', 
          'btn-brand-outline': buttonStyle !== 'suc',
        }"
        :for="id + '-sm-' + label"
      >
        {{ labelsResponsive.sm }}
      </label>
    </span>    
    <span
      :class="{    
          'checkbox-button': true, 
          'show-sm': !showLabelSmall,
          'show-md': showLabelSmall,
          'hide-lg': showLabelLarge,
          'hide-xl': !showLabelLarge && showLabelExtraLarge,
          'hide-xxl': !showLabelLarge && !showLabelExtraLarge && showLabelExtraExtraLarge,
        }"
        v-if="showLabelMedium"
    >
      <input
        type="checkbox"
        class="btn-check" 
        :name="name"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
        :id="id + '-md-' + label" 
        autocomplete="off"
        v-model="localValue"
      >
      <label
        class="btn btn-suc-outline"
        :class="{
          'btn-suc-outline': buttonStyle === 'suc', 
          'btn-brand-outline': buttonStyle !== 'suc',    
        }"
        :for="id + '-md-' + label"
      >
        {{ labelsResponsive.md }}
      </label>
    </span>
    <span
      :class="{
        'checkbox-button': true, 
        'show-sm': !showLabelSmall && !showLabelMedium, //
        'show-lg': showLabelSmall || showLabelMedium,
        'hide-xl': showLabelExtraLarge,
        'hide-xxl': !showLabelExtraLarge && showLabelExtraExtraLarge,
      }"
      v-if="showLabelLarge"
    >
      <input
        type="checkbox"
        class="btn-check" 
        :name="name"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
        :id="id + '-lg-' + label" 
        autocomplete="off"
        v-model="localValue"
      >
      <label
        class="btn btn-suc-outline"
        :class="{
          'btn-suc-outline': buttonStyle === 'suc', 
          'btn-brand-outline': buttonStyle !== 'suc',
        }"
        :for="id + '-' + label"
      >
        {{ labelsResponsive.lg }}
      </label>
    </span>
    <span
      :class="{    
        'show-sm': !showLabelSmall && !showLabelMedium && !showLabelLarge, 
        'show-xl': showLabelSmall || showLabelMedium || showLabelLarge,
        'hide-xxl': showLabelExtraExtraLarge,
      }"
      v-if="showLabelExtraLarge"
    >
      <input
        type="checkbox"
        class="btn-check" 
        :name="name"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
        :id="id + '-xl-' + label" 
        autocomplete="off"
        v-model="localValue"
      >
      <label
        class="btn btn-suc-outline"
        :class="{
          'btn-suc-outline': buttonStyle === 'suc', 
          'btn-brand-outline': buttonStyle !== 'suc',
        }"
        :for="id + '-' + label"
      >
        {{ labelsResponsive.xl }}
      </label>
    </span>
    <span
      :class="{    
        'show-sm': !showLabelSmall && !showLabelMedium && !showLabelLarge && !showLabelExtraLarge,
        'show-xxl': showLabelSmall || showLabelMedium || showLabelLarge || showLabelExtraLarge,
      }"
      v-if="showLabelExtraExtraLarge"
    >
      <input
        type="checkbox"
        class="btn-check" 
        :name="name"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
        :id="id + '-xxl-' + label" 
        autocomplete="off"
        v-model="localValue"
      >
      <label
        class="btn btn-suc-outline"
        :class="{
          'btn-suc-outline': buttonStyle === 'suc', 
          'btn-brand-outline': buttonStyle !== 'suc',
        }"
        :for="id + '-' + label"
      >
        {{ labelsResponsive.xxl }}
      </label>
    </span>
  </fieldset>
</template>

<script>
export default {
  name: 'CheckboxButtonInput',
  data(){
    return {
      localValue: false,
      isLocalValueReversion: false,
      isValueChange: false,
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: Boolean,
      required: true
    },
    name:{
      type: String
    },
    id:{
      type: String
    },
    label:{
      type: String
    },
    labelsResponsive:{
      type: Object
    },
    mandatory:{
      type: Boolean
    },
    centered: {
      type: Boolean
    },
    abortMessage:{
      type: String
    },
    confirmMessage:{
      type: String
    },
    buttonStyle: { /* brand / suc / ... */
      type: String
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue',
    'aborted:modelValue',
    'cancelled:modelValue',
  ],
  computed:{
    showResponsiveLabels(){
      if (this.labelsResponsive){
        return true
      }
      return false
    },
    showLabelSmall(){
      if (!this.labelsResponsive){
        return false
      }
      return this.labelsResponsive['sm'] !== undefined
    },
    showLabelMedium(){
      if (!this.labelsResponsive){
        return false
      }
      return this.labelsResponsive['md'] !== undefined
    },
    showLabelLarge(){
      if (!this.labelsResponsive){
        return false
      }
      return this.labelsResponsive['lg'] !== undefined
    },
    showLabelExtraLarge(){
      if (!this.labelsResponsive){
        return false
      }
      return this.labelsResponsive['xl'] !== undefined
    },
    showLabelExtraExtraLarge(){
      if (!this.labelsResponsive){
        return false
      }
      return this.labelsResponsive['xxl'] !== undefined
    }
  },
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (value != this.localValue){
          this.isValueChange = true
        }
        this.localValue = value
      }
    },
    localValue(){
      if (this.mandatory === true && this.localValue === false){
        this.isValid = false
        this.$emit('invalid:modelValue', "Das Feld '" + this.label +"' ist ein Pflichtfeld und muss bestätigt werden.")
        return
      }
      if (this.isLocalValueReversion){
        this.isLocalValueReversion = false
        return
      }
      if (this.abortMessage){
        alert(this.abortMessage)
        this.isLocalValueReversion = true
        this.localValue = !this.localValue
        this.syncAllInputsCheckedWithLocalValue()
        this.$emit("aborted:modelValue")
        return
      }
      if (this.confirmMessage && !this.isValueChange){
        const isConfirmed = confirm(this.confirmMessage)
        if (!isConfirmed){
          this.isLocalValueReversion = true
          this.localValue = !this.localValue
          this.syncAllInputsCheckedWithLocalValue()
          this.$emit("cancelled:modelValue")
          return
        }
      }
      this.isValueChange = false
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  methods:{
    resetIsValid(){
      this.isValid = null
    },
    syncAllInputsCheckedWithLocalValue(){      
      this.syncCheckedWithLocalValueForInput(this.id + '-' + this.label)
      this.syncCheckedWithLocalValueForInput(this.id + '-sm-' + this.label)
      this.syncCheckedWithLocalValueForInput(this.id + '-md-' + this.label)
      this.syncCheckedWithLocalValueForInput(this.id + '-lg-' + this.label)
      this.syncCheckedWithLocalValueForInput(this.id + '-xl-' + this.label)
      this.syncCheckedWithLocalValueForInput(this.id + '-xxl-' + this.label)
    },
    syncCheckedWithLocalValueForInput(inputId){
      const elem = document.getElementById(inputId)
      if (!elem){
        return
      }
      elem.checked = this.localValue
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid var(--co-suc-brd, green);
  background-color: var(--co-suc-bg, lightgreen);
}
.input-invalid{
  border: 1px solid var(--co-err-brd, red);  
  background-color: var(--co-err-bg, lightpink);
}
</style>