<template>
    <div>
      <FormMessages
        :allMessages="messageStore.messages"
      />
      <nav 
        id="owner-search-bar"
        class="col-12 col-lg-10"
        v-show="currentComponent === 'ServiceProviderOverview' && contextUUID === ''"
      >
        <div class="row gx-1 gx-lg-2 position-relative">
            <div class="col-6 col-sm-8 col-xl-5">
              <StringInput
                  name="searchText" 
                  label="Hinweis"
                  class="form-control"
                  placeholder="Herr ..."
                  v-model="searchText"
                  @valid:modelValue="searchTextValid = true"
                  @invalid:modelValue="searchTextValid = false"
                  @reset:modelValue="searchTextValid = null"
                  :maxLength="100"
              />
            </div>
            <div class="col-1 col-xl-3 btn-icon-container">
              <button
                class="btn btn-sec form-control show-xl"
                type="button"
                @click="displayFilters = !displayFilters"
              >
                Filtern
              </button>
              <button
                class="btn btn-icon-sec form-control hide-xl"
                type="button"
                @click="displayFilters = !displayFilters"
              >
                <FunnelIcon />
              </button>
            </div>
            <div class="col-1 col-xl-3 btn-icon-container">
              <button
                class="btn btn-warn form-control show-xl"
                type="button"
                @click="resetSearchFilterCriteria"
              >
                Zurücksetzen
              </button>
              <button
                class="btn btn-icon-warn form-control hide-xl"
                type="button"
                @click="resetSearchFilterCriteria"
              >
                <UndoArrowIcon />
              </button>
            </div>
            <h6
              v-show="displayFilters"
              class="mt-1"
            >Filter</h6>
            <ServiceProviderFilters
              v-show="displayFilters"
              class="col-8 col-md-6"
              :filterValues="filterValues"
              @update:filterValues="updateFilterValues"
            />
        </div>
      </nav>
      <div
        v-if="currentComponent === 'ServiceProviderOverview'"
      >
        <div class="my-2">
          <div
            class="btn btn-brand"
            title="Neuen Dienstleister erstellen"
            role="button"
            @click="createServiceProvider"
          >
            Neuen Dienstleister erstellen
          </div>
        </div>
        <ServiceProviderTable 
          id="service-providers"
          :contextType="contextType"
          :contextUUID="contextUUID"
          :serviceProviders="sortedDisplayServiceProviders"
          :isLoaded="isLoaded"
          :isSubView="isSubView"
          @triggered:modelEdit="selectServiceProviderForEdit"
          @triggered:modelDetach="detachServiceProvider"
        />
        <div
          class="my-2 row"
          v-if="isContextGiven"
          v-show="isLoaded"
        >
          <div class="col-8 col-md-8 my-1">
            <SelectAutocompleteInput
                name="attachOwner"
                label="Zusatztyp"
                id="attachOwner"
                class="form-control" 
                v-model="attachServiceProviderUUID"
                :options="unattachedServiceProviderOptions"
            />
          </div>
          <div class="col-8 col-md-4 my-1">
            <button
              class="btn btn-sec w-100"
              title="Neuen Dienstleister hinzufügen"
              role="button"
              @click="attachServiceProvider"
              :disabled="attachServiceProviderUUID == ''"
            >
              Dienstleister hinzuf.
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="currentComponent === 'ServiceProviderEditForm'"
      >
        <ServiceProviderEditForm
          class="col-12"
          @cancelled:modelEdit="exitToRelatedContextOverview"
          @success:modelDelete="exitToRelatedContextOverview"
          @navigate:model="selectServiceProviderForEdit"
          :serviceProviderUUID="currentServiceProviderUUID"
          :prevModelUUID="prevServiceProviderUUID"
          :nextModelUUID="nextServiceProviderUUID"
          :contextType="contextType"
          :contextUUID="contextUUID"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { services } from '@digiscape/js-core'
  
  import ServiceProvider from '@/diaspora/models/service-provider.js'
  import { readAllServiceProviders, readAllServiceProvidersByRelated, searchServiceProviders, createServiceProvider, attachServiceProvider, detachServiceProvider } from '@/diaspora/models/queries/service-provider.js'
  
  import ServiceProviderTable from '@/components/tables/ServiceProviderTable.vue'
  import ServiceProviderEditForm from '@/components/forms/ServiceProviderEditForm.vue'
  
  import SelectAutocompleteInput from '@/components/inputs/SelectAutocompleteInput.vue'
  import StringInput from  '@/components/inputs/StringInput.vue'
  
  import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'
  import FunnelIcon from  '@/components/icons/FunnelIcon.vue'
  
  import FormMessages from '@/components/forms/messages/FormMessages.vue'
  import MessageStore from '@/components/forms/messages/message-store.js'

  import ServiceProviderFilters from '@/components/control/ServiceProviderFilters.vue'
  
  export default {
    name: 'ServiceProviderCollection',
    data(){
      return {      
        messageStore: new MessageStore(),
        contextServiceProviders: [],
        allServiceProviders: [],
        searchText: '',
        searchTextValid: false,
        displayFilters: false,
        filterValues: {
          department: '',
          type: '',
        },
        isLoaded: false,
        currentComponent: 'ServiceProviderOverview',
        currentServiceProviderUUID: '',
        attachServiceProviderUUID: '',
      }
    },
    props:{
      isSubView: {
        type: Boolean,
      },
      realEstateUUID: {
        type: String,
      },
      realEstateUnitUUID: {
        type: String,
      },
      realEstateOwnerUUID: {
        type: String,
      },
    },
    computed:{
      userDepartmentPrefilter(){
        const department = this.$store.getters.userDepartment
        if (department === 'Alle'){
          return ''
        }
        return department
      },
      contextType(){
        if (this.realEstateUUID){
          return 'RealEstate'
        }
        if (this.realEstateUnitUUID){
          return 'RealEstateUnit'
        }
        if (this.realEstateOwnerUUID){
          return 'RealEstateOwner'
        }
        return ''
      }, 
      contextUUID(){
        if (this.realEstateUUID){
          return this.realEstateUUID
        }
        if (this.realEstateUnitUUID){
          return this.realEstateUnitUUID
        }
        if (this.realEstateOwnerUUID){
          return this.realEstateOwnerUUID
        }
        return ''
      }, 
      isContextGiven(){
        return this.contextType != '' && this.contextUUID != ''
      },
      displayServiceProviders(){
        let serviceProviderSubset = this.contextServiceProviders
        if (!this.contextServiceProviders){
          return []
        }
        serviceProviderSubset = this.filterServiceProvidersByDepartment(serviceProviderSubset)
        serviceProviderSubset = this.filterServiceProvidersByType(serviceProviderSubset)        
        return serviceProviderSubset
      },
      sortedDisplayServiceProviders(){
        const sorted = this.cloneArray(this.displayServiceProviders)
        return sorted.sort(this.serviceProviderNameComparator)
      },
      unattachedServiceProviders(){
        const unattachedOwners = [] 
        for (let ownerIdx = 0; ownerIdx < this.allServiceProviders.length; ownerIdx++) {
          const owner = this.allServiceProviders[ownerIdx];
          if (this.isDisplayServiceProvider(owner.uuid)){
            continue
          }
          unattachedOwners.push(owner)
        }
        return unattachedOwners
      },
      sortedUnattachedServiceProviders(){
        const sorted = this.cloneArray(this.unattachedServiceProviders)
        return sorted.sort(this.serviceProviderNameComparator)
      },
      unattachedServiceProviderOptions(){
        const unattachedOwnerOptions = []
        for (let ownerIdx = 0; ownerIdx < this.sortedUnattachedServiceProviders.length; ownerIdx++) {
          const owner = this.sortedUnattachedServiceProviders[ownerIdx];
          const ownerOption = {
            label: this.getOwnerOptionLabel(owner), 
            value: owner.uuid,
          }
          if (ownerOption.label === '' || !ownerOption.label){
            continue
          }
          unattachedOwnerOptions.push(ownerOption)
        }
        return unattachedOwnerOptions
      },
      urlServiceProviderUUID(){
        return this.$route.params.serviceProviderUUID
      },    
      prevServiceProviderUUID(){
        if (!this.currentServiceProviderUUID){
          return ''
        }
        let prevServiceProvider = undefined
        for (let serviceProviderIdx = 0; serviceProviderIdx < this.sortedDisplayServiceProviders.length; serviceProviderIdx++) {
          const serviceProvider = this.sortedDisplayServiceProviders[serviceProviderIdx]
          if (serviceProvider.uuid !== this.currentServiceProviderUUID){
            prevServiceProvider = serviceProvider
            continue
          }
          if (prevServiceProvider){
            return prevServiceProvider.uuid
          }
          return ''
        }
        return ''
      },
      nextServiceProviderUUID(){
        if (!this.currentServiceProviderUUID){
          return ''
        }
        let nextServiceProvider = undefined
        for (let serviceProviderIdx = this.sortedDisplayServiceProviders.length - 1; serviceProviderIdx >= 0; serviceProviderIdx--) {
          const serviceProvider = this.sortedDisplayServiceProviders[serviceProviderIdx]
          if (serviceProvider.uuid !== this.currentServiceProviderUUID){
            nextServiceProvider = serviceProvider
            continue
          }
          if (nextServiceProvider){
            return nextServiceProvider.uuid
          }
          return ''
        }
        return ''
      },
    },
    async mounted(){
      if (this.userDepartmentPrefilter){
        this.filterValues.department = this.userDepartmentPrefilter
      }
      if (this.urlServiceProviderUUID && !this.isSubView){
        this.showServiceProviderEditForm(this.urlServiceProviderUUID)
      }
      await this.loadServiceProviderData()
    },
    watch:{
      async searchText(){
        await this.loadServiceProviderData()
      },
      urlServiceProviderUUID(newUUID){
        if (newUUID){
          this.showServiceProviderEditForm(newUUID)
          return
        }
        this.showServiceProviderOverview()
      },
      async contextUUID(newUUID){
        if (!newUUID){
          this.allServiceProviders = []
          return
        }
        
        await this.loadServiceProviderData()
      },
    },
    methods:{
      async loadServiceProviderData(){      
        this.isLoaded = false
        await Promise.all([
          this.loadContextServiceProviders(),
          this.loadAllServiceProviders()
        ])
        this.attachServiceProviderUUID = ''
        this.isLoaded = true
      },
      async loadContextServiceProviders(){
        if (this.searchText){
          this.contextServiceProviders = await this.searchServiceProviders()
          return
        }
        if (this.isContextGiven) {
          this.contextServiceProviders = await this.readRelatedServiceProviders()
          return
        }
        this.contextServiceProviders = await this.readAllServiceProviders()
      },
      async loadAllServiceProviders(){
        if (!this.isContextGiven) {
          return
        }
        this.allServiceProviders = await this.readAllServiceProviders()
      },
      async resetSearchFilterCriteria(){
        this.filterValues.department = ''
        this.filterValues.type = ''
        this.searchText = ''
      },
      filterServiceProviderByDepartment(serviceProviderubset){      
        const departmentFilter = this.filterValues.department
        if (!departmentFilter || departmentFilter === 'Alle'){
          return serviceProviderubset
        }
        const filteredServiceProvider = serviceProviderubset.filter(function(realEstateOwner){
          if (realEstateOwner.department === '' || realEstateOwner.department === 'Alle'){
            return true
          }
          if (!realEstateOwner.department){
            return false
          }
          return realEstateOwner.department === departmentFilter
        })
        return filteredServiceProvider
      },
      filterServiceProvidersByType(serviceProviderSubset){
        const typeFilter = this.filterValues.type
        if (!typeFilter){
          return serviceProviderSubset
        }
        const filteredServiceProviders = serviceProviderSubset.filter(function(serviceProvider){
          if (!serviceProvider.type){
            return false
          }
          return serviceProvider.type === typeFilter
        })
        return filteredServiceProviders
      },
      async searchServiceProviders(){
        const messageTarget = `searchServiceProviders`
        this.messageStore.flushTarget(messageTarget)
  
        let resultServiceProviders = []
        try{
          resultServiceProviders = await searchServiceProviders(this.searchText)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0137", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message)
          return []
        }
        return resultServiceProviders      
      },
      async readRelatedServiceProviders(){
        if (!this.isContextGiven){
          services.$log.warn("MOC0138", "read all service providers cannot be executed, invalid related uuid / type, skipping")
          return
        }
        const messageTarget = `readRelatedServiceProviders`
        this.messageStore.flushTarget(messageTarget)
  
        let relatedServiceProviders = []
        try{
          relatedServiceProviders = await readAllServiceProvidersByRelated(this.contextType, this.contextUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0139", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message)
          return []
        }
        return relatedServiceProviders      
      },
      async readAllServiceProviders(){
        const messageTarget = `readAllServiceProviders`
        this.messageStore.flushTarget(messageTarget)
  
        let allServiceProviders = []
        try{
          allServiceProviders = await readAllServiceProviders()
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0140", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message)
          return []
        }
        return allServiceProviders
      },
      async createServiceProvider(e){
        if (e){
          e.preventDefault()
        }
  
        const messageTarget = `createServiceProvider`
        this.messageStore.flushTarget(messageTarget)
        this.messageStore.pending('', 'Erstelle einen neuen Dienstleister...', messageTarget)
  
        const newServiceProvider = new ServiceProvider()
        const newServiceProviderJSON = newServiceProvider.marshalJSON()
        let createdUUID = ''
        try{
          createdUUID = await createServiceProvider(newServiceProviderJSON, this.contextType, this.contextUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0141", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Dienstleister erfolgreich erstellt.", messageTarget)
        this.selectServiceProviderForEdit(createdUUID)
      },
      async attachServiceProvider(e){
        if (e){
          e.preventDefault()
        }
  
        const messageTarget = `attachServiceProvider`
        this.messageStore.flushTarget(messageTarget)
        this.messageStore.pending('', 'Füge Dienstleister hinzu...', messageTarget)
  
        if (!this.attachServiceProviderUUID){
          this.messageStore.error("MOC0142", "Etwas ist schiefgelaufen. Der ausgewählte Dienstleister kann nicht identifiziert werden.", messageTarget)
          return
        }
  
        try{
          await attachServiceProvider(this.contextType, this.contextUUID, this.attachServiceProviderUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0143", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Dienstleister erfolgreich hinzugefügt.", messageTarget)
        await this.loadServiceProviderData()
      },
      async detachServiceProvider(detachServiceProviderUUID){
        const messageTarget = `detachServiceProvider`
        this.messageStore.flushTarget(messageTarget)
        
        const isConfirmed = confirm("Sicher, dass der Dienstleister entfernt werden soll?")
        if (!isConfirmed){
          return
        }
  
        this.messageStore.pending('', 'Entferne Dienstleister...', messageTarget)
  
        if (!detachServiceProviderUUID){
          this.messageStore.error("MOC0144", "Etwas ist schiefgelaufen. Der ausgewählte Dienstleister kann nicht identifiziert werden.", messageTarget)
          return
        }
  
        try{
          await detachServiceProvider(this.contextType, this.contextUUID, detachServiceProviderUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0145", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Dienstleister erfolgreich entfernt.", messageTarget)
        await this.loadServiceProviderData()
      },
      updateFilterValues(newValues){
        for (const filterName in newValues) {
          const newFilterValue = newValues[filterName]
          this.filterValues[filterName] = newFilterValue
        }
      },
      filterServiceProvidersByDepartment(serviceProviderSubset){      
        const departmentFilter = this.filterValues.department
        if (!departmentFilter || departmentFilter === 'Alle'){
          return serviceProviderSubset
        }
        const filteredServiceProviders = serviceProviderSubset.filter(function(serviceProvider){
          if (serviceProvider.department === '' || serviceProvider.department === 'Alle'){
            return true
          }
          if (!serviceProvider.department){
            return false
          }
          return serviceProvider.department === departmentFilter
        })
        return filteredServiceProviders
      },
      isDisplayServiceProvider(ownerUUID){
        for (let ownerIdx = 0; ownerIdx < this.displayServiceProviders.length; ownerIdx++) {
          const displayOwner = this.displayServiceProviders[ownerIdx];
          if (displayOwner.uuid === ownerUUID){
            return true
          }
        }
        return false
      },
      getOwnerOptionLabel(ownerJSON){
        let ownerLabel = ""
        if (ownerJSON.name){
          ownerLabel += ownerJSON.name
        }
        if (ownerJSON.salutation || ownerJSON.first_name || ownerJSON.last_name){
          ownerLabel += " / " + (ownerJSON.salutation || '') + " " + (ownerJSON.first_name || '') + " " + (ownerJSON.last_name || '')
        }
        if (ownerJSON.company_name){
          ownerLabel += " / " + ownerJSON.company_name
        }
        return ownerLabel
      },
      selectServiceProviderForEdit(serviceProviderUUID){
        this.showServiceProviderEditForm(serviceProviderUUID)
        this.navigateToServiceProvider(serviceProviderUUID)
      },
      exitToRelatedContextOverview(){
        this.showServiceProviderOverview()
        this.navigateToRelatedContextFromServiceProvider()
      },
      navigateToRelatedContextFromServiceProvider(){
        if (this.isOverviewActive){
          return
        }
        if (this.contextType === 'RealEstate'){
          const newRoute = '/real-estate/' + this.contextUUID
          this.$router.push(newRoute)
          return
        }
        if (this.contextType === 'RealEstateUnit'){
          const newRoute = '/real-estate-unit/' + this.contextUUID
          this.$router.push(newRoute)
          return
        }
        if (this.contextType === 'RealEstateOwner'){
          const newRoute = '/real-estate-owner/' + this.contextUUID
          this.$router.push(newRoute)
          return
        }
        const newRoute = '/service-provider'
        this.$router.push(newRoute)
      },
      navigateToServiceProvider(newUUID){
        if (this.contextType === 'RealEstate'){
          const newRoute = '/real-estate/' + this.contextUUID + '/service-provider/' + newUUID
          this.$router.push(newRoute)
          return
        }
        if (this.contextType === 'RealEstateUnit'){
          const newRoute = '/real-estate-unit/' + this.contextUUID + '/service-provider/' + newUUID
          this.$router.push(newRoute)
          return
        }
        if (this.contextType === 'RealEstateOwner'){
          const newRoute = '/real-estate-owner/' + this.contextUUID + '/service-provider/' + newUUID
          this.$router.push(newRoute)
          return
        }
        const newRoute = '/service-provider/' + newUUID
        this.$router.push(newRoute)
      },
      showServiceProviderEditForm(serviceProviderUUID){
        if (!serviceProviderUUID){
          services.$log.warnWrap('showServiceProviderEditForm', 'MOC0146', 'no service provider uuid provided, showing overview instead')
          this.showServiceProviderOverview()
          return
        }
        this.currentServiceProviderUUID = serviceProviderUUID
        this.currentComponent = 'ServiceProviderEditForm'
      },
      async showServiceProviderOverview(){
        this.currentServiceProviderUUID = ''
        this.currentComponent = 'ServiceProviderOverview'
  
        await this.loadServiceProviderData()
      },
      serviceProviderNameComparator(first, second) {
        const orderFirstBeforeSecond = -1
        const orderSecondBeforeFirst = 1
        const orderEqual = 0
  
        if (!first.name){
          return orderFirstBeforeSecond
        }
        if (!second.name){
          return orderSecondBeforeFirst
        }
        if (first.name < second.name){
          return orderFirstBeforeSecond
        }
        if (first.name > second.name){
          return orderSecondBeforeFirst
        }
        return orderEqual
      },
      cloneArray(cloneSource){
        return [...cloneSource]
      },
    },
    components:{
      ServiceProviderTable,
      ServiceProviderEditForm,
      ServiceProviderFilters,
      UndoArrowIcon,
      FunnelIcon,
      StringInput,
      SelectAutocompleteInput,
      FormMessages,
    }
  }
  </script>
  