class FieldOption {
    constructor(label, value, isDefault = false, groupName = '') {
        this.label = label;
        this.value = value;
        this.isDefault = isDefault;
        this.groupName = groupName;
    }

    isEqual(otherOption){
        const properties = ['label', 'value', 'isDefault', 'groupName']
        for (let propertyIdx = 0; propertyIdx < properties.length; propertyIdx++) {
            const property = properties[propertyIdx];
            if (this[property] != otherOption[property]){
                return false
            }
        }        
        return true
    }
}

export default FieldOption