<template>
  <div>
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Zählerstände werden geladen...</h5>
    </div>
    <table
      id="meterReadings"
      class="table table-sm table-striped"
      v-show="isLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th style="width:100px">Erfasst</th>
          <th style="width:100px">Messwert</th>
          <th style="width:40px">Einh.</th>
          <th style="width:110px">Notiz</th>
          <th style="width:30px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="meterReading in meterReadings"
          :key="meterReading.uuid"
        >
          <td
            label="Erfasst"
          >
            {{ formatTimestamp(meterReading.reading_at) }}
          </td>
          <td
            label="Messwert"
          >
            {{ formatFloat(meterReading.reading_value) }}
          </td>
          <td
            label="Einh."
          >
            <UnitLabel
              :type="meterReading.reading_unit"
            />
          </td>
          <td
            label="Notiz"
          >
            {{ meterReading.reading_note }}
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Löschen"
              role="button"
              @click="triggerDeleteMeterReading($event, meterReading)"
            >
            Löschen
            </div>
            <div
              class="btn-icon-warn show-md"
              title="Löschen"
              role="button"
              @click="triggerDeleteMeterReading($event, meterReading)"
            >
              <TrashIcon />
            </div>
          </td>
        </tr>
        <tr class="proto">
          <td
            label="Erfasst"
          >
            <TimestampInput
              name="readingAt" 
              label="Erfasst"
              class="form-control"
              placeholder="01.10.2023 14:42:43"
              v-model="protoFields.readingAt.value"
              @valid:modelValue="protoFields.readingAt.isValid = true"
              @invalid:modelValue="protoFields.readingAt.isValid = false"
              @reset:modelValue="protoFields.readingAt.isValid = null"
              mandatory
            />
          </td>
          <td
            label="Messwert"
          >
            <FloatInput
              name="readingValue" 
              label="Messwert"
              class="form-control"
              placeholder="39,43"
              v-model="protoFields.readingValue.value"
              @valid:modelValue="protoFields.readingValue.isValid = true"
              @invalid:modelValue="protoFields.readingValue.isValid = false"
              @reset:modelValue="protoFields.readingValue.isValid = null"
              :decimalPlaces="3"
            />
          </td>
          <td
            label="Einh."
          >
            <SelectInput
              name="readingUnit"
              label="Einh."
              id="type"
              class="form-control" 
              v-model="protoFields.readingUnit.value"
              @valid:modelValue="protoFields.readingUnit.isValid = true"
              @invalid:modelValue="protoFields.readingUnit.isValid = false"
              @reset:modelValue="protoFields.readingUnit.isValid = null"
              :options="protoFields.readingUnit.options"
              :optionGroup="meterType"
              mandatory
            />
          </td>
          <td
            label="Notiz"
          > 
            <StringInput
                name="readingNote" 
                label="Notiz"
                class="form-control"
                placeholder="Zählerstand von ..."
                v-model="protoFields.readingNote.value"
                @valid:modelValue="protoFields.readingNote.isValid = true"
                @invalid:modelValue="protoFields.readingNote.isValid = false"
                @reset:modelValue="protoFields.readingNote.isValid = null"
                :maxLength="250"
                :numRows="3"
                resizable
            />
          </td>
          <td>
            <button
              class="btn btn-suc hide-md"
              title="Erstellen"
              role="button"
              @click="triggerCreateMeter($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              Erstellen
            </button>
            <button
              class="btn-icon-suc show-md"
              title="Erstellen"
              @click="triggerCreateMeter($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              <PlusIcon />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import MeterReading from '@/diaspora/models/meter-reading.js'

import SelectInput from  '@/components/inputs/SelectInput.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import TimestampInput from  '@/components/inputs/TimestampInput.vue'
import FloatInput from  '@/components/inputs/FloatInput.vue'
import UnitLabel from  '@/components/labels/UnitLabel.vue'

import TrashIcon from  '@/components/icons/TrashIcon.vue'
import PlusIcon from  '@/components/icons/PlusIcon.vue'

export default {
  name: 'MeterReadingTable',
  emits: [
    'triggered:modelCreate',
    'triggered:modelDelete'
  ],
  data(){
    return {
      protoModel: new MeterReading(),
      areAllProtoFieldsValid: false,
    }
  },
  props:{
    meterType:{
      type: String,
      required: true
    },
    meterReadings:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    }
  },
  computed:{
    protoFields(){
      return this.protoModel.fields
    }
  },
  watch:{
    protoFields: {
      deep:true,
      handler(){
        this.areAllProtoFieldsValid = this.protoModel.areFieldsValid()
      }
    },
  },
  methods:{
    triggerCreateMeter(e, meterReading){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelCreate', meterReading)
      this.protoModel = new MeterReading()
    },
    triggerDeleteMeterReading(e, meterReading){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelDelete', meterReading)
    },
    formatTimestamp(value){
      return services.$format.timestamp(value)
    },
    formatFloat(value){
      return services.$format.float(value, 3)
    }
  },
  components:{
    TrashIcon,
    PlusIcon,
    FloatInput,
    TimestampInput,
    StringInput,
    SelectInput,
    UnitLabel,
},
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>