<template>
    <nav
      id="nsb"
      :class="{ deactivated: !isUserAuthenticated }" 
      role="navigation"
    >
      <div id="nsb-container">
        <div id="nsb-logo-container">
          <a href="https://crm.immobilien-mohler.de">
            <img 
              id="logo"
              src="@/assets/logo_weiss_large.png"
              alt="Objektverwaltung"
            />
          </a>
        </div>
        <div id="nsb-content" v-if="isUserAuthenticated">
          <ul
            v-for="(pageGroup, pageGroupName) in userAccessiblePageGroups"
            :key="pageGroupName"
            class="nsb-content-group"
          >
            <li class="nsb-page-group-name">{{ pageGroupName }}</li>
            <li 
              class="nsb-page-link"
              v-for="page in pageGroup"
              :key="page.key"
            >
              <router-link 
                :to="page.route"
                :class="{ 'nav-link': true, 'active': currentPageRoute == page.route }"
                :title="page.label"
              >
                <SpeedometerIcon v-if="page.icon === 'Dashboard'" />
                <PersonIcon v-if="page.icon === 'Person'" />
                <PersonFilledIcon v-if="page.icon === 'PersonFilledIcon'" />
                <HouseIcon v-if="page.icon === 'House'" />
                <HouseGearIcon v-if="page.icon === 'HouseGear'" />
                <BoxIcon v-if="page.icon === 'Box'" />
                {{ page.label }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router'

import SpeedometerIcon from  '@/components/icons/SpeedometerIcon.vue'
import PersonIcon from  '@/components/icons/PersonIcon.vue'
import PersonFilledIcon from  '@/components/icons/PersonFilledIcon.vue'
import HouseIcon from  '@/components/icons/HouseIcon.vue'
import HouseGearIcon from  '@/components/icons/HouseGearIcon.vue'
import BoxIcon from  '@/components/icons/BoxIcon.vue'

export default {
  name: 'NavSideBar',
  data() {
    return {
      allPageGroups: {
        "": {
          pages:[
            {
              key: "dashboard",
              route: "/",
              label: "Home",
              icon: "Dashboard"
            },
          ],
        },
        "Vermarktung": {
          permissions: ["edit_real_estate_listings"],
          pages: [
            {
              key: "real-estate-listing-live",
              route: "/real-estate-listing/live",
              label: "Anzeigen",
              icon:"House",
            },
            {
              key: "real-estate-listing-archive",
              route: "/real-estate-listing/archive",
              label: "Archiv",
              icon:"Box"
            },
          ],
        },
        "Stammdaten": {
          permissions: ["edit_real_estates"],
          pages: [
            {
              key: "real-estate",
              route: "/real-estate",
              label: "Objekte",
              icon:"House",
            },
            {
              key: "owner",
              route: "/real-estate-owner",
              label: "Eigentümer",
              icon:"Person",
            },
            {
              key: "renter",
              route: "/real-estate-renter",
              label: "Mieter",
              icon:"PersonFilledIcon",
            },
            {
              key: "service-provider",
              route: "/service-provider",
              label: "Dienstleister",
              icon:"HouseGear",
            },
          ],
        },
      }
    }
  },
  computed:{
    isUserAuthenticated(){
      return this.$store.getters.isUserAuthenticated
    },
    isUserAllowedEditRealEstates(){
      return this.$store.getters.isUserAllowedEditRealEstates
    },
    isUserAllowedEditRealEstateListings(){
      return this.$store.getters.isUserAllowedEditRealEstateListings
    },
    currentPageRoute(){
      const route = useRoute()
      return route.path
    },
    userAccessiblePageGroups(){
      const userAccessiblePageGroups = {}
      for (const [pageGroupName, pageGroup] of Object.entries(this.allPageGroups)) {
        if (this.canUserAccessPageGroup(pageGroup)){
          userAccessiblePageGroups[pageGroupName] = pageGroup.pages
        }
      }
      return userAccessiblePageGroups
    }
  },
  methods: {
    canUserAccessPageGroup(pageGroup){
      if (!pageGroup.permissions) {
        return true
      }
      const userPermissions = this.$store.state.permissions
      for (const pagePermission of pageGroup.permissions) {
        if (!userPermissions.includes(pagePermission)) {
          return false
        }
      }
      return true
    }
  },
  components: {
    SpeedometerIcon,
    PersonIcon,
    PersonFilledIcon,
    HouseGearIcon,
    HouseIcon,
    BoxIcon
  }
}
</script>

<style scoped>
#nsb{
    max-width: min(20vw, 300px);
    max-height: 100vh;
    min-width:  30px;
    min-height: 75px;
    
    padding: 10px;
    background-color: var(--co-bg-sec);
    color: var(--co-bg-sec-inv);    
}

#nsb.deactivated{
    background-color: var(--co-bg-ina);
    color: var(--co-bg-ina-inv);
}

#nsb-content{
  margin-top: 5rem;
}

#nsb-logo-container{
    width: 60%;
    margin-left:auto;
    margin-right:auto;
}

#nsb-logo-container > a{
    width: 100%;
}
#nsb-logo-container > a > img{
    width: 100%;
}

#nsb-content > ul{  
  list-style: none;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

#nsb-content > ul > li{  
  margin-bottom: 0.5rem;
}

.nsb-page-group-name{
  color: var(--co-hl-inv);
  font-size: 0.7em;
  text-transform: uppercase;
}

.nsb-page-link{
  font-size: 0.8em;
}

@media (min-width: 1200px) {  /* xl */
  #nsb-content > ul{
    font-size: 1.5rem;
  }
  #nsb-content .nav-link > svg{    
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 1199.98px) {  /* lg */
  #nsb-content > ul{
    padding-left: 0;
  }
  #nsb-content > ul{
    font-size: 0rem;
  }
  #nsb-content .nsb-content-group:not(:first-child){
    border-top: 5px solid var(--co-hl-inv);
  }
  #nsb-content .nav-link > svg{
    width: 100%;
    height: 3rem;
  }
  #nsb {    
    max-width: min(20vw, 100px);
  }
  #nsb-content .nav-link{
    padding: 0.5rem 0.3rem;
  }
}

#nsb-content .nav-link{
    color: var(--co-hl-inv);
}
#nsb-content .nav-link:hover,
#nsb-content .nav-link.active:hover{
    color: var(--co-hl);
}
#nsb-content .nav-link.active{
    color: var(--co-hl);
}

</style>
