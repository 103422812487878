<template>
  <div id="real-estate-renter-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Der Mieter wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 v-if="contextType === 'RealEstateUnit'" style="display:flex">        
        <span style="width: 75px;">Einheit</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localRealEstateUnitIdentifier }}</span>
      </h5>
      <h5 style="display:flex">
        <span style="width: 75px;">Mieter</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ this.getRealEstateRenterNameLine() }}</span>
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteRealEstateRenter"
          >
            Löschen
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1 center-txt">
          <span
            class="btn btn-icon form-control"
            @click="prevModelEdit"
            :class="{ 'disabled': !prevModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </span>
          <span
            class="btn btn-icon form-control mx-1"
            @click="nextModelEdit"
            :class="{ 'disabled': !nextModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-2 column-brd-right">
          <div class="row mb-3 sticky-top">
            <div
              class="col-sm-12 centered mb-1"
            >  
              <div
                class="col-sm-12 centered mb-1"
                v-if="contextType === 'RealEstateUnit' && realEstateUUID"
              >  
                <button
                  class="btn btn-sec form-control"
                  style="max-width: 150px;"
                  type="button"
                  @click="navigateToRealEstate"
                >
                  Zum Objekt
                </button>
              </div>
              <CheckboxButtonInput
                name="subViewRealEstateUnits"
                label="Einheiten"
                :modelValue="currentSubView === 'Einheiten'"
                @click="toggleSubView($event, 'Einheiten')"
              />
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewBankAccounts"
                label="Konten"
                :modelValue="currentSubView === 'Konten'"
                @click="toggleSubView($event, 'Konten')"
              />
            </div>
          </div>
        </div>
        <div 
          class="col-12 col-lg-10"
        >
          <div
            v-show="currentSubView === ''"
          >
            <RealEstateRenterFields
              id="renter"
              :realEstateRenter="localRealEstateRenter"
              @update:modelValue="updateLocalRealEstateRenter"
              @update:fieldValue="saveRealEstateRenterField"
              @error:modelFields="modelFieldsErrorHandler"
            />
            <RentalContractFields
              v-if="contextType === 'RealEstateUnit'"
              id="contract"
              :rentalContract="localRentalContract"
              @update:modelValue="updateLocalRentalContract"
              @update:fieldValue="saveRentalContractField"
              @error:modelFields="modelFieldsErrorHandler"
            />
            <div class="row mb-3 gx-1">
              <div class="col-6">
                <button
                  class="btn btn-brand form-control"
                  type="button"
                  @click="cancelEdit"
                >
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="currentSubView === 'Einheiten'"
          >
            <RealEstateUnitCollection
              :realEstateRenterUUID="realEstateRenterUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Konten'"
          >
            <BankAccountCollection
              :realEstateRenterUUID="realEstateRenterUUID"
              :isSubView="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import { readRealEstateRenter, saveRealEstateRenterField, deleteRealEstateRenter } from '@/diaspora/models/queries/real-estate-renter.js'
import { readRealEstateUnit } from '@/diaspora/models/queries/real-estate-unit.js'
import { readRentalContractByRelated, saveRentalContractField } from '@/diaspora/models/queries/rental-contract.js'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import BankAccountCollection from '@/components/collections/BankAccountCollection.vue'

import RealEstateRenterFields from '@/components/forms/RealEstateRenterFields.vue'
import RentalContractFields from '@/components/forms/RentalContractFields.vue'

import RealEstateRenter from '@/diaspora/models/real-estate-renter.js'
import RentalContract from '@/diaspora/models/rental-contract.js'

import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'

export default {
  name: 'RealEstateRenterEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
    'navigate:model',
  ],
  data(){
    return {
      currentSubView: "",
      localRealEstateRenter: {},
      localRealEstateUnit: {},
      localRentalContract: {},
      messageStore: new MessageStore(),
      isLoaded: false,
      isSubViewLoaded: {
        '': true,
      },
      shortcutHandlers: {
        "ESCAPE": this.cancelEditShortcut.bind(this),
        "CTRL + ARROWLEFT": this.prevModelEditShortcut.bind(this),
        "CTRL + ARROWRIGHT": this.nextModelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  props:{
    realEstateRenterUUID:{
      type: String,
      required: true
    },
    prevModelUUID:{
      type: String
    },
    nextModelUUID:{
      type: String
    },
    contextType:{
      type: String
    },
    contextUUID:{
      type: String
    }
  },
  computed:{
    localRealEstateRenterIdentifier(){
      let identifier = this.getRealEstateRenterNameLine()
      if (identifier) {
        return identifier
      }
      return this.localRealEstateRenter.uuid
    },
    localRealEstateUnitIdentifier(){
      let identifier = this.getRealEstateUnitAddressLine()
      if (identifier && this.localRealEstateUnit.name){
        return identifier + "(" + this.localRealEstateUnit.name + ")"
      }
      if (identifier) {
        return identifier
      }
      identifier = this.localRealEstateUnit.short_code
      if (identifier && this.localRealEstateUnit.name){
        return identifier + "(" + this.localRealEstateUnit.name + ")"
      }
      if (identifier) {
        return identifier
      }
      if (this.localRealEstateUnit.name){
        return this.localRealEstateUnit.name
      }
      return this.localRealEstateUnit.uuid
    },
    realEstateUUID(){
      return this.localRealEstateUnit.real_estate_uuid
    }
  },
  async mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadRealEstateRenter.bind(this))

    await this.reloadRealEstateRenter()
  },
  beforeCreate(){
    this.$options.components.RealEstateUnitCollection = require('../collections/RealEstateUnitCollection.vue').default
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async realEstateRenterUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadRealEstateRenter()
    },
  },
  methods:{
    async reloadRealEstateRenter(){
      this.isLoaded = false
      await Promise.all([
        this.loadRealEstateRenter(this.realEstateRenterUUID),
        this.loadRealEstateUnit(this.contextType, this.contextUUID),
        this.loadRentalContract(this.contextType, this.contextUUID, this.realEstateRenterUUID),
      ])
      this.isLoaded = true
    },
    async loadRealEstateRenter(realEstateRenterUUID){
      await Promise.all([
        this.readRealEstateRenter(realEstateRenterUUID),
      ])
    },
    async loadRealEstateUnit(contextType, contextUUID){
      if (!contextType || !contextUUID || contextType !== 'RealEstateUnit'){
        return
      }
      await Promise.all([
        this.readRealEstateUnit(contextUUID),
      ])
    },
    async loadRentalContract(contextType, contextUUID, realEstateRenterUUID){
      if (!contextType || !contextUUID || contextType !== 'RealEstateUnit'){
        return
      }
      await Promise.all([
        this.readRentalContract(contextUUID, realEstateRenterUUID),
      ])
    },
    async readRealEstateRenter(realEstateRenterUUID){
      const messageTarget = `readRealEstateRenter`
      this.messageStore.flushTarget(messageTarget)

      let realEstateRenter = {}
      try{
        realEstateRenter = await readRealEstateRenter(realEstateRenterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0094", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localRealEstateRenter = realEstateRenter
      return true
    },
    async readRealEstateUnit(realEstateUnitUUID){
      const messageTarget = `readRealEstateUnit`
      this.messageStore.flushTarget(messageTarget)

      let realEstateUnit = {}
      try{
        realEstateUnit = await readRealEstateUnit(realEstateUnitUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0109", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localRealEstateUnit = realEstateUnit
      return true
    },
    async readRentalContract(realEstateUnitUUID, realEstateRenterUUID){
      const messageTarget = `readRentalContract`
      this.messageStore.flushTarget(messageTarget)

      let rentalContract = {}
      try{
        rentalContract = await readRentalContractByRelated(realEstateUnitUUID, realEstateRenterUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0094", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localRentalContract = rentalContract
      return true
    },
    async deleteRealEstateRenter(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `deleteRealEstateRenter.${this.localRealEstateRenter.uuid}`
      this.messageStore.flushTarget(messageTarget)

      if (!this.localRealEstateRenter.uuid){
        this.messageStore.error("MOC0095", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0096", "delete real estate renter cannot be executed, invalid uuid")
        return
      }
      const identifier = this.localRealEstateRenterIdentifier
      const isConfirmed = confirm("Sicher, dass der Mieter '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Der Mieter '"+ identifier +"' wird gelöscht...", messageTarget)

      try{
        await deleteRealEstateRenter(this.localRealEstateRenter.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0097", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Mieter '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },
    async saveRealEstateRenterField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.localRealEstateRenter.uuid){
        this.messageStore.error("MOC0098", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0099", "save real estate field cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Mieter-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let realEstateRenter = {}
      try{
        realEstateRenter = await saveRealEstateRenterField(this.localRealEstateRenter.uuid, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0100", requestErr)
        this.messageStore.error(parsedError.code, `Mieter-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Mieter-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new RealEstateRenter()
      saved.unmarshalJSON(realEstateRenter)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localRealEstateRenter[jsonFieldName] = savedValue
      }
    },
    async saveRentalContractField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.localRentalContract.uuid){
        this.messageStore.error("MOC0104", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0105", "save rental contract field cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Mieter-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let rentalContract = {}
      try{
        rentalContract = await saveRentalContractField(this.localRentalContract.uuid, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0106", requestErr)
        this.messageStore.error(parsedError.code, `Mieter-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Mieter-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new RentalContract()
      saved.unmarshalJSON(rentalContract)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localRentalContract[jsonFieldName] = savedValue
      }
    },
    navigateToRealEstate(e){
      if (e){
        e.preventDefault()
      }
      if (!this.realEstateUUID){
        this.messageStore.error("MOC0113", "Etwas ist schiefgelaufen. Das zugehörige Objekt konnte nicht bestimmt werden.")
      }

      const newRoute = '/real-estate/'+this.realEstateUUID
      this.$router.push(newRoute)
    },
    async toggleSubView(e, selectedSubView){
      if (e){
        e.preventDefault()
      }
      if (this.currentSubView == selectedSubView){
        this.currentSubView = ''
        return
      }
      this.currentSubView = selectedSubView
    },
    getRealEstateRenterNameLine(){
      let namePieces = []
      if (this.localRealEstateRenter.salutation){
        namePieces.push(this.localRealEstateRenter.salutation)
      }
      if (this.localRealEstateRenter.first_name){
        namePieces.push(this.localRealEstateRenter.first_name)
      }
      if (this.localRealEstateRenter.last_name){
        namePieces.push(this.localRealEstateRenter.last_name)
      }
      return namePieces.join(" ")
    },
    getRealEstateUnitAddressLine(){
      let addrPieces = []
      if (this.localRealEstateUnit.street_name){
        addrPieces.push(this.localRealEstateUnit.street_name)
      }
      if (this.localRealEstateUnit.street_number){
        addrPieces.push(this.localRealEstateUnit.street_number)
      }
      if (this.localRealEstateUnit.zip_code){
        addrPieces.push(this.localRealEstateUnit.zip_code)
      }
      if (this.localRealEstateUnit.city){
        addrPieces.push(this.localRealEstateUnit.city)
      }
      return addrPieces.join(" ")
    },
    updateLocalRealEstateRenter(realEstateRenter){
      if (JSON.stringify(this.localRealEstateRenter) === JSON.stringify(realEstateRenter)){
        return
      }
      this.localRealEstateRenter = realEstateRenter
    },
    updateLocalRentalContract(rentalContract){
      if (JSON.stringify(this.localRentalContract) === JSON.stringify(rentalContract)){
        return
      }
      this.localRentalContract = rentalContract
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }      
      this.$emit('cancelled:modelEdit')
    },
    prevModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.prevModelEdit()
    },
    prevModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.prevModelUUID){
        return
      }      
      this.$emit('navigate:model', this.prevModelUUID)
    },
    nextModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.nextModelEdit()
    },
    nextModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.nextModelUUID){
        return
      }      
      this.$emit('navigate:model', this.nextModelUUID)
    }, 
  },
  components:{
    RealEstateRenterFields,
    RentalContractFields,
    CheckboxButtonInput,
    FormMessages,
    BankAccountCollection,
  }
}
</script>