import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField  from '@/diaspora/fields/string_field.js'
import UUIDField    from '@/diaspora/fields/uuid_field.js'
import IntegerField from '@/diaspora/fields/integer_field.js'
import FloatField   from '@/diaspora/fields/float_field.js'

class RealEstate extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:           new UUIDField('uuid', 'UUID'),
            shortCode:      new StringField('shortCode', 'Objekt-Nr'),
            department:     new StringField('department', 'Abteilung', false, [
                new FieldOption('Alle',         'Alle',         true),
                new FieldOption('Vermarktung',  'Vermarktung'),
                new FieldOption('Verwaltung',   'Verwaltung'),
            ]),
            type:           new StringField('type', 'Typ', true, [
                new FieldOption('Wohnung',  'Wohnung', true),
                new FieldOption('Haus',     'Haus'),
            ]),
            subType:       new StringField('subType', 'Zusatztyp', false, [
                new FieldOption('Dachgeschoss',         'Dachgeschoss',         false, 'Wohnung'),
                new FieldOption('Maisonette',           'Maisonette',           false, 'Wohnung'),
                new FieldOption('Loft',                 'Loft',                 false, 'Wohnung'),
                new FieldOption('Penthouse',            'Penthouse',            false, 'Wohnung'),
                new FieldOption('Terrassenwohnung',     'Terrassenwohnung',     false, 'Wohnung'),
                new FieldOption('Erdgeschosswohnung',   'Erdgeschosswohnung',   false, 'Wohnung'),
                new FieldOption('Etagenwohnung',        'Etagenwohnung',        false, 'Wohnung'),
                new FieldOption('Hochparterre',         'Hochparterre',         false, 'Wohnung'),
                new FieldOption('Souterrain',           'Souterrain',           false, 'Wohnung'),
                new FieldOption('Sonstige',             'Sonstige',             false, 'Wohnung'),

                new FieldOption('Einfamilienhaus (freistehend)',  'Einfamilienhaus (freistehend)', false, 'Haus'),
                new FieldOption('Reihenmittelhaus',               'Reihenmittelhaus',              false, 'Haus'),
                new FieldOption('Reiheneckhaus',                  'Reiheneckhaus',                 false, 'Haus'),
                new FieldOption('Mehrfamilienhaus',               'Mehrfamilienhaus',              false, 'Haus'),
                new FieldOption('Bungalow',                       'Bungalow',                      false, 'Haus'),
                new FieldOption('Bauernhaus',                     'Bauernhaus',                    false, 'Haus'),
                new FieldOption('Doppelhaushälfte',               'Doppelhaushälfte',              false, 'Haus'),
                new FieldOption('Villa',                          'Villa',                         false, 'Haus'),
                new FieldOption('Burg/Schloss',                   'Burg/Schloss',                  false, 'Haus'),
                new FieldOption('Besondere Immobilie',            'Besondere Immobilie',           false, 'Haus'),
                new FieldOption('Wohnimmobilie (sonstige)',       'Wohnimmobilie (sonstige)',      false, 'Haus'),
            ]),
            streetName:     new StringField('streetName', 'Straße', true),
            streetNumber:   new StringField('streetNumber', 'Haus-Nr', true),
            zipCode:        new StringField('zipCode', 'PLZ', true),
            city:           new StringField('city', 'Ort', true),
            condition:      new StringField('condition', 'Zustand', false, [
                new FieldOption('Erstbezug',                'Erstbezug'),
                new FieldOption('Erstbezug nach Sanierung', 'Erstbezug nach Sanierung'),
                new FieldOption('Neuwertig',                'Neuwertig'),
                new FieldOption('Saniert',                  'Saniert'),
                new FieldOption('Modernisiert',             'Modernisiert'),
                new FieldOption('Vollständig renoviert',    'Vollständig renoviert'),
                new FieldOption('Gepflegt',                 'Gepflegt'),
                new FieldOption('Renovierungsbedürftig',    'Renovierungsbedürftig'),
                new FieldOption('Nach Vereinbarung',        'Nach Vereinbarung'),
                new FieldOption('Abbruchreif',              'Abbruchreif'),
            ]),
            numberTotalRooms:   new IntegerField('numberTotalRooms', 'Anz. Zimmer', true),
            numberBathrooms:    new IntegerField('numberBathrooms', 'Anz. Badezimmer'),
            numberBedrooms:     new IntegerField('numberBedrooms', 'Anz. Schlafzimmer'),
            numberFloors:       new IntegerField('numberFloors', 'Anz. Etagen'),
            floor:              new IntegerField('floor', 'Etage'),
            yearOfConstruction: new IntegerField('yearOfConstruction', 'Baujahr'),
            lastRenovationYear: new IntegerField('lastRenovationYear', 'Saniert'),
            
            phaseOfConstruction:        new StringField('phaseOfConstruction', 'Bauphase', false, [
                new FieldOption('Haus in Planung (projektiert)',    'Haus in Planung (projektiert)'),
                new FieldOption('Haus im Bau',                      'Haus im Bau'),
                new FieldOption('Haus fertig gestellt',             'Haus fertig gestellt'),
            ]),
            spaceUsable:    new FloatField('spaceUsable', 'Nutzfläche'),
            spaceLiving:    new FloatField('spaceLiving', 'Wohnfläche', true),
            spaceProperty:  new FloatField('spaceProperty', 'Grundstücksfl.'),

            energyCertificateAvailability: new StringField('energyCertificateAvailability', 'Energieausweis Verf.', false, [
                new FieldOption('Für das Gebäude',                              'Energieausweis liegt für das Gebäude vor'),
                new FieldOption('Zur Besichtigung',                             'Energieausweis liegt zur Besichtigung vor'),
                new FieldOption('Gebäude unterliegt keine GEG-Anforderungen',   'Dieses Gebäude unterliegt nicht den GEG-Anforderungen'),
            ]),
        }
    }

    areFieldsValid(){
        const isValid = super.areFieldsValid()
        if (!isValid){
            return false
        }
        if (this.fields.type.value === 'Haus' && !this.fields.spaceProperty.value){
            return false
        }
        return true        
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (fieldName === 'spaceProperty' && this.fields.type.value === 'Haus' && !field.value){
            return "error"
        }
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default RealEstate