<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <nav 
      id="object-search-bar"
      class="col-12 col-lg-10"
      v-show="currentComponent === 'RealEstateOverview' && contextUUID === ''"
    >
      <div class="row gx-1 gx-lg-2 position-relative">
          <div class="col-7 col-sm-8 col-xl-6">
            <StringInput
                name="searchText" 
                label="Hinweis"
                class="form-control"
                placeholder="EFH Kaiserslautern ..."
                v-model="searchText"
                @valid:modelValue="searchTextValid = true"
                @invalid:modelValue="searchTextValid = false"
                @reset:modelValue="searchTextValid = null"
                :maxLength="100"
            />
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-sec form-control show-xl"
              type="button"
              @click="displayFilters = !displayFilters"
            >
              Filtern
            </button>
            <button
              class="btn btn-icon-sec form-control hide-xl"
              type="button"
              @click="displayFilters = !displayFilters"
            >
              <FunnelIcon />
            </button>
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-warn form-control show-xl"
              type="button"
              @click="resetSearchFilterCriteria"
            >
              Zurücksetzen
            </button>
            <button
              class="btn btn-icon-warn form-control hide-xl"
              type="button"
              @click="resetSearchFilterCriteria"
            >
              <UndoArrowIcon />
            </button>
          </div>
          <h6
            v-show="displayFilters"
            class="mt-1"
          >Filter</h6>
          <RealEstateFilters
            v-show="displayFilters"
            class="col-8 col-md-6 col-xxl-4"
            :filterValues="filterValues"
            @update:filterValues="updateFilterValues"
          />
      </div>
    </nav>
    <div
      v-if="currentComponent === 'RealEstateOverview'"
    >
      <div class="my-2">
        <div
          class="btn btn-brand"
          title="Neues Objekt erstellen"
          role="button"
          @click="createRealEstate"
        >
          Neues Objekt erstellen
        </div>
      </div>
      <RealEstateTable 
        id="real-estates"
        :realEstates="displayRealEstates"
        :isLoaded="isLoaded"
        :contextUUID="contextUUID"
        :contextType="contextType"
        @triggered:modelEdit="selectRealEstateForEdit"
        @triggered:modelDetach="detachRealEstate"
        :compact="contextUUID != '' && contextType != ''"
      />
    </div>
    <div
      v-if="currentComponent === 'RealEstateEditForm'"
    >
      <RealEstateEditForm
        class="col-12"
        @cancelled:modelEdit="exitToRelatedContextOverview"
        @success:modelDelete="exitToRelatedContextOverview"
        @navigate:model="selectRealEstateForEdit"
        :realEstateUUID="currentRealEstateUUID"
        :prevModelUUID="prevRealEstateUUID"
        :nextModelUUID="nextRealEstateUUID"
        :contextType="contextType"
        :contextUUID="contextUUID"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import RealEstate from '@/diaspora/models/real-estate.js'
import { readAllRealEstates, readAllRealEstatesByRelated, searchRealEstates, createRealEstate, detachRealEstate } from '@/diaspora/models/queries/real-estate.js'

import RealEstateTable from '@/components/tables/RealEstateTable.vue'
import RealEstateEditForm from '@/components/forms/RealEstateEditForm.vue'

import StringInput from  '@/components/inputs/StringInput.vue'

import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'
import FunnelIcon from  '@/components/icons/FunnelIcon.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import RealEstateFilters from '@/components/control/RealEstateFilters.vue'

export default {
  name: 'RealEstateCollection',
  data(){
    return {      
      messageStore: new MessageStore(),
      contextRealEstates: [],
      searchText: '',
      searchTextValid: false,
      displayFilters: false,
      isLoaded: false,
      currentComponent: 'RealEstateOverview',
      currentRealEstateUUID: '',
      filterValues: {
        department: '',
        type: '',
      },
    }
  },
  props:{
    isSubView: {
      type: Boolean,
    },
    realEstateOwnerUUID: {
      type: String,
    },
    serviceProviderUUID: {
      type: String,
    },
  },
  computed:{
    userDepartmentPrefilter(){
      const department = this.$store.getters.userDepartment
      if (department === 'Alle'){
        return ''
      }
      return department
    },
    contextType(){
      if (this.realEstateOwnerUUID){
        return 'RealEstateOwner'
      }
      if (this.serviceProviderUUID){
        return 'ServiceProvider'
      }
      return ''
    }, 
    contextUUID(){
      if (this.realEstateOwnerUUID){
        return this.realEstateOwnerUUID
      }
      if (this.serviceProviderUUID){
        return this.serviceProviderUUID
      }
      return ''
    }, 
    isContextGiven(){
      return this.contextType != '' && this.contextUUID != ''
    },
    displayRealEstates(){
      let realEstateSubset = this.contextRealEstates
      if (!realEstateSubset){
        return []
      }
      realEstateSubset = this.cloneArray(this.contextRealEstates)
      realEstateSubset = this.filterRealEstatesByDepartment(realEstateSubset)
      realEstateSubset = this.filterRealEstatesByType(realEstateSubset)
      return realEstateSubset.sort(this.realEstateZipCodeComparator)
    },
    urlRealEstateUUID(){
      return this.$route.params.realEstateUUID
    },    
    prevRealEstateUUID(){
      if (!this.currentRealEstateUUID){
        return ''
      }
      let prevRealEstate = undefined
      for (let realEstateIdx = 0; realEstateIdx < this.displayRealEstates.length; realEstateIdx++) {
        const realEstate = this.displayRealEstates[realEstateIdx]
        if (realEstate.uuid !== this.currentRealEstateUUID){
          prevRealEstate = realEstate
          continue
        }
        if (prevRealEstate){
          return prevRealEstate.uuid
        }
        return ''
      }
      return ''
    },
    nextRealEstateUUID(){
      if (!this.currentRealEstateUUID){
        return ''
      }
      let nextRealEstate = undefined
      for (let realEstateIdx = this.displayRealEstates.length - 1; realEstateIdx >= 0; realEstateIdx--) {
        const realEstate = this.displayRealEstates[realEstateIdx]
        if (realEstate.uuid !== this.currentRealEstateUUID){
          nextRealEstate = realEstate
          continue
        }
        if (nextRealEstate){
          return nextRealEstate.uuid
        }
        return ''
      }
      return ''
    },
  },
  async mounted(){
    if (this.userDepartmentPrefilter){
      this.filterValues.department = this.userDepartmentPrefilter
    }
    if (this.urlRealEstateUUID && !this.isSubView){
      this.showRealEstateEditForm(this.urlRealEstateUUID)
    }
    await this.loadRealEstateData()
  },
  watch:{
    async searchText(){
      await this.loadRealEstateData()
    },
    urlRealEstateUUID(newUUID){
      if (newUUID){
        this.showRealEstateEditForm(newUUID)
        return
      }
      this.showRealEstateOverview()
    },
    async contextUUID(newUUID){
      if (!newUUID){
        this.contextRealEstates = []
        return
      }
      
      await this.loadRealEstateData()
    },
  },
  methods:{
    async loadRealEstateData(){
      this.isLoaded = false  
      await this.loadContextRealEstates()
      this.isLoaded = true
    },
    async loadContextRealEstates(){
      if (this.searchText){
        this.contextRealEstates = await this.searchRealEstates()
        return
      }
      if (this.isContextGiven) {
        this.contextRealEstates = await this.readRelatedRealEstates()
        return
      }
      this.contextRealEstates = await this.readAllRealEstates()
    },
    async readAllRealEstates(){
      let allRealEstates = []
      try{
        allRealEstates = await readAllRealEstates()
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0057", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return false
      }
      return allRealEstates    
    },
    async readRelatedRealEstates(){
      if (!this.isContextGiven){
        services.$log.warn("MOC0102", "read all real estates cannot be executed, invalid related uuid / type, skipping")
        return
      }
      const messageTarget = `readRelatedRealEstates`
      this.messageStore.flushTarget(messageTarget)

      let relatedRealEstates = []
      try{
        relatedRealEstates = await readAllRealEstatesByRelated(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0088", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedRealEstates
    },
    async searchRealEstates(){
      const messageTarget = `searchRealEstates`
      this.messageStore.flushTarget(messageTarget)

      if (!this.searchTextValid){
        return
      }
      let resultRealEstates = []
      try{
        resultRealEstates = await searchRealEstates(this.searchText)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0064", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      return resultRealEstates  
    },
    async createRealEstate(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `createRealEstate`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Erstelle ein neues Immobilien-Objekt...', messageTarget)

      const newRealEstate = new RealEstate()
      const newRealEstateJSON = newRealEstate.marshalJSON()
      let createdUUID = ''
      try{
        createdUUID = await createRealEstate(newRealEstateJSON, this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0058", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Immobilien-Objekt erfolgreich erstellt.", messageTarget)
      this.selectRealEstateForEdit(createdUUID)
    },
    async detachRealEstate(detachRealEstateUUID){
      const messageTarget = `detachRealEstate`
      this.messageStore.flushTarget(messageTarget)
      
      const isConfirmed = confirm("Sicher, dass das Objekt entfernt werden soll?")
      if (!isConfirmed){
        return
      }

      this.messageStore.pending('', 'Entferne Objekt...', messageTarget)

      if (!detachRealEstateUUID){
        this.messageStore.error("MOS2098", "Etwas ist schiefgelaufen. Das ausgewählte Objekt kann nicht identifiziert werden.", messageTarget)
        return
      }

      try{
        await detachRealEstate(this.contextType, this.contextUUID, detachRealEstateUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOS2097", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Objekt erfolgreich entfernt.", messageTarget)
      await this.loadRealEstateData()
    },
    updateFilterValues(newValues){
      for (const filterName in newValues) {
        const newFilterValue = newValues[filterName]
        this.filterValues[filterName] = newFilterValue
      }
    },
    filterRealEstatesByDepartment(realEstateSubset){      
      const departmentFilter = this.filterValues.department
      if (!departmentFilter || departmentFilter === 'Alle'){
        return realEstateSubset
      }
      const filteredRealEstates = realEstateSubset.filter(function(realEstate){
        if (realEstate.department === '' || realEstate.department === 'Alle'){
          return true
        }
        if (!realEstate.department){
          return false
        }
        return realEstate.department === departmentFilter
      })
      return filteredRealEstates
    },
    filterRealEstatesByType(realEstateSubset){
      const typeFilter = this.filterValues.type
      if (!typeFilter){
        return realEstateSubset
      }
      const filteredRealEstates = realEstateSubset.filter(function(realEstate){
        if (!realEstate.type){
          return false
        }
        return realEstate.type === typeFilter
      })
      return filteredRealEstates
    },
    selectRealEstateForEdit(realEstateUUID){
      this.showRealEstateEditForm(realEstateUUID)
      this.navigateToRealEstate(realEstateUUID)
    },
    exitToRelatedContextOverview(){
      this.showRealEstateOverview()
      this.navigateToRelatedContextFromRealEstate()
    },
    navigateToRelatedContextFromRealEstate(){
      if (this.isOverviewActive){
        return
      }
      if (this.contextType === 'RealEstateOwner'){
        const newRoute = '/real-estate-owner/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextType === 'ServiceProvider'){
        const newRoute = '/service-provider/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      const newRoute = '/real-estate'
      this.$router.push(newRoute)
    },
    navigateToRealEstate(newUUID){
      if (this.contextType === 'RealEstateOwner'){
        const newRoute = '/real-estate-owner/' + this.contextUUID + '/real-estate/' + newUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextType === 'ServiceProvider'){
        const newRoute = '/service-provider/' + this.contextUUID + '/real-estate/' + newUUID
        this.$router.push(newRoute)
        return
      }
      const newRoute = '/real-estate/' + newUUID
      this.$router.push(newRoute)
    },
    async resetSearchFilterCriteria(){
      this.filterValues.department = ''
      this.filterValues.type = ''
      this.searchText = ''
    },
    showRealEstateEditForm(realEstateUUID){
      if (!realEstateUUID){
        services.$log.warnWrap('showRealEstateEditForm', 'MOC0059', 'no real estate uuid provided, showing overview instead')
        this.showRealEstateOverview()
        return
      }
      this.currentRealEstateUUID = realEstateUUID
      this.currentComponent = 'RealEstateEditForm'
    },
    async showRealEstateOverview(){
      this.currentRealEstateUUID = ''
      this.currentComponent = 'RealEstateOverview'

      await this.loadRealEstateData()
    },
    realEstateZipCodeComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.zip_code){
        return orderFirstBeforeSecond
      }
      if (!second.zip_code){
        return orderSecondBeforeFirst
      }
      if (first.zip_code < second.zip_code){
        return orderFirstBeforeSecond
      }
      if (first.zip_code > second.zip_code){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    RealEstateTable,
    RealEstateEditForm,
    UndoArrowIcon,
    FunnelIcon,
    StringInput,
    FormMessages,
    RealEstateFilters,
  }
}
</script>
