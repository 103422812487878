<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-if="currentComponent === 'RealEstateUnitOverview'"
    >
      <div class="my-2" v-if="contextType === 'RealEstate'">
        <div
          class="btn btn-brand"
          title="Neue Einheit erstellen"
          role="button"
          @click="createRealEstateUnit"
        >
          Neue Einheit erstellen
        </div>
      </div>
      <RealEstateUnitTable 
        id="real-estate-units"
        :realEstateUnits="sortedRealEstateUnits"
        :isLoaded="isLoaded"
        :contextUUID="contextUUID"
        :contextType="contextType"
        @triggered:modelEdit="selectRealEstateUnitForEdit"
        @triggered:modelDetach="detachRealEstateUnit"
      />
    </div>
    <div
      v-if="currentComponent === 'RealEstateUnitEditForm'"
    >
      <RealEstateUnitEditForm
        class="col-12"
        @cancelled:modelEdit="exitToRelatedContextOverview"
        @success:modelDelete="exitToRelatedContextOverview"
        @navigate:model="selectRealEstateUnitForEdit"
        :realEstateUnitUUID="currentRealEstateUnitUUID"
        :prevModelUUID="prevRealEstateUnitUUID"
        :nextModelUUID="nextRealEstateUnitUUID"
        :contextType="contextType"
        :contextUUID="contextUUID"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import RealEstateUnit from '@/diaspora/models/real-estate-unit.js'
import { readRelatedRealEstateUnits, createRealEstateUnit, detachRealEstateUnit } from '@/diaspora/models/queries/real-estate-unit.js'

import RealEstateUnitTable from '@/components/tables/RealEstateUnitTable.vue'
import RealEstateUnitEditForm from '@/components/forms/RealEstateUnitEditForm.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

export default {
  name: 'RealEstateUnitCollection',
  data(){
    return {      
      messageStore: new MessageStore(),
      contextRealEstateUnits: [],
      isLoaded: false,
      currentComponent: 'RealEstateUnitOverview',
      currentRealEstateUnitUUID: '',
    }
  },
  props:{
    isSubView: {
      type: Boolean,
    },
    realEstateUUID: {
      type: String,
    },
    realEstateRenterUUID: {
      type: String,
    },
    serviceProviderUUID: {
      type: String,
    },
  },
  computed:{
    contextType(){
      if (this.realEstateRenterUUID){
        return 'RealEstateRenter'
      }
      if (this.realEstateUUID){
        return 'RealEstate'
      }
      if (this.serviceProviderUUID){
        return 'ServiceProvider'
      }
      return ''
    }, 
    contextUUID(){
      if (this.realEstateRenterUUID){
        return this.realEstateRenterUUID
      }
      if (this.realEstateUUID){
        return this.realEstateUUID
      }
      if (this.serviceProviderUUID){
        return this.serviceProviderUUID
      }
      return ''
    },
    sortedRealEstateUnits(){
      const sorted = this.cloneArray(this.contextRealEstateUnits)
      return sorted.sort(this.realEstateUnitNameComparator)
    },
    urlRealEstateUnitUUID(){
      return this.$route.params.realEstateUnitUUID
    },    
    prevRealEstateUnitUUID(){
      if (!this.currentRealEstateUnitUUID){
        return ''
      }
      let prevRealEstateUnit = undefined
      for (let realEstateUnitIdx = 0; realEstateUnitIdx < this.sortedRealEstateUnits.length; realEstateUnitIdx++) {
        const realEstateUnit = this.sortedRealEstateUnits[realEstateUnitIdx]
        if (realEstateUnit.uuid !== this.currentRealEstateUnitUUID){
          prevRealEstateUnit = realEstateUnit
          continue
        }
        if (prevRealEstateUnit){
          return prevRealEstateUnit.uuid
        }
        return ''
      }
      return ''
    },
    nextRealEstateUnitUUID(){
      if (!this.currentRealEstateUnitUUID){
        return ''
      }
      let nextRealEstateUnit = undefined
      for (let realEstateUnitIdx = this.sortedRealEstateUnits.length - 1; realEstateUnitIdx >= 0; realEstateUnitIdx--) {
        const realEstateUnit = this.sortedRealEstateUnits[realEstateUnitIdx]
        if (realEstateUnit.uuid !== this.currentRealEstateUnitUUID){
          nextRealEstateUnit = realEstateUnit
          continue
        }
        if (nextRealEstateUnit){
          return nextRealEstateUnit.uuid
        }
        return ''
      }
      return ''
    },
  },
  async mounted(){
    if (this.urlRealEstateUnitUUID && !this.isSubView){
      this.showRealEstateUnitEditForm(this.urlRealEstateUnitUUID)
    }
    await this.loadRealEstateUnitData()
  },
  watch:{
    urlRealEstateUnitUUID(newUUID){
      if (newUUID){
        this.showRealEstateUnitEditForm(newUUID)
        return
      }
      this.showRealEstateUnitOverview()
    },
    async contextUUID(newUUID){
      if (!newUUID){
        this.contextRealEstateUnits = []
        return
      }
      await this.loadRealEstateUnitData()
    },
  },
  methods:{
    async loadRealEstateUnitData(){      
      this.isLoaded = false
      await Promise.all([
        this.loadContextRealEstateUnits(),
      ])
      this.isLoaded = true
    },
    async loadContextRealEstateUnits(){
      this.contextRealEstateUnits = await this.readRelatedRealEstateUnits()
    },
    async readRelatedRealEstateUnits(){
      if (!this.contextType || !this.contextUUID){
        services.$log.warn("MOC0068", "read all real estate units cannot be executed, invalid related uuid / type, skipping")
        return
      }
      const messageTarget = `readRelatedRealEstateUnits`
      this.messageStore.flushTarget(messageTarget)

      let contextRealEstateUnits = []
      try{
        contextRealEstateUnits = await readRelatedRealEstateUnits(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0069", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return false
      }
      return contextRealEstateUnits
    },
    async createRealEstateUnit(e){
      if (e){
        e.preventDefault()
      }
      const messageTarget = `createRealEstateUnit`
      this.messageStore.flushTarget(messageTarget)

      if (!this.realEstateUUID || this.contextType != 'RealEstate'){
        this.messageStore.error("MOC0070", "Etwas ist schiefgelaufen. Das ausgewählte Objekt konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0071", "create real estate unit cannot be executed, invalid real estate uuid")
        return
      }

      this.messageStore.pending('', 'Erstelle eine neue Objekt-Einheit...', messageTarget)

      const newRealEstateUnit = new RealEstateUnit()
      const newRealEstateUnitJSON = newRealEstateUnit.marshalJSON()
      let createdUUID = ''

      try{
        createdUUID = await createRealEstateUnit(this.realEstateUUID, newRealEstateUnitJSON)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0072", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Objekt-Einheit erfolgreich erstellt.", messageTarget)
      this.selectRealEstateUnitForEdit(createdUUID)
    },
    async detachRealEstateUnit(detachRealEstateUnitUUID){
      const messageTarget = `detachRealEstateUnit`
      this.messageStore.flushTarget(messageTarget)
      
      const isConfirmed = confirm("Sicher, dass die Einheit entfernt werden soll?")
      if (!isConfirmed){
        return
      }

      this.messageStore.pending('', 'Entferne Einheit...', messageTarget)

      if (!detachRealEstateUnitUUID){
        this.messageStore.error("MOC0156", "Etwas ist schiefgelaufen. Die ausgewählte Einheit kann nicht identifiziert werden.", messageTarget)
        return
      }

      try{
        await detachRealEstateUnit(this.contextType, this.contextUUID, detachRealEstateUnitUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0155", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Einheit erfolgreich entfernt.", messageTarget)
      await this.loadRealEstateUnitData()
    },
    selectRealEstateUnitForEdit(realEstateUnitUUID){
      this.showRealEstateUnitEditForm(realEstateUnitUUID)
      this.navigateToRealEstateUnit(realEstateUnitUUID)
    },
    exitToRelatedContextOverview(realEstateUUID){
      this.showRealEstateUnitOverview()
      this.navigateToRelatedContextFromRealEstateUnit(realEstateUUID)
    },
    navigateToRelatedContextFromRealEstateUnit(realEstateUUID){
      if (this.contextType === 'RealEstateRenter'){
        const newRoute = '/real-estate-renter/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextType === 'ServiceProvider'){
        const newRoute = '/service-provider/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextUUID){
        const newRoute = '/real-estate/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      if (realEstateUUID){
        const newRoute = '/real-estate/' + realEstateUUID
        this.$router.push(newRoute)
        return
      }
      const newRoute = '/real-estate'
      this.$router.push(newRoute)
    },
    navigateToRealEstateUnit(newUUID){
      if (this.contextType === 'RealEstateRenter'){
        const newRoute = '/real-estate-renter/' + this.contextUUID + '/real-estate-unit/' + newUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextType === 'ServiceProvider'){
        const newRoute = '/service-provider/' + this.contextUUID + '/real-estate-unit/' + newUUID
        this.$router.push(newRoute)
        return
      }
      const newRoute = '/real-estate/' + this.contextUUID + '/real-estate-unit/' + newUUID
      this.$router.push(newRoute)
    },
    showRealEstateUnitEditForm(realEstateUnitUUID){
      if (!realEstateUnitUUID){
        services.$log.warnWrap('showRealEstateUnitEditForm', 'MOC0073', 'no real estate unit uuid provided, showing overview instead')
        this.showRealEstateUnitOverview()
        return
      }
      if (this.isSubView){
        this.navigateToRealEstateUnit(realEstateUnitUUID)
        return
      }
      this.currentRealEstateUnitUUID = realEstateUnitUUID
      this.currentComponent = 'RealEstateUnitEditForm'
    },
    async showRealEstateUnitOverview(){
      this.currentRealEstateUnitUUID = ''
      this.currentComponent = 'RealEstateUnitOverview'

      await this.reloadAllRealEstateUnits()
    },
    async reloadAllRealEstateUnits(){
      await this.loadRealEstateUnitData()
    },
    realEstateUnitNameComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.name){
        return orderFirstBeforeSecond
      }
      if (!second.name){
        return orderSecondBeforeFirst
      }
      if (first.name < second.name){
        return orderFirstBeforeSecond
      }
      if (first.name > second.name){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    RealEstateUnitTable,
    RealEstateUnitEditForm,
    FormMessages,
  }
}
</script>
