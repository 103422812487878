import { services } from '@digiscape/js-core'

async function createMeter(newMeterJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/meter/create", newMeterJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'RealEstate'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/meter/create", newMeterJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    throw new Error("Invalid related type for Meter creation: '" + relatedType + "'")
}

async function readAllMetersByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/meter/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_meters
        return responseBody
    }
    if (relatedType === 'RealEstate'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/meter/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_meters
        return responseBody
    }
    return []
}

async function readMeter(meterUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/meter/" + meterUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.meter
    return responseBody
}

async function saveMeterField(meterUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: meterUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/meter/" + meterUUID +"/field", reqBody)
    const responseBody = response.data.meter
    return responseBody
}

async function deleteMeter(meterUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/meter/" + meterUUID)
}

export {
    createMeter,
    readAllMetersByRelated,
    readMeter,
    saveMeterField,
    deleteMeter,
}