import { services } from '@digiscape/js-core'

async function readRentalContractByRelated(realEstateUnitUUID, realEstateRenterUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/rental-contract/" + realEstateUnitUUID + "/" + realEstateRenterUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.rental_contract
    return responseBody
}

async function saveRentalContractField(rentalContractUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: rentalContractUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/rental-contract/" + rentalContractUUID +"/field", reqBody)
    const responseBody = response.data.rental_contract
    return responseBody
}

export { readRentalContractByRelated, saveRentalContractField }