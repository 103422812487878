<template>
  <select 
    :name="name"
    :placeholder="placeholder"
    :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
    v-model.lazy="localValue"
  >
    <option
      v-for="option in localOptions"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import FieldOption  from '@/diaspora/fields/field_option.js'

export default {
  name: 'SelectInput',
  data(){
    return {
      localValue: '',
      isValid: null
    }
  },
  props:{
    modelValue:{
      required: true
    },
    options:{
      type: Array,
      required: true
    },
    optionGroup: {
      type: String
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "n.v.",
    },
    mandatory:{
      type: Boolean
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue'
  ],
  computed:{
    defaultOption(){
      if (!this.selectableOptions || this.selectableOptions.length < 1){
        return new FieldOption('n.v.', '')
      }
      for (let optionIdx = 0; optionIdx < this.selectableOptions.length; optionIdx++) {
        const option = this.selectableOptions[optionIdx]
        if (option.default === true){
          return option
        }
      }
      if (this.mandatory === true){
        return this.selectableOptions[0]
      }
      return new FieldOption('n.v.', '')
    },
    localOptions(){
      if (!this.defaultOption){
        return []
      }
      if (!this.selectableOptions){
        return [this.defaultOption]
      }
      if (this.defaultOption.value !== ''){
        return this.selectableOptions
      }
      return [this.defaultOption, ...this.selectableOptions]
    },
    selectableOptions(){
      const currentGroup = this.optionGroup
      if (!currentGroup){
        return this.options
      }
      const filteredOptions = this.options.filter(function(option){
        if (!option.groupName){
          return true
        }
        return option.groupName === currentGroup
      })
      return filteredOptions
    },
    isValidLocalValueForGroup(){
      return this.isValidValueForGroup(this.optionGroup, this.localValue)
    }
  },
  watch:{
    modelValue:{
      immediate:true,
      handler(value){
        if (!value && this.localValue){
          this.localValue = ''
          return
        }
        const isValidValue = this.isValidValueForGroup(this.optionGroup, value)
        if (!isValidValue && this.localValue !== this.defaultOption.value){
          this.localValue = this.defaultOption.value
          return
        }
        if (!isValidValue){
          this.$emit('update:modelValue', this.defaultOption.value)
          this.$emit('valid:modelValue')
          return
        }
        this.localValue = value
        if (this.localValue == this.defaultOption.value){
          this.$emit('valid:modelValue')
        }
      }
    },
    defaultOption:{
      deep: true,
      handler(){
        if (this.defaultOption && this.defaultOption.value && (!this.modelValue || !this.isValidLocalValueForGroup)){
          this.localValue = this.defaultOption.value
        }
      }
    },
    optionGroup(newValue, oldValue){
      if (!oldValue || newValue === oldValue || this.isValidLocalValueForGroup){
        return
      }
      this.localValue = this.defaultOption.value
    },
    localValue() {
      if (this.mandatory === true && !this.localValue){
        this.isValid = false
        this.$emit('invalid:modelValue', "Das Feld '" + this.label +"' ist ein Pflichtfeld und muss ausgewählt werden.")
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000)
      }
    }
  },
  methods:{
    resetIsValid(){
      this.isValid = null
    },
    isValidValueForGroup(optionGroup, value){
      if (!value || !optionGroup){
        return true
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        if (option.value !== value){
          continue
        }
        if (option.groupName === optionGroup){
          return true
        }
      }
      return false
    }
  },
  mounted(){
    if (this.defaultOption && this.defaultOption.value && !this.modelValue){
      this.localValue = this.defaultOption.value
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>