<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-if="currentComponent === 'TechnicalInstallationOverview'"
    >
      <TechnicalInstallationTable 
        id="technicalInstallations"
        :technicalInstallations="sortedTechnicalInstallations"
        :isLoaded="isLoaded"
        @triggered:modelEdit="selectTechnicalInstallationForEdit"
        @triggered:modelCreate="createTechnicalInstallation"
        :contextType="contextType"
        :contextUUID="contextUUID"
      />
    </div>
    <div
      v-if="currentComponent === 'TechnicalInstallationEditForm'"
    >
      <TechnicalInstallationEditForm
        class="col-12"
        @cancelled:modelEdit="exitToOverview"
        @success:modelDelete="exitToOverview"
        :technicalInstallationUUID="currentTechnicalInstallationUUID"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import { readAllTechnicalInstallationsByRelated, createTechnicalInstallation } from '@/diaspora/models/queries/technical-installation.js'

import TechnicalInstallationTable from '@/components/tables/TechnicalInstallationTable.vue'
import TechnicalInstallationEditForm from '@/components/forms/TechnicalInstallationEditForm.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

export default {
  name: 'TechnicalInstallationCollection',
  data(){
    return {      
      messageStore: new MessageStore(),
      contextTechnicalInstallations: [],
      isLoaded: false,
      currentComponent: 'TechnicalInstallationOverview',
      currentTechnicalInstallationUUID: '',
    }
  },
  props:{
    isSubView: {
      type: Boolean,
    },
    realEstateUnitUUID: {
      type: String,
    },
    realEstateUUID: {
      type: String,
    },
  },
  computed:{
    contextType(){
      if (this.realEstateUnitUUID){
        return 'RealEstateUnit'
      }
      if (this.realEstateUUID){
        return 'RealEstate'
      }
      return ''
    }, 
    contextUUID(){
      if (this.realEstateUnitUUID){
        return this.realEstateUnitUUID
      }
      if (this.realEstateUUID){
        return this.realEstateUUID
      }
      return ''
    }, 
    isContextGiven(){
      return this.contextType != '' && this.contextUUID != ''
    },
    sortedTechnicalInstallations(){
      const sorted = this.cloneArray(this.contextTechnicalInstallations)
      return sorted.sort(this.technicalInstallationNumberComparator)
    }, 
  },
  async mounted(){
    await this.loadTechnicalInstallationData()
  },
  watch:{
    async contextUUID(newUUID){
      if (!newUUID){
        this.contextTechnicalInstallations = []
        return
      }
      
      await this.showTechnicalInstallationOverview()
      await this.loadTechnicalInstallationData()
    },
  },
  methods:{
    async loadTechnicalInstallationData(){
      this.isLoaded = false  
      await this.loadContextTechnicalInstallations()
      this.isLoaded = true
    },
    async loadContextTechnicalInstallations(){
      this.contextTechnicalInstallations = await this.readRelatedTechnicalInstallations()
    },
    async readRelatedTechnicalInstallations(){
      if (!this.isContextGiven){
        services.$log.warn("MOC0123", "read all technicalInstallations cannot be executed, invalid related uuid / type, skipping")
        return
      }
      const messageTarget = `readRelatedTechnicalInstallations`
      this.messageStore.flushTarget(messageTarget)

      let relatedTechnicalInstallations = []
      try{
        relatedTechnicalInstallations = await readAllTechnicalInstallationsByRelated(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0124", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedTechnicalInstallations
    },
    async createTechnicalInstallation(technicalInstallationJSON, contextType, contextUUID){
      const messageTarget = `createTechnicalInstallation`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Erstelle eine neuee technische Anlage...', messageTarget)
      if (!contextType){
        contextType = this.contextType
      }
      if (!contextUUID){
        contextUUID = this.contextUUID
      }
      try{
        await createTechnicalInstallation(technicalInstallationJSON, contextType, contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0125", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Technische Anlage erfolgreich erstellt.", messageTarget)
      
      await this.loadTechnicalInstallationData()
    },
    selectTechnicalInstallationForEdit(technicalInstallationUUID){
      this.showTechnicalInstallationEditForm(technicalInstallationUUID)
    },
    async exitToOverview(){
      await this.showTechnicalInstallationOverview()
    },
    showTechnicalInstallationEditForm(technicalInstallationUUID){
      if (!technicalInstallationUUID){
        services.$log.warnWrap('showTechnicalInstallationEditForm', 'MOC0126', 'no real estate uuid provided, showing overview instead')
        this.showTechnicalInstallationOverview()
        return
      }
      this.currentTechnicalInstallationUUID = technicalInstallationUUID
      this.currentComponent = 'TechnicalInstallationEditForm'
    },
    async showTechnicalInstallationOverview(){
      this.currentTechnicalInstallationUUID = ''
      this.currentComponent = 'TechnicalInstallationOverview'

      await this.loadTechnicalInstallationData()
    },
    technicalInstallationNumberComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.number){
        return orderFirstBeforeSecond
      }
      if (!second.number){
        return orderSecondBeforeFirst
      }
      if (first.number < second.number){
        return orderFirstBeforeSecond
      }
      if (first.number > second.number){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    TechnicalInstallationTable,
    TechnicalInstallationEditForm,
    FormMessages,
  }
}
</script>
