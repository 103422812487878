<template>
  <div class="container">
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <div 
      v-if="createAllowed"
      class="flow-card centered btn suc"
      @click="triggerCreateRealEstateListing"
    >
      <div class="body">
        <span class="btn-text">
          Neue Anzeige erstellen
        </span>
      </div>
    </div>
    <div 
      class="flow-card btn"
      @click="triggerEditRealEstateListing($event, realEstateListing)"
      v-for="realEstateListing in realEstateListings"      
      :key="realEstateListing.uuid"
    >
      <img
        class="overlay stamp"
        v-if="realEstateListing.publish_status === 'Draft'"
        src="@/assets/StampDraft.png"
      />
      <img
        class="overlay stamp"
        v-if="realEstateListing.publish_status === 'Archived'"
        src="@/assets/StampArchived.png"
      />
      <div
        class="overlay-hover-hl"
      >
      </div>
      <div
        class="background"
        v-if="realEstateListing.cover_image_relative_path_thumbnail"
      >
        <img :src="coverImagePath(realEstateListing.cover_image_relative_path_thumbnail)"/>
      </div>
      <div class="body text-start">
        <div v-if="realEstateListing.real_estate_listing_tags">
          <span
            v-for="(tag, tagIdx) in realEstateListing.real_estate_listing_tags"
            :key="tagIdx"
            class="tag"
            :style="'background-color:' + tag.color"
          >
            <span>{{ tag.name }}</span>
          </span>
        </div>
      </div>
      <div class="footer">
        <div class="real-estate-address">
          <span class="real-estate-street">{{ realEstateListing.street_name}} {{ realEstateListing.street_number }}</span>
          <span class="real-estate-city">{{ realEstateListing.zip_code}} {{ realEstateListing.city }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMessages from '@/components/forms/features/form-messages.js'

export default {
  name: 'RealEstateListingGrid',
  emits: [
    'triggered:modelCreation',
    'triggered:modelEdit'
  ],
  data(){
    return {
      currentComponent: 'RealEstateListingGrid',
      messages: new FormMessages()
    }
  },
  props:{
    createAllowed:{
      type: Boolean,
      default: true
    },
    realEstateListings:{
      type: Array
    }
  },
  computed:{
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    }
  },
  methods:{
    triggerCreateRealEstateListing(){
      this.$emit('triggered:modelCreation')
    },
    triggerEditRealEstateListing(e, realEstateListing){
      this.$emit('triggered:modelEdit', realEstateListing.uuid)
    },
    coverImagePath(relativePath){
      return process.env.VUE_APP_API_BASE_URL + '/files' + relativePath
    }
  }
}
</script>

<style>
.real-estate-address{
  width: 100%;
  font-weight: bold;
}
.real-estate-street,
.real-estate-city{
  display: block;
  display: -webkit-box;

  text-overflow: ellipsis;
  max-height: 1.4rem;
  overflow: hidden;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
}

.real-estate-details > .field > label{
  width: 35%;
}
.real-estate-details > .field > .value{
  width: 65%;
}
</style>
