<template>
  <div id="technical-installation-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Die technische Anlage wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 style="display:flex">
        Technische Anlage -
        <span class="text-limit-3 fst-italic txt-sec"> {{ localTechnicalInstallationIdentifier }}</span>
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteTechnicalInstallation"
          >
            Löschen
          </button>
        </div>
      </div>
      <div class="row">
        <div 
          class="col-12"
        >
          <TechnicalInstallationFields
            :technicalInstallation="localTechnicalInstallation"
            @update:modelValue="updateLocalTechnicalInstallation"
            @update:fieldValue="saveTechnicalInstallationField"
            @error:modelFields="modelFieldsErrorHandler"
          />
          <h5>Wartungseinträge</h5>
          <TechnicalInstallationMaintenanceTable
            id="technical-installation-maintenances"
            :technicalInstallationType="localTechnicalInstallationType"
            :technicalInstallationMaintenances="sortedTechnicalInstallationMaintenances"
            :isLoaded="isLoaded"
            @triggered:modelDelete="deleteTechnicalInstallationMaintenance"
            @triggered:modelCreate="createTechnicalInstallationMaintenance"
          />
          <div class="row mb-3 gx-1">
            <div class="col-6">
                <button
                class="btn btn-brand form-control"
                type="button"
                @click="cancelEdit"
                >
                  Abbrechen
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import { readTechnicalInstallation, saveTechnicalInstallationField, deleteTechnicalInstallation } from '@/diaspora/models/queries/technical-installation.js'
import { readAllTechnicalInstallationMaintenances, createTechnicalInstallationMaintenance, deleteTechnicalInstallationMaintenance } from '@/diaspora/models/queries/technical-installation-maintenance.js'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import TechnicalInstallationFields from  '@/components/forms/TechnicalInstallationFields.vue'
import TechnicalInstallationMaintenanceTable from  '@/components/tables/TechnicalInstallationMaintenanceTable.vue'

import TechnicalInstallation from '@/diaspora/models/technical-installation.js'
import TechnicalInstallationMaintenance from '@/diaspora/models/technical-installation-maintenance.js'

export default {
  name: 'TechnicalInstallationEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
  ],
  data(){
    return {
      localTechnicalInstallation: {},
      localTechnicalInstallationMaintenances: [],
      messageStore: new MessageStore(),
      isLoaded: false,
      shortcutHandlers: {
        "ESCAPE": this.cancelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  props:{
    technicalInstallationUUID:{
      type: String,
      required: true
    },
  },
  computed:{    
    localTechnicalInstallationModel(){
      const installation = new TechnicalInstallation()
      installation.unmarshalJSON(this.localTechnicalInstallation)
      return installation
    },
    localTechnicalInstallationIdentifier(){
      return this.localTechnicalInstallationModel.getIdentifierString()
    },
    localTechnicalInstallationType(){
      if (this.localTechnicalInstallation.type){
        return this.localTechnicalInstallation.type
      }
      return ''
    },
    sortedTechnicalInstallationMaintenances(){
      const sorted = this.cloneArray(this.localTechnicalInstallationMaintenances)
      return sorted.sort(this.technicalInstallationMaintenanceMaintenanceAtComparator)
    }, 
  },
  async mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadTechnicalInstallation.bind(this))

    await this.reloadTechnicalInstallation()
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async technicalInstallationUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadTechnicalInstallation()
    },
  },
  methods:{
    async reloadTechnicalInstallation(){
      this.isLoaded = false
      await this.loadTechnicalInstallation(this.technicalInstallationUUID)
      this.isLoaded = true
    },
    async loadTechnicalInstallation(technicalInstallationUUID){
      await Promise.all([
        this.readTechnicalInstallation(technicalInstallationUUID),
        this.readAllTechnicalInstallationMaintenances(technicalInstallationUUID),
      ])
    },
    async readTechnicalInstallation(technicalInstallationUUID){
      const messageTarget = `readTechnicalInstallation`
      this.messageStore.flushTarget(messageTarget)

      let technicalInstallation = {}
      try{
        technicalInstallation = await readTechnicalInstallation(technicalInstallationUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0116", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localTechnicalInstallation = technicalInstallation
      return true
    },
    async readAllTechnicalInstallationMaintenances(technicalInstallationUUID){
      const messageTarget = `readAllTechnicalInstallationMaintenances`
      this.messageStore.flushTarget(messageTarget)

      let technicalInstallationMaintenances = []
      try{
        technicalInstallationMaintenances = await readAllTechnicalInstallationMaintenances(technicalInstallationUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0127", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localTechnicalInstallationMaintenances = technicalInstallationMaintenances
      return true
    },
    async deleteTechnicalInstallation(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `deleteTechnicalInstallation.${this.technicalInstallationUUID}`
      this.messageStore.flushTarget(messageTarget)

      if (!this.technicalInstallationUUID){
        this.messageStore.error("MOC0117", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0118", "delete technical installation cannot be executed, invalid uuid")
        return
      }
      const identifier = this.localTechnicalInstallationIdentifier
      const isConfirmed = confirm("Sicher, dass die technische Anlage '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Die technische Anlage '"+ identifier +"' wird gelöscht...", messageTarget)

      try{
        await deleteTechnicalInstallation(this.technicalInstallationUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0119", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Technische Anlage '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },
    async saveTechnicalInstallationField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.technicalInstallationUUID){
        this.messageStore.error("MOC0120", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0121", "delete real estate cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Feld der technischen Anlage '${fieldLabel}' wird gespeichert...`, fieldName)

      let technicalInstallation = {}
      try{
        technicalInstallation = await saveTechnicalInstallationField(this.technicalInstallationUUID, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0122", requestErr)
        this.messageStore.error(parsedError.code, `Feld der technischen Anlage '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Feld der technischen Anlage '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new TechnicalInstallation()
      saved.unmarshalJSON(technicalInstallation)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localTechnicalInstallation[jsonFieldName] = savedValue
      }
    },
    async createTechnicalInstallationMaintenance(technicalInstallationJSON){
      const messageTarget = `createTechnicalInstallationMaintenance`
      this.messageStore.flushTarget(messageTarget)

      if (!this.technicalInstallationUUID){
        this.messageStore.error("MOC0132", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0133", "create technical installation maintenance cannot be executed, invalid technicalInstallation uuid")
        return
      }

      this.messageStore.pending('', 'Erstelle einen neuen Wartungseintrag...', messageTarget)

      try{
        await createTechnicalInstallationMaintenance(this.technicalInstallationUUID, technicalInstallationJSON)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0128", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Wartungseintrag erfolgreich erstellt.", messageTarget)
      
      await this.reloadTechnicalInstallation()
    },
    async deleteTechnicalInstallationMaintenance(technicalInstallationMaintenanceJSON){
      const messageTarget = `deleteTechnicalInstallationMaintenance`
      this.messageStore.flushTarget(messageTarget)

      if (!technicalInstallationMaintenanceJSON.uuid){
        this.messageStore.error("MOC0129", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0130", "delete technical installation maintenance cannot be executed, invalid uuid")
        return
      }

      const technicalInstallationMaintenanceMod = new TechnicalInstallationMaintenance()
      technicalInstallationMaintenanceMod.unmarshalJSON(technicalInstallationMaintenanceJSON)
      const identifier = technicalInstallationMaintenanceMod.getIdentifierString()
      const isConfirmed = confirm("Sicher, dass der Wartungseintrag '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Der Wartungseintrag vom "+ identifier +" wird gelöscht...", messageTarget)

      try{
        await deleteTechnicalInstallationMaintenance(technicalInstallationMaintenanceJSON.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0131", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Wartungseintrag vom "+identifier+" wurde erfolgreich gelöscht.", messageTarget)
      await this.reloadTechnicalInstallation()
    },
    updateLocalTechnicalInstallation(technicalInstallation){
      if (JSON.stringify(this.localTechnicalInstallation) === JSON.stringify(technicalInstallation)){
        return
      }
      this.localTechnicalInstallation = technicalInstallation
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    technicalInstallationMaintenanceMaintenanceAtComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      const firstMaintenanceAt = services.$parse.timestamp(first.maintenance_at)
      if (!firstMaintenanceAt || firstMaintenanceAt.getFullYear() < 2000){
        return orderFirstBeforeSecond
      }
      const secondMaintenanceAt = services.$parse.timestamp(second.maintenance_at)
      if (!secondMaintenanceAt || secondMaintenanceAt.getFullYear() < 2000){
        return orderSecondBeforeFirst
      }
      if (firstMaintenanceAt < secondMaintenanceAt){
        return orderFirstBeforeSecond
      }
      if (firstMaintenanceAt > secondMaintenanceAt){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }      
      this.$emit('cancelled:modelEdit')
    },
  },
  components:{
    TechnicalInstallationFields,
    TechnicalInstallationMaintenanceTable,
    FormMessages,
  }
}
</script>