import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class BankAccount extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField(  'uuid',                 'UUID'),
            name:                   new StringField('name',                 'Name', true),
            iban:                   new StringField('iban',                 'IBAN', true),
            bic:                    new StringField('bic',                  'BIC'),
            bankName:               new StringField('bankName',             'Bank'),
            accountHolderUUID:      new UUIDField(  'accountHolderUUID',    'UUID (Kontoinh.)'),
            accountHolderType:      new StringField('accountHolderType',    'Typ (Kontoinh.)'),
            accountHolderName:      new StringField('accountHolderName',    'Name (Kontoinh.)'),
            notes:                  new StringField('notes',                'Notizen'),
        }
    }
    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default BankAccount