<template>
  <div>
    <div
      v-show="!isLoaded"
      class="m-2"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Objekte werden geladen...</h5>
    </div>
    <table
      id="real-estates"
      class="table table-sm table-striped"
      v-show="isLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th>Objekt-Nr</th>
          <th style="width:110px">Typ</th>
          <th class="show-xxl" v-show="!compact">Zusatztyp</th>
          <th style="width:70px">PLZ</th>
          <th>Ort</th>
          <th>Straße</th>
          <th style="width:100px">Haus-Nr</th>
          <th class="show-xxl" v-show="!compact" style="width:80px">Baujahr</th>
          <th class="show-xxl" v-show="!compact" style="width:80px">Saniert</th>
          <th style="width:50px"></th>
          <th
            v-if="isDetachAllowed"
            style="width:50px"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="realEstate in realEstates"
          :key="realEstate.uuid"
        >
          <td
            label="Objekt-Nr"
          >
            {{ realEstate.short_code }}
          </td>
          <td
            label="Typ"
          >
            {{ realEstate.type }}
          </td>
          <td
            class="show-xxl show-sm"
            v-show="!compact"
            label="Zusatztyp"
          >
            {{ realEstate.sub_type }}
          </td>
          <td
            label="PLZ"
          >
            {{ realEstate.zip_code }}
          </td>
          <td
            label="Ort"
          >
            {{ realEstate.city }}
          </td>
          <td
            label="Straße"
          >
            {{ realEstate.street_name }}
          </td>
          <td
            label="Haus-Nr"
          >
            {{ realEstate.street_number }}
          </td>
          <td 
            class="show-xxl show-sm"
            v-show="!compact"
            label="Baujahr"
          >
            <span v-if="realEstate.year_of_construction > 0">{{ realEstate.year_of_construction }}</span>
          </td>
          <td
            class="show-xxl show-sm"
            v-show="!compact"
            label="Saniert"
          >
            <span v-if="realEstate.last_renovation_year > 0">{{ realEstate.last_renovation_year }}</span>
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditRealEstate($event, realEstate)"
            >
              Bearbeiten
            </div>
            <div
              class="btn-icon show-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditRealEstate($event, realEstate)"
            >
              <PencilIcon />
            </div>
          </td>
          <td
            v-if="isDetachAllowed"
          >
            <div
              class="btn btn-warn hide-md"
              title="Entfernen"
              role="button"
              @click="triggerDetachRealEstate($event, realEstate)"
            >
              Entfernen
            </div>
            <div
            class="btn-icon-warn show-md"
            title="Entfernen"
            role="button"
            @click="triggerDetachRealEstate($event, realEstate)"
            >
              <XIcon />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import PencilIcon from  '@/components/icons/PencilIcon.vue'
import XIcon from  '@/components/icons/XIcon.vue'

export default {
  name: 'RealEstateTable',
  emits: [
    'triggered:modelEdit'
  ],
  props:{
    contextType: {
      type: String,
    },
    contextUUID:{
      type: String,
    },
    realEstates:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    },
    compact:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    isDetachAllowed(){
      return this.contextUUID && this.contextType == 'ServiceProvider'
    }
  },
  methods:{
    triggerEditRealEstate(e, realEstate){
      this.$emit('triggered:modelEdit', realEstate.uuid)
    },
    triggerDetachRealEstate(e, realEstate){
      this.$emit('triggered:modelDetach', realEstate.uuid)
    },
  },
  components:{
    PencilIcon,
    XIcon,
  },
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>