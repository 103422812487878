import Derivative from '@/diaspora/derivative.js'

import IntegerField from '@/diaspora/fields/integer_field.js'

class ChannelAPIUsageOverview extends Derivative{
    constructor() {
        super() 
        this.fields = {
            apiCalls:                       new IntegerField('apiCalls', 'API-Calls'),
            apiCallLimit:                   new IntegerField('apiCallLimit', 'Max. API-Calls'),
            apiCallNotificationInterval:    new IntegerField('apiCallNotificationInterval', 'Notifikations-Intervall'),
        }
    }
}

export default ChannelAPIUsageOverview