<template>
  <fieldset  
    :class="{ 'select-button-container': true, 'centered': centered }"
  >
    <select 
      :name="name"
      v-model.lazy="selectedValue"
      class="select-button"
      :class="[{'small': small }, buttonClass]"
    >
      <option class="select-button-label" :value="label" style="display:none" selected>{{label}}</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </fieldset>
</template>

<script>
export default {
  name: 'SelectButtonInput',
  data(){
    return {
      selectedValue: ''
    }
  },
  props:{
    options:{
      type: Array,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    centered: {
      type: Boolean
    },
    small: {
      type: Boolean
    },
    buttonClass:{
      type: String
    },
    confirmMessage:{
      type: String
    }
  },
  emits: [
    'selected:modelValue',
  ],
  created(){
    this.selectedValue = this.label
  },
  watch:{
    selectedValue(){
      if (this.selectedValue == this.label){
        return
      }
      if (this.confirmMessage){
        let resolvedMessage = this.injectConfirmMessageWithValue(this.selectedLabel)
        const isConfirmed = confirm(resolvedMessage)
        if (!isConfirmed){
          this.selectedValue = this.label
          return
        }
      }
      this.$emit("selected:option", this.selectedLabel, this.selectedValue)
      this.selectedValue = this.label
    }
  },
  methods: {
    injectConfirmMessageWithValue(value){
      return this.confirmMessage.replace(new RegExp(`{{[ ]*option[ ]*}}`), value)
    }
  },
  computed:{
    selectedLabel(){      
      if (!this.selectedValue || this.selectedValue == this.label){
        return ""
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx];
        if (this.selectedValue == option.value){
          return option.label
        }        
      }
      return ""
    }
  }
}
</script>

<style scoped>
.select-button-container{
  position: relative;
}
.select-button-container:before {
    content: "";
    position: absolute;
    right: 7px;
    top: calc(50% - 3.5px);
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid;
    pointer-events: none;
}
</style>