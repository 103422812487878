
// FormMessages is deprecated. Use multiple message `MessageStore` implementation with `FormMessages` component instead.
class FormMessages{
    constructor(){
        this.successMessage = ''
        this.errorMessage = ''
        this.errorCode = ''
        this.pendingMessage = ''
    }

    pending(message){
      this.successMessage = ''
      this.pendingMessage = message
      this.errorCode = ''
      this.errorMessage = ''
    }

    success(message){      
      window.setTimeout(this.resetSuccessMessage.bind(this), 1500)
      this.successMessage = message
      this.pendingMessage = ''
      this.errorCode = ''
      this.errorMessage = ''
    }
  
    resetSuccessMessage(){
      this.successMessage = ''
    }
  
    error(code, message){
      this.successMessage = ''
      this.pendingMessage = ''
      this.errorCode = code
      this.errorMessage = message
    }

    reset(){
      this.successMessage = ''
      this.pendingMessage = ''
      this.errorCode = ''
      this.errorMessage = ''
    }
}

export default FormMessages