<template>
  <div id="bank-account-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Das Konto wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 style="display:flex">
        <span style="width: 125px;">Konto</span> - <span class="text-limit-3 fst-italic txt-sec ps-1">{{ localBankAccount.name }}</span>
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteBankAccount"
          >
            Löschen
          </button>
        </div>
      </div>
      <div class="row">
        <div 
          class="col-12"
        >
          <BankAccountFields
            :bankAccount="localBankAccount"
            @update:modelValue="updateLocalBankAccount"
            @update:fieldValue="saveBankAccountField"
            @error:modelFields="modelFieldsErrorHandler"
          />
          <div class="row mb-3 gx-1">
            <div class="col-6">
                <button
                class="btn btn-brand form-control"
                type="button"
                @click="cancelEdit"
                >
                  Abbrechen
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import { readBankAccount, saveBankAccountField, deleteBankAccount } from '@/diaspora/models/queries/bank-account.js'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import BankAccountFields from  '@/components/forms/BankAccountFields.vue'

import BankAccount from '@/diaspora/models/bank-account.js'

export default {
  name: 'BankAccountEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
  ],
  data(){
    return {
      localBankAccount: {},
      messageStore: new MessageStore(),
      isLoaded: false,
      shortcutHandlers: {
        "ESCAPE": this.cancelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  props:{
    bankAccountUUID:{
      type: String,
      required: true
    },
  },
  computed:{    
    localBankAccountIdentifier(){
      let identifier = this.localBankAccount.number
      if (identifier) {
        return identifier
      }
      return this.bankAccountUUID
    },
    localBankAccountType(){
      if (this.localBankAccount.type){
        return this.localBankAccount.type
      }
      return ''
    },
  },
  async mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadBankAccount.bind(this))

    await this.reloadBankAccount()
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async bankAccountUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadBankAccount()
    },
  },
  methods:{
    async reloadBankAccount(){
      this.isLoaded = false
      await this.loadBankAccount(this.bankAccountUUID)
      this.isLoaded = true
    },
    async loadBankAccount(bankAccountUUID){
      await Promise.all([
        this.readBankAccount(bankAccountUUID),
      ])
    },
    async readBankAccount(bankAccountUUID){
      const messageTarget = `readBankAccount`
      this.messageStore.flushTarget(messageTarget)

      let bankAccount = {}
      try{
        bankAccount = await readBankAccount(bankAccountUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0163", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localBankAccount = bankAccount
      return true
    },
    async deleteBankAccount(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `deleteBankAccount.${this.bankAccountUUID}`
      this.messageStore.flushTarget(messageTarget)

      if (!this.bankAccountUUID){
        this.messageStore.error("MOC0164", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0165", "delete bankAccount cannot be executed, invalid uuid")
        return
      }
      const identifier = this.localBankAccountIdentifier
      const isConfirmed = confirm("Sicher, dass der Konto '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Das Konto '"+ identifier +"' wird gelöscht...", messageTarget)

      try{
        await deleteBankAccount(this.bankAccountUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0166", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Konto '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },
    async saveBankAccountField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.bankAccountUUID){
        this.messageStore.error("MOC0167", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0168", "delete real estate cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Konto-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let bankAccount = {}
      try{
        bankAccount = await saveBankAccountField(this.bankAccountUUID, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0169", requestErr)
        this.messageStore.error(parsedError.code, `Konto-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Konto-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new BankAccount()
      saved.unmarshalJSON(bankAccount)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localBankAccount[jsonFieldName] = savedValue
      }
    },
    updateLocalBankAccount(bankAccount){
      if (JSON.stringify(this.localBankAccount) === JSON.stringify(bankAccount)){
        return
      }
      this.localBankAccount = bankAccount
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }      
      this.$emit('cancelled:modelEdit')
    },
  },
  components:{
    BankAccountFields,
    FormMessages,
  }
}
</script>