<template>
<div
    @click="triggerToggleEditDocument"
>
    <div
        v-show="!draggingDocumentUUID"
        class="overlay-hover"
        :class="{ 'overlay suc': editDocumentUUID === documentUUID }"
    >
    </div>
    <div
        class="drop-zone left"
        @dragover="handleDragOverDocumentDropZone"
        @dragleave="handleDragOutDocumentDropZone"
        @drop="handleDocumentDropLeft"
        v-show="draggingDocumentUUID && draggingDocumentUUID !== documentUUID"
    >
        <span class="label">&lt;</span>
    </div>
    <div
        class="drop-zone right"
        @dragover="handleDragOverDocumentDropZone"
        @dragleave="handleDragOutDocumentDropZone"
        @drop="handleDocumentDropRight"
        v-show="draggingDocumentUUID && draggingDocumentUUID !== documentUUID"
    >
        <span class="label">&gt;</span>
    </div>
    <div class="body hide-hover-background">
        <SyncStatusIndicator 
            label="IS24"
            :isActive="documentModel.fields.channelImmoscout24.value"
            :status="channelImmoscout24SyncStatus"
        />
        <SyncStatusIndicator 
            label="B"
            :isActive="documentModel.fields.channelBookoo.value"
            :status="channelBookooSyncStatus"
        />
    </div>
    <div class="background">
        <img :src="documentURLPath(documentModel.fields.relativePathThumbnail.value)">
    </div>
    <div class="footer">
        <span
        class="text-limit-2"
        :title="documentModel.fields.name.value"
        >
        {{ documentModel.fields.name.value }}
        </span>
    </div>
</div>
</template>

<script>
import { services } from '@digiscape/js-core'

import SyncStatusIndicator from  '@/components/indicators/SyncStatusIndicator.vue'

export default {
    name: 'DocumentGridItem',
    emits: [
        'success:message',
        'error:message',
        'pending:message',
        'dropLeft:document',
        'dropRight:document',
        'triggered:modelDeletion',
        'triggered:toggleModelEdit',
    ],
    props:{
        documentModel:{
            type: Object,
            required: true,
        },
        editDocumentUUID:{
            type: String,
        },
        draggingDocumentUUID:{
            type: String,
        }
    },
    data(){
        return {
            channelImmoscout24SyncStatus: '',
            channelBookooSyncStatus: '',
        }
    },
    async created(){
        await this.readAllDocumentSyncStatuses(this.documentUUID)
    },
    computed:{
        fields(){
            return this.documentModel.fields
        },
        documentUUID(){
            return this.fields.uuid.value
        },
    },
    watch:{
        fields: {
            deep: true,
            async handler(){
                await this.readAllDocumentSyncStatuses(this.documentUUID)
            }
        },
    },
    methods:{
        async readAllDocumentSyncStatuses(documentUUID){
            if (!documentUUID){
                return
            }
            let response = {}
            try{
                response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/document/" + documentUUID + "/sync-status")
            } catch(requestErr) {
                const parsedError = services.$err.parseRequestError("MOC0039", requestErr)
                this.emitErrorMessage(parsedError.code, parsedError.message)
                return false
            }
            if (response.status === 204){
                this.channelImmoscout24SyncStatus = ''
                this.channelBookooSyncStatus = ''
                return
            }
            this.channelImmoscout24SyncStatus = response.data.channel_immoscout24
            this.channelBookooSyncStatus = response.data.channel_bookoo
            return true
        },
        handleDragOverDocumentDropZone(event){
            event.preventDefault()
            event.target.classList.add("active")
        },
        handleDragOutDocumentDropZone(event){
            event.target.classList.remove("active")
        },
        handleDocumentDropLeft(event){
            event.preventDefault()

            event.target.classList.remove("active")

            const sourceOrder = event.dataTransfer.getData('channelOrder')
            const targetOrder = this.fields.channelOrder.value
            this.$emit('dropLeft:document', sourceOrder, targetOrder)
        },
        handleDocumentDropRight(event){
            event.preventDefault()

            event.target.classList.remove("active")

            const sourceOrder = event.dataTransfer.getData('channelOrder')
            const targetOrder = this.fields.channelOrder.value
            this.$emit('dropRight:document', sourceOrder, targetOrder)
        },
        triggerDeleteDocument(event){
            event.preventDefault()

            if (!this.documentUUID){
                this.setErrorMessage("MOC0018", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
                services.$log.fatal("MOC0018", "delete document cannot be executed, invalid uuid")
                return false
            }
            const documentName = this.fields.name.value
            const isConfirmed = confirm("Sicher, dass die Datei '"+documentName+"' gelöscht werden soll?")
            if (!isConfirmed){
                return false
            }
            this.$emit('triggered:modelDeletion', this.documentUUID)
        },
        triggerToggleEditDocument(event){
            event.preventDefault()

            if (!this.documentUUID){
                this.setErrorMessage("MOC0041", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
                services.$log.fatal("MOC0041", "toggle edit document cannot be executed, invalid uuid")
                return false
            }
            this.$emit('triggered:toggleModelEdit', this.documentUUID)
        },
        emitSuccessMessage(successMessage){      
            this.$emit('success:message', successMessage)    
        },
        emitErrorMessage(errorCode, errorMessage){      
            this.$emit('error:message', errorCode, errorMessage)    
        },
        emitPendingMessage(pendingMessage){      
            this.$emit('pending:message', pendingMessage)    
        },
        documentURLPath(relativePath){
            return process.env.VUE_APP_API_BASE_URL + '/files' + relativePath
        },
    },
    components:{
        SyncStatusIndicator,
    },
}
</script>