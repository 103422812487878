<template>
  <div class="container">
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <div>
      <h4>Immobilien-Objekte</h4>
      <div v-if="!unresolvedSyncErrorsExist">Aktuell liegen keine Synchronisationsfehler vor.</div>
      <table id="real-estate-sync-errors" class="table table-striped" v-if="unresolvedSyncErrorsExist">
        <thead class="thead-dark">
          <tr>
            <th width="175px">Zeitstempel</th>
            <th width="65px">Kanal</th>
            <th width="200px">Operation</th>
            <th>Meldung</th>
            <th>Daten (lokal)</th>
            <th>Daten (Kanal)</th>
            <th width="50px"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="syncErr in unresolvedSyncErrors"
            :key="syncErr.uuid"
          >
            <td>
              {{ formatDate(syncErr.synced_at)}}
            </td>
            <td>
              {{ formatChannel(syncErr.channel_id) }}
            </td>
            <td>
              {{ syncErr.sync_operation }}
            </td>
            <td>
              {{ syncErr.resolution_message }}
            </td>
            <td
              class="clickable"
              @click="copyToClipboard($event, syncErr.local_json)"
            >
              <div class="text-limit-2">
                {{ syncErr.local_json }}
              </div>
            </td>
            <td              
              class="clickable"
              @click="copyToClipboard($event, syncErr.remote_json)"
            >
              <div class="text-limit-2">
                {{ syncErr.remote_json }}
              </div>
            </td>
            <td>
              <div
                class="btn-icon"
                title="Zum Objekt"
                role="button"
                v-show="syncErr.real_estate_listing_uuid"
                @click="navigateToRealEstateListing($event, syncErr.real_estate_listing_uuid)"
              >
                <HouseIcon />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import HouseIcon from  '@/components/icons/HouseIcon.vue'

export default {
  name: 'RealEstateListingSyncErrorsTable',
  data(){
    return {
      unresolvedSyncErrors: [],
      successMessage: '',
      errorCode: '',
      errorMessage: ''
    }
  },
  async created(){
    await this.readAllUnresolvedSyncErrors()
  },
  methods:{
    async readAllUnresolvedSyncErrors(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/sync-status/errors/real-estate-listings")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0045", requestErr)
        this.setErrorMessage(parsedError.code, parsedError.message)
        return false
      }
      this.unresolvedSyncErrors = response.data.sync_logs
      return true
    },
    formatDate(value){
      return services.$format.timestamp(value)
    },
    formatChannel(value){
      if (value === 'Immoscout24'){
        return 'IS24'
      }
      if (value === 'Bookoo'){
        return 'B'
      }
      return ''
    },
    navigateToRealEstateListing(e, realEstateListingUUID){
      e.preventDefault()

      const newRoute = '/real-estate-listing/live/'+realEstateListingUUID
      this.$router.push(newRoute)
    },
    async copyToClipboard(e, value){
      e.preventDefault()

      await navigator.clipboard.writeText(value) 
      this.setSuccessMessage("Text kopiert!")
    },
    setSuccessMessage(message){
      window.setTimeout(this.resetSuccessMessage, 1500)
      this.successMessage = message
      this.errorCode = ''
      this.errorMessage = ''
    },
    resetSuccessMessage(){
      this.successMessage = ''
    },
    setErrorMessage(code, message){
      this.successMessage = ''
      this.errorCode = code
      this.errorMessage = message
    }
  },
  computed:{
    unresolvedSyncErrorsExist(){
      if (!this.unresolvedSyncErrors){
        return false
      }
      return this.unresolvedSyncErrors.length > 0
    },
  },
  components:{
    HouseIcon
  }
}
</script>

<style>
</style>
