import { services } from '@digiscape/js-core'

import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'
import TimestampField from '@/diaspora/fields/timestamp_field'

class TechnicalInstallationMaintenance extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                       new UUIDField('uuid', 'UUID'),
            technicalInstallationUUID:  new UUIDField('technicalInstallationUUID', 'UUID (Techn. Anl.)'),
            maintenanceAt:              new TimestampField('maintenanceAt', 'Wartung am', true),
            type:                       new StringField('type', 'Typ', true, [
                new FieldOption('Wartung',      'Wartung'),
                new FieldOption('Reparatur',    'Reparatur'),
                new FieldOption('Austausch',    'Austausch'),
            ]),
            description:                new StringField('description', 'Beschreibung'),
        }
    }

    getIdentifierString(){
        const maintenanceAtDate = services.$format.date(this.fields.maintenanceAt.value)
        if (!maintenanceAtDate){
            return this.fields.uuid.value
        }
        return maintenanceAtDate
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default TechnicalInstallationMaintenance