import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField  from '@/diaspora/fields/string_field.js'
import UUIDField    from '@/diaspora/fields/uuid_field.js'
import BoolField    from '@/diaspora/fields/bool_field.js'
import IntegerField from '@/diaspora/fields/integer_field.js'
import FloatField   from '@/diaspora/fields/float_field.js'

class RealEstateListing extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:           new UUIDField('uuid', 'UUID'),
            coverImageUUID: new UUIDField('coverImageUUID', 'Titelbild-UUID'),
            type:           new StringField('type', 'Typ', true, [
                new FieldOption('Wohnung',  'Wohnung', true),
                new FieldOption('Haus',     'Haus'),
            ]),
            subType:       new StringField('subType', 'Zusatztyp', false, [
                new FieldOption('Dachgeschoss',         'Dachgeschoss',         false, 'Wohnung'),
                new FieldOption('Maisonette',           'Maisonette',           false, 'Wohnung'),
                new FieldOption('Loft',                 'Loft',                 false, 'Wohnung'),
                new FieldOption('Penthouse',            'Penthouse',            false, 'Wohnung'),
                new FieldOption('Terrassenwohnung',     'Terrassenwohnung',     false, 'Wohnung'),
                new FieldOption('Erdgeschosswohnung',   'Erdgeschosswohnung',   false, 'Wohnung'),
                new FieldOption('Etagenwohnung',        'Etagenwohnung',        false, 'Wohnung'),
                new FieldOption('Hochparterre',         'Hochparterre',         false, 'Wohnung'),
                new FieldOption('Souterrain',           'Souterrain',           false, 'Wohnung'),
                new FieldOption('Sonstige',             'Sonstige',             false, 'Wohnung'),

                new FieldOption('Einfamilienhaus (freistehend)',  'Einfamilienhaus (freistehend)', false, 'Haus'),
                new FieldOption('Reihenmittelhaus',               'Reihenmittelhaus',              false, 'Haus'),
                new FieldOption('Reiheneckhaus',                  'Reiheneckhaus',                 false, 'Haus'),
                new FieldOption('Mehrfamilienhaus',               'Mehrfamilienhaus',              false, 'Haus'),
                new FieldOption('Bungalow',                       'Bungalow',                      false, 'Haus'),
                new FieldOption('Bauernhaus',                     'Bauernhaus',                    false, 'Haus'),
                new FieldOption('Doppelhaushälfte',               'Doppelhaushälfte',              false, 'Haus'),
                new FieldOption('Villa',                          'Villa',                         false, 'Haus'),
                new FieldOption('Burg/Schloss',                   'Burg/Schloss',                  false, 'Haus'),
                new FieldOption('Besondere Immobilie',            'Besondere Immobilie',           false, 'Haus'),
                new FieldOption('Wohnimmobilie (sonstige)',       'Wohnimmobilie (sonstige)',      false, 'Haus'),
            ]),
            contractType: new StringField('contractType', 'Vermarktung', true, [
                new FieldOption('Vermietung',   'Vermietung', true),
                new FieldOption('Verkauf',      'Verkauf'),
            ]),
            streetName:     new StringField('streetName', 'Straße', true),
            streetNumber:   new StringField('streetNumber', 'Haus-Nr', true),
            zipCode:        new StringField('zipCode', 'PLZ', true),
            city:           new StringField('city', 'Ort', true),
            condition:      new StringField('condition', 'Zustand', false, [
                new FieldOption('Erstbezug',                'Erstbezug'),
                new FieldOption('Erstbezug nach Sanierung', 'Erstbezug nach Sanierung'),
                new FieldOption('Neuwertig',                'Neuwertig'),
                new FieldOption('Saniert',                  'Saniert'),
                new FieldOption('Modernisiert',             'Modernisiert'),
                new FieldOption('Vollständig renoviert',    'Vollständig renoviert'),
                new FieldOption('Gepflegt',                 'Gepflegt'),
                new FieldOption('Renovierungsbedürftig',    'Renovierungsbedürftig'),
                new FieldOption('Nach Vereinbarung',        'Nach Vereinbarung'),
                new FieldOption('Abbruchreif',              'Abbruchreif'),
            ]),            
            furnishingsQuality:       new StringField('furnishingsQuality', 'Qualität d. Ausstattung', false, [
                new FieldOption('Luxus',   'Luxus'),
                new FieldOption('Gehoben', 'Gehoben'),
                new FieldOption('Normal',  'Normal'),
                new FieldOption('Einfach', 'Einfach'),
            ]),
            numberTotalRooms:   new IntegerField('numberTotalRooms', 'Anz. Zimmer', true),
            numberBathrooms:    new IntegerField('numberBathrooms', 'Anz. Badezimmer'),
            numberBedrooms:     new IntegerField('numberBedrooms', 'Anz. Schlafzimmer'),
            numberFloors:       new IntegerField('numberFloors', 'Anz. Etagen'),
            floor:              new IntegerField('floor', 'Etage'),
            yearOfConstruction: new IntegerField('yearOfConstruction', 'Baujahr'),
            lastRenovationYear: new IntegerField('lastRenovationYear', 'Saniert'),
            commercialUse:      new BoolField('commercialUse', 'Gewerbl. Nutzung', false, [
                new FieldOption('Privat',   false, true),
                new FieldOption('Gewerbl.', true),
            ]),

            channelImmoscout24: new BoolField('channelImmoscout24', 'Immoscout24', false, [
                new FieldOption('Nein', false),
                new FieldOption('Ja',   true, true),
            ] ),
            channelBookoo:      new BoolField('channelBookoo', 'Bookoo'),
            publishStatus:      new StringField('publishStatus', 'Veröffentlichungsstatus', false, [
                new FieldOption('Entwurf',      'Draft', true),
                new FieldOption('Veröffentl.',  'Active'),
                new FieldOption('Archiviert',   'Archived'),
            ]),
            contactUserUUID:            new StringField('contactUserUUID', 'Kontaktperson', true),
            title:                      new StringField('title', 'Titel', true),
            description:                new StringField('description', 'Beschreibung'),
            descriptionFurnishings:     new StringField('descriptionFurnishings', 'Ausstattung'),
            descriptionLocation:        new StringField('descriptionLocation', 'Lage'),
            descriptionOther:           new StringField('descriptionOther', 'Sonstiges'),
            purchasePrice:              new FloatField('purchasePrice', 'Kaufpreis'),
            monthlyRentCold:            new FloatField('monthlyRentCold', 'Kaltmiete'),
            monthlyRentIncome:          new FloatField('monthlyRentIncome', 'Mieteinnahmen'),
            monthlyRentTotal:           new FloatField('monthlyRentTotal', 'Gesamtmiete'),
            monthlyHeating:             new FloatField('monthlyHeating', 'Heizkosten'),
            monthlyUtilities:           new FloatField('monthlyUtilities', 'Nebenkosten'),
            monthlyHoaFee:              new FloatField('monthlyHoaFee', 'Hausgeld'),
            monthlyHeatingInUtilities:  new StringField('monthlyHeatingInUtilities', 'Heizk. in Nebenk.', false, [
                new FieldOption('n.v.', '', true),
                new FieldOption('Nein', 'Nein'),
                new FieldOption('Ja',   'Ja'),
            ]),
            phaseOfConstruction:        new StringField('phaseOfConstruction', 'Bauphase', false, [
                new FieldOption('Haus in Planung (projektiert)',    'Haus in Planung (projektiert)'),
                new FieldOption('Haus im Bau',                      'Haus im Bau'),
                new FieldOption('Haus fertig gestellt',             'Haus fertig gestellt'),
            ]),
            deposit:        new StringField('deposit', 'Kaution'),
            spaceUsable:    new FloatField('spaceUsable', 'Nutzfläche'),
            spaceLiving:    new FloatField('spaceLiving', 'Wohnfläche', true),
            spaceProperty:  new FloatField('spaceProperty', 'Grundstücksfl.'),
            availability:   new StringField('availability', 'Verfügbar ab'),

            withBalcony:                    new BoolField('withBalcony', 'Balkon/Terrasse'), // 'Wohnung'
            withBasement:                   new BoolField('withBasement', 'Keller'),
            withBuiltInKitchen:             new BoolField('withBuiltInKitchen', 'Einbauküche'), // 'Wohnung'
            withElevator:                   new BoolField('withElevator', 'Personenaufzug'), // 'Wohnung'
            withGardenUsage:                new BoolField('withGardenUsage', 'Garten/-mitbenutzung'), // 'Wohnung'
            withGuestBathroom:              new BoolField('withGuestBathroom', 'Gäste-WC'), 
            withMonumentProtection:         new BoolField('withMonumentProtection', 'Denkmalschutz'), // 'Kauf'
            withSecondarySuite:             new BoolField('withSecondarySuite', 'Einliegerwohnung'), // 'Wohnung'
            withWheelchairAccess:           new BoolField('withWheelchairAccess', 'Stufenloser Zugang'),
            suitableForSharedApartment:     new BoolField('suitableForSharedApartment', 'WG-geeignet'), // 'Wohnung'
            suitableForVacationHome:        new BoolField('suitableForVacationHome', 'Als Ferienhaus geeignet'), // 'Kauf'
            petsAllowed:                    new StringField('petsAllowed', 'Haustiere erlaubt', false, [
                new FieldOption('Nach Vereinbarung', 'Nach Vereinbarung'),
                new FieldOption('Nein',              'Nein'),
                new FieldOption('Ja',                'Ja'),
            ]),
            alreadyRented:                  new BoolField('alreadyRented', 'Vermietet'), // 'Kauf'
            eligibilityCertificateRequired: new BoolField('eligibilityCertificateRequired', 'Wohnber.-Schein erf.'), // 'Miete'

            numberGarages:          new IntegerField('numberGarages', 'Anz. Stellplätze'),
            garageType:             new StringField('garageType', 'Garage / Stellpl.', false, [
                new FieldOption('Garage',           'Garage'),
                new FieldOption('Außenstellplatz',  'Außenstellplatz'),
                new FieldOption('Carport',          'Carport'),
                new FieldOption('Duplex',           'Duplex'),
                new FieldOption('Parkhaus',         'Parkhaus'),
                new FieldOption('Tiefgarage',       'Tiefgarage'),
            ]),
            monthlyRentGarage:      new FloatField('monthlyRentGarage', 'Stellplatzmiete'),

            energyCertificateAvailability: new StringField('energyCertificateAvailability', 'Energieausweis Verf.', false, [
                new FieldOption('Für das Gebäude',                              'Energieausweis liegt für das Gebäude vor'),
                new FieldOption('Zur Besichtigung',                             'Energieausweis liegt zur Besichtigung vor'),
                new FieldOption('Gebäude unterliegt keine GEG-Anforderungen',   'Dieses Gebäude unterliegt nicht den GEG-Anforderungen'),
            ]),
            energySource: new StringField('energySource', 'Energieträger', false, [
                new FieldOption('Öl',                   'Öl'),
                new FieldOption('Gas',                  'Gas'),
                new FieldOption('Erdwärme',             'Erdwärme'),
                new FieldOption('Solar',                'Solar'),
                new FieldOption('Holzpellets',          'Holzpellets'),
                new FieldOption('Fernwärme',            'Fernwärme'),
                new FieldOption('Fernwärme-Dampf',      'Fernwärme-Dampf'),
                new FieldOption('Nahwärme',             'Nahwärme'),
                new FieldOption('Strom',                'Strom'),
                new FieldOption('Kohle',                'Kohle'),
                new FieldOption('Erdgas (leicht)',      'Erdgas (leicht)'),
                new FieldOption('Erdgas (schwer)',      'Erdgas (schwer)'),
                new FieldOption('Flüssiggas',           'Flüssiggas'),
                new FieldOption('Holz',                 'Holz'),
                new FieldOption('Holz-Hackschnitzel',   'Holz-Hackschnitzel'),
                new FieldOption('Kohle / Koks',         'Kohle / Koks'),
                new FieldOption('Wärmelieferung',       'Wärmelieferung'),
                new FieldOption('Bioenergie',           'Bioenergie'),
                new FieldOption('Windenergie',          'Windenergie'),
                new FieldOption('Wasserenergie',        'Wasserenergie'),
                new FieldOption('Umweltwärme',          'Umweltwärme'),
                new FieldOption('KWK fossil',           'KWK fossil'),
                new FieldOption('KWK erneuerbar',       'KWK erneuerbar'),
                new FieldOption('KWK regenerativ',      'KWK regenerativ'),
                new FieldOption('KWK bio',              'KWK bio'),
            ]),
            heatingType: new StringField('heatingType', 'Heizungsart', false, [
                new FieldOption('Etagenheizung',           'Etagenheizung'),
                new FieldOption('Ofenheizung',             'Ofenheizung'),
                new FieldOption('Zentralheizung',          'Zentralheizung'),
                new FieldOption('Blockheizkraftwerke',     'Blockheizkraftwerke'),
                new FieldOption('Elektro-Heizung',         'Elektro-Heizung'),
                new FieldOption('Fernwärme',               'Fernwärme'),
                new FieldOption('Fußbodenheizung',         'Fußbodenheizung'),
                new FieldOption('Gas-Heizung',             'Gas-Heizung'),
                new FieldOption('Holz-Pelletheizung',      'Holz-Pelletheizung'),
                new FieldOption('Nachtspeicherofen',       'Nachtspeicherofen'),
                new FieldOption('Öl-Heizung',              'Öl-Heizung'),
                new FieldOption('Solar-Heizung',           'Solar-Heizung'),
                new FieldOption('Wärmepumpe',              'Wärmepumpe'),
            ]),
            commissionAmountGross:                  new StringField('commissionAmountGross', 'Provision'),
            commissionNote:                         new StringField('commissionNote', 'Hinweis'),

            immoscout24ID:                          new StringField('immoscout24ID', 'ID (Immoscout24)'),
            immoscout24ExternalID:                  new StringField('immoscout24ExternalID', 'Objekt-ID'),
            immoscout24GroupID:                     new IntegerField('immoscout24GroupID', 'Gruppen-ID'),
            immoscout24ShowAddress:                 new BoolField('immoscout24ShowAddress', 'Adresse veröff.'),
            immoscout24ConstructionYearUnknown:     new BoolField('immoscout24ConstructionYearUnknown', 'Baujahr unbekannt'),

            bookooID:                               new StringField('bookooID', 'ID (Bookoo)'),
            bookooTitle:                            new StringField('bookooTitle', 'Titel', false, []),
            bookooDescription:                      new StringField('bookooDescription', 'Anzeige-Text'),
            bookooDescriptionTemplate:              new StringField('bookooDescriptionTemplate', 'Text-Vorlage'),
            bookooDescriptionFreeText:              new StringField('bookooDescriptionFreeText', 'Freitext-Beschr.'),
            bookooDescriptionMode:                  new StringField('bookooDescriptionMode', 'Beschr.-Modus', false, [
                new FieldOption('Automatisch',      'AutoGenerate', true),
                new FieldOption('Manuell',          'ManualOverride'),
            ]),
            bookooDescriptionSpaceLiving:           new StringField('bookooDescriptionSpaceLiving', 'Anzeige-Text (Wohnfläche)'),
            bookooDescriptionMonthlyRentTotal:      new StringField('bookooDescriptionMonthlyRentTotal', 'Anzeige-Text (Gesamtmiete)'),
            bookooDescriptionMonthlyUtilities:      new StringField('bookooDescriptionMonthlyUtilities', 'Anzeige-Text (Nebenkosten)'),
            bookooDescriptionPurchasePrice:         new StringField('bookooDescriptionPurchasePrice', 'Anzeige-Text (Kaufpreis)'),
            bookooDescriptionFooterText:            new StringField('bookooDescriptionFooterText', 'Anzeige-Text (Fußzeile)'),
            bookooDescriptionDistancesText:         new StringField('bookooDescriptionDistancesText', 'Anzeige-Text (Fahrzeiten)'),
            bookooDescriptionAvailability:          new StringField('bookooDescriptionAvailability', 'Anzeige-Text (Verfügbarkeit)'),
            bookooDescriptionCommissionNote:        new StringField('bookooDescriptionCommissionNote', 'Anzeige-Text (Provision)'),
            bookooDescriptionPetsAllowed:           new StringField('bookooDescriptionPetsAllowed', 'Anzeige-Text (Haustiere erlaubt)'),
        }

        this.fields.channelImmoscout24.value = true
    }

    areFieldsValid(){
        const isValid = super.areFieldsValid()
        if (!isValid){
            return false
        }        
        if (!this.fields.monthlyRentCold.value && !this.fields.purchasePrice.value){
            return false
        }
        if (this.fields.type.value === 'Haus' && !this.fields.spaceProperty.value){
            return false
        }
        if (this.fields.channelBookoo.value && !this.fields.bookooTitle.value){
            return false
        }
        return true        
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldActiveValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldActiveInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldActiveValid(fieldName, field){
        if (!field.value && this.fields.publishStatus.value == 'Draft'){
            return false
        }
        return field.value && field.isValid
    }
    isFieldActiveInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }        
        if (fieldName == 'monthlyRentCold' && this.fields.contractType.value == 'Vermietung' && !field.value){
            return true
        } 
        if (fieldName == 'purchasePrice' && this.fields.contractType.value == 'Verkauf' && !field.value){
            return true
        } 
        if (fieldName == 'spaceProperty' && this.fields.type.value == 'Haus' && !field.value){
            return true
        }
        if (fieldName == 'bookooTitle' && this.fields.channelBookoo.value == true && !field.value){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default RealEstateListing