import Derivative from '@/diaspora/derivative.js'

import IntegerField from '@/diaspora/fields/integer_field.js'

class SyncLogStatisticsOverview extends Derivative{
    constructor() {
        super() 
        this.fields = {
            numFailures:    new IntegerField('numFailures', 'Anz. Gescheitert'),
            numSuccesses:   new IntegerField('numSuccesses', 'Anz. Erfolgreich'),
            numTotal:       new IntegerField('numTotal', 'Anz. Gesamt'),
        }
    }
}

export default SyncLogStatisticsOverview