import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'
import BoolField from '@/diaspora/fields/bool_field.js'

class Meter extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            type:                   new StringField('type', 'Typ', true, [
                new FieldOption('Wasser',          'Wasser', true),
                new FieldOption('Wärmemenge',      'Wärmemenge'),
                new FieldOption('Heizung',         'Heizung'),
                new FieldOption('Gas',             'Gas'),
                new FieldOption('Strom',           'Strom'),
                new FieldOption('Fernwärme',       'Fernwärme'),
            ]),
            number:                 new StringField('number',           'Nummer',       true),
            isMain:                 new BoolField(  'isMain',           'Hauptzähler'),
            contractNumber:         new StringField('contractNumber',   'Vertrags-Nr'),
            installationUUID:       new UUIDField(  'installationUUID', 'UUID (Inst.)'),
            installationType:       new StringField('installationType', 'Typ (Inst.)'),
            locationDescription:    new StringField('locationDescription',   'Zählerort'),
        }
    }
    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default Meter