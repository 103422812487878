import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import EmailField from '@/diaspora/fields/email_field.js'
import StringField from '@/diaspora/fields/string_field.js'
import PasswordField from '@/diaspora/fields/password_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class User extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:       new UUIDField('uuid', 'UUID'),
            email:      new EmailField('email', 'E-Mail', true),
            password:   new PasswordField('password', 'Passwort'),
            role:       new StringField('role', 'Rolle', true, [
                new FieldOption('Administrator',            'admin'),
                new FieldOption('Führungskraft',            'manager'),
                new FieldOption('Vermarktung',              'user-marketing'),
                new FieldOption('Verwaltung',               'user-administration', true),
            ]),
            salutation:     new StringField('salutation', 'Anrede', false, [
                new FieldOption('Herr', 'Herr'),
                new FieldOption('Frau', 'Frau'),
            ]),
            firstName:      new StringField('firstName', 'Vorname'),
            lastName:       new StringField('lastName', 'Nachname'),
            publicEmail:    new EmailField('publicEmail', 'Öffentl. E-Mail'),
            phoneLandline:  new StringField('phoneLandline', 'Telefon-Nr (Festnetz)'),
            phoneMobile:    new StringField('phoneMobile', 'Telefon-Nr (Mobil)'),
        }
    }
}

export default User