import { services } from '@digiscape/js-core'

async function createTechnicalInstallation(newTechnicalInstallationJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/technical-installation/create", newTechnicalInstallationJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'RealEstate'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/technical-installation/create", newTechnicalInstallationJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    throw new Error("Invalid related type for TechnicalInstallation creation: '" + relatedType + "'")
}

async function readAllTechnicalInstallationsByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/technical-installation/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_technical_installations
        return responseBody
    }
    if (relatedType === 'RealEstate'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/technical-installation/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_technical_installations
        return responseBody
    }
    return []
}

async function readTechnicalInstallation(technicalInstallationUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/technical-installation/" + technicalInstallationUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.technical_installation
    return responseBody
}

async function saveTechnicalInstallationField(technicalInstallationUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: technicalInstallationUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/technical-installation/" + technicalInstallationUUID +"/field", reqBody)
    const responseBody = response.data.technical_installation
    return responseBody
}

async function deleteTechnicalInstallation(technicalInstallationUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/technical-installation/" + technicalInstallationUUID)
}

export {
    createTechnicalInstallation,
    readAllTechnicalInstallationsByRelated,
    readTechnicalInstallation,
    saveTechnicalInstallationField,
    deleteTechnicalInstallation,
}