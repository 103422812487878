<template>
  <div>
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Wartungseinträge werden geladen...</h5>
    </div>
    <table
      id="technical-installation-maintenances"
      class="table table-sm table-striped"
      v-show="isLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th style="width:100px">Wartung am</th>
          <th style="width:100px">Typ</th>
          <th style="width:110px">Beschreibung</th>
          <th style="width:30px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="technicalInstallationMaintenance in technicalInstallationMaintenances"
          :key="technicalInstallationMaintenance.uuid"
        >
          <td
            label="Wartung am"
          >
            {{ formatDate(technicalInstallationMaintenance.maintenance_at) }}
          </td>
          <td
            label="Typ"
          >
            {{ technicalInstallationMaintenance.type }}
          </td>
          <td
            label="Beschreibung"
          >
            {{ technicalInstallationMaintenance.description }}
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Löschen"
              role="button"
              @click="triggerDeleteTechnicalInstallationMaintenance($event, technicalInstallationMaintenance)"
            >
            Löschen
            </div>
            <div
              class="btn-icon-warn show-md"
              title="Löschen"
              role="button"
              @click="triggerDeleteTechnicalInstallationMaintenance($event, technicalInstallationMaintenance)"
            >
              <TrashIcon />
            </div>
          </td>
        </tr>
        <tr class="proto">
          <td
            label="Wartung am"
          >
            <DateInput
              name="maintenanceAt" 
              label="Wartung am"
              class="form-control"
              placeholder="01.10.2023"
              v-model="protoFields.maintenanceAt.value"
              @valid:modelValue="protoFields.maintenanceAt.isValid = true"
              @invalid:modelValue="protoFields.maintenanceAt.isValid = false"
              @reset:modelValue="protoFields.maintenanceAt.isValid = null"
              mandatory
            />
          </td>
          <td
            label="Typ"
          >
            <SelectInput
              name="type"
              label="Typ"
              id="type"
              class="form-control" 
              v-model="protoFields.type.value"
              @valid:modelValue="protoFields.type.isValid = true"
              @invalid:modelValue="protoFields.type.isValid = false"
              @reset:modelValue="protoFields.type.isValid = null"
              :options="protoFields.type.options"
              mandatory
            />
          </td>
          <td
            label="Beschreibung"
          >
            <StringInput
                name="description" 
                label="Beschreibung"
                class="form-control"
                placeholder="Die Anlage war..."
                v-model="protoFields.description.value"
                @valid:modelValue="protoFields.description.isValid = true"
                @invalid:modelValue="protoFields.description.isValid = false"
                @reset:modelValue="protoFields.description.isValid = null"
                :maxLength="250"
                :numRows="3"
                resizable
            />
          </td>
          <td>
            <button
              class="btn btn-suc hide-md"
              title="Erstellen"
              role="button"
              @click="triggerCreateTechnicalInstallation($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              Erstellen
            </button>
            <button
              class="btn-icon-suc show-md"
              title="Erstellen"
              @click="triggerCreateTechnicalInstallation($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              <PlusIcon />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import TechnicalInstallationMaintenance from '@/diaspora/models/technical-installation-maintenance.js'

import SelectInput from  '@/components/inputs/SelectInput.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import DateInput from  '@/components/inputs/DateInput.vue'

import TrashIcon from  '@/components/icons/TrashIcon.vue'
import PlusIcon from  '@/components/icons/PlusIcon.vue'

export default {
  name: 'TechnicalInstallationMaintenanceTable',
  emits: [
    'triggered:modelCreate',
    'triggered:modelDelete'
  ],
  data(){
    return {
      protoModel: new TechnicalInstallationMaintenance(),
      areAllProtoFieldsValid: false,
    }
  },
  props:{
    technicalInstallationType:{
      type: String,
      required: true
    },
    technicalInstallationMaintenances:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    }
  },
  computed:{
    protoFields(){
      return this.protoModel.fields
    }
  },
  watch:{
    protoFields: {
      deep:true,
      handler(){
        this.areAllProtoFieldsValid = this.protoModel.areFieldsValid()
      }
    },
  },
  methods:{
    triggerCreateTechnicalInstallation(e, technicalInstallationMaintenance){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelCreate', technicalInstallationMaintenance)
      this.protoModel = new TechnicalInstallationMaintenance()
    },
    triggerDeleteTechnicalInstallationMaintenance(e, technicalInstallationMaintenance){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelDelete', technicalInstallationMaintenance)
    },
    formatDate(value){
      return services.$format.date(value)
    }
  },
  components:{
    TrashIcon,
    PlusIcon,
    DateInput,
    StringInput,
    SelectInput,
},
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>