import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField    from '@/diaspora/fields/uuid_field.js'
import PasswordField from '@/diaspora/fields/password_field.js'
import BoolField from '@/diaspora/fields/bool_field'

class CredentialsBookoo extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:           new UUIDField('uuid', 'UUID'),
            userEmail:      new StringField('userEmail', 'E-Mail', true),
            userPassword:   new PasswordField('userPassword', 'Passwort', true),
            isVerified:     new BoolField('isVerified', 'Zugang freigeschaltet'),
        }
    }
}

export default CredentialsBookoo