<template>
  <div id="credentials-bookoo">
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <div class="pending-container" v-if="pendingMessage">
      <span class="pending-message">
        <div class="spinner-grow" role="status"></div>
        {{pendingMessage}}
      </span>
    </div>
    <h4>Bookoo Zugänge</h4>
    <div
      v-show="fields.isVerified.value" 
      class="col-8 col-xl-6 mb-3 auth-indicator-container"
    >
      <CheckBubbleIcon class="auth-indicator-success" /> Zugang freigeschaltet
    </div>
    <form id="credentials-bookoo-fields">
      <div class="row mb-3">
        <label for="consumerKey" class="col-4 col-form-label">E-Mail</label>     
        <div class="col-8">   
          <StringInput
            name="userEmail" 
            id="userEmail"
            label="E-Mail"
            class="form-control"
            placeholder="info@my-gmbh.de"
            v-model="fields.userEmail.value"
            @valid:modelValue="fields.userEmail.isValid = true"
            @invalid:modelValue="fields.userEmail.isValid = false"
            @reset:modelValue="fields.userEmail.isValid = null"
            :min-length="3"
            format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label for="userPassword" class="col-4 col-form-label">Passwort</label>
        <div class="col-8">   
          <PasswordInput
            name="userPassword" 
            id="userPassword"
            label="Secret-Schlüssel"
            class="form-control"
            v-model="fields.userPassword.value"
            @valid:modelValue="fields.userPassword.isValid = true"
            @invalid:modelValue="fields.userPassword.isValid = false"
            @reset:modelValue="fields.userPassword.isValid = null"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">   
          <button
            class="btn btn-brand form-control"
            type="button"
            @click="saveCredentials"
            :disabled="!areAllFieldsValid"
          >
            Speichern
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import StringInput from  '@/components/inputs/StringInput.vue'
import PasswordInput from  '@/components/inputs/PasswordInput.vue'

import FormMessages from '@/components/forms/features/form-messages.js'

import CredentialsBookoo from '@/diaspora/models/credentials-bookoo.js'

import CheckBubbleIcon from  '@/components/icons/CheckBubbleIcon.vue'

export default {
  name: 'CredentialsBookooForm',
  data(){
    return {
      areAllFieldsValid: false,
      messages: new FormMessages(),      
      credentialsBookooModel: new CredentialsBookoo()
    }
  },
  async created(){
    await this.loadCredentials()
  },
  computed:{
    fields(){
      return this.credentialsBookooModel.fields
    },
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    }
  },
  watch:{
    fields: {
      deep:true,
      handler(){
        this.areAllFieldsValid = this.credentialsBookooModel.areFieldsValid()
      }
    }
  },
  methods:{
    async loadCredentials(){
      this.messages.reset()
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/bookoo/credentials")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0042", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      if (response.status == 204){
        return
      }
      const responseBody = response.data.credentials
      this.credentialsBookooModel.unmarshalJSON(responseBody)
    },
    async saveCredentials(event){
      event.preventDefault()
      this.messages.reset()
      this.messages.pending('Bookoo Zugangsdaten werden geprüft...')
      try{
        await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/bookoo/login", this.credentialsBookooModel.marshalJSON())
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0043", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      this.fields.isVerified.value = true
      this.messages.success('Bookoo Zugangsdaten erfolgreich gespeichert')
    }
  },
  components:{
    StringInput,
    PasswordInput,
    CheckBubbleIcon
  }
}
</script>
