<template>
  <fieldset  
    :class="{ 'radio-button-container': true, 'centered': centered, 'inline': inline }"
  >
    <span
      class="radio-button"
      :class="[{'small': small, 'shrink': shrink }, buttonClass]"
      v-for="option in options"
      :key="option.label"
    >
      <input
        type="radio"
        class="btn-check" 
        :name="name" 
        :id="id + '-' + option.value" 
        autocomplete="off"
        :value="option.value"
        v-model="localValue"
        @click="$emit('select:option', option)"
      >
      <label
        class="btn btn-brand-outline"
        :for="id + '-' + option.value"
      >
        {{option.label}}
      </label>
    </span>
  </fieldset>
</template>

<script>
import FieldOption from '@/diaspora/fields/field_option.js'

export default {
  name: 'RadioButtonInput',
  data(){
    return {
      localValue: undefined,
      isLocalValueReversion: false,
      isValueChange: false,
    }
  },
  props:{
    modelValue:{
      required: true
    },
    id:{
      type: String,
      required: true
    },
    name:{
      type: String,
      required: true
    },
    options:{
      type: Object,
      required: true
    },
    centered: {
      type: Boolean
    },
    inline: {
      type: Boolean
    },
    small: {
      type: Boolean
    },
    shrink: {
      type: Boolean
    },
    buttonClass:{
      type: String
    },
    abortMessage:{
      type: String
    },
    confirmMessage:{
      type: String
    }
  },
  emits: [
    'update:modelValue', 
    'set:modelValue',
    'aborted:modelValue',
    'select:option',
  ],
  computed:{
    defaultOption(){
      if (!this.options || this.options.length < 1){
        return new FieldOption('n.v.', '')
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        if (option.default === true){
          return option
        }
      }
      if (this.mandatory === true){
        return this.options[0]
      }
      return new FieldOption('n.v.', '')
    }
  },
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (value != this.localValue && this.localValue){
          this.isValueChange = true
        }
        this.localValue = value
      }
    },       
    defaultOption(){      
      if (this.defaultOption && this.defaultOption.value && !this.modelValue){
        this.localValue = this.defaultOption.value
      }
    },
    localValue(newValue, oldValue){    
      if (this.isLocalValueReversion){
        this.isLocalValueReversion = false
        return
      }
      if (this.abortMessage){
        alert(this.abortMessage)
        this.isLocalValueReversion = true
        this.localValue = oldValue
        this.syncAllInputsCheckedWithLocalValue()
        this.$emit("aborted:modelValue")
        return
      }
      if (this.confirmMessage && !this.isValueChange){
        const isConfirmed = confirm(this.confirmMessage)
        if (!isConfirmed){
          this.isLocalValueReversion = true
          this.localValue = oldValue
          this.syncAllInputsCheckedWithLocalValue()
          return
        }
      }
      this.isValueChange = false
      this.$emit('update:modelValue', this.localValue)
      this.$emit('set:modelValue')
    }
  },
  methods:{    
    syncAllInputsCheckedWithLocalValue(){
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        const optionInputId = this.id + '-' + option.value
        const inputElem = document.getElementById(optionInputId)
        if (!inputElem){
          continue
        }
        inputElem.checked = option.value === this.localValue        
      }
    },
  },
  created(){
    if (this.defaultOption && this.defaultOption.value && !this.modelValue){
      this.localValue = this.defaultOption.value
      return
    }
    this.isOverwritingLocalValue = true
    this.localValue = this.modelValue
  }
}
</script>

<style scoped>
</style>