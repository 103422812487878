<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLocalLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Zähler werden geladen...</h5>
    </div>
    <table
      id="meters"
      class="table table-sm table-striped"
      v-show="isLocalLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th style="width:100px">Nummer</th>
          <th style="width:110px">Typ</th>
          <th style="width:100px">Vertrag</th>
          <th style="width:70px" v-if="contextType == 'RealEstate'">Einheit</th>
          <th style="width:40px">Hauptz.</th>
          <th style="width:30px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="meter in sortedMeters"
          :key="meter.uuid"
        >
          <td
            label="Nummer"
          >
            {{ meter.number }}
          </td>
          <td
            label="Typ"
          >
            {{ meter.type }}
          </td>
          <td
            label="Vertrag"
          >
            {{ meter.contract_number }}
          </td>
          <td
            v-if="contextType == 'RealEstate'"
            label="Einheit"
          >
            <span v-if="meter.installation_type == 'RealEstateUnit'">{{ getRealEstateUnitName(meter.installation_uuid) }}</span>
          </td>
          <td
            label="Hauptz."
          >
            <CheckBubbleIcon
              v-if="meter.is_main"
              class="table-icon"
            />
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditMeter($event, meter)"
            >
              Bearbeiten
            </div>
            <div
              class="btn-icon show-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditMeter($event, meter)"
            >
              <PencilIcon />
            </div>
          </td>
        </tr>
        <tr class="proto">
          <td
            label="Nummer"
          >
            <StringInput
              name="number" 
              label="Nummer"
              class="form-control"
              placeholder="XXX-XX..."
              v-model="protoFields.number.value"
              @valid:modelValue="protoFields.number.isValid = true"
              @invalid:modelValue="protoFields.number.isValid = false"
              @reset:modelValue="protoFields.number.isValid = null"
              :maxLength="50"
            />
          </td>
          <td
            label="Typ"
          > 
            <SelectInput
              name="type"
              label="Typ"
              id="type"
              class="form-control" 
              v-model="protoFields.type.value"
              @valid:modelValue="protoFields.type.isValid = true"
              @invalid:modelValue="protoFields.type.isValid = false"
              @reset:modelValue="protoFields.type.isValid = null"
              :options="protoFields.type.options"
              mandatory
            />
          </td>
          <td
            label="Vertrag"
          >
            <StringInput
              name="contractNumber" 
              label="Vertrags-Nr."
              class="form-control"
              placeholder="XXX-XX..."
              v-model="protoFields.contractNumber.value"
              @valid:modelValue="protoFields.contractNumber.isValid = true"
              @invalid:modelValue="protoFields.contractNumber.isValid = false"
              @reset:modelValue="protoFields.contractNumber.isValid = null"
              :maxLength="100"
            />
          </td>
          <td
            v-if="contextType == 'RealEstate'"
            label="Einheit"
          >
            <SelectAutocompleteInput
                name="protoRealEstateUnitUUID"
                label="Zusatztyp"
                id="protoRealEstateUnitUUID"
                class="form-control" 
                v-model="protoRealEstateUnitUUID"
                :options="sortedRealEstateUnitOptions"
            />
          </td>
          <td
            label="Hauptz."
          >
            <CheckboxButtonInput
              id="isMain-checkbox"
              name="isMain"
              label="Ja"
              v-model="protoFields.isMain.value"
              @valid:modelValue="protoFields.isMain.isValid = true"
              @invalid:modelValue="protoFields.isMain.isValid = false"
            />
          </td>
          <td>
            <button
              class="btn btn-suc hide-md"
              title="Erstellen"
              role="button"
              @click="triggerCreateMeter($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              Erstellen
            </button>
            <button
              class="btn-icon-suc show-md"
              title="Erstellen"
              @click="triggerCreateMeter($event, protoModel.marshalJSON())"
              :disabled="!areAllProtoFieldsValid"
            >
              <PlusIcon />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import Meter from '@/diaspora/models/meter.js'

import { readRelatedRealEstateUnits } from '@/diaspora/models/queries/real-estate-unit.js'

import StringInput from  '@/components/inputs/StringInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'
import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'
import SelectAutocompleteInput from  '@/components/inputs/SelectAutocompleteInput.vue'

import PencilIcon from  '@/components/icons/PencilIcon.vue'
import PlusIcon from  '@/components/icons/PlusIcon.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'
import CheckBubbleIcon from  '@/components/icons/CheckBubbleIcon.vue'

export default {
  name: 'MeterTable',
  emits: [
    'triggered:modelEdit',
    'triggered:modelCreate'
  ],
  data(){
    return {
      messageStore: new MessageStore(),
      protoModel: new Meter(),
      protoRealEstateUnitUUID: '',
      allRealEstateUnits: [],
      isUnitListLoaded: false,
      areAllProtoFieldsValid: false,
    }
  },
  props:{
    meters:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    },
    contextType: {
      type: String,
      required: true
    },
    contextUUID: {
      type: String,
      required: true
    },
  },
  computed:{
    isLocalLoaded(){
      if (this.contextType != 'RealEstate'){
        return this.isLoaded
      }
      return this.isUnitListLoaded && this.isLoaded
    },
    protoFields(){
      return this.protoModel.fields
    },
    sortedMeters(){
      const sorted = this.cloneArray(this.meters)
      return sorted.sort(this.meterRealEstateUnitNameComparator)
    },
    sortedRealEstateUnits(){
      const sorted = this.cloneArray(this.allRealEstateUnits)
      return sorted.sort(this.realEstateUnitNameComparator)
    },
    sortedRealEstateUnitOptions(){
      const sortedUnitOptions = []
      for (let unitIdx = 0; unitIdx < this.sortedRealEstateUnits.length; unitIdx++) {
        const unit = this.sortedRealEstateUnits[unitIdx];
        const unitOption = {
          label: unit.name, 
          value: unit.uuid,
        }
        if (unitOption.label === '' || !unitOption.label){
          continue
        }
        sortedUnitOptions.push(unitOption)
      }
      return sortedUnitOptions
    },
  },
  watch:{
    protoFields: {
      deep:true,
      handler(){
        this.areAllProtoFieldsValid = this.protoModel.areFieldsValid()
      }
    },
    async contextUUID(newUUID){
      if (!newUUID){
        this.allRealEstateUnits = []
        return
      }
      await this.loadRealEstateUnitData()
    },
  },
  async mounted(){
    await this.loadRealEstateUnitData()
  },
  methods:{
    async loadRealEstateUnitData(){      
      this.isUnitListLoaded = false
      await Promise.all([
        this.loadAllRealEstateUnits(),
      ])
      this.isUnitListLoaded = true
    },
    async loadAllRealEstateUnits(){
      if (this.contextType != 'RealEstate') {
        return
      }
      this.allRealEstateUnits = await this.readRelatedRealEstateUnits()
    },
    async readRelatedRealEstateUnits(){
      if (!this.contextType || !this.contextUUID){
        services.$log.warn("MOC0134", "read all real estate units cannot be executed, invalid related uuid / type, skipping")
        return []
      }
      if (this.contextType != 'RealEstate') {
        services.$log.warn("MOC0135", "read all real estate units cannot be executed, invalid context type '"+this.contextType+"', skipping")
        return []
      }
      const messageTarget = `readRelatedRealEstateUnits`
      this.messageStore.flushTarget(messageTarget)

      let allRealEstateUnits = []
      try{
        allRealEstateUnits = await readRelatedRealEstateUnits(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0136", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return false
      }
      return allRealEstateUnits
    },
    isDisplayRealEstateUnit(unitUUID){
      for (let meterIdx = 0; meterIdx < this.meters.length; meterIdx++) {
        const displayMeter = this.meters[meterIdx];
        if (displayMeter.installation_type == 'RealEstateUnit' && displayMeter.installation_uuid === unitUUID){
          return true
        }
      }
      return false
    },
    getRealEstateUnitName(unitUUID){
      for (let unitIdx = 0; unitIdx < this.allRealEstateUnits.length; unitIdx++) {
        const unit = this.allRealEstateUnits[unitIdx];
        if (unit.uuid === unitUUID){
          return unit.name
        }
      }
      return ''
    },
    triggerEditMeter(e, meter){
      if (e){
        e.preventDefault()
      }
      this.$emit('triggered:modelEdit', meter.uuid)
    },
    triggerCreateMeter(e, meter){
      if (e){
        e.preventDefault()
      }
      if (this.protoRealEstateUnitUUID){
        this.$emit('triggered:modelCreate', meter, 'RealEstateUnit', this.protoRealEstateUnitUUID)
      } else {
        this.$emit('triggered:modelCreate', meter, this.contextType, this.contextUUID)
      }
      this.protoModel = new Meter()
      this.protoRealEstateUnitUUID = ''
    },
    meterRealEstateUnitNameComparator(first, second){
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0
      
      if (first.installation_type == 'RealEstateUnit' && second.installation_type != 'RealEstateUnit'){
        return orderSecondBeforeFirst
      }
      if (first.installation_type != 'RealEstateUnit' && second.installation_type == 'RealEstateUnit'){
        return orderFirstBeforeSecond
      }
      if (first.installation_type != 'RealEstateUnit' && second.installation_type != 'RealEstateUnit'){
        return orderEqual
      }

      const firstName = this.getRealEstateUnitName(first.installation_uuid)
      const secondName = this.getRealEstateUnitName(second.installation_uuid)
      return this.realEstateUnitNameValueComparator(firstName, secondName)
    },
    realEstateUnitNameComparator(first, second) {
      return this.realEstateUnitNameValueComparator(first.name, second.name)
    },
    realEstateUnitNameValueComparator(firstName, secondName) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!firstName){
        return orderFirstBeforeSecond
      }
      if (!secondName){
        return orderSecondBeforeFirst
      }
      if (firstName < secondName){
        return orderFirstBeforeSecond
      }
      if (firstName > secondName){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    PencilIcon,
    PlusIcon,
    CheckBubbleIcon,
    StringInput,
    SelectInput,
    CheckboxButtonInput,
    SelectAutocompleteInput,
    FormMessages,
},
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>