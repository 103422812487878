<template>
    <div class="form-messages-container">
        <div
            v-for="message in allMessages"
            :class="['message-container', message.type]"
            :key="message.uuid"
        >
            <span
                :class="message.type + '-message'"
            >
                <div
                    v-if="message.type == 'pending'"
                    class="spinner-grow"
                    role="status"
                ></div>
                <span
                    v-if="message.contextCode"
                    :class="message.type + '-code'"
                >
                    [{{message.contextCode}}]
                </span>
                {{ message.body }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormMessages',
    props:{
        allMessages:{
            type: Array,
            required: true,
        }
    },
}
</script>