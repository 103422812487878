import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField  from '@/diaspora/fields/string_field.js'
import UUIDField    from '@/diaspora/fields/uuid_field.js'
import IntegerField from '@/diaspora/fields/integer_field.js'
import FloatField   from '@/diaspora/fields/float_field.js'

class RealEstateUnit extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:           new UUIDField('uuid', 'UUID'),
            tenantUUID:     new UUIDField('tenantUUID', 'Kunden-UUID'),
            realEstateUUID: new UUIDField('realEstateUUID', 'Objekt-UUID'),
            name:           new StringField('name', 'Bezeichnung'),
            type:           new StringField('type', 'Typ', true, [
                new FieldOption('Wohnung',  'Wohnung', true),
                new FieldOption('Haus',     'Haus'),
            ]),
            subType:       new StringField('subType', 'Zusatztyp', false, [
                new FieldOption('Dachgeschoss',         'Dachgeschoss',         false, 'Wohnung'),
                new FieldOption('Maisonette',           'Maisonette',           false, 'Wohnung'),
                new FieldOption('Loft',                 'Loft',                 false, 'Wohnung'),
                new FieldOption('Penthouse',            'Penthouse',            false, 'Wohnung'),
                new FieldOption('Terrassenwohnung',     'Terrassenwohnung',     false, 'Wohnung'),
                new FieldOption('Erdgeschosswohnung',   'Erdgeschosswohnung',   false, 'Wohnung'),
                new FieldOption('Etagenwohnung',        'Etagenwohnung',        false, 'Wohnung'),
                new FieldOption('Hochparterre',         'Hochparterre',         false, 'Wohnung'),
                new FieldOption('Souterrain',           'Souterrain',           false, 'Wohnung'),
                new FieldOption('Sonstige',             'Sonstige',             false, 'Wohnung'),

                new FieldOption('Einfamilienhaus (freistehend)',  'Einfamilienhaus (freistehend)', false, 'Haus'),
                new FieldOption('Reihenmittelhaus',               'Reihenmittelhaus',              false, 'Haus'),
                new FieldOption('Reiheneckhaus',                  'Reiheneckhaus',                 false, 'Haus'),
                new FieldOption('Mehrfamilienhaus',               'Mehrfamilienhaus',              false, 'Haus'),
                new FieldOption('Bungalow',                       'Bungalow',                      false, 'Haus'),
                new FieldOption('Bauernhaus',                     'Bauernhaus',                    false, 'Haus'),
                new FieldOption('Doppelhaushälfte',               'Doppelhaushälfte',              false, 'Haus'),
                new FieldOption('Villa',                          'Villa',                         false, 'Haus'),
                new FieldOption('Burg/Schloss',                   'Burg/Schloss',                  false, 'Haus'),
                new FieldOption('Besondere Immobilie',            'Besondere Immobilie',           false, 'Haus'),
                new FieldOption('Wohnimmobilie (sonstige)',       'Wohnimmobilie (sonstige)',      false, 'Haus'),
            ]),
            streetName:     new StringField('streetName', 'Straße', true),
            streetNumber:   new StringField('streetNumber', 'Haus-Nr', true),
            zipCode:        new StringField('zipCode', 'PLZ', true),
            city:           new StringField('city', 'Ort', true),
            numberTotalRooms:   new IntegerField('numberTotalRooms', 'Anz. Zimmer', true),
            numberBathrooms:    new IntegerField('numberBathrooms', 'Anz. Badezimmer'),
            numberBedrooms:     new IntegerField('numberBedrooms', 'Anz. Schlafzimmer'),
            numberFloors:       new IntegerField('numberFloors', 'Anz. Etagen'),
            floor:              new IntegerField('floor', 'Etage'),
            spaceUsable:    new FloatField('spaceUsable', 'Nutzfläche'),
            spaceLiving:    new FloatField('spaceLiving', 'Wohnfläche', true),
            spaceProperty:  new FloatField('spaceProperty', 'Grundstücksfl.'),
        }
    }

    areFieldsValid(){
        const isValid = super.areFieldsValid()
        if (!isValid){
            return false
        }
        if (this.fields.type.value === 'Haus' && !this.fields.spaceProperty.value){
            return false
        }
        return true        
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (fieldName === 'spaceProperty' && this.fields.type.value === 'Haus' && !field.value){
            return "error"
        }
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default RealEstateUnit