<template>
  <div class="container">
    <br/>
    <h3>Dashboard - {{ userDepartment }}</h3>
    <SyncLogOverviewWidget 
      v-if="isUserAllowedAdminSyncLog"
    />
    <ChannelUsageOverviewWidget 
      v-if="isUserAllowedAdminSyncLog"
    />
  </div>
</template>

<script>
import SyncLogOverviewWidget from '@/components/widgets/SyncLogOverviewWidget.vue'
import ChannelUsageOverviewWidget from '@/components/widgets/ChannelUsageOverviewWidget.vue'

export default {
  name: 'Dashboard',
  computed:{
    isUserAllowedAdminSyncLog(){
      return this.$store.getters.isUserAllowedAdminSyncLogs
    },
    userDepartment(){
      return this.$store.getters.userDepartment
    }

  },
  components:{
    SyncLogOverviewWidget,
    ChannelUsageOverviewWidget,
  }
}
</script>

<style>
.widget-container{
  width: 500px;
  font-size: 0.9rem;
  border: 1px black solid; 
  border-radius: 5px;
  padding: 1rem;
  background-color: var(--co-bg-lit);

  display: inline-block;
  margin: 15px;
  vertical-align: top;
}
.widget-container h1{
  font-size: 1.3rem;
}
.widget-container h2{
  font-size: 1.1rem;
}
.widget-container h3{
  font-size: 0.9rem;
}
</style>
