<template>
  <div class="container">
    <h2>Immobilien-Eigentümer</h2>
    <RealEstateOwnerCollection
      :realEstateUUID="urlRealEstateUUID"
      :serviceProviderUUID="urlServiceProviderUUID"
      :isSubView="false"
    />
  </div>
</template>

<script>
import RealEstateOwnerCollection from '@/components/collections/RealEstateOwnerCollection.vue'

export default {
  name: 'RealEstateOwner',
  computed:{
    urlRealEstateUUID(){
      return this.$route.params.realEstateUUID
    },
    urlServiceProviderUUID(){
      return this.$route.params.serviceProviderUUID
    },
  },
  components:{
    RealEstateOwnerCollection,
  }
}
</script>

<style>
</style>
