<template>
  <fieldset>
    <section
      :id="id+'-fields-main'" 
    >
      <h5
        class="collapsable"
        data-bs-toggle="collapse" 
        :data-bs-target="`#${id}-fields-main-body`"
        :aria-controls="`#${id}-fields-main-body`"
        aria-expanded="true"
      >
        Mietvertrag
      </h5>
      <div
        :id="id+'-fields-main-body'"
        class="collapse show"
      >
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-isSubTenant'"
            class="col-sm-4 col-form-label"
            label="Untermieter"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('isSubTenant')"
          />
          <div class="col-sm-8">
            <RadioButtonInput
                :name="id+'-isSubTenant'"
                label="Untermieter"
                :id="id+'-isSubTenant'"
                v-model="fields.isSubTenant.value"
                :options="fields.isSubTenant.labels"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-contractStartAt'"
            class="col-sm-4 col-form-label"
            label="Vertragsbeginn"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('contractStartAt')"
          />
          <div class="col-sm-8">
            <DateInput
              name="contractStartAt" 
              label="Vertragsbeginn"
              placeholder="01.02.2021"
              class="form-control" 
              v-model="fields.contractStartAt.value"
              @valid:modelValue="fields.contractStartAt.isValid = true"
              @invalid:modelValue="fields.contractStartAt.isValid = false"
              @reset:modelValue="fields.contractStartAt.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-contractEndAt'"
            class="col-sm-4 col-form-label"
            label="Vertragsende"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('contractEndAt')"
          />
          <div class="col-sm-8">
            <DateInput
              name="contractEndAt" 
              label="Vertragsende"
              placeholder="31.01.2022"
              class="form-control" 
              v-model="fields.contractEndAt.value"
              @valid:modelValue="fields.contractEndAt.isValid = true"
              @invalid:modelValue="fields.contractEndAt.isValid = false"
              @reset:modelValue="fields.contractEndAt.isValid = null"
            />
          </div>
        </div>      
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-monthlyRentCold'"
            class="col-sm-4 col-form-label"
            label="Kaltmiete"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('monthlyRentCold')"
          />
          <div class="col-sm-8 input-container">
            <FloatInput
              :name="id+'-monthlyRentCold'"
              label="Kaltmiete"
              class="form-control"
              placeholder="755,50"
              v-model="fields.monthlyRentCold.value"
              @valid:modelValue="fields.monthlyRentCold.isValid = true"
              @invalid:modelValue="fields.monthlyRentCold.isValid = false"
              @reset:modelValue="fields.monthlyRentCold.isValid = null"
            />
            <UnitLabel
              class="input-placeholder-unit"
              type="eur"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-securityDeposit'"
            class="col-sm-4 col-form-label"
            label="Kaution"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('securityDeposit')"
          />
          <div class="col-sm-8 input-container">
            <FloatInput
              :name="id+'-securityDeposit'"
              label="Kaution"
              class="form-control"
              placeholder="755,50"
              v-model="fields.securityDeposit.value"
              @valid:modelValue="fields.securityDeposit.isValid = true"
              @invalid:modelValue="fields.securityDeposit.isValid = false"
              @reset:modelValue="fields.securityDeposit.isValid = null"
            />
            <UnitLabel
              class="input-placeholder-unit"
              type="eur"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-moveInAt'"
            class="col-sm-4 col-form-label"
            label="Einzug am"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('moveInAt')"
          />
          <div class="col-sm-8">
            <DateInput
              name="moveInAt" 
              label="Einzug am"
              placeholder="01.02.2021"
              class="form-control" 
              v-model="fields.moveInAt.value"
              @valid:modelValue="fields.moveInAt.isValid = true"
              @invalid:modelValue="fields.moveInAt.isValid = false"
              @reset:modelValue="fields.moveInAt.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-moveOutAt'"
            class="col-sm-4 col-form-label"
            label="Auszug am"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('moveOutAt')"
          />
          <div class="col-sm-8">
            <DateInput
              name="moveOutAt" 
              label="Auszug am"
              placeholder="31.01.2022"
              class="form-control" 
              v-model="fields.moveOutAt.value"
              @valid:modelValue="fields.moveOutAt.isValid = true"
              @invalid:modelValue="fields.moveOutAt.isValid = false"
              @reset:modelValue="fields.moveOutAt.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-cancellationPeriod'"
            class="col-sm-4 col-form-label"
            label="Kündigungsfrist"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('cancellationPeriod')"
          />
          <div class="col-sm-8">
            <StringInput
              name="cancellationPeriod" 
              label="Kündigungsfrist"
              placeholder="3 Monate"
              class="form-control" 
              v-model="fields.cancellationPeriod.value"
              @valid:modelValue="fields.cancellationPeriod.isValid = true"
              @invalid:modelValue="fields.cancellationPeriod.isValid = false"
              @reset:modelValue="fields.cancellationPeriod.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-cancellationAt'"
            class="col-sm-4 col-form-label"
            label="Kündigung am"
            :validationState="rentalContractModel.getFieldValidityIndicatorClass('cancellationAt')"
          />
          <div class="col-sm-8">
            <DateInput
              name="cancellationAt" 
              label="Kündigung am"
              placeholder="31.01.2022"
              class="form-control" 
              v-model="fields.cancellationAt.value"
              @valid:modelValue="fields.cancellationAt.isValid = true"
              @invalid:modelValue="fields.cancellationAt.isValid = false"
              @reset:modelValue="fields.cancellationAt.isValid = null"
            />
          </div>
        </div>
      </div>
    </section>
  </fieldset>
</template>

<script>
import FieldLabel from  '@/components/labels/FieldLabel.vue'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import DateInput from  '@/components/inputs/DateInput.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import FloatInput from  '@/components/inputs/FloatInput.vue'
import UnitLabel from  '@/components/labels/UnitLabel.vue'

import RentalContract from '@/diaspora/models/rental-contract.js'

export default {
  name: 'RentalContractFields',
  emits: [
    'update:modelValue', 
    'update:fieldValue', 
    'valid:modelFields', 
    'invalid:modelFields',
    'error:modelFields'
  ],
  data(){
    return {
      rentalContractModel: new RentalContract(),
    }
  },
  props:{
    id: {
      type: String,
    },
    rentalContract:{
      type: Object
    },
  },
  async created(){
    if (this.rentalContract){
        this.rentalContractModel.unmarshalJSON(this.rentalContract)
    }
    this.emitModelFieldValidity()
    
    this.rentalContractModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
  },
  computed:{
    fields(){
      return this.rentalContractModel.fields
    },
  },
  watch:{
    fields: {
      deep:true,
      handler(){
          this.emitModelFieldValidity()
          this.$emit('update:modelValue', this.rentalContractModel.marshalJSON())
      }
    },
    rentalContract: {
        deep: true,
        handler(){
            if (this.rentalContract){
                this.rentalContractModel.unmarshalJSON(this.rentalContract)
            }
        }
    },
  },
  methods:{
    async emitUpdateFieldValue(fieldName, oldValue, newValue){
      await this.$nextTick()      
      const isValid = this.fields[fieldName].isValid;
      const fieldLabel = this.fields[fieldName].displayName;
      this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
    },
    emitModelFieldValidity(){        
      const areAllFieldsValid = this.rentalContractModel.areFieldsValid()
      if (areAllFieldsValid === true) {
        this.$emit('valid:modelFields')
        return
      }
      this.$emit('invalid:modelFields')
    },
  },
  components:{
    FieldLabel,
    RadioButtonInput,
    DateInput,
    StringInput,
    FloatInput,
    UnitLabel,
  }
}
</script>