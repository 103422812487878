<template>
  <div class="container">
    <br/>
    <h3>Synchronisationsfehler</h3>
    <RealEstateListingSyncErrorsTable 
      class="col-12"
    />
    <br/>
    <DocumentSyncErrorsTable 
      class="col-12"
    />
  </div>
</template>

<script>
import RealEstateListingSyncErrorsTable from '@/components/tables/RealEstateListingSyncErrorsTable.vue'
import DocumentSyncErrorsTable from '@/components/tables/DocumentSyncErrorsTable.vue'

export default {
  name: 'AdminSyncErrors',
  components: {
    RealEstateListingSyncErrorsTable,
    DocumentSyncErrorsTable
  }
}
</script>

<style>
</style>
