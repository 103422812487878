import { createWebHistory, createRouter } from 'vue-router'

import { services } from '@digiscape/js-core'

import { store } from '@/store/store.js'

import Dashboard from '@/components/views/Dashboard.vue'
import Login from '@/components/views/Login.vue'
import Logout from '@/components/views/Logout.vue'
import UserProfile from '@/components/views/UserProfile.vue'
import RealEstateListingLive from '@/components/views/RealEstateListingLive.vue'
import RealEstateListingArchive from '@/components/views/RealEstateListingArchive.vue'
import RealEstate from '@/components/views/RealEstate.vue'
import RealEstateUnit from '@/components/views/RealEstateUnit.vue'
import ServiceProvider from '@/components/views/ServiceProvider.vue'
import RealEstateOwner from '@/components/views/RealEstateOwner.vue'
import RealEstateRenter from '@/components/views/RealEstateRenter.vue'
import Admin from '@/components/views/Admin.vue'
import AdminSyncErrors from '@/components/views/AdminSyncErrors.vue'

let vueRouter = createRouter({
    history: createWebHistory(),
    routes:[
        { 
            path: '/:pathMatch(.*)*',
            component: Dashboard,
            meta:{
                authenticate: true,
            }
        },
        { 
            path: '/login',
            component: Login
        },
        { 
            path: '/logout',
            component: Logout
        },
        {
            path: '/profile',
            component: UserProfile,
            meta:{
                authenticate: true,
            }
        },
        {
            path: '/admin',
            component: Admin,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_immoscout24_credentials',
                    'edit_bookoo_credentials',
                ]
            }
        },
        {
            path: '/admin/sync-errors',
            component: AdminSyncErrors,
            meta:{
                authenticate: true,
                permissions: [
                    'admin_sync_statuses',
                ]
            }
        },
        {
            path: '/real-estate-listing/live/:realEstateListingUUID?',
            component: RealEstateListingLive,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estate_listings',
                ]
            }
        },
        {
            path: '/real-estate-listing/archive/:realEstateListingUUID?',
            component: RealEstateListingArchive,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estate_listings',
                ]
            }
        },
        {
            path: '/real-estate/:realEstateUUID/real-estate-unit/:realEstateUnitUUID',
            component: RealEstateUnit,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate/:realEstateUUID/real-estate-owner/:realEstateOwnerUUID',
            component: RealEstateOwner,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-owner/:realEstateOwnerUUID/real-estate/:realEstateUUID',
            component: RealEstate,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-owner/:realEstateOwnerUUID/service-provider/:serviceProviderUUID',
            component: ServiceProvider,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate/:realEstateUUID/service-provider/:serviceProviderUUID',
            component: ServiceProvider,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-unit/:realEstateUnitUUID/service-provider/:serviceProviderUUID',
            component: ServiceProvider,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-renter/:realEstateRenterUUID/real-estate-unit/:realEstateUnitUUID',
            component: RealEstateUnit,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-unit/:realEstateUnitUUID/real-estate-renter/:realEstateRenterUUID',
            component: RealEstateRenter,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/service-provider/:serviceProviderUUID?/real-estate/:realEstateUUID?',
            component: RealEstate,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/service-provider/:serviceProviderUUID?/real-estate-unit/:realEstateUnitUUID?',
            component: RealEstateUnit,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/service-provider/:serviceProviderUUID?/real-estate-owner/:realEstateOwnerUUID?',
            component: RealEstateOwner,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-owner/:realEstateOwnerUUID?',
            component: RealEstateOwner,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-renter/:realEstateRenterUUID?',
            component: RealEstateRenter,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/service-provider/:serviceProviderUUID?',
            component: ServiceProvider,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate-unit/:realEstateUnitUUID?',
            component: RealEstateUnit,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
        {
            path: '/real-estate/:realEstateUUID?',
            component: RealEstate,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_real_estates',
                ]
            }
        },
    ]
})

vueRouter.beforeEach(function(to){
    const isAuthRequired = to.matched.some(route => route.meta.authenticate)
    if (isAuthRequired){
        const token = store.state.token
        if (!token){
            return {
                path: '/login',
                params: {nextUrl:to.fullPath}
            }
        }
        services.$http.storeAuthHeaderToken(process.env.VUE_APP_CLIENT_NAME, token)
        let hasAllPermissions = true
        const userPermissions = store.state.permissions
        to.matched.forEach(function(route){
            const requiredPermissions = route.meta.permissions
            if (!requiredPermissions || !hasAllPermissions){
                return 
            }
            for (let permissionIdx = 0; permissionIdx < requiredPermissions.length; permissionIdx++) {
                const permission = requiredPermissions[permissionIdx]
                if (!userPermissions.includes(permission)){
                    hasAllPermissions = false
                    return
                }
            }
        })
        if (!hasAllPermissions){
            return {
                path: '/',
                params: {nextUrl:to.fullPath}
            }
        }
        return true
    }
    return true
})

export let router = vueRouter