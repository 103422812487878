import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field'
import IntegerField from '@/diaspora/fields/integer_field'
import TimestampField from '@/diaspora/fields/timestamp_field'
import BoolField from '@/diaspora/fields/bool_field'

class Document extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            immoscout24ID:          new StringField('immoscout24ID', 'ID (Immoscout24)'),
            name:                   new StringField('name', 'Name', true, [
                new FieldOption('Schlafzimmer', 'Schlafzimmer'),
                new FieldOption('Wohnzimmer', 'Wohnzimmer'),
                new FieldOption('Kinderzimmer', 'Kinderzimmer'),
                new FieldOption('Arbeitszimmer', 'Arbeitszimmer'),
                new FieldOption('Badezimmer', 'Badezimmer'),
                new FieldOption('Gäste-WC', 'Gäste-WC'),
                new FieldOption('Küche', 'Küche'),
                new FieldOption('Flur', 'Flur'),
                new FieldOption('Hausansicht', 'Hausansicht'),
                new FieldOption('Garten', 'Garten'),
                new FieldOption('Ausblick', 'Ausblick'),
                new FieldOption('Balkon', 'Balkon'),
                new FieldOption('Abstellraum', 'Abstellraum'),
                new FieldOption('Eingangsbereich', 'Eingangsbereich'),
            ]),
            type:                   new StringField('type', 'Typ'),
            fileName:               new StringField('fileName', 'Dateiname'),
            fileSize:               new IntegerField('fileSize', 'Dateigröße'),
            fileType:               new StringField('fileType', 'Dateityp'),
            uploadUserUUID:         new UUIDField('uploadUserUUID', 'Hochgeladen von'),
            uploadedAt:             new TimestampField('uploadedAt', 'Hochgeladen am'),
            relativePath:           new StringField('relativePath', 'Rel. Datei-Pfad'),
            relativePathThumbnail:  new StringField('relativePathThumbnail', 'Rel. Datei-Pfad (Thumbn.)'),
            channelImmoscout24:     new BoolField('channelImmoscout24', 'Immoscout24'),
            channelBookoo:          new BoolField('channelBookoo', 'Bookoo'),
            channelOrder:           new IntegerField('channelOrder', 'Reihenfolge'),
        }
    }
}

export default Document