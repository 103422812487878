<template>
  <div class="container">
    <h2>Immobilienanzeigen (Archiv)</h2>
    <RealEstateListingCollection
      :createAllowed="false"
      :prefilterFields="{ publishStatus: [ 'Archived' ] }"
    />
  </div>
</template>

<script>
import RealEstateListingCollection from '@/components/collections/RealEstateListingCollection.vue'

export default {
  name: 'RealEstateListingArchive',
  components:{
    RealEstateListingCollection,
  }
}
</script>

<style>
</style>
