import * as Vue from 'vue';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import '@/app.css'

import { services } from '@digiscape/js-core'
import { router } from '@/router.js'
import { store } from '@/store/store.js'

import App from '@/App.vue'

const app = Vue.createApp({
    render(){
        return Vue.h(App)
    },
    created(){
        services.$http.initializeClient(process.env.VUE_APP_CLIENT_NAME, process.env.VUE_APP_API_BASE_URL)
        services.$time.setLocation(process.env.VUE_APP_TIMEZONE)
        if (window.localStorage.email && window.localStorage.token){            
            this.$store.commit({
                type: 'updateUser',
                token: window.localStorage.token,
                email: window.localStorage.email,
            })
        }
    }
})
app.use(router)
router.app = app
app.use(store)
app.mount('#app')