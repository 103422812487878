import Field from '@/diaspora/fields/field.js'

class StringField extends Field{    
    constructor(fieldName = '', displayName = '', isMandatory = false, options = []){
        super(fieldName, displayName, '', '', isMandatory)

        this.options = options
        for (let optionIdx = 0; optionIdx < options.length; optionIdx++) {
            const option = options[optionIdx];
            if (option.default == true){
                this.value = option.value
            }
        }
    }

    clone(){
        const clone = super.clone()
        clone.options = this.options
        return clone
    }

    isEqual(otherField){
        if (! super.isEqual(otherField)){
            return false
        }
        if (this.options.length != otherField.options.length){
            return false
        }
        for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
            const option = this.options[optionIdx];
            const otherOption = otherField.options[optionIdx];
            if (!option.isEqual(otherOption)){
                return false
            }
        }
        return true
    }
}


export default StringField