import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import EmailField from '@/diaspora/fields/email_field.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class RealEstateOwner extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            tenantUUID:             new UUIDField('tenantUUID', 'Kunden-UUID'),
            department:             new StringField('department', 'Abteilung', false, [
                new FieldOption('Alle',         'Alle',         true),
                new FieldOption('Vermarktung',  'Vermarktung'),
                new FieldOption('Verwaltung',   'Verwaltung'),
            ]),
            name:                   new StringField('name', 'Bezeichnung'),
            salutation:             new StringField('salutation', 'Anrede', false, [
                new FieldOption('Herr',  'Herr'),
                new FieldOption('Frau',  'Frau'),
            ]),
            firstName:              new StringField('firstName', 'Vorname'),
            lastName:               new StringField('lastName', 'Nachname'),
            streetName:             new StringField('streetName', 'Straße'),
            streetNumber:           new StringField('streetNumber', 'Haus-Nr'),
            zipCode:                new StringField('zipCode', 'PLZ'),
            city:                   new StringField('city', 'Ort'),
            email:                  new EmailField('email', 'E-Mail'),
            phoneLandline:          new StringField('phoneLandline', 'Telefon-Nr (Festnetz)'),
            phoneMobile:            new StringField('phoneMobile', 'Telefon-Nr (Mobil)'),
            companyName:            new StringField('companyName', 'Unternehmen'),
            companyEmail:           new StringField('companyEmail', 'E-Mail'),
            companyPhoneLandline:   new StringField('companyPhoneLandline', 'Telefon-Nr (Festnetz)'),
            companyPhoneMobile:     new StringField('companyPhoneMobile', 'Telefon-Nr (Mobil)'),
            companyStreetName:      new StringField('companyStreetName', 'Straße'),
            companyStreetNumber:    new StringField('companyStreetNumber', 'Haus-Nr'),
            companyZipCode:         new StringField('companyZipCode', 'PLZ'),
            companyCity:            new StringField('companyCity', 'Ort'),
        }
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default RealEstateOwner