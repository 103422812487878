<template>
    <fieldset>
      <section
        :id="id+'-fields-main'" 
      >
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-isMain'"
            class="col-sm-4 col-form-label"
            label="Hauptzähler"
            validationState="success"
            mandatory
          />
          <div class="col-sm-8">
              <RadioButtonInput
                :name="id+'-isMain'" 
                label="Hauptzähler"
                :id="id+'-isMain'"
                v-model="fields.isMain.value"
                :options="fields.isMain.labels"
              />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
              :for="id+'-number'"
              class="col-sm-4 col-form-label"
              label="Nummer"
              :validationState="meterModel.getFieldValidityIndicatorClass('number')"
          />
          <div class="col-sm-8">
              <StringInput
                  name="number" 
                  label="Nummer"
                  class="form-control"
                  placeholder="XXX-XX..."
                  v-model="fields.number.value"
                  @valid:modelValue="fields.number.isValid = true"
                  @invalid:modelValue="fields.number.isValid = false"
                  @reset:modelValue="fields.number.isValid = null"
                  :maxLength="50"
              />
          </div>
        </div>
        <div class="row mb-3">
        <FieldLabel
            :for="id+'-type'"
            class="col-sm-4 col-form-label"
            label="Typ"
            :validationState="meterModel.getFieldValidityIndicatorClass('type')"
        />
        <div class="col-sm-8">
            <SelectInput
                name="type"
                label="Typ"
                id="type"
                class="form-control" 
                v-model="fields.type.value"
                @valid:modelValue="fields.type.isValid = true"
                @invalid:modelValue="fields.type.isValid = false"
                @reset:modelValue="fields.type.isValid = null"
                :options="fields.type.options"
                mandatory
                disabled
            />
        </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-contractNumber'"
            class="col-sm-4 col-form-label"
            label="Vertrags-Nr."
            :validationState="meterModel.getFieldValidityIndicatorClass('contractNumber')"
          />
        <div class="col-sm-8">
            <StringInput
                name="contractNumber" 
                label="Vertrags-Nr."
                class="form-control"
                placeholder="XXX-XX..."
                v-model="fields.contractNumber.value"
                @valid:modelValue="fields.contractNumber.isValid = true"
                @invalid:modelValue="fields.contractNumber.isValid = false"
                @reset:modelValue="fields.contractNumber.isValid = null"
                :maxLength="100"
            />
        </div>
      </div>
      <div class="row mb-3">
        <FieldLabel
          :for="id+'-locationDescription'"
          class="col-sm-4 col-form-label"
          label="Zählerort"
          :validationState="meterModel.getFieldValidityIndicatorClass('locationDescription')"
        />
        <div class="col-sm-8">
          <StringInput
            name="locationDescription" 
            label="Zählerort"
            class="form-control"
            placeholder="Rechts neben..."
            v-model="fields.locationDescription.value"
            @valid:modelValue="fields.locationDescription.isValid = true"
            @invalid:modelValue="fields.locationDescription.isValid = false"
            @reset:modelValue="fields.locationDescription.isValid = null"
            :maxLength="250"
          />
        </div>
      </div>
    </section>
    </fieldset>
  </template>
  
  <script>
  import FieldLabel from  '@/components/labels/FieldLabel.vue'
  import StringInput from  '@/components/inputs/StringInput.vue'
  import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
  import SelectInput from  '@/components/inputs/SelectInput.vue'
  
  import Meter from '@/diaspora/models/meter.js'
  
  export default {
    name: 'MeterFields',
    emits: [
      'update:modelValue', 
      'update:fieldValue', 
      'valid:modelFields', 
      'invalid:modelFields',
      'error:modelFields'
    ],
    data(){
      return {
        meterModel: new Meter(),
      }
    },
    props:{
      id: {
        type: String,
      },
      meter:{
        type: Object
      },
    },
    async created(){
      if (this.meter){
          this.meterModel.unmarshalJSON(this.meter)
      }
      this.emitModelFieldValidity()
      
      this.meterModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
    },
    computed:{
      fields(){
        return this.meterModel.fields
      },
    },
    watch:{
      fields: {
        deep:true,
        handler(){
            this.emitModelFieldValidity()
            this.$emit('update:modelValue', this.meterModel.marshalJSON())
        }
      },
      meter: {
          deep: true,
          handler(){
              if (this.meter){
                  this.meterModel.unmarshalJSON(this.meter)
              }
          }
      },
    },
    methods:{
      async emitUpdateFieldValue(fieldName, oldValue, newValue){
        await this.$nextTick()      
        const isValid = this.fields[fieldName].isValid;
        const fieldLabel = this.fields[fieldName].displayName;
        this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
      },
      emitModelFieldValidity(){        
        const areAllFieldsValid = this.meterModel.areFieldsValid()
        if (areAllFieldsValid === true) {
          this.$emit('valid:modelFields')
          return
        }
        this.$emit('invalid:modelFields')
      },
    },
    components:{
      FieldLabel,
      StringInput,
      RadioButtonInput,
      SelectInput,
    }
  }
  </script>