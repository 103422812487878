<template>
  <div class="container">
    <h2>Immobilien-Objekte</h2>
    <RealEstateCollection
      :realEstateOwnerUUID="urlRealEstateOwnerUUID"
      :serviceProviderUUID="urlServiceProviderUUID"
      :isSubView="false"
    />
  </div>
</template>

<script>
import RealEstateCollection from '@/components/collections/RealEstateCollection.vue'

export default {
  name: 'RealEstate',
  computed:{
    urlRealEstateOwnerUUID(){
      return this.$route.params.realEstateOwnerUUID
    },
    urlServiceProviderUUID(){
      return this.$route.params.serviceProviderUUID
    },
  },
  components:{
    RealEstateCollection,
  }
}
</script>

<style>
</style>
