<template>
    <fieldset>
      <section
        :id="id+'-fields-main'" 
      >
          <h5
            class="collapsable"
            data-bs-toggle="collapse" 
            :data-bs-target="`#${id}-fields-main-body`"
            :aria-controls="`#${id}-fields-main-body`"
            aria-expanded="true"
          >
              Einheit
          </h5>
          <div
              :id="id+'-fields-main-body'"
              class="collapse show"
          >
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-type'"
                    class="col-sm-4 col-form-label"
                    label="Typ"
                    :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('type')"
                    mandatory
                  />
                  <div class="col-sm-8">
                      <RadioButtonInput
                        :name="id+'-type'" 
                        label="Typ"
                        :id="id+'-type'"
                        v-model="fields.type.value"
                        @set:modelValue="fields.type.isValid = true"
                        :options="fields.type.options"
                        mandatory
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-subType'"
                    class="col-sm-4 col-form-label"
                    label="Zusatztyp"
                    :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('subType')"
                  />
                  <div class="col-sm-8">            
                      <SelectInput
                        :name="id+'-subType'"
                        label="Zusatztyp"
                        :id="id+'-subType'"
                        class="form-control" 
                        v-model="fields.subType.value"
                        @valid:modelValue="fields.subType.isValid = true"
                        @invalid:modelValue="fields.subType.isValid = false"
                        @reset:modelValue="fields.subType.isValid = null"
                        :options="fields.subType.options"
                        :optionGroup="fields.type.value"
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-name'"
                    class="col-sm-4 col-form-label"
                    label="Name"
                    :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('name')"
                  />
                  <div class="col-sm-8">            
                      <StringInput
                          :name="id+'-name'"
                          label="Name"
                          class="form-control"
                          placeholder="DG Rechts"
                          v-model="fields.name.value"
                          @valid:modelValue="fields.name.isValid = true"
                          @invalid:modelValue="fields.name.isValid = false"
                          @reset:modelValue="fields.name.isValid = null"
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-spaceLiving'"
                      class="col-sm-4 col-form-label"
                      label="Wohnfläche"
                      :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('spaceLiving')"
                      mandatory
                  />
                  <div class="col-sm-8 input-container">
                      <FloatInput
                          :name="id+'-spaceLiving'"
                          label="Wohnfläche"
                          class="form-control"
                          placeholder="80,00"
                          v-model="fields.spaceLiving.value"
                          @valid:modelValue="fields.spaceLiving.isValid = true"
                          @invalid:modelValue="fields.spaceLiving.isValid = false"
                          @reset:modelValue="fields.spaceLiving.isValid = null"
                          mandatory
                      />
                      <UnitLabel
                          class="input-placeholder-unit"
                          type="sqm"
                      />
                  </div>
              </div>
              <div class="row mb-3" v-show="isRealEstateUnitTypeHouse">
                  <FieldLabel
                      :for="id+'-spaceProperty'"
                      class="col-sm-4 col-form-label"
                      label="Grundstücksfl."
                      :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('spaceProperty')"
                      mandatory
                  />
                  <div class="col-sm-8 input-container">
                  <FloatInput
                      :name="id+'-spaceProperty'"
                      label="Grundstücksfl."
                      class="form-control"
                      placeholder="125,00"
                      v-model="fields.spaceProperty.value"
                      @valid:modelValue="fields.spaceProperty.isValid = true"
                      @invalid:modelValue="fields.spaceProperty.isValid = false"
                      @reset:modelValue="fields.spaceProperty.isValid = null"
                  />
                  <UnitLabel
                      class="input-placeholder-unit"
                      type="sqm"
                  />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-spaceUsable'"
                      class="col-sm-4 col-form-label"
                      label="Nutzfläche"
                      :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('spaceUsable')"
                  />
                  <div class="col-sm-8 input-container">
                      <FloatInput
                          :name="id+'-spaceUsable'"
                          label="Nutzfläche"
                          class="form-control"
                          placeholder="25,00"
                          v-model="fields.spaceUsable.value"
                          @valid:modelValue="fields.spaceUsable.isValid = true"
                          @invalid:modelValue="fields.spaceUsable.isValid = false"
                          @reset:modelValue="fields.spaceUsable.isValid = null"
                      />
                      <UnitLabel
                          class="input-placeholder-unit"
                          type="sqm"
                      />
                  </div>
              </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberTotalRooms'"
              class="col-sm-4 col-form-label"
              label="Anz. Zimmer"
              :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('numberTotalRooms')"
              mandatory
            />
            <div class="col-sm-8">
                <IntegerInput
                  :name="id+'-numberTotalRooms'"
                  label="Anz. Zimmer"
                  class="form-control"
                  placeholder="5"
                  v-model="fields.numberTotalRooms.value"
                  @valid:modelValue="fields.numberTotalRooms.isValid = true"
                  @invalid:modelValue="fields.numberTotalRooms.isValid = false"
                  @reset:modelValue="fields.numberTotalRooms.isValid = null"
                  mandatory
                />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberBathrooms'"
              class="col-sm-4 col-form-label"
              label="Anz. Badezimmer"
              :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('numberBathrooms')"
            />
            <div class="col-sm-8">
                <IntegerInput
                  :name="id+'-numberBathrooms'"
                  label="Anz. Badezimmer"
                  class="form-control"
                  placeholder="2"
                  v-model="fields.numberBathrooms.value"
                  @valid:modelValue="fields.numberBathrooms.isValid = true"
                  @invalid:modelValue="fields.numberBathrooms.isValid = false"
                  @reset:modelValue="fields.numberBathrooms.isValid = null"
                />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberBedrooms'"
              class="col-sm-4 col-form-label"
              label="Anz. Schlafzimmer"
              :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('numberBedrooms')"
            />
            <div class="col-sm-8">
              <IntegerInput
                :name="id+'-numberBedrooms'"
                label="Anz. Schlafzimmer"
                class="form-control" 
                placeholder="2"
                v-model="fields.numberBedrooms.value"
                @valid:modelValue="fields.numberBedrooms.isValid = true"
                @invalid:modelValue="fields.numberBedrooms.isValid = false"
                @reset:modelValue="fields.numberBedrooms.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberFloors'"
              class="col-sm-4 col-form-label"
              label="Anz. Etagen"
              :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('numberFloors')"
            />
            <div class="col-sm-8 input-container">
              <IntegerInput
                :name="id+'-numberFloors'"
                label="Anz. Etagen"
                class="form-control"
                placeholder="4"
                v-model="fields.numberFloors.value"
                @valid:modelValue="fields.numberFloors.isValid = true"
                @invalid:modelValue="fields.numberFloors.isValid = false"
                @reset:modelValue="fields.numberFloors.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="isRealEstateUnitTypeApartment">
            <FieldLabel
              :for="id+'-floor'"
              class="col-sm-4 col-form-label"
              label="Etage"
              :validationState="realEstateUnitModel.getFieldValidityIndicatorClass('floor')"
            />
            <div class="col-sm-8 input-container">
              <IntegerInput
                :name="id+'-floor'"
                label="Etage"
                class="form-control"
                placeholder="2"
                v-model="fields.floor.value"
                @valid:modelValue="fields.floor.isValid = true"
                @invalid:modelValue="fields.floor.isValid = false"
                @reset:modelValue="fields.floor.isValid = null"
              />
            </div>
          </div>    
        </div>  
      </section>
      <!-- TODO: Einheit-spez. "Sanierungs"-Jahr, ... -->
    </fieldset>
</template>

<script>
import FieldLabel from  '@/components/labels/FieldLabel.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import IntegerInput from  '@/components/inputs/IntegerInput.vue'
import FloatInput from  '@/components/inputs/FloatInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import UnitLabel from  '@/components/labels/UnitLabel.vue'

import RealEstateUnit from '@/diaspora/models/real-estate-unit.js'

export default {
  name: 'RealEstateUnitFields',
  emits: [
    'update:modelValue', 
    'update:fieldValue', 
    'valid:modelFields', 
    'invalid:modelFields',
    'error:modelFields'
  ],
  data(){
    return {
      realEstateUnitModel: new RealEstateUnit(),
    }
  },
  props:{
    id: {
      type: String,
    },
    realEstateUnit:{
      type: Object
    },
  },
  async created(){
    if (this.realEstateUnit){
        this.realEstateUnitModel.unmarshalJSON(this.realEstateUnit)
    }
    this.emitModelFieldValidity()
    
    this.realEstateUnitModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
  },
  computed:{
    fields(){
      return this.realEstateUnitModel.fields
    },
    isRealEstateUnitTypeApartment(){
      return this.fields.type.value === 'Wohnung'
    },
    isRealEstateUnitTypeHouse(){
      return this.fields.type.value === 'Haus'
    },
  },
  watch:{
    fields: {
      deep:true,
      handler(){
          this.emitModelFieldValidity()
          this.$emit('update:modelValue', this.realEstateUnitModel.marshalJSON())
      }
    },
    realEstateUnit: {
        deep: true,
        handler(){
            if (this.realEstateUnit){
                this.realEstateUnitModel.unmarshalJSON(this.realEstateUnit)
            }
        }
    },
    isRealEstateUnitTypeApartment(){
      this.resetRealEstateUnitTypeSpecificFields()
    },
    isRealEstateUnitTypeHouse(){
      this.resetRealEstateUnitTypeSpecificFields()
    },
  },
  methods:{
    async emitUpdateFieldValue(fieldName, oldValue, newValue){
      await this.$nextTick()      
      const isValid = this.fields[fieldName].isValid;
      const fieldLabel = this.fields[fieldName].displayName;
      this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
    },
    emitModelFieldValidity(){        
      const areAllFieldsValid = this.realEstateUnitModel.areFieldsValid()
      if (areAllFieldsValid === true) {
        this.$emit('valid:modelFields')
        return
      }
      this.$emit('invalid:modelFields')
    },
    resetRealEstateUnitTypeSpecificFields(){
      if (this.isRealEstateUnitTypeHouse){
        return
      }
      this.fields.spaceProperty.value = 0.0
    },
  },
  components:{
    FieldLabel,
    StringInput,
    IntegerInput,
    FloatInput,
    SelectInput,
    RadioButtonInput,
    UnitLabel,
  }
}
</script>