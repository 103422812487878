<template>
<div>
    <div class="row g-1 p-1">
        <div class="col-6 col-sm-3">
            <RadioButtonInput
                name="contractTypeFilter" 
                label="Vermarktung"
                id="contractTypeFilter"
                v-model="filters.contractType.value"
                :options="filters.contractType.options"
                @select:option="toggleFilterOptionContractType"
                centered
            />
        </div>
        <div class="col-6 col-sm-3">
            <RadioButtonInput
                name="typeFilter" 
                label="Typ"
                id="typeFilter"
                v-model="filters.type.value"
                :options="filters.type.options"
                @select:option="toggleFilterOptionType"
                centered
            />
        </div>
        <div class="col-12 col-sm-6 p-1">
            <div
                class="tag px-1"
                :style="'background-color:' + tag.color"
                v-for="(tag, tagIdx) in tagOptions"
                :key="tagIdx"
            >
                <CheckboxInput
                    :id="tag.uuid + '-checkbox'"
                    class="form-check-input mx-1"
                    v-model="filters.tags[tag.uuid]"
                />
                <label
                    :id="tag.uuid + '-text'"
                    class="form-check-label" 
                    :for="tag.uuid + '-checkbox'"
                >
                    {{tag.name}}
                </label>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import FieldOption  from '@/diaspora/fields/field_option.js'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import CheckboxInput from '@/components/inputs/CheckboxInput.vue'

export default {
    name: 'RealEstateListingFilters',
    emits: [
        'update:filterValues',
        'update:filterTagUUIDs'
    ],
    data(){
        return {            
            filters: {
                contractType: {
                    value: '',
                    options: [
                        new FieldOption('Vermietung',   'Vermietung'),
                        new FieldOption('Verkauf',      'Verkauf'),
                    ]
                },
                type: {
                    value: '',
                    options: [
                        new FieldOption('Wohnung',   'Wohnung'),
                        new FieldOption('Haus',      'Haus'),
                    ]
                },
                tags: {}
            },
        }
    },
    props:{
        filterValues:{
            Type: Object,
            mandatory: true
        },
        filterTagUUIDs:{
            Type: Object,
            mandatory: true
        },
        tagOptions:{
            Type: Array
        }
    },
    computed:{
        contractTypeValue(){
            return this.filters.contractType.value
        },
        typeValue(){
            return this.filters.type.value
        },
        tagFlags(){
            return this.filters.tags
        }
    },
    watch:{
        contractTypeValue(){
            const newValues = {
                contractType:   this.contractTypeValue,
                type:           this.typeValue
            }
            this.$emit('update:filterValues', newValues)
        },
        typeValue(){
            const newValues = {
                contractType:   this.contractTypeValue,
                type:           this.typeValue
            }
            this.$emit('update:filterValues', newValues)
        },
        tagFlags:{
            deep: true,
            handler(){
                const tagFlagsSet = {}
                for (const tagUUID in this.tagFlags) {
                    const tagFlag = this.tagFlags[tagUUID]
                    if (tagFlag === true){
                        tagFlagsSet[tagUUID] = ''
                    }
                }
                this.$emit('update:filterTagUUIDs', tagFlagsSet)
            }
        },
        filterValues:{
            deep: true,
            handler(){
                this.filters.contractType.value = this.filterValues.contractType
                this.filters.type.value = this.filterValues.type
            }
        },
        filterTagUUIDs:{
            deep: true,
            handler(){
                const activeTagFlagUUIDs = Object.keys(this.filterTagUUIDs)
                if (activeTagFlagUUIDs && activeTagFlagUUIDs.length > 0){
                    return
                }
                for (const tagUUID in this.filters.tags) {
                    this.filters.tags[tagUUID] = false
                }
            }
        },
        tagOptions:{
            deep: true,
            handler(){
                const allTagFlags = {}
                for (let tagIdx = 0; tagIdx < this.tagOptions.length; tagIdx++) {
                    const tag = this.tagOptions[tagIdx]
                    if (typeof this.filters.tags[tag.uuid] !== 'boolean'){
                        allTagFlags[tag.uuid] = false
                        continue
                    }
                    allTagFlags[tag.uuid] = this.filters.tags[tag.uuid]
                }
                this.filters.tags = allTagFlags
            }
        }
    },
    methods:{
        toggleFilterOptionContractType(filterOption){
            if (this.filters.contractType.value !== filterOption.value){
                return
            }
            this.filters.contractType.value = ''
        },
        toggleFilterOptionType(filterOption){
            if (this.filters.type.value !== filterOption.value){
                return
            }
            this.filters.type.value = ''
        }  
    },
    components:{
        RadioButtonInput,
        CheckboxInput
    }
}
</script>

<style>

</style>