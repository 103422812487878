<template>
  <fieldset>
    <section
      :id="id+'-fields-main'" 
    >
      <h5
        class="collapsable"
        data-bs-toggle="collapse" 
        :data-bs-target="`#${id}-fields-main-body`"
        :aria-controls="`#${id}-fields-main-body`"
        aria-expanded="true"
      >
        Mieter
      </h5>
      <div
        :id="id+'-fields-main-body'"
        class="collapse show"
      >
        <div class="row mb-3">
            <FieldLabel
              :for="id+'-type'"
              class="col-sm-4 col-form-label"
              label="Abteilung"
              :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('department')"
            />
            <div class="col-sm-8">
                <RadioButtonInput
                  :name="id+'-department'" 
                  label="Abteilung"
                  :id="id+'-department'"
                  v-model="fields.department.value"
                  @set:modelValue="fields.department.isValid = true"
                  :options="fields.department.options"
                />
            </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-salutation'"
            class="col-sm-4 col-form-label"
            label="Anrede"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('salutation')"
          />
          <div class="col-sm-8">
            <SelectInput
              name="salutation" 
              label="Anrede"
              class="form-control" 
              v-model="fields.salutation.value"
              @valid:modelValue="fields.salutation.isValid = true"
              @invalid:modelValue="fields.salutation.isValid = false"
              @reset:modelValue="fields.salutation.isValid = null"
              :options="fields.salutation.options"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-firstName'"
            class="col-sm-4 col-form-label"
            label="Vorname"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('firstName')"
          />
          <div class="col-sm-8">
            <StringInput
              name="firstName" 
              label="Vorname"
              placeholder="Max"
              class="form-control" 
              v-model="fields.firstName.value"
              @valid:modelValue="fields.firstName.isValid = true"
              @invalid:modelValue="fields.firstName.isValid = false"
              @reset:modelValue="fields.firstName.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-lastName'"
            class="col-sm-4 col-form-label"
            label="Nachname"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('lastName')"
          />
          <div class="col-sm-8">
            <StringInput
              name="lastName" 
              label="Nachname"
              placeholder="Mustermann"
              class="form-control" 
              v-model="fields.lastName.value"
              @valid:modelValue="fields.lastName.isValid = true"
              @invalid:modelValue="fields.lastName.isValid = false"
              @reset:modelValue="fields.lastName.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-email'"
            class="col-sm-4 col-form-label"
            label="E-Mail"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('email')"
          />
          <div class="col-sm-8">
            <StringInput
              name="email" 
              label="E-Mail"
              placeholder="info@mustermann.de"
              class="form-control" 
              v-model="fields.email.value"
              @valid:modelValue="fields.email.isValid = true"
              @invalid:modelValue="fields.email.isValid = false"
              @reset:modelValue="fields.email.isValid = null"
              format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-phoneLandline'"
            class="col-sm-4 col-form-label"
            label="Telefon (Festnetz)"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('phoneLandline')"
          />
          <div class="col-sm-8">
            <StringInput
                name="phoneLandline" 
                label="Telefon (Festnetz)"
                placeholder="0631 1234 5678"
                class="form-control" 
                v-model="fields.phoneLandline.value"
                @valid:modelValue="fields.phoneLandline.isValid = true"
                @invalid:modelValue="fields.phoneLandline.isValid = false"
                @reset:modelValue="fields.phoneLandline.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-phoneMobile'"
            class="col-sm-4 col-form-label"
            label="Telefon (Mobil)"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('phoneMobile')"
          />
          <div class="col-sm-8">
            <StringInput
                name="phoneMobile" 
                label="Telefon (Mobil)"
                placeholder="01743 1234 5678"
                class="form-control" 
                v-model="fields.phoneMobile.value"
                @valid:modelValue="fields.phoneMobile.isValid = true"
                @invalid:modelValue="fields.phoneMobile.isValid = false"
                @reset:modelValue="fields.phoneMobile.isValid = null"
            />
          </div>
        </div>
        <div class="row mb-3">
          <FieldLabel
            :for="id+'-streetName'"
            class="col-sm-4 col-form-label"
            label="Straße"
            :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('streetName')"
          />  
          <div class="col-sm-8">
            <StringInput
              :name="id+'-streetName'"
              label="Straße"
              class="form-control"
              placeholder="Musterstr."
              v-model="fields.streetName.value"
              @valid:modelValue="fields.streetName.isValid = true"
              @invalid:modelValue="fields.streetName.isValid = false"
              @reset:modelValue="fields.streetName.isValid = null"
              :min-length="3"
            />
          </div>
      </div>
      <div class="row mb-3">
          <FieldLabel
              :for="id+'-streetNumber'"
              class="col-sm-4 col-form-label"
              label="Haus-Nr."
              :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('streetNumber')"
          />
          <div class="col-sm-8">
              <StringInput
                  :name="id+'-streetNumber'"
                  label="Haus-Nr."
                  class="form-control"
                  placeholder="2b"
                  v-model="fields.streetNumber.value"
                  @valid:modelValue="fields.streetNumber.isValid = true"
                  @invalid:modelValue="fields.streetNumber.isValid = false"
                  @reset:modelValue="fields.streetNumber.isValid = null"
                  :min-length="1"
              />
          </div>
      </div>
      <div class="row mb-3">                
          <FieldLabel
              :for="id+'-zipCode'"
              class="col-sm-4 col-form-label"
              label="PLZ"
              :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('zipCode')"
          />
          <div class="col-sm-8">
              <StringInput
                  :name="id+'-zipCode'"
                  label="PLZ"
                  class="form-control"
                  placeholder="85325"
                  v-model="fields.zipCode.value"
                  @valid:modelValue="fields.zipCode.isValid = true"
                  @invalid:modelValue="fields.zipCode.isValid = false"
                  @reset:modelValue="fields.zipCode.isValid = null"
                  :min-length="3"
                  :max-length="5"
              />
          </div>
      </div>
      <div class="row mb-3">
          <FieldLabel
              :for="id+'-city'"
              class="col-sm-4 col-form-label"
              label="Ort"
              :validationState="realEstateRenterModel.getFieldValidityIndicatorClass('city')"
          />
          <div class="col-sm-8">
              <StringInput
                  :name="id+'-city'"
                  label="Ort"
                  class="form-control"
                  placeholder="Musterstadt"
                  v-model="fields.city.value"
                  @valid:modelValue="fields.city.isValid = true"
                  @invalid:modelValue="fields.city.isValid = false"
                  @reset:modelValue="fields.city.isValid = null"
                  :min-length="3"
              />
          </div>
      </div>
    </div>
  </section>
  </fieldset>
</template>

<script>
import FieldLabel from  '@/components/labels/FieldLabel.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'

import RealEstateRenter from '@/diaspora/models/real-estate-renter.js'

export default {
  name: 'RealEstateRenterFields',
  emits: [
    'update:modelValue', 
    'update:fieldValue', 
    'valid:modelFields', 
    'invalid:modelFields',
    'error:modelFields'
  ],
  data(){
    return {
      realEstateRenterModel: new RealEstateRenter(),
    }
  },
  props:{
    id: {
      type: String,
    },
    realEstateRenter:{
      type: Object
    },
  },
  async created(){
    if (this.realEstateRenter){
        this.realEstateRenterModel.unmarshalJSON(this.realEstateRenter)
    }
    this.emitModelFieldValidity()
    
    this.realEstateRenterModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
  },
  computed:{
    fields(){
      return this.realEstateRenterModel.fields
    },
  },
  watch:{
    fields: {
      deep:true,
      handler(){
          this.emitModelFieldValidity()
          this.$emit('update:modelValue', this.realEstateRenterModel.marshalJSON())
      }
    },
    realEstateRenter: {
        deep: true,
        handler(){
            if (this.realEstateRenter){
                this.realEstateRenterModel.unmarshalJSON(this.realEstateRenter)
            }
        }
    },
  },
  methods:{
    async emitUpdateFieldValue(fieldName, oldValue, newValue){
      await this.$nextTick()      
      const isValid = this.fields[fieldName].isValid;
      const fieldLabel = this.fields[fieldName].displayName;
      this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
    },
    emitModelFieldValidity(){        
      const areAllFieldsValid = this.realEstateRenterModel.areFieldsValid()
      if (areAllFieldsValid === true) {
        this.$emit('valid:modelFields')
        return
      }
      this.$emit('invalid:modelFields')
    },
  },
  components:{
    FieldLabel,
    StringInput,
    RadioButtonInput,
    SelectInput,
  }
}
</script>