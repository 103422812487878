<template>
<div
    class="widget-container"
>
  <div class="header">
    <h1>Sync-Übersicht</h1>
    <router-link 
      to="/admin/sync-errors"
      id="sync-errors-link"
    >
      Zur Fehlerübersicht
    </router-link>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-6">
        <h2>Objekte</h2>
        <section>
          <h3>Immoscout24</h3>
          <span class="row">
              <label class="col-6">Gescheitert</label>
              <span class="col-6 text-end">{{ realEstateSyncOverviewImmoscout24.fields.numFailures.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Erfolgreich</label>
              <span class="col-6 text-end">{{ realEstateSyncOverviewImmoscout24.fields.numSuccesses.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Gesamt</label>
              <span class="col-6 text-end">{{ realEstateSyncOverviewImmoscout24.fields.numTotal.value }}</span>
          </span>
        </section>
        <hr />
        <section>
          <h3>Bookoo</h3>
          <span class="row">
              <label class="col-6">Gescheitert</label>
              <span class="col-6 text-end">{{ realEstateSyncOverviewBookoo.fields.numFailures.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Erfolgreich</label>
              <span class="col-6 text-end">{{ realEstateSyncOverviewBookoo.fields.numSuccesses.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Gesamt</label>
              <span class="col-6 text-end">{{ realEstateSyncOverviewBookoo.fields.numTotal.value }}</span>
          </span>
        </section>
      </div>
      <div class="col-6">
        <h2>Dokumente</h2>
        <section>
          <h3>Immoscout24</h3>
          <span class="row">
              <label class="col-6">Gescheitert</label>
              <span class="col-6 text-end">{{ documentSyncOverviewImmoscout24.fields.numFailures.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Erfolgreich</label>
              <span class="col-6 text-end">{{ documentSyncOverviewImmoscout24.fields.numSuccesses.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Gesamt</label>
              <span class="col-6 text-end">{{ documentSyncOverviewImmoscout24.fields.numTotal.value }}</span>
          </span>
        </section>
        <hr />
        <section>
          <h3>Bookoo</h3>
          <span class="row">
              <label class="col-6">Gescheitert</label>
              <span class="col-6 text-end">{{ documentSyncOverviewBookoo.fields.numFailures.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Erfolgreich</label>
              <span class="col-6 text-end">{{ documentSyncOverviewBookoo.fields.numSuccesses.value }}</span>
          </span>
          <span class="row">
              <label class="col-6">Gesamt</label>
              <span class="col-6 text-end">{{ documentSyncOverviewBookoo.fields.numTotal.value }}</span>
          </span>
        </section>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { services } from '@digiscape/js-core'

import FormMessages from '@/components/forms/features/form-messages.js'

import SyncLogStatisticsOverview from '@/diaspora/derivatives/sync_log_statistics_overview.js'

export default {
  name: 'SyncLogOverviewWidget',
  data(){
    return {
      messages: new FormMessages(),     
      realEstateSyncOverviewBookoo: new SyncLogStatisticsOverview(),
      realEstateSyncOverviewImmoscout24: new SyncLogStatisticsOverview(),
      documentSyncOverviewBookoo: new SyncLogStatisticsOverview(), 
      documentSyncOverviewImmoscout24: new SyncLogStatisticsOverview(),
    }
  },
  computed:{
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    }
  },
  async created(){
    await this.readAllRealEstateListingSyncOverviews()
    await this.readAllDocumentSyncOverviews()
  },
  methods:{
    async readAllRealEstateListingSyncOverviews(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/sync-status/statistics/real-estate-listings")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0047", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.realEstateSyncOverviewBookoo.unmarshalJSON(response.data.Bookoo)
      this.realEstateSyncOverviewImmoscout24.unmarshalJSON(response.data.Immoscout24)
      return true
    },
    async readAllDocumentSyncOverviews(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/sync-status/statistics/documents")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0048", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.documentSyncOverviewBookoo.unmarshalJSON(response.data.Bookoo)
      this.documentSyncOverviewImmoscout24.unmarshalJSON(response.data.Immoscout24)
      return true
    },
  }
}
</script>

<style scoped>
#sync-errors-link{
  position: absolute;
  top: 0px;
  right: 5px;
}

.widget-container .header{
  position: relative;
}
</style>