<template>
  <div class="row">
    <div>
      <h5 v-show="type === 'image'">Bilder</h5>
      <h5 v-show="type === 'floorPlan'">Grundrisse</h5>
      <h5 v-show="type === 'document'">Dokumente</h5>
    </div>
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">{{ typeLabel }} werden geladen...</h5>
    </div>
    <div
      v-show="isLoaded"
      class="col-12"
      :class="{ 'col-lg-8 order-2 order-lg-1 column-brd-right': editDocumentUUID }"
    >
      <div
        class="col-6 col-sm-4 col-lg-2 col-xl-2"
        v-if="documentModels.length > 0"
      >
        <button
          class="btn btn-brand form-control"
          type="button"
          @click="handleSortDocuments"
        >
          Sortieren
        </button>
      </div>
      <div 
        class="flow-card small btn suc"
        :class="{'dragging': isOverFileUploadDropZone}"
      >
        <input
          type="file"
          :name="name" 
          class="form-control"
          @change="handleNewFiles"
          @dragover="handleDragOverFileUpload"
          @dragleave="handleDragLeaveFileUpload"
          multiple
        >
        <div class="body">
          <span class="btn-text">
            {{ label }}
          </span>
          <span class="btn-subtext">
            {{ hint }}
          </span>
        </div>
      </div>
      <DocumentGridItem 
        v-for="(documentModel, documentIdx) in documentModels"
        :id="documentModel.fields.uuid.value"      
        :key="documentIdx"
        :documentModel="documentModel"
        :draggingDocumentUUID="draggingDocumentUUID"
        :editDocumentUUID="editDocumentUUID"
        class="flow-card small grabbable pointable"
        :class="{
          'cover-image': coverImageUUID === documentModel.fields.uuid.value, 
          'selected': editDocumentUUID === documentModel.fields.uuid.value
        }"
        @dragstart="handleDragStartDocument($event, documentModel)"
        @drag="handleDragDocument"
        @dragend="handleDragEndDocument"
        @dropLeft:document="handleDocumentDropLeft"
        @dropRight:document="handleDocumentDropRight"
        @triggered:modelDeletion="triggerDeleteDocument"
        @triggered:toggleModelEdit="toggleEditDocument"
        @success:message="emitSuccessMessage"
        @error:message="emitErrorMessage"
        @pending:message="emitPendingMessage"
        :draggable="true"
      />
    </div>
    <div
      id="editDocumentFields"
      class="col-12 col-lg-4 order-1 order-lg-2"
      v-if="editDocumentUUID && isLoaded"
    >
      <DocumentEditForm
        class="row mb-3 sticky-top fields-sticky"
        :documentUUID="editDocumentUUID"
        :bookooLimitReached="(numBookooDocuments >= 5)"
        @navigate:nextModel="selectNextEditDocument"
        @navigate:previousModel="selectPreviousEditDocument"
        @cancelled:modelEdit="editDocumentUUID = ''"
        @success:modelEdit="finalizeEditDocument"
        @success:modelDelete="finalizeDeleteDocument"
        @triggered:updateCoverImage="triggerUpdateCoverImage"
        @success:message="emitSuccessMessage"
        @error:message="emitErrorMessage"
        @pending:message="emitPendingMessage"
      />
    </div>
  </div>
</template>

<script>
import Document from '@/diaspora/models/document.js'

import DocumentEditForm from  '@/components/forms/DocumentEditForm.vue'
import DocumentGridItem from  '@/components/grids/DocumentGridItem.vue'

export default {
  name: 'DocumentGrid',
  emits: [
    'update:documentFiles',
    'success:modelEdit',
    'success:modelDelete',
    'triggered:modelUpdate',
    'triggered:modelDeletion',
    'triggered:updateCoverImage',
    'triggered:reloadDocuments',
    'triggered:modelSelected',
    'triggered:modelUnselected',
    'success:message',
    'error:message',
    'pending:message',
  ],
  data(){
    return {
      currentComponent: 'DocumentGrid',
      isOverFileUploadDropZone: false,      
      editDocumentUUID: '',
      files: [],
      draggingDocumentUUID: '',
      dragDocCoords:{
        initialX: 0,
        initialY: 0,
        currentX: 0,
        currentY: 0,
      },
    }
  },
  props:{
    documents: {
      type: Array,
      required: true
    },
    documentChannelStatistics:{
      type: Array,
      required: true,
    },
    realEstateListingUUID:{
      type: String
    },
    coverImageUUID:{
      type: String
    },
    name:{
      type: String
    },
    label:{
      type: String,
      default: 'Dateien hinzufügen'
    },
    hint:{
      type: String,
      default: '(Max. 50 MB)'
    },
    type:{
      type: String,
      required: true,
    },
    isLoaded:{
      type: Boolean
    }
  },
  computed:{
    typeLabel(){
      if (this.type == 'image'){
        return "Bilder" 
      }
      if (this.type == 'floorPlan'){
        return "Grundrisse" 
      }
      return "Dokumente"
    },
    documentModels(){
      const documentModels = []
      for (let jsonDocIdx = 0; jsonDocIdx < this.documents.length; jsonDocIdx++) {
        const jsonDocument = this.documents[jsonDocIdx]
        const documentModel = new Document()
        documentModel.unmarshalJSON(jsonDocument)
        documentModels.push(documentModel)
      } 
      return documentModels
    },
    numBookooFloorPlanDocuments(){
      let numFloorPlans = 0
      for (let channelStatIdx = 0; channelStatIdx < this.documentChannelStatistics.length; channelStatIdx++) {
        const channelStatistic = this.documentChannelStatistics[channelStatIdx]
        if (channelStatistic.document_type != 'floorPlan'){
          continue
        }
        if (channelStatistic.channel_id != 'Bookoo'){
          continue 
        }
        numFloorPlans += channelStatistic.num_records
      }
      return numFloorPlans
    },
    numBookooImageDocuments(){
      let numImages = 0
      for (let channelStatIdx = 0; channelStatIdx < this.documentChannelStatistics.length; channelStatIdx++) {
        const channelStatistic = this.documentChannelStatistics[channelStatIdx]
        if (channelStatistic.document_type != 'image'){
          continue
        }
        if (channelStatistic.channel_id != 'Bookoo'){
          continue 
        }
        numImages += channelStatistic.num_records
      }
      return numImages
    },
    editDocumentIdx(){      
      if (!this.editDocumentUUID){
        return -1
      }
      for (let jsonDocIdx = 0; jsonDocIdx < this.documents.length; jsonDocIdx++) {
        const jsonDocument = this.documents[jsonDocIdx]
        if (jsonDocument.uuid != this.editDocumentUUID){
          continue
        }
        return jsonDocIdx
      }
      return -1
    },
    numBookooDocuments(){
      return this.numBookooFloorPlanDocuments + this.numBookooImageDocuments
    }
  },
  watch:{
    files(){
      this.$emit('update:documentFiles', this.type, this.files)
    },
    realEstateListingUUID(){
      this.editDocumentUUID = ''
    },
    editDocumentUUID(){
      if (this.editDocumentUUID && this.editDocumentUUID != ''){
        this.$emit('triggered:modelSelected')
        return
      }
      this.$emit('triggered:modelUnselected')
    }
  },
  beforeUnmount(){
    this.$emit('triggered:modelUnselected')
  },
  methods:{    
    emitSuccessMessage(successMessage){      
      this.$emit('success:message', successMessage)    
    },
    emitErrorMessage(errorCode, errorMessage){      
      this.$emit('error:message', errorCode, errorMessage)    
    },
    emitPendingMessage(pendingMessage){      
      this.$emit('pending:message', pendingMessage)    
    },
    handleSortDocuments(event){
      event.preventDefault()
      const shouldSort = confirm('Sicher, dass die Elemente nach Zahlen-Präfix im Namen neu sortiert werden sollen? \n\nBeispiel: 2_wohnung, 1_kueche -> 1_kueche, 2_wohung, ...)')
      if (!shouldSort){
        return
      }
      this.$emit('triggered:sortByPrefix', this.type)
    },
    handleNewFiles(event){
      const fileList = event.target.files
      const newFiles = []
      for (let fileIdx = 0; fileIdx < fileList.length; fileIdx++) {
        const file = fileList[fileIdx]
        newFiles.push(file)
      }
      this.files = newFiles
    },
    handleDragOverFileUpload (event) {
      event.preventDefault()
      if (this.draggingDocumentUUID){
        return
      }
      this.isOverFileUploadDropZone = true
    },
    handleDragLeaveFileUpload (event) {
      event.preventDefault()
      if (this.draggingDocumentUUID){
        return
      }
      this.isOverFileUploadDropZone = false
    },
    handleDragStartDocument (event, documentModel) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('uuid', documentModel.fields.uuid.value)
      event.dataTransfer.setData('channelOrder', documentModel.fields.channelOrder.value)
      this.draggingDocumentUUID = documentModel.fields.uuid.value

      if (event.type === 'touchstart'){
        this.dragDocCoords.initialX = event.touches[0].clientX
        this.dragDocCoords.initialY = event.touches[0].clientY
      } else {
        this.dragDocCoords.initialX = event.clientX
        this.dragDocCoords.initialY = event.clientY
      }
    },
    handleDragDocument(event){
      event.preventDefault()
      if (event.clientX === 0 && event.clientY === 0){
        return
      }
      if (event.type === "touchmove") {
        this.dragDocCoords.currentX = event.touches[0].clientX - this.dragDocCoords.initialX
        this.dragDocCoords.currentY = event.touches[0].clientY - this.dragDocCoords.initialY
      } else {
        this.dragDocCoords.currentX = event.clientX - this.dragDocCoords.initialX
        this.dragDocCoords.currentY = event.clientY - this.dragDocCoords.initialY
      }
      event.target.style.transform = 'translate3d('+this.dragDocCoords.currentX+'px, '+this.dragDocCoords.currentY+'px, 0)'
    },
    handleDragEndDocument (event) {
      this.dragDocCoords.initialX = this.dragDocCoords.currentX
      this.dragDocCoords.initialY = this.dragDocCoords.currentY
      this.draggingDocumentUUID = ''
      event.target.style.transform = ''
    },
    handleDocumentDropLeft(sourceOrder, targetOrder){
      const sourceModel = this.documentModels[sourceOrder - 1]

      let newOrder = targetOrder
      if (sourceOrder < targetOrder){
        newOrder = newOrder - 1
      }
      sourceModel.fields.channelOrder.value = newOrder

      const sourceFields = sourceModel.marshalJSON()
      this.$emit('triggered:modelUpdate', sourceFields)
    },
    handleDocumentDropRight(sourceOrder, targetOrder){
      const sourceModel = this.documentModels[sourceOrder - 1]
      
      let newOrder = targetOrder
      if (sourceOrder > targetOrder){
        newOrder = newOrder + 1
      }
      sourceModel.fields.channelOrder.value = newOrder

      const sourceFields = sourceModel.marshalJSON()
      this.$emit('triggered:modelUpdate', sourceFields)
    },    
    triggerDeleteDocument(documentUUID){
      this.$emit('triggered:modelDeletion', documentUUID)
    },
    toggleEditDocument(documentUUID){
      if (!this.editDocumentUUID){
        this.editDocumentUUID = documentUUID
        this.$nextTick(this.scrollRelevantElementIntoView.bind(this, documentUUID))
        return        
      }
      if (this.editDocumentUUID === documentUUID){
        this.editDocumentUUID = ''
        return
      }
      this.editDocumentUUID = documentUUID
      this.$nextTick(this.scrollRelevantElementIntoView.bind(this, documentUUID))
    },
    scrollRelevantElementIntoView(documentUUID){      
      const isSmallScreen = window.innerWidth <= 992;
      if (isSmallScreen){
        document.getElementById(documentUUID).scrollIntoView()
        document.getElementById("editDocumentFields").scrollIntoView()
        return
      }
      document.getElementById("editDocumentFields").scrollIntoView()
      document.getElementById(documentUUID).scrollIntoView()
    },
    finalizeEditDocument(documentJSON){
      this.triggerUpdateLocalDocument(documentJSON)    
      this.$nextTick(() => {
        this.selectNextEditDocument()
      })
    },
    finalizeDeleteDocument(documentJSON){
      this.selectNextEditDocument()
      this.triggerDeleteLocalDocument(documentJSON)
    },
    selectPreviousEditDocument(){      
      if (this.editDocumentIdx == -1 || this.documents.length == 0 || this.editDocumentIdx == 0){
        return
      }
      const prevDocumentIdx = this.editDocumentIdx - 1
      const prevDocument = this.documents[prevDocumentIdx]
      this.editDocumentUUID = prevDocument.uuid
    },
    selectNextEditDocument(){      
      const lastDocumentIdx = this.documents.length - 1
      if (this.editDocumentIdx == -1 || lastDocumentIdx == -1 || this.editDocumentIdx == lastDocumentIdx){
        return
      }
      const nextDocumentIdx = this.editDocumentIdx + 1
      const nextDocument = this.documents[nextDocumentIdx]
      this.editDocumentUUID = nextDocument.uuid
    },
    triggerReloadDocuments(){
      this.$emit('triggered:reloadDocuments')
    },
    triggerUpdateLocalDocument(documentJSON){
      this.$emit('success:modelEdit', documentJSON)
    },
    triggerDeleteLocalDocument(documentJSON){
      this.$emit('success:modelDelete', documentJSON)
    },
    triggerUpdateCoverImage(documentUUID){
      this.$emit('triggered:updateCoverImage', documentUUID)
    }
  },
  components:{
    DocumentEditForm,
    DocumentGridItem
  }
}
</script>

<style>
.cover-image{
  border: 2px solid var(--co-hl);
  overflow: initial;
}
.cover-image::before{
  content: 'Titelbild';
  position: absolute;
  top: -1.2rem;
  left: -2px;
  background-color: var(--co-hl);
  padding: 0px 5px;
  border-radius: 5px;
  color: var(--co-hl-inv);
  letter-spacing: 0.5px;
}

.selected{
    border: 2px solid var(--co-suc-brd);
    overflow: initial;
}
.selected::after{
  content: 'Ausgewählt';
  position: absolute;
  top: -1.2rem;
  right: 0px;
  background-color: var(--co-suc-brd);
  color: var(--co-suc-bg-inv);
  padding: 0px 5px;
  border-radius: 5px;
  color: var(--co-hl-inv);
  letter-spacing: 0.5px;
}
</style>
