<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <nav 
      id="owner-search-bar"
      class="col-12 col-lg-10"
      v-show="currentComponent === 'RealEstateOwnerOverview' && contextUUID === ''"
    >
      <div class="row gx-1 gx-lg-2 position-relative">
          <div class="col-7 col-sm-8 col-xl-6">
            <StringInput
                name="searchText" 
                label="Hinweis"
                class="form-control"
                placeholder="Herr ..."
                v-model="searchText"
                @valid:modelValue="searchTextValid = true"
                @invalid:modelValue="searchTextValid = false"
                @reset:modelValue="searchTextValid = null"
                :maxLength="100"
            />
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-sec form-control show-xl"
              type="button"
              @click="displayFilters = !displayFilters"
            >
              Filtern
            </button>
            <button
              class="btn btn-icon-sec form-control hide-xl"
              type="button"
              @click="displayFilters = !displayFilters"
            >
              <FunnelIcon />
            </button>
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-warn form-control show-xl"
              type="button"
              @click="resetSearchFilterCriteria"
            >
              Zurücksetzen
            </button>
            <button
              class="btn btn-icon-warn form-control hide-xl"
              type="button"
              @click="resetSearchFilterCriteria"
            >
              <UndoArrowIcon />
            </button>
          </div>
          <h6
            v-show="displayFilters"
            class="mt-1"
          >Filter</h6>
          <RealEstateOwnerFilters
            v-show="displayFilters"
            class="col-8 col-md-6 col-xxl-4"
            :filterValues="filterValues"
            @update:filterValues="updateFilterValues"
          />
      </div>
    </nav>
    <div
      v-if="currentComponent === 'RealEstateOwnerOverview'"
    >
      <div class="my-2">
        <div
          class="btn btn-brand"
          title="Neuen Eigentümer erstellen"
          role="button"
          @click="createRealEstateOwner"
        >
          Neuen Eigentümer erstellen
        </div>
      </div>
      <RealEstateOwnerTable 
        id="real-estate-owners"
        :contextType="contextType"
        :contextUUID="contextUUID"
        :realEstateOwners="displayRealEstateOwners"
        :isLoaded="isLoaded"
        @triggered:modelEdit="selectRealEstateOwnerForEdit"
        @triggered:modelDetach="detachRealEstateOwner"
      />
      <div
        class="my-2 row"
        v-if="isContextGiven"
        v-show="isLoaded"
      >
        <div class="col-8 col-md-8 my-1">
          <SelectAutocompleteInput
              name="attachOwner"
              label="Zusatztyp"
              id="attachOwner"
              class="form-control" 
              v-model="attachRealEstateOwnerUUID"
              :options="unattachedRealEstateOwnerOptions"
          />
        </div>
        <div class="col-8 col-md-4 my-1">
          <button
            class="btn btn-sec w-100"
            title="Neuen Eigentümer hinzufügen"
            role="button"
            @click="attachRealEstateOwner"
            :disabled="attachRealEstateOwnerUUID == ''"
          >
            Eigentümer hinzuf.
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="currentComponent === 'RealEstateOwnerEditForm'"
    >
      <RealEstateOwnerEditForm
        class="col-12"
        @cancelled:modelEdit="exitToRelatedContextOverview"
        @success:modelDelete="exitToRelatedContextOverview"
        @navigate:model="selectRealEstateOwnerForEdit"
        :realEstateOwnerUUID="currentRealEstateOwnerUUID"
        :prevModelUUID="prevRealEstateOwnerUUID"
        :nextModelUUID="nextRealEstateOwnerUUID"
        :contextType="contextType"
        :contextUUID="contextUUID"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import RealEstateOwner from '@/diaspora/models/real-estate-owner.js'
import { readAllRealEstateOwners, readAllRealEstateOwnersByRelated, searchRealEstateOwners, createRealEstateOwner, attachRealEstateOwner, detachRealEstateOwner } from '@/diaspora/models/queries/real-estate-owner.js'

import RealEstateOwnerTable from '@/components/tables/RealEstateOwnerTable.vue'
import RealEstateOwnerEditForm from '@/components/forms/RealEstateOwnerEditForm.vue'

import SelectAutocompleteInput from '@/components/inputs/SelectAutocompleteInput.vue'
import StringInput from  '@/components/inputs/StringInput.vue'

import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'
import FunnelIcon from  '@/components/icons/FunnelIcon.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import RealEstateOwnerFilters from '@/components/control/RealEstateOwnerFilters.vue'

export default {
  name: 'RealEstateOwnerCollection',
  data(){
    return {      
      messageStore: new MessageStore(),
      allRealEstateOwners: [],
      contextRealEstateOwners: [],
      searchText: '',
      searchTextValid: false,
      displayFilters: false,
      isLoaded: false,
      currentComponent: 'RealEstateOwnerOverview',
      currentRealEstateOwnerUUID: '',
      attachRealEstateOwnerUUID: '',
      filterValues: {
        department: '',
      },
    }
  },
  props:{
    isSubView: {
      type: Boolean,
    },
    realEstateUUID: {
      type: String,
    },
    serviceProviderUUID: {
      type: String,
    },
    realEstateUnitUUID: {
      type: String,
    }
  },
  computed:{
    userDepartmentPrefilter(){
      const department = this.$store.getters.userDepartment
      if (department === 'Alle'){
        return ''
      }
      return department
    },
    contextType(){
      if (this.realEstateUUID){
        return 'RealEstate'
      }
      if (this.realEstateUnitUUID){
        return 'RealEstateUnit'
      }
      if (this.serviceProviderUUID){
        return 'ServiceProvider'
      }
      return ''
    }, 
    contextUUID(){
      if (this.realEstateUUID){
        return this.realEstateUUID
      }
      if (this.realEstateUnitUUID){
        return this.realEstateUnitUUID
      }
      if (this.serviceProviderUUID){
        return this.serviceProviderUUID
      }
      return ''
    }, 
    isContextGiven(){
      return this.contextType != '' && this.contextUUID != ''
    },
    displayRealEstateOwners(){
      let realEstateOwnerSubset = this.contextRealEstateOwners
      if (!realEstateOwnerSubset){
        return []
      }
      realEstateOwnerSubset = this.cloneArray(this.contextRealEstateOwners)
      realEstateOwnerSubset = this.filterRealEstateOwnersByDepartment(realEstateOwnerSubset)
      return realEstateOwnerSubset.sort(this.realEstateOwnerNameComparator)
    },
    sortedUnattachedRealEstateOwners(){
      const sorted = this.cloneArray(this.unattachedRealEstateOwners)
      return sorted.sort(this.realEstateOwnerNameComparator)
    },
    unattachedRealEstateOwners(){
      const unattachedOwners = [] 
      for (let ownerIdx = 0; ownerIdx < this.allRealEstateOwners.length; ownerIdx++) {
        const owner = this.allRealEstateOwners[ownerIdx];
        if (this.isContextRealEstateOwner(owner.uuid)){
          continue
        }
        unattachedOwners.push(owner)
      }
      return unattachedOwners
    },
    unattachedRealEstateOwnerOptions(){
      const unattachedOwnerOptions = []
      for (let ownerIdx = 0; ownerIdx < this.sortedUnattachedRealEstateOwners.length; ownerIdx++) {
        const owner = this.sortedUnattachedRealEstateOwners[ownerIdx];
        const ownerOption = {
          label: this.getOwnerOptionLabel(owner), 
          value: owner.uuid,
        }
        if (ownerOption.label === '' || !ownerOption.label){
          continue
        }
        unattachedOwnerOptions.push(ownerOption)
      }
      return unattachedOwnerOptions
    },
    urlRealEstateOwnerUUID(){
      return this.$route.params.realEstateOwnerUUID
    },    
    prevRealEstateOwnerUUID(){
      if (!this.currentRealEstateOwnerUUID){
        return ''
      }
      let prevRealEstateOwner = undefined
      for (let realEstateOwnerIdx = 0; realEstateOwnerIdx < this.displayRealEstateOwners.length; realEstateOwnerIdx++) {
        const realEstateOwner = this.displayRealEstateOwners[realEstateOwnerIdx]
        if (realEstateOwner.uuid !== this.currentRealEstateOwnerUUID){
          prevRealEstateOwner = realEstateOwner
          continue
        }
        if (prevRealEstateOwner){
          return prevRealEstateOwner.uuid
        }
        return ''
      }
      return ''
    },
    nextRealEstateOwnerUUID(){
      if (!this.currentRealEstateOwnerUUID){
        return ''
      }
      let nextRealEstateOwner = undefined
      for (let realEstateOwnerIdx = this.displayRealEstateOwners.length - 1; realEstateOwnerIdx >= 0; realEstateOwnerIdx--) {
        const realEstateOwner = this.displayRealEstateOwners[realEstateOwnerIdx]
        if (realEstateOwner.uuid !== this.currentRealEstateOwnerUUID){
          nextRealEstateOwner = realEstateOwner
          continue
        }
        if (nextRealEstateOwner){
          return nextRealEstateOwner.uuid
        }
        return ''
      }
      return ''
    },
  },
  async mounted(){
    if (this.userDepartmentPrefilter){
      this.filterValues.department = this.userDepartmentPrefilter
    }
    if (this.urlRealEstateOwnerUUID && !this.isSubView){
      this.showRealEstateOwnerEditForm(this.urlRealEstateOwnerUUID)
    }
    await this.loadRealEstateOwnerData()
  },
  watch:{
    async searchText(){
      await this.loadRealEstateOwnerData()
    },
    urlRealEstateOwnerUUID(newUUID){
      if (newUUID){
        this.showRealEstateOwnerEditForm(newUUID)
        return
      }
      this.showRealEstateOwnerOverview()
    },
    async contextUUID(newUUID){
      if (!newUUID){
        this.allRealEstateOwners = []
        this.contextRealEstateOwners = []
        return
      }
      
      await this.loadRealEstateOwnerData()
    },
  },
  methods:{
    async loadRealEstateOwnerData(){      
      this.isLoaded = false
      await Promise.all([
        this.loadContextRealEstateOwners(),
        this.loadAllRealEstateOwners()
      ])
      this.attachRealEstateOwnerUUID = ''
      this.isLoaded = true
    },
    async loadContextRealEstateOwners(){
      if (this.searchText){
        this.contextRealEstateOwners = await this.searchRealEstateOwners()
        return
      }
      if (this.isContextGiven) {
        this.contextRealEstateOwners = await this.readRelatedRealEstateOwners()
        return
      }
      this.contextRealEstateOwners = await this.readAllRealEstateOwners()
    },
    async loadAllRealEstateOwners(){
      if (!this.isContextGiven) {
        return
      }
      this.allRealEstateOwners = await this.readAllRealEstateOwners()
    },
    async searchRealEstateOwners(){
      const messageTarget = `searchRealEstateOwners`
      this.messageStore.flushTarget(messageTarget)

      let resultRealEstateOwners = []
      try{
        resultRealEstateOwners = await searchRealEstateOwners(this.searchText)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0093", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return resultRealEstateOwners      
    },
    async readRelatedRealEstateOwners(){
      if (!this.isContextGiven){
        services.$log.warn("MOC0101", "read all real estate owners cannot be executed, invalid related uuid / type, skipping")
        return
      }
      const messageTarget = `readRelatedRealEstateOwners`
      this.messageStore.flushTarget(messageTarget)

      let relatedRealEstateOwners = []
      try{
        relatedRealEstateOwners = await readAllRealEstateOwnersByRelated(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0088", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedRealEstateOwners      
    },
    async readAllRealEstateOwners(){
      const messageTarget = `readAllRealEstateOwners`
      this.messageStore.flushTarget(messageTarget)

      let allRealEstateOwners = []
      try{
        allRealEstateOwners = await readAllRealEstateOwners()
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0089", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return allRealEstateOwners
    },
    async createRealEstateOwner(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `createRealEstateOwner`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Erstelle einen neuen Eigentümer...', messageTarget)

      const newRealEstateOwner = new RealEstateOwner()
      const newRealEstateOwnerJSON = newRealEstateOwner.marshalJSON()
      let createdUUID = ''
      try{
        createdUUID = await createRealEstateOwner(newRealEstateOwnerJSON, this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0086", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Eigentümer erfolgreich erstellt.", messageTarget)
      this.selectRealEstateOwnerForEdit(createdUUID)
    },
    async attachRealEstateOwner(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `attachRealEstateOwner`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Füge Eigentümer hinzu...', messageTarget)

      if (!this.attachRealEstateOwnerUUID){
        this.messageStore.error("MOC0090", "Etwas ist schiefgelaufen. Der ausgewählte Eigentümer kann nicht identifiziert werden.", messageTarget)
        return
      }

      try{
        await attachRealEstateOwner(this.contextType, this.contextUUID, this.attachRealEstateOwnerUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0086", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Eigentümer erfolgreich hinzugefügt.", messageTarget)
      await this.loadRealEstateOwnerData()
    },
    async detachRealEstateOwner(detachRealEstateOwnerUUID){
      const messageTarget = `detachRealEstateOwner`
      this.messageStore.flushTarget(messageTarget)
      
      const isConfirmed = confirm("Sicher, dass der Eigentümer entfernt werden soll?")
      if (!isConfirmed){
        return
      }

      this.messageStore.pending('', 'Entferne Eigentümer...', messageTarget)

      if (!detachRealEstateOwnerUUID){
        this.messageStore.error("MOC0091", "Etwas ist schiefgelaufen. Der ausgewählte Eigentümer kann nicht identifiziert werden.", messageTarget)
        return
      }

      try{
        await detachRealEstateOwner(this.contextType, this.contextUUID, detachRealEstateOwnerUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0092", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Eigentümer erfolgreich entfernt.", messageTarget)
      await this.loadRealEstateOwnerData()
    },
    updateFilterValues(newValues){
      for (const filterName in newValues) {
        const newFilterValue = newValues[filterName]
        this.filterValues[filterName] = newFilterValue
      }
    },
    filterRealEstateOwnersByDepartment(realEstateOwnerSubset){      
      const departmentFilter = this.filterValues.department
      if (!departmentFilter || departmentFilter === 'Alle'){
        return realEstateOwnerSubset
      }
      const filteredRealEstateOwners = realEstateOwnerSubset.filter(function(realEstateOwner){
        if (realEstateOwner.department === '' || realEstateOwner.department === 'Alle'){
          return true
        }
        if (!realEstateOwner.department){
          return false
        }
        return realEstateOwner.department === departmentFilter
      })
      return filteredRealEstateOwners
    },
    isContextRealEstateOwner(ownerUUID){
      for (let ownerIdx = 0; ownerIdx < this.contextRealEstateOwners.length; ownerIdx++) {
        const displayOwner = this.contextRealEstateOwners[ownerIdx];
        if (displayOwner.uuid === ownerUUID){
          return true
        }
      }
      return false
    },
    getOwnerOptionLabel(ownerJSON){
      let ownerLabel = ""
      if (ownerJSON.name){
        ownerLabel += ownerJSON.name
      }
      if (ownerJSON.salutation || ownerJSON.first_name || ownerJSON.last_name){
        ownerLabel += " / " + (ownerJSON.salutation || '') + " " + (ownerJSON.first_name || '') + " " + (ownerJSON.last_name || '')
      }
      if (ownerJSON.company_name){
        ownerLabel += " / " + ownerJSON.company_name
      }
      return ownerLabel
    },
    selectRealEstateOwnerForEdit(realEstateOwnerUUID){
      this.showRealEstateOwnerEditForm(realEstateOwnerUUID)
      this.navigateToRealEstateOwner(realEstateOwnerUUID)
    },
    exitToRelatedContextOverview(){
      this.showRealEstateOwnerOverview()
      this.navigateToRelatedContextFromRealEstateOwner()
    },
    navigateToRelatedContextFromRealEstateOwner(){
      if (this.contextType === 'RealEstate'){
        const newRoute = '/real-estate/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextType === 'ServiceProvider'){
        const newRoute = '/service-provider/' + this.contextUUID
        this.$router.push(newRoute)
        return
      }
      const newRoute = '/real-estate-owner'
      this.$router.push(newRoute)
    },
    navigateToRealEstateOwner(newUUID){
      if (this.contextType === 'RealEstate'){
        const newRoute = '/real-estate/' + this.contextUUID + '/real-estate-owner/' + newUUID
        this.$router.push(newRoute)
        return
      }
      if (this.contextType === 'ServiceProvider'){
        const newRoute = '/service-provider/' + this.contextUUID + '/real-estate-owner/' + newUUID
        this.$router.push(newRoute)
        return
      }
      const newRoute = '/real-estate-owner/' + newUUID
      this.$router.push(newRoute)
    },
    async resetSearchFilterCriteria(){
      this.filterValues.department = ''
      this.searchText = ''
    },
    showRealEstateOwnerEditForm(realEstateOwnerUUID){
      if (!realEstateOwnerUUID){
        services.$log.warnWrap('showRealEstateOwnerEditForm', 'MOC0087', 'no real estate uuid provided, showing overview instead')
        this.showRealEstateOwnerOverview()
        return
      }
      this.currentRealEstateOwnerUUID = realEstateOwnerUUID
      this.currentComponent = 'RealEstateOwnerEditForm'
    },
    async showRealEstateOwnerOverview(){
      this.currentRealEstateOwnerUUID = ''
      this.currentComponent = 'RealEstateOwnerOverview'

      await this.loadRealEstateOwnerData()
    },
    realEstateOwnerNameComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.name){
        return orderFirstBeforeSecond
      }
      if (!second.name){
        return orderSecondBeforeFirst
      }
      if (first.name < second.name){
        return orderFirstBeforeSecond
      }
      if (first.name > second.name){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    RealEstateOwnerTable,
    RealEstateOwnerEditForm,
    FunnelIcon,
    UndoArrowIcon,
    StringInput,
    SelectAutocompleteInput,
    FormMessages,
    RealEstateOwnerFilters,
  }
}
</script>
