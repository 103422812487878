import { createStore } from 'vuex';
import { jwt } from '@/auth/jwt.js';

function isUserAllowedPermission(state, permissionName){
    if (!state.permissions){
        return false
    }
    if (state.permissions.length === 0){
        return false
    }
    for (let permissionIdx = 0; permissionIdx < state.permissions.length; permissionIdx++) {
        const permission = state.permissions[permissionIdx]
        if (permission == permissionName){
            return true
        }                
    }
    return false
}

export const store = createStore({
    state(){
        return{
            token: '',
            email: '',
            department: '',
            permissions: []
        }
    },
    mutations: {
        updateUser(state, { token, email }){
            state.token = token
            window.localStorage.token = token

            if (!token){
                state.email = email
                window.localStorage.email = email
                return
            }

            const tokenContents = jwt.parse(token)
            state.department = tokenContents.user_department
            state.permissions = tokenContents.user_permissions
            if (!email){                
                email = tokenContents.user_email
            }
            state.email = email
            window.localStorage.email = email
        }
    },
    getters:{
        userEmail(state){
            return state.email
        },
        userDepartment(state){
            return state.department
        },
        isUserAuthenticated(state){
            return state.email !== '' 
                && state.email !== undefined
        },
        isUserAllowedEditUsers(state){
            return isUserAllowedPermission(state, 'edit_users')
        },
        isUserAllowedEditRealEstateListings(state){
            return isUserAllowedPermission(state, 'edit_real_estate_listings')
        },
        isUserAllowedEditRealEstates(state){
            return isUserAllowedPermission(state, 'edit_real_estates')
        },
        isUserAllowedEditImmoscoutCredentials(state){
            return isUserAllowedPermission(state, 'edit_immoscout24_credentials')
        },
        isUserAllowedAdminSyncLogs(state){
            return isUserAllowedPermission(state, 'admin_sync_statuses')
        },
        isUserAllowedEditExternalIDs(state){
            return isUserAllowedPermission(state, 'edit_external_ids')
        }
    },
    actions:{        
        clearUser(context){
            context.commit({
                type: 'updateUser',
                token: '',
                email: '',
              })
        }
    }
})