<template>
<div>
    <div class="row g-1 p-1">
        <div class="col-4">
            <RadioButtonInput
                name="departmentFilter" 
                label="Abteilung"
                id="departmentFilter"
                v-model="filterFields.department.value"
                :options="filterFields.department.options"
                @select:option="toggleFilterOptionDepartment"
                centered
            />
        </div>
        <div class="col-8">
            <StringAutocompleteInput
                :name="'filter-type'"
                label="Typ"
                class="form-control" 
                v-model="filterFields.type.value"
                @valid:modelValue="filterFields.type.isValid = true"
                @invalid:modelValue="filterFields.type.isValid = false"
                @reset:modelValue="filterFields.type.isValid = null"
                :options="filterFields.type.options"
            />
        </div>
    </div>
</div>
</template>

<script>
import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField  from '@/diaspora/fields/string_field.js'

import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import StringAutocompleteInput from  '@/components/inputs/StringAutocompleteInput.vue'

export default {
    name: 'ServiceProviderFilters',
    emits: [
        'update:filterValues',
    ],
    data(){
        return {            
            filterFields: this.newFilterFields(),
        }
    },
    props:{
        filterValues:{
            Type: Object,
            mandatory: true
        },
    },
    computed:{
        departmentValue(){
            return this.filterFields.department.value
        },
        typeValue(){
            return this.filterFields.type.value
        },
    },
    watch:{
        departmentValue(){
            const newValues = {
                department: this.departmentValue,
                type: this.typeValue,
            }
            this.$emit('update:filterValues', newValues)
        },
        typeValue(){
            const newValues = {
                department: this.departmentValue,
                type: this.typeValue,
            }
            this.$emit('update:filterValues', newValues)
        },
        filterValues:{
            deep: true,
            handler(){
                this.filterFields.department.value = this.filterValues.department
                this.filterFields.type.value = this.filterValues.type
            }
        },
    },
    methods:{
        toggleFilterOptionDepartment(filterOption){
            if (this.filterFields.department.value !== filterOption.value){
                return
            }
            this.filterFields.department.value = ''
        },
        newFilterFields(){
            const filterFields = {
                department: new StringField('department', 'Abteilung', false, [
                        new FieldOption('Vermarktung', 'Vermarktung'),
                        new FieldOption('Verwaltung', 'Verwaltung'),
                    ]
                ),                
                type:       new StringField('type', 'Typ', true, [
                    new FieldOption('Makler',           'Makler'),
                    new FieldOption('Hausverwaltung',   'Hausverwaltung'),
                    new FieldOption('Architekt',        'Architekt'),
                    new FieldOption('Statiker',         'Statiker'),
                    new FieldOption('Energieberater',   'Energieberater'),
                    new FieldOption('Dachdecker',       'Dachdecker'),
                    new FieldOption('Gerüstbauer',      'Gerüstbauer'),
                    new FieldOption('Heizungsbauer',    'Heizungsbauer'),
                    new FieldOption('Trockenbauer',     'Trockenbauer'),
                    new FieldOption('Elektriker',       'Elektriker'),
                    new FieldOption('Maler',            'Maler'),
                    new FieldOption('Installateur',     'Installateur'),
                    new FieldOption('Rohrreiniger',     'Rohrreiniger'),
                    new FieldOption('Gartenbauer',      'Gartenbauer'),
                    new FieldOption('Gebäudereiniger',  'Gebäudereiniger'),
                    new FieldOption('Schornsteinfeger', 'Schornsteinfeger'),
                    new FieldOption('Hausmeister',      'Hausmeister'),
                    new FieldOption('Rechtsanwalt',     'Rechtsanwalt'),
                    new FieldOption('Notar',            'Notar'),
                    new FieldOption('Bauamt',           'Bauamt'),
                ]),
            }
            return filterFields
        }
    },
    components:{
        RadioButtonInput,
        StringAutocompleteInput,
    }
}
</script>

<style>

</style>