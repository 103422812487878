import { services } from '@digiscape/js-core'

async function createBankAccount(newBankAccountJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstateOwner'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + relatedUUID+ "/bank-account/create", newBankAccountJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'RealEstateRenter'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + relatedUUID+ "/bank-account/create", newBankAccountJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'ServiceProvider'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + relatedUUID+ "/bank-account/create", newBankAccountJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    throw new Error("Invalid related type for BankAccount creation: '" + relatedType + "'")
}

async function readAllBankAccountsByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstate'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + relatedUUID+ "/bank-account/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_bank_accounts
        return responseBody
    }
    if (relatedType === 'RealEstateOwner'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + relatedUUID+ "/bank-account/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_bank_accounts
        return responseBody
    }
    if (relatedType === 'RealEstateRenter'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + relatedUUID+ "/bank-account/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_bank_accounts
        return responseBody
    }
    if (relatedType === 'ServiceProvider'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + relatedUUID+ "/bank-account/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_bank_accounts
        return responseBody
    }
    return []
}

async function readAllBankAccountsByRelatedAccountable(accountableType, accountableUUID){
    if (accountableType === 'RealEstate'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + accountableUUID+ "/bank-account/real-estate-owner")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_bank_accounts
        return responseBody
    }
    return []
}

async function readBankAccount(bankAccountUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/bank-account/" + bankAccountUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.bank_account
    return responseBody
}

async function saveBankAccountField(bankAccountUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: bankAccountUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/bank-account/" + bankAccountUUID +"/field", reqBody)
    const responseBody = response.data.bank_account
    return responseBody
}

async function deleteBankAccount(bankAccountUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/bank-account/" + bankAccountUUID)
}

async function attachBankAccount(relatedType, relatedUUID, bankAccountUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/bank-account/" + bankAccountUUID + "/attach", requestBody)
}

async function detachBankAccount(relatedType, relatedUUID, bankAccountUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/bank-account/" + bankAccountUUID + "/detach", requestBody)
}

export {
    createBankAccount,
    readAllBankAccountsByRelated,
    readAllBankAccountsByRelatedAccountable,
    readBankAccount,
    saveBankAccountField,
    deleteBankAccount,
    attachBankAccount, 
    detachBankAccount,
}