<template>
  <fieldset
    :id="id"
    class="position-relative"    
    @focusin="isSearchInputSelected = true"
    @focusout="isSearchInputSelected = false"
  >
    <input
      type="text" 
      :name="name"
      :id="'input-'+id"
      :placeholder="placeholder"
      :class="[ this.class, 'form-control', 'tag-search' ]"
      v-model="tagValue"
      @keyup.enter="submitTag"
    >
    <div
      :id="'list-' + id"
      class="tag-options"
      @mouseover="isMouseOverOptions = true"
      @mouseleave="isMouseOverOptions = false"
      v-show="displayTagOptions.length > 0 && (isColorInputSelected || isSearchInputSelected || isMouseOverOptions)"
    >
      <div
        v-for="(tag, tagIdx) in displayTagOptions"
        :key="tagIdx"
        class="tag-option"
        type="button"
        @click="selectTagOption($event, tag)"
      >
        <input 
          type="color"
          v-model.lazy="tag.color"
          class="tag-option-color"          
          @focusin="isColorInputSelected = true"
          @focusout="isColorInputSelected = false"
          @change="updateTagColor($event, tag)"
          :list="'colors-'+tagIdx"
          :name="'colors-'+tagIdx"
        />
        <datalist :id="'colors-'+tagIdx">
          <option
            v-for="(color, colorIdx) in colorOptions"
            :key="colorIdx"
            :value="color"
          />
        </datalist>
        <span class="tag-option-name">{{ tag.name }}</span>
        <button
          class="btn-icon-warn small tag-option-delete"
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </button>
      </div>
    </div>
    <div v-show="displayTagValues">
      <span
        v-for="(tag, tagIdx) in displayTagValues"
        :key="tagIdx"
        class="tag tag-ctrl"
        :style="'background-color:' + tag.color"
      >
        <span>{{ tag.name }}</span>
        <span
          class="tag-remove"
          @click="removeTag($event, tag)"
        >
          X
        </span>
      </span>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'TagsInput',
  emits:[
    'create:modelValue',
    'update:modelValue',
    'delete:modelValue',
    'attach:modelValue',
    'detach:modelValue'
  ],
  data(){
    return {
      tagValue: '',
      isSearchInputSelected: false,
      isColorInputSelected: false,
      isMouseOverOptions: false,
      colorOptions: [
        '#e9c7cd',
        '#d8cce7',
        '#f93e4e',//'#c50f1f',
        '#ff8c00',
        '#13a10e',
        '#00b7c3'
      ]
    }
  },
  props:{
    tagOptions:{
      type: Array,
      required: true
    },
    tagValues:{
      type: Array,
      required: true
    },
    id:{
      type: String,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "Attribut hinzufügen",
    },
    class: {
      type: String
    }
  },
  computed: {
    displayTagOptions(){      
      const tagValue = this.tagValue
      let tagSubSet = this.tagOptions
      tagSubSet = this.filterTagsBySearchTerm(tagSubSet, tagValue)
      tagSubSet = this.sortTagsByName(tagSubSet)
      return tagSubSet
    },
    displayTagValues(){
      let tagSubSet = this.tagValues
      tagSubSet = this.sortTagsByName(tagSubSet)
      return tagSubSet
    }
  },
  methods:{
    removeTag(e, tag){
      e.preventDefault()

      this.$emit('detach:modelValue', tag)
    },
    submitTag(){
      if (!this.tagValue){
        return
      }
      this.$emit('create:modelValue', this.tagValue)
      this.tagValue = ''
    },
    selectTagOption(e, tag){
      if (e.target.matches('input[type=color]')){
        return
      }
      e.preventDefault()
      if (e.target.matches('button *')){        
        const isConfirmed = confirm("Sicher, dass das Objekt-Attribut '"+tag.name+"' permanent für alle Objekte gelöscht werden soll?")
        if (!isConfirmed){
          return false
        }
        this.$emit('delete:modelValue', tag)
        return
      }
      this.isSearchInputSelected = false
      this.$emit('attach:modelValue', tag)
    },
    updateTagColor(e, tag){
      e.preventDefault()

      this.$emit('update:modelValue', tag)
    },
    filterTagsBySearchTerm(tagSubSet, searchTerm){
      if(!searchTerm){
        return tagSubSet
      }
      const fuzzyPattern = '.*' + searchTerm.split('').join('.*') + '.*'
      const fuzzyRegexp = new RegExp(fuzzyPattern, 'i')
      return tagSubSet.filter(function(option){
        if (!option.name){
          return false
        }
        return fuzzyRegexp.test(option.name)
      })
    },
    sortTagsByName(tagSubSet){
      return tagSubSet.sort(function(first, second){
        if (first.name > second.name){
          return 1
        }
        if (first.name < second.name){
          return -1
        }
        return 0
      })
    }
  }
}
</script>

<style scoped>
.tag-overview{
  margin-top: 5px;
}
.tag-ctrl{
  min-width: 75px;
}
.tag-ctrl .tag-remove{
    float: right;
    cursor: pointer;
    color: var(--co-err-bg);
    padding: 0px 4px;
}
.tag-options{
  border: 1px solid #555;
  position: absolute;
  top: 2.5rem;
  z-index: 5;
  width: 100%;
  background-clip: padding-box;
  background-color: white;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.tag-option{
  padding: 1px 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: auto;
}
.tag-option:hover{
  cursor: pointer;
  color: var(--co-bg-bl-hov-inv);
  background-color: var(--co-bg-bl-hov)!important;
}
.tag-option-color{
  max-height: 1rem;
  width: 2rem;
  position: relative;
  margin: 0px 5px;
}
.tag-option-delete{
  float: right;
  margin-top: 2px;
}
</style>