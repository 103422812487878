import { v4 as uuidv4 } from "uuid";

class Message{
    constructor(type, contextCode = '', body){
        this.uuid = uuidv4()
        this.type = type
        this.contextCode = contextCode
        this.body = body
    }
}

export default Message