import { services } from '@digiscape/js-core'

import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'
import FloatField from '@/diaspora/fields/float_field.js'
import TimestampField from '@/diaspora/fields/timestamp_field.js'

class MeterReading extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            meterUUID:              new UUIDField('meterUUID', 'UUID (Zähler)'),
            readingAt:              new TimestampField('readingAt', 'Erfasst', true, new Date().toISOString()),
            readingByUserUUID:      new UUIDField('readingByUserUUID', 'Von'),
            readingValue:           new FloatField('readingValue', 'Messwert', true),
            readingUnit:            new StringField('readingUnit', 'Einheit', true, [
                new FieldOption('l',    'ltr', true,    'Wasser'),
                new FieldOption('m³',   'cum', false,   'Wasser'),

                new FieldOption('MJ',   'mjo', true,    'Wärmemenge'),
                new FieldOption('kWh',  'kwh', false,   'Wärmemenge'),

                new FieldOption('l',    'ltr', true,    'Heizung'),
                new FieldOption('kWh',  'kwh', false,   'Heizung'),

                new FieldOption('m³',   'cum', true,    'Gas'),
                new FieldOption('kWh',  'kwh', false,   'Gas'),
                
                new FieldOption('kWh',  'kwh', true,    'Strom'),

                new FieldOption('MJ',   'mjo', true,    'Fernwärme'),
                new FieldOption('kWh',  'kwh', false,   'Fernwärme'),
            ]),
            readingNote:            new StringField('readingNote', 'Notiz'),
        }
    }
    getIdentifierString(){
        const readingAtDate = services.$format.timestamp(this.fields.readingAt.value)
        if (!readingAtDate){
            return this.fields.uuid.value
        }
        return readingAtDate
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default MeterReading