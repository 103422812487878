import { services } from '@digiscape/js-core'

async function createRealEstate(newRealEstateJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstateOwner'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + relatedUUID+ "/real-estate/create", newRealEstateJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    if (relatedType === 'ServiceProvider'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + relatedUUID+ "/real-estate/create", newRealEstateJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/create", newRealEstateJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readAllRealEstates(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_real_estates
    return responseBody
}

async function readAllRealEstatesByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstateOwner'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-owner/" + relatedUUID+ "/real-estate/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estates
        return responseBody
    }
    if (relatedType === 'ServiceProvider'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + relatedUUID+ "/real-estate/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estates
        return responseBody
    }
    return []
}

async function readRealEstate(realEstateUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + realEstateUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.real_estate
    return responseBody
}

async function searchRealEstates(searchText){
    const requestBody = { "search_term": searchText }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/search", requestBody)
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.result_real_estates
    return responseBody
}

async function saveRealEstateField(realEstateUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: realEstateUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + realEstateUUID +"/field", reqBody)
    const responseBody = response.data.real_estate
    return responseBody
}

async function deleteRealEstate(realEstateUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + realEstateUUID)
}

async function detachRealEstate(relatedType, relatedUUID, realEstateUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + realEstateUUID + "/detach", requestBody)
}

export { createRealEstate, readAllRealEstates, readAllRealEstatesByRelated, readRealEstate, searchRealEstates, saveRealEstateField, deleteRealEstate, detachRealEstate }