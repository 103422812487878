<template>
    <div>
      <div
        v-show="!isLoaded"
        class="m-1"
      >
        <div class="spinner-grow spinner co-hl" role="status"></div>
        <h5 class="spinner-label spinner-label co-sec">Dienstleister werden geladen...</h5>
      </div>
      <div
        v-show="isLoaded && isSubView"
        class="row mb-1"
      >
        <h6>Filter</h6>
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="row mb-3">
              <FieldLabel
                for="filter-type"
                class="col-4 col-form-label"
                label="Typ"
              />
              <div class="col-8">
                <SelectAutocompleteInput
                  :name="'filter-type'"
                  label="Typ"
                  class="form-control" 
                  v-model="filterFields.type.value"
                  @valid:modelValue="filterFields.type.isValid = true"
                  @invalid:modelValue="filterFields.type.isValid = false"
                  @reset:modelValue="filterFields.type.isValid = null"
                  :options="filterFields.type.options"
                />
              </div>
            </div>
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-warn form-control show-xl"
              type="button"
              @click="resetFilters"
            >
              Zurücksetzen
            </button>
            <button
              class="btn btn-icon-warn form-control hide-xl"
              type="button"
              @click="resetFilters"
            >
              <UndoArrowIcon />
            </button>
          </div>
        </div>
      </div>
      <table
        id="real-estate-owners"
        class="table table-sm table-striped"
        v-show="isLoaded"
      >
        <thead
          class="thead-dark show-md"
        >
          <tr class="text-nowrap">
            <th>Bez.</th>
            <th>Typ</th>
            <th style="width:50px;">Anr.</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Unternehmen</th>
            <th>E-Mail</th>
            <th>Tel.</th>
            <th style="width:50px"></th>
            <th
              style="width:50px" 
              v-if="contextUUID"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="serviceProvider in displayServiceProviders"
            :key="serviceProvider.uuid"
          >
            <td
              label="Bezeichnung"
            >
              {{ serviceProvider.name }}
            </td>
            <td
              label="Typ"
            >
              {{ serviceProvider.type }}
            </td>
            <td
              label="Anrede"
            >
              {{ serviceProvider.salutation }}
            </td>
            <td
              label="Vorname"
            >
              {{ serviceProvider.first_name }}
            </td>
            <td
              label="Nachname"
            >
              {{ serviceProvider.last_name }}
            </td>
            <td
              label="Unternehmen"
            >
              {{ serviceProvider.company_name }}
            </td>
            <td
              label="E-Mail"
            >
              <a :href="'mailto:'+ serviceProvider.email">{{ serviceProvider.email }}</a>
            </td>
            <td
                label="Tel."
            >
                <a v-if="serviceProvider.phone_landline" :href="'tel:'+ serviceProvider.phone_landline">{{ serviceProvider.phone_landline }}</a>
                <a v-else :href="'tel:'+ serviceProvider.phone_mobile">{{ serviceProvider.phone_mobile }}</a>
            </td>
            <td>
              <div
                class="btn btn-sec hide-md"
                title="Bearbeiten"
                role="button"
                @click="triggerEditServiceProvider($event, serviceProvider)"
              >
                Bearbeiten
              </div>
              <div
                class="btn-icon show-md"
                title="Bearbeiten"
                role="button"
                @click="triggerEditServiceProvider($event, serviceProvider)"
              >
                <PencilIcon />
              </div>
            </td>
            <td
              v-if="contextUUID"
            >
              <div
                class="btn btn-warn hide-md"
                title="Entfernen"
                role="button"
                @click="triggerDetachServiceProvider($event, serviceProvider)"
              >
                Entfernen
              </div>
              <div
                class="btn-icon-warn show-md"
                title="Entfernen"
                role="button"
                @click="triggerDetachServiceProvider($event, serviceProvider)"
              >
                <XIcon />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  // import { services } from '@digiscape/js-core'
  
  import ServiceProvider from '@/diaspora/models/service-provider.js'
  
  import FieldLabel from '@/components/labels/FieldLabel.vue'
  import SelectAutocompleteInput from '@/components/inputs/SelectAutocompleteInput.vue'
  
  import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'
  
  import PencilIcon from  '@/components/icons/PencilIcon.vue'
  import XIcon from  '@/components/icons/XIcon.vue'
  
  export default {
    name: 'ServiceProviderTable',
    emits: [
      'triggered:modelEdit',
      'triggered:modelDetach'
    ],
    data(){
      return {
        filterModel: new ServiceProvider(),
      }
    },
    props:{
      contextType: {
        type: String,
      },
      contextUUID:{
        type: String,
      },
      serviceProviders:{
        type: Array,
        required: true
      },
      isLoaded:{
        type: Boolean,
        required: true
      },
      isSubView:{
        type: Boolean,
      }
    },
    computed:{
      filterFields(){
        return this.filterModel.fields
      },
      displayServiceProviders(){
        const filterType = this.filterFields.type.value
        let serviceProviders = this.serviceProviders
        if (!filterType){
          return serviceProviders
        }
        if(this.filterFields.type.value){
          serviceProviders = serviceProviders.filter(serviceProvider => serviceProvider.type === filterType)
        }
        return serviceProviders
      },
    },
    methods:{
      triggerEditServiceProvider(e, serviceProvider){
        this.$emit('triggered:modelEdit', serviceProvider.uuid)
      },
      triggerDetachServiceProvider(e, serviceProvider){
        this.$emit('triggered:modelDetach', serviceProvider.uuid)
      },
      resetFilters(){
        this.filterModel = new ServiceProvider()
      },
    },
    components:{
      PencilIcon,
      XIcon,
      FieldLabel,
      SelectAutocompleteInput,
      UndoArrowIcon,
    },
  }
  </script>
  
  <style scoped>
  @media screen and (max-width: 768px) {
    table thead {
      display: none;
    }
    table tr{
      margin: 5px 0;
    }
    table td {
      display: flex;
    }  
    table td::before {
      content: attr(label);
      font-weight: bold;
      width: 120px;
      min-width: 120px;
    }  
    table td .btn{
      width: 100%;
    }
  }
  </style>