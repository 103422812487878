<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-if="currentComponent === 'MeterOverview'"
    >
      <MeterTable 
        id="meters"
        :meters="sortedMeters"
        :isLoaded="isLoaded"
        @triggered:modelEdit="selectMeterForEdit"
        @triggered:modelCreate="createMeter"
        :contextType="contextType"
        :contextUUID="contextUUID"
      />
    </div>
    <div
      v-if="currentComponent === 'MeterEditForm'"
    >
      <MeterEditForm
        class="col-12"
        @cancelled:modelEdit="exitToOverview"
        @success:modelDelete="exitToOverview"
        :meterUUID="currentMeterUUID"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import { readAllMetersByRelated, createMeter } from '@/diaspora/models/queries/meter.js'

import MeterTable from '@/components/tables/MeterTable.vue'
import MeterEditForm from '@/components/forms/MeterEditForm.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

export default {
  name: 'MeterCollection',
  data(){
    return {      
      messageStore: new MessageStore(),
      contextMeters: [],
      isLoaded: false,
      currentComponent: 'MeterOverview',
      currentMeterUUID: '',
    }
  },
  props:{
    isSubView: {
      type: Boolean,
    },
    realEstateUnitUUID: {
      type: String,
    },
    realEstateUUID: {
      type: String,
    },
  },
  computed:{
    contextType(){
      if (this.realEstateUnitUUID){
        return 'RealEstateUnit'
      }
      if (this.realEstateUUID){
        return 'RealEstate'
      }
      return ''
    }, 
    contextUUID(){
      if (this.realEstateUnitUUID){
        return this.realEstateUnitUUID
      }
      if (this.realEstateUUID){
        return this.realEstateUUID
      }
      return ''
    }, 
    isContextGiven(){
      return this.contextType != '' && this.contextUUID != ''
    },
    sortedMeters(){
      const sorted = this.cloneArray(this.contextMeters)
      return sorted.sort(this.meterNumberComparator)
    }, 
  },
  async mounted(){
    await this.loadMeterData()
  },
  watch:{
    async contextUUID(newUUID){
      if (!newUUID){
        this.contextMeters = []
        return
      }
      
      await this.showMeterOverview()
      await this.loadMeterData()
    },
  },
  methods:{
    async loadMeterData(){
      this.isLoaded = false  
      await this.loadContextMeters()
      this.isLoaded = true
    },
    async loadContextMeters(){
      this.contextMeters = await this.readRelatedMeters()
    },
    async readRelatedMeters(){
      if (!this.isContextGiven){
        services.$log.warn("MOC0123", "read all meters cannot be executed, invalid related uuid / type, skipping")
        return
      }
      const messageTarget = `readRelatedMeters`
      this.messageStore.flushTarget(messageTarget)

      let relatedMeters = []
      try{
        relatedMeters = await readAllMetersByRelated(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0124", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedMeters
    },
    async createMeter(meterJSON, contextType, contextUUID){
      const messageTarget = `createMeter`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Erstelle einen neuen Zähler...', messageTarget)
      if (!contextType){
        contextType = this.contextType
      }
      if (!contextUUID){
        contextUUID = this.contextUUID
      }
      try{
        await createMeter(meterJSON, contextType, contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0125", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Zähler erfolgreich erstellt.", messageTarget)
      
      await this.loadMeterData()
    },
    selectMeterForEdit(meterUUID){
      this.showMeterEditForm(meterUUID)
    },
    async exitToOverview(){
      await this.showMeterOverview()
    },
    showMeterEditForm(meterUUID){
      if (!meterUUID){
        services.$log.warnWrap('showMeterEditForm', 'MOC0126', 'no real estate uuid provided, showing overview instead')
        this.showMeterOverview()
        return
      }
      this.currentMeterUUID = meterUUID
      this.currentComponent = 'MeterEditForm'
    },
    async showMeterOverview(){
      this.currentMeterUUID = ''
      this.currentComponent = 'MeterOverview'

      await this.loadMeterData()
    },
    meterNumberComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.number){
        return orderFirstBeforeSecond
      }
      if (!second.number){
        return orderSecondBeforeFirst
      }
      if (first.number < second.number){
        return orderFirstBeforeSecond
      }
      if (first.number > second.number){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    MeterTable,
    MeterEditForm,
    FormMessages,
  }
}
</script>
