<template>
  <div>
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Eigentümer werden geladen...</h5>
    </div>
    <table
      id="real-estate-owners"
      class="table table-sm table-striped"
      v-show="isLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th>Bez.</th>
          <th style="width:50px;">Anr.</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Unternehmen</th>
          <th>E-Mail</th>
          <th style="width:50px"></th>
          <th
            style="width:50px" 
            v-if="contextUUID"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="realEstateOwner in realEstateOwners"
          :key="realEstateOwner.uuid"
        >
          <td
            label="Bezeichnung"
          >
            {{ realEstateOwner.name }}
          </td>
          <td
            label="Anrede"
          >
            {{ realEstateOwner.salutation }}
          </td>
          <td
            label="Vorname"
          >
            {{ realEstateOwner.first_name }}
          </td>
          <td
            label="Nachname"
          >
            {{ realEstateOwner.last_name }}
          </td>
          <td
            label="Unternehmen"
          >
            {{ realEstateOwner.company_name }}
          </td>
          <td
            label="E-Mail"
          >
            <a :href="'mailto:'+ realEstateOwner.email">{{ realEstateOwner.email }}</a>
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditRealEstateOwner($event, realEstateOwner)"
            >
              Bearbeiten
            </div>
            <div
              class="btn-icon show-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditRealEstateOwner($event, realEstateOwner)"
            >
              <PencilIcon />
            </div>
          </td>
          <td
            v-if="contextUUID"
          >
            <div
              class="btn btn-warn hide-md"
              title="Entfernen"
              role="button"
              @click="triggerDetachRealEstateOwner($event, serviceProvider)"
            >
              Entfernen
            </div>
            <div
              class="btn-icon-warn show-md"
              title="Entfernen"
              role="button"
              @click="triggerDetachRealEstateOwner($event, realEstateOwner)"
            >
              <XIcon />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import { services } from '@digiscape/js-core'

import PencilIcon from  '@/components/icons/PencilIcon.vue'
import XIcon from  '@/components/icons/XIcon.vue'

export default {
  name: 'RealEstateOwnerTable',
  emits: [
    'triggered:modelEdit',
    'triggered:modelDetach'
  ],
  props:{
    contextUUID:{
      type: String,
    },
    contextType:{
      type: String,
    },
    realEstateOwners:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    }
  },
  methods:{
    triggerEditRealEstateOwner(e, realEstateOwner){
      this.$emit('triggered:modelEdit', realEstateOwner.uuid)
    },
    triggerDetachRealEstateOwner(e, realEstateOwner){
      this.$emit('triggered:modelDetach', realEstateOwner.uuid)
    }
  },
  components:{
    PencilIcon,
    XIcon,
  },
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>