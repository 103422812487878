import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField    from '@/diaspora/fields/uuid_field.js'
import PasswordField from '@/diaspora/fields/password_field.js'
import BoolField from '@/diaspora/fields/bool_field'

class CredentialsImmoscout extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:               new UUIDField('uuid', 'UUID'),
            consumerKey:        new StringField('consumerKey', 'Lizenz-Schlüssel', true),
            consumerSecret:     new PasswordField('consumerSecret', 'Secret-Schlüssel', true),
            authorizationUrl:   new StringField('authorizationUrl', 'Authorisierungs-URL'),
            isVerified:         new BoolField('isVerified', 'Zugang freigeschaltet'),
        }
    }
}

export default CredentialsImmoscout