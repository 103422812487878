import Field from '@/diaspora/fields/field.js'
import FieldOption  from '@/diaspora/fields/field_option.js'

function getDefaultValue(labels){
    for (const label of labels){
        if (label.default){
            return label.value
        }
    }
    return false
}

let defaultLabels = [
    new FieldOption('Nein', false, true),
    new FieldOption('Ja', true),
]

class BoolField extends Field{
    constructor(fieldName = '', displayName = '', isMandatory = false, labels = defaultLabels){
        const defaultValue = getDefaultValue(labels)
        super(fieldName, displayName, false, defaultValue, isMandatory)

        this.labels = labels
    }

}

export default BoolField