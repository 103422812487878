<template>
  <div id="real-estate-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Das Objekt wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 style="display:flex" v-if="contextType === 'RealEstateOwner'">
        <span style="width: 125px;">Eigentümer</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
      </h5>
      <h5 style="display:flex" v-if="contextType === 'ServiceProvider'">
        <span style="width: 125px;">Dienstleister</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localContextModelIdentifier }}</span>
      </h5>
      <h5 style="display:flex">
        <span style="width: 125px;">Objekt</span> - <span class="text-limit-4 fst-italic txt-sec ps-1">{{ localRealEstateIdentifier }}</span>
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteRealEstate"
          >
            Löschen
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1 center-txt">
          <span
            class="btn btn-icon form-control"
            @click="prevModelEdit"
            :class="{ 'disabled': !prevModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </span>
          <span
            class="btn btn-icon form-control mx-1"
            @click="nextModelEdit"
            :class="{ 'disabled': !nextModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-2 column-brd-right">
          <div class="row mb-3 sticky-top">  
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewUnits"
                label="Einheiten"
                :modelValue="currentSubView === 'Einheiten'"
                @click="toggleSubView($event, 'Einheiten')"
              />
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewOwners"
                label="Eigentümer"
                :modelValue="currentSubView === 'Eigentümer'"
                @click="toggleSubView($event, 'Eigentümer')"
              />
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewMeters"
                label="Zähler"
                :modelValue="currentSubView === 'Zähler'"
                @click="toggleSubView($event, 'Zähler')"
              />
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewTechnicalInstallations"
                label="Techn. Anlagen"
                :modelValue="currentSubView === 'Techn. Anlagen'"
                @click="toggleSubView($event, 'Techn. Anlagen')"
              />
            </div> 
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewServiceProviders"
                label="Dienstleister"
                :modelValue="currentSubView === 'Dienstleister'"
                @click="toggleSubView($event, 'Dienstleister')"
              />
            </div>
            <div
              class="col-sm-12 centered mb-1"
            >  
              <CheckboxButtonInput
                name="subViewBankAccounts"
                label="Konten"
                :modelValue="currentSubView === 'Konten'"
                @click="toggleSubView($event, 'Konten')"
              />
            </div>
          </div> 
        </div>
        <div 
          class="col-12 col-lg-10"
        >
          <div
            v-show="currentSubView === ''"
          >
            <RealEstateFields
              :realEstate="localRealEstate"
              @update:modelValue="updateLocalRealEstate"
              @update:fieldValue="saveRealEstateField"
              @error:modelFields="modelFieldsErrorHandler"
            />
            <div class="row mb-3 gx-1">
              <div class="col-6">
                <button
                  class="btn btn-brand form-control"
                  type="button"
                  @click="cancelEdit"
                >
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="currentSubView === 'Einheiten'"
          >
            <RealEstateUnitCollection
              :realEstateUUID="realEstateUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Eigentümer'"
          >
            <RealEstateOwnerCollection
              :realEstateUUID="realEstateUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Zähler'"
          >
            <MeterCollection
              :realEstateUUID="realEstateUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Techn. Anlagen'"
          >
            <TechnicalInstallationCollection
              :realEstateUUID="realEstateUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Dienstleister'"
          >
            <ServiceProviderCollection
              :realEstateUUID="realEstateUUID"
              :isSubView="true"
            />
          </div>
          <div
            v-if="currentSubView === 'Konten'"
          >
            <BankAccountCollection
              :realEstateUUID="realEstateUUID"
              :isSubView="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import { readRealEstateOwner } from '@/diaspora/models/queries/real-estate-owner.js'
import { readServiceProvider } from '@/diaspora/models/queries/service-provider.js'
import { readRealEstate, saveRealEstateField, deleteRealEstate } from '@/diaspora/models/queries/real-estate.js'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

import RealEstateFields from  '@/components/forms/RealEstateFields.vue'

import MeterCollection                  from  '@/components/collections/MeterCollection.vue'
import ServiceProviderCollection        from  '@/components/collections/ServiceProviderCollection.vue'
import TechnicalInstallationCollection  from  '@/components/collections/TechnicalInstallationCollection.vue'
import RealEstateUnitCollection         from  '@/components/collections/RealEstateUnitCollection.vue'
import RealEstateOwnerCollection        from  '@/components/collections/RealEstateOwnerCollection.vue'
import BankAccountCollection            from  '@/components/collections/BankAccountCollection.vue'

import RealEstate from '@/diaspora/models/real-estate.js'

import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'

export default {
  name: 'RealEstateEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
    'navigate:model',
  ],
  data(){
    return {
      currentSubView: "",
      localRealEstate: {},
      localContextModel: {},
      messageStore: new MessageStore(),
      isLoaded: false,
      shortcutHandlers: {
        "ESCAPE": this.cancelEditShortcut.bind(this),
        "CTRL + ARROWLEFT": this.prevModelEditShortcut.bind(this),
        "CTRL + ARROWRIGHT": this.nextModelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  props:{
    realEstateUUID:{
      type: String,
      required: true
    },
    prevModelUUID:{
      type: String
    },
    nextModelUUID:{
      type: String
    },
    contextType:{
      type: String
    },
    contextUUID:{
      type: String
    }
  },
  computed:{    
    localRealEstateIdentifier(){
      let identifier = this.getRealEstateAddressLine()
      if (identifier) {
        return identifier
      }
      identifier = this.localRealEstate.short_code
      if (identifier) {
        return identifier
      }
      return this.localRealEstate.uuid
    },
    localContextModelIdentifier(){
      if (!this.contextType || !this.contextUUID){
        return ""
      }
      let identifier = this.getPersonCompanyModelNameLine()
      if (identifier && this.contextType === 'ServiceProvider'){
        return identifier
      }
      if (identifier && this.contextType === 'RealEstateOwner') {
        return identifier
      }
      return this.localContextModel.uuid
    },
  },
  async mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadRealEstate.bind(this))

    await this.reloadRealEstate()
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async realEstateUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadRealEstate()
    },
  },
  methods:{
    async reloadRealEstate(){
      this.isLoaded = false
      await this.loadRealEstate(this.realEstateUUID)
      this.isLoaded = true
    },
    async loadRealEstate(realEstateUUID){
      await Promise.all([
        this.readRealEstate(realEstateUUID),
        this.loadRelatedContextModel(this.contextType, this.contextUUID)
      ])
    },
    async loadRelatedContextModel(contextType, contextUUID){
      if (!contextType || !contextUUID){
        return
      }
      if (contextType === 'RealEstateOwner'){
        await this.readRealEstateOwner(contextUUID)
        return
      }
      if (contextType === 'ServiceProvider'){
        await this.readServiceProvider(contextUUID)
        return
      }
    },
    async readRealEstate(realEstateUUID){
      const messageTarget = `readRealEstate`
      this.messageStore.flushTarget(messageTarget)

      let realEstate = {}
      try{
        realEstate = await readRealEstate(realEstateUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0066", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localRealEstate = realEstate
      return true
    },
    async readRealEstateOwner(realEstateOwnerUUID){
      const messageTarget = `readRealEstateOwner`
      this.messageStore.flushTarget(messageTarget)

      let realEstateOwner = {}
      try{
        realEstateOwner = await readRealEstateOwner(realEstateOwnerUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0160", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localContextModel = realEstateOwner
      return true
    },
    async readServiceProvider(serviceProviderUUID){
      const messageTarget = `readServiceProvider`
      this.messageStore.flushTarget(messageTarget)

      let serviceProvider = {}
      try{
        serviceProvider = await readServiceProvider(serviceProviderUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0161", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.localContextModel = serviceProvider
      return true
    },
    async deleteRealEstate(e){
      if (e){
        e.preventDefault()
      }

      const messageTarget = `deleteRealEstate.${this.localRealEstate.uuid}`
      this.messageStore.flushTarget(messageTarget)

      if (!this.localRealEstate.uuid){
        this.messageStore.error("MOC0060", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0060", "delete real estate cannot be executed, invalid uuid")
        return
      }
      const identifier = this.localRealEstateIdentifier
      const isConfirmed = confirm("Sicher, dass das Objekt '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }
      this.messageStore.pending('', "Das Objekt '"+ identifier +"' wird gelöscht...", messageTarget)

      try{
        await deleteRealEstate(this.localRealEstate.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0061", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Objekt '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },
    async saveRealEstateField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (!this.localRealEstate.uuid){
        this.messageStore.error("MOC0063", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0063", "delete real estate cannot be executed, invalid uuid")
        return
      }
      this.messageStore.pending('', `Objekt-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      let realEstate = {}
      try{
        realEstate = await saveRealEstateField(this.localRealEstate.uuid, fieldName, oldValue, newValue)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0062", requestErr)
        this.messageStore.error(parsedError.code, `Objekt-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Objekt-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const saved = new RealEstate()
      saved.unmarshalJSON(realEstate)
      const savedValue = saved.fields[fieldName].value

      if (savedValue != newValue){
        this.localRealEstate[jsonFieldName] = savedValue
      }
    },
    async toggleSubView(e, selectedSubView){
      if (e){
        e.preventDefault()
      }
      if (this.currentSubView == selectedSubView){
        this.currentSubView = ''
        return
      }
      this.currentSubView = selectedSubView
    },
    getRealEstateAddressLine(){
      let addrPieces = []
      if (this.localRealEstate.street_name){
        addrPieces.push(this.localRealEstate.street_name)
      }
      if (this.localRealEstate.street_number){
        addrPieces.push(this.localRealEstate.street_number)
      }
      if (this.localRealEstate.zip_code){
        addrPieces.push(this.localRealEstate.zip_code)
      }
      if (this.localRealEstate.city){
        addrPieces.push(this.localRealEstate.city)
      }
      return addrPieces.join(" ")
    },
    getPersonCompanyModelNameLine(){
      let namePieces = []
      if (this.localContextModel.name){
        namePieces.push(this.localContextModel.name)
        namePieces.push(" / ")
      }
      if (this.localContextModel.salutation){
        namePieces.push(this.localContextModel.salutation)
      }
      if (this.localContextModel.first_name){
        namePieces.push(this.localContextModel.first_name)
      }
      if (this.localContextModel.last_name){
        namePieces.push(this.localContextModel.last_name)
      }
      if (namePieces.length > 2 && this.localContextModel.company_name){
        namePieces.push(" / ")
      }
      if (this.localContextModel.company_name){
        namePieces.push(this.localContextModel.company_name)
      }

      return namePieces.join(" ")
    },
    updateLocalRealEstate(realEstate){
      if (JSON.stringify(this.localRealEstate) === JSON.stringify(realEstate)){
        return
      }
      this.localRealEstate = realEstate
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }      
      this.$emit('cancelled:modelEdit')
    },
    prevModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.prevModelEdit()
    },
    prevModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.prevModelUUID){
        return
      }      
      this.$emit('navigate:model', this.prevModelUUID)
    },
    nextModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      this.nextModelEdit()
    },
    nextModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.nextModelUUID){
        return
      }      
      this.$emit('navigate:model', this.nextModelUUID)
    }, 
  },
  components:{
    RealEstateFields,
    ServiceProviderCollection,
    TechnicalInstallationCollection,
    MeterCollection,
    RealEstateUnitCollection,
    RealEstateOwnerCollection,
    BankAccountCollection,
    CheckboxButtonInput,
    FormMessages,
  }
}
</script>