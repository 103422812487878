<template>
  <div id="real-estate-edit">
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner-lg co-hl" role="status"></div>
      <h4 class="spinner-label spinner-label-lg co-sec">Die Anzeige wird geladen...</h4>
    </div>
    <div
      v-show="isLoaded"
    >
      <h5 style="display:flex">
        <button
          ref="toggleDisplayModeButton"
          class="btn small btn-icon-sec" 
          style="margin: 2px; opacity:1;" 
          role="button"
          tabindex="-1"
          @click="toggleDisplayMode"
        >
          <ListColumnsIcon v-if="isDisplayModeGrid" />
          <GridIcon v-if="isDisplayModeList" />
        </button>
        Objektanzeige - <span class="text-limit-3 fst-italic txt-sec">{{ remoteRealEstateListing.zip_code }} {{ remoteRealEstateListing.city }}, <span class="text-limit-2">{{ remoteRealEstateListing.street_name }}</span> {{ remoteRealEstateListing.street_number }}</span>
        <div
          class="sync-status-bar"
          v-show="remoteRealEstateListing.publish_status === 'Active'"
        >
          <SyncStatusIndicator 
            label="IS24"
            :isActive="remoteRealEstateListing.channel_immoscout24"
            :status="channelImmoscout24SyncStatus"
            :exposeURL="channelImmoscout24ExposeURL"
          />
          <SyncStatusIndicator 
            label="B"
            :isActive="remoteRealEstateListing.channel_bookoo"
            :status="channelBookooSyncStatus"
            :exposeURL="channelBookooExposeURL"
          />
        </div>    
      </h5>
      <div class="row mb-3 gx-1 gy-2">
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-sec form-control"
            type="button"
            @click="cancelEdit"
          >
            Zurück
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1">
          <button
            class="btn btn-warn form-control"
            type="button"
            @click="deleteRealEstateListing"
          >
            Löschen
          </button>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 col-xl-1 center-txt">
          <span
            class="btn btn-icon form-control"
            @click="prevModelEdit"
            :class="{ 'disabled': !prevModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </span>
          <span
            class="btn btn-icon form-control mx-1"
            @click="nextModelEdit"
            :class="{ 'disabled': !nextModelUUID }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>
        <div class="col-6 col-sm-10 col-xl-6">
          <RadioButtonInput
              name="documentTypeFilter" 
              label="Dokument-Auswahl"
              id="documentTypeFilter"
              v-model="currentSubView"
              :options="documentSubViewOptions"
              @select:option="toggleSubView"
              inline
          />
        </div>
      </div>
      <form
        :class="{'mode-list': isDisplayModeList, 'mode-grid': isDisplayModeGrid }"
      >
          <div
            v-show="currentSubView === '' && isDisplayModeList"
            class="row"
          >
            <div 
              class="col-12 col-lg-2 order-1"
            >         
              <div class="row mb-3 sticky-top">    
                  <div
                    class="col-sm-12 centered mb-1 channels-container"              
                  >  
                    <CheckboxButtonInput
                      id="list-channelImmoscout24-checkbox"
                      name="channelImmoscout24"
                      label="Immoscout24"
                      class="m-1"
                      v-model="channelImmoscout24"
                      buttonStyle="suc"
                      :confirmMessage="confirmMessageImmoscout24"
                    />      
                    <CheckboxButtonInput
                      id="list-channelBookoo-checkbox"
                      name="channelBookoo"
                      label="Bookoo"
                      class="m-1"
                      v-model="channelBookoo"
                      buttonStyle="suc"
                      @aborted:modelValue="focusBookooTitleField"
                      :abortMessage="abortMessageBookoo"
                      :confirmMessage="confirmMessageBookoo"
                    />
                  </div>         
                  <div
                    class="col-sm-12 publish-status-container"
                    v-show="channelBookoo || channelImmoscout24 || (syncChannelOptions && syncChannelOptions.length > 0)"
                  >
                      <RadioButtonInput
                          name="list-publishStatus" 
                          label="Veröffentlichungsstatus"
                          id="list-publishStatus"
                          v-model="publishStatus"
                          :options="publishStatusOptions"
                          :abortMessage="abortMessagePublishStatus"
                          :confirmMessage="confirmMessagePublishStatus"
                          buttonClass="m-1"
                          centered
                      />
                  </div>
                  <div
                    class="col-sm-12 centered mb-1 sync-container"    
                  >          
                    <div class="m-1 d-inline-block">
                      <button
                        class="form-control btn btn-sec-outline form-control"
                        type="button"
                        @click="createSuccessorRealEstateListing"
                      >
                        Nachfolge
                      </button>
                    </div>    
                    <SelectButtonInput
                        v-if="syncChannelOptions && syncChannelOptions.length > 0"
                        name="syncListing" 
                        label="Synchr."
                        class="m-1 d-inline-block"
                        buttonClass="form-control btn sec-outline"
                        :options="syncChannelOptions"
                        @selected:option="triggerSyncForChannel"
                        confirmMessage="Sicher, dass das Objekt jetzt für den Kanal '{{ option }}' synchronisiert werden soll?"
                    />
                  </div>
              </div>
            </div>
            <div 
              class="col-12 order-3 col-lg-6 order-lg-2 column-brd"
            >
              <RealEstateListingFields
                id="list"
                :realEstate="localRealEstateListing"
                :showBookooDescription="true"
                @update:modelValue="updateLocalRealEstateListing"
                @update:fieldValue="saveRealEstateListingFieldList"
                @valid:modelFields="areAllLocalFieldsValid = true"
                @invalid:modelFields="areAllLocalFieldsValid = false"
                @error:modelFields="modelFieldsErrorHandler"
                displayMode="List"
              />
              <div class="row mb-3 gx-1">
                <div class="col-6">
                  <button
                    class="btn btn-brand form-control"
                    type="button"
                    @click="cancelEdit"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
            <div 
              class="col-12 order-2 col-lg-4 order-lg-3 position-relative"
            >
              <div class="row mb-3 sticky-top fields-sticky">
                <label for="type" class="col-sm-4 col-form-label">Attribute</label>
                <div class="col-sm-8">
                    <TagsInput 
                        id="list-tags"
                        :tagOptions="localTagOptions"
                        :tagValues="localRealEstateListingTags"
                        @create:modelValue="createTag"
                        @update:modelValue="updateTag"
                        @delete:modelValue="deleteTag"
                        @attach:modelValue="attachRealEstateListingTag"
                        @detach:modelValue="detachRealEstateListingTag"
                    />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="currentSubView === '' && isDisplayModeGrid"
            class="row"
          >
            <div 
              class="col-12 col-lg-2 order-1"
            >         
              <div class="row mb-3 sticky-top">    
                  <div
                    class="col-sm-12 centered mb-1 channels-container"              
                  >  
                    <CheckboxButtonInput
                      id="grid-channelImmoscout24-checkbox"
                      name="channelImmoscout24"
                      label="Immoscout24"
                      class="m-1 d-inline-block"
                      v-model="channelImmoscout24"
                      buttonStyle="suc"
                      :confirmMessage="confirmMessageImmoscout24"
                    />      
                    <CheckboxButtonInput
                      id="grid-channelBookoo-checkbox"
                      name="channelBookoo"
                      label="Bookoo"
                      class="m-1 d-inline-block"
                      v-model="channelBookoo"
                      buttonStyle="suc"
                      @aborted:modelValue="focusBookooTitleField"
                      :abortMessage="abortMessageBookoo"
                      :confirmMessage="confirmMessageBookoo"
                    />
                  </div>         
                  <div
                    class="col-sm-12 publish-status-container"
                    v-show="channelBookoo || channelImmoscout24 || (syncChannelOptions && syncChannelOptions.length > 0)"
                  >
                      <RadioButtonInput
                          name="grid-publishStatus" 
                          label="Veröffentlichungsstatus"
                          id="grid-publishStatus"
                          v-model="publishStatus"
                          :options="publishStatusOptions"
                          :abortMessage="abortMessagePublishStatus"
                          :confirmMessage="confirmMessagePublishStatus"
                          buttonClass="m-1"
                          centered
                      />
                  </div>               
                  <div
                    class="col-sm-12 centered mb-1 sync-container"
                    v-show="syncChannelOptions && syncChannelOptions.length > 0"         
                  >              
                    <SelectButtonInput
                        name="syncListing" 
                        label="Synchr."
                        class="m-1 d-inline-block"
                        buttonClass="form-control btn sec-outline"
                        :options="syncChannelOptions"
                        @selected:option="triggerSyncForChannel"
                        confirmMessage="Sicher, dass das Objekt jetzt für den Kanal '{{ option }}' synchronisiert werden soll?"
                    />
                  </div>
              </div>
            </div>
            <div 
              class="col-12 order-3 col-lg-10 order-lg-2"
            >
              <RealEstateListingFields
                id="grid"
                :realEstate="localRealEstateListing"
                :showBookooDescription="true"
                @update:modelValue="updateLocalRealEstateListing"
                @update:fieldValue="saveRealEstateListingFieldGrid"
                @valid:modelFields="areAllLocalFieldsValid = true"
                @invalid:modelFields="areAllLocalFieldsValid = false"
                @error:modelFields="modelFieldsErrorHandler"
                displayMode="Grid"
              />
              <div class="row mb-3 gx-1">
                <div class="col-6">
                  <button
                    class="btn btn-brand form-control"
                    type="button"
                    @click="cancelEdit"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
          </div>   
      </form>
      <DocumentGrid
        v-if="currentSubView === 'Bilder'"
        :realEstateListingUUID="realEstateListingUUID"
        :isLoaded="isDocumentTypeLoaded['image']"
        :documents="localRealEstateListingImages"
        :documentChannelStatistics="localRealEstateListingDocumentChannelStatistics"
        :coverImageUUID="coverImageUUID"
        @success:modelEdit="updateLocalDocument"
        @success:modelDelete="deleteLocalDocument"
        @update:documentFiles="createRealEstateListingDocuments"
        @triggered:modelUpdate="saveDocument"
        @triggered:modelDeletion="deleteDocument"
        @triggered:updateCoverImage="updateRealEstateListingCoverImageDocument"
        @triggered:sortByPrefix="sortRealEstateListingDocumentsByPrefix"
        @triggered:reloadDocuments="reloadAllRealEstateListingDocuments" 
        @triggered:modelSelected="isEditingDocument = true"
        @triggered:modelUnselected="isEditingDocument = false" 
        @pending:message="setDocumentPendingMessage"    
        @success:message="setDocumentSuccessMessage"
        @error:message="setDocumentErrorMessage"
        type="image"
        label="Bilder hinzufügen"
        hint="Formate: png, jpg, jpeg, gif, bmp (Max. 50 MB)"
      />
      <DocumentGrid
        v-if="currentSubView === 'Grundrisse'"
        :realEstateListingUUID="realEstateListingUUID"
        :isLoaded="isDocumentTypeLoaded['floorPlan']"
        :documents="localRealEstateListingFloorPlans"
        :documentChannelStatistics="localRealEstateListingDocumentChannelStatistics"
        @success:modelEdit="updateLocalDocument"
        @success:modelDelete="deleteLocalDocument"
        @update:documentFiles="createRealEstateListingDocuments"
        @triggered:modelUpdate="saveDocument"
        @triggered:modelDeletion="deleteDocument"
        @triggered:sortByPrefix="sortRealEstateListingDocumentsByPrefix"
        @triggered:reloadDocuments="reloadAllRealEstateListingDocuments"
        @triggered:modelSelected="isEditingDocument = true"
        @triggered:modelUnselected="isEditingDocument = false"
        @pending:message="setDocumentPendingMessage"    
        @success:message="setDocumentSuccessMessage"
        @error:message="setDocumentErrorMessage"
        type="floorPlan"
        label="Grundrisse hinzufügen"
        hint="Formate: pdf, png, jpg, jpeg, gif, bmp (Max. 50 MB)"
      />
      <DocumentGrid
        v-if="currentSubView === 'Dokumente'"
        :realEstateListingUUID="realEstateListingUUID"
        :isLoaded="isDocumentTypeLoaded['document']"
        :documents="localRealEstateListingDocuments"
        :documentChannelStatistics="localRealEstateListingDocumentChannelStatistics"
        @success:modelEdit="updateLocalDocument"
        @success:modelDelete="deleteLocalDocument"
        @update:documentFiles="createRealEstateListingDocuments"
        @triggered:modelUpdate="saveDocument"
        @triggered:modelDeletion="deleteDocument"
        @triggered:sortByPrefix="sortRealEstateListingDocumentsByPrefix"
        @triggered:reloadDocuments="reloadAllRealEstateListingDocuments"
        @triggered:modelSelected="isEditingDocument = true"
        @triggered:modelUnselected="isEditingDocument = false"
        @pending:message="setDocumentPendingMessage"    
        @success:message="setDocumentSuccessMessage"
        @error:message="setDocumentErrorMessage"
        type="document"
        label="Dokumente hinzufügen"
        hint="Formate: pdf (Max. 50 MB)"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import IdleManager from '@/events/idle-manager.js'
import ShortcutManager from '@/events/shortcut-manager.js'

import DocumentGrid from  '@/components/grids/DocumentGrid.vue'
import RealEstateListingFields from  '@/components/forms/RealEstateListingFields.vue'

import FieldOption  from '@/diaspora/fields/field_option.js'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'
import SelectButtonInput from  '@/components/inputs/SelectButtonInput.vue'
import TagsInput from  '@/components/inputs/TagsInput.vue'

import RealEstateListing from '@/diaspora/models/real-estate-listing.js'
import RealEstateListingTag from '@/diaspora/models/real-estate-listing-tag.js'
import SyncSingleRealEstateListing from '@/diaspora/messages/sync-single-real-estate-listing.js'
import Tag from '@/diaspora/models/tag.js'

import SyncStatusIndicator from  '@/components/indicators/SyncStatusIndicator.vue'

import ListColumnsIcon from  '@/components/icons/ListColumnsIcon.vue'
import GridIcon from  '@/components/icons/GridIcon.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'


export default {
  name: 'RealEstateListingEditForm',
  emits: [
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
    'navigate:model',
  ],
  data(){
    return {
      displayMode: 'List',
      publishStatus: 'Draft',
      channelImmoscout24: false,
      channelBookoo: false,
      isOverwritingChannelImmoscout24: false,
      isOverwritingChannelBookoo: false,
      isOverwritingPublishStatus: false,
      isEditingDocument: false,
      areAllLocalFieldsValid: false,
      areAllRemoteFieldsValid: false,
      isDocumentTypeLoaded: {
        'image': false,
        'document': false,
        'floorPlan': false,
      },
      remoteRealEstateListing: {},
      localRealEstateListing: {},
      localRealEstateListingDocuments: [],
      localRealEstateListingImages: [],
      localRealEstateListingFloorPlans: [],
      localRealEstateListingDocumentChannelStatistics: [],
      localRealEstateListingTags: [],
      localTagOptions: [],
      messageStore: new MessageStore(),
      isLoaded: false,
      currentSubView: '',
      channelImmoscout24SyncStatus: '',
      channelBookooSyncStatus: '',      
      shortcutHandlers: {
        "CTRL + I": this.toggleChannelImmoscout24.bind(this),
        "CTRL + B": this.toggleChannelBookoo.bind(this),
        "ESCAPE": this.cancelEditShortcut.bind(this),
        "CTRL + ARROWLEFT": this.prevModelEditShortcut.bind(this),
        "CTRL + ARROWRIGHT": this.nextModelEditShortcut.bind(this),
      },
      idleManager: new IdleManager(),
      shortcutManager: new ShortcutManager(),
    }
  },
  computed:{
    isDisplayModeList(){
      return this.displayMode === 'List'
    },    
    isDisplayModeGrid(){
      return this.displayMode === 'Grid'
    },
    coverImageUUID(){
      if (!this.remoteRealEstateListing.cover_image_uuid){
        return ''
      }
      return this.remoteRealEstateListing.cover_image_uuid
    },
    realEstateIdentifier(){
      let identifier = this.getRealEstateListingAddressLine()
      if (identifier) {
        return identifier
      }
      identifier = this.remoteRealEstateListing.title
      if (identifier) {
        return identifier
      }
      return this.remoteRealEstateListing.uuid
    },
    confirmMessageImmoscout24(){
      const identifier = this.realEstateIdentifier
      let confirmMessage = "Sicher, dass das Objekt '"+ identifier +"' mit Immoscout24 synchronisiert werden soll?"
      if (this.channelImmoscout24){
        confirmMessage = "Sicher, dass das Objekt '"+ identifier +"' nicht mehr mit Immoscout24 synchronisiert werden soll?"
      }
      return confirmMessage
    },
    abortMessageBookoo() {
      if (!this.isLoaded){
        return
      }
      if (!this.channelBookoo && !this.remoteRealEstateListing.bookoo_title){
        return 'Erfasse zuerst einen Bookoo-Titel, um den Synchronisations-Kanal "Bookoo" für das Objekt zu aktivieren.'
      }
      return ''
    },
    confirmMessageBookoo(){
      const identifier = this.realEstateIdentifier
      let confirmMessage = "Sicher, dass das Objekt '"+ identifier +"' mit Bookoo synchronisiert werden soll?"
      if (this.channelBookoo){
        confirmMessage = "Sicher, dass das Objekt '"+ identifier +"' nicht mehr mit Bookoo synchronisiert werden soll?"
      }
      return confirmMessage
    },
    abortMessagePublishStatus(){
      if (!this.areAllRemoteFieldsValid && this.localRealEstateListing.publish_status === 'Draft'){
        return "Der Veröffentlichungsstatus kann nicht geändert werden, bis alle rot markierten Pflichtfelder mit gültigen Werten befüllt sind."
      }
      return ""
    },
    confirmMessagePublishStatus(){
      const identifier = this.realEstateIdentifier
      return "Sicher, dass der Veröffentlichungsstatus für das Objekt '"+identifier+"' geändert werden soll?"
    },
    channelImmoscout24ExposeURL(){
      if (!this.remoteRealEstateListing.immoscout24_id || !this.remoteRealEstateListing.channel_immoscout24){
        return ''
      }
      if (this.remoteRealEstateListing.publish_status != 'Active'){
        return process.env.VUE_APP_IMMOSCOUT24_BASE_URL + '/scoutmanager/exposemanager/' + this.remoteRealEstateListing.immoscout24_id
      }
      return process.env.VUE_APP_IMMOSCOUT24_BASE_URL + '/expose/' + this.remoteRealEstateListing.immoscout24_id
    },
    channelBookooExposeURL(){
      if (!this.remoteRealEstateListing.bookoo_id || !this.remoteRealEstateListing.bookoo_id || this.remoteRealEstateListing.publish_status !== 'Active'){
        return ''
      }
      return 'https://ramstein.bookoo.com/i/' + this.remoteRealEstateListing.bookoo_id
    },
    syncChannelOptions(){
      let channelOptions = []
      if (this.isSyncPossibleImmoscout24()){
        channelOptions.push(new FieldOption('Immoscout24', 'Immoscout24'))
      }
      if (this.isSyncPossibleBookoo()){
        channelOptions.push(new FieldOption('Bookoo', 'Bookoo'))
      }
      if (channelOptions.length > 1){
        channelOptions.push(new FieldOption('Alle', 'All'))
      }
      return channelOptions
    },
    documentSubViewOptions() {
      return [
        new FieldOption('Bilder',     'Bilder'),
        new FieldOption('Dokumente',  'Dokumente'),
        new FieldOption('Grundrisse', 'Grundrisse'),
      ]
    },
    publishStatusOptions() {
      return [
        new FieldOption('Entwurf',      'Draft', true),
        new FieldOption('Veröffentl.',  'Active'),
        new FieldOption('Archiviert',   'Archived'),
      ]
    },
  },
  props:{
    realEstateListingUUID:{
      type: String,
      required: true
    },
    prevModelUUID:{
      type: String
    },
    nextModelUUID:{
      type: String
    }
  },
  async created(){    
      await this.reloadRealEstateListing()
  },
  mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()

    this.idleManager = new IdleManager(5 * 60 * 1000, this.reloadRealEstateListing.bind(this))
    if (this.isTouchDevice()){
      this.displayMode = 'Grid';
    }
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async realEstateListingUUID() {
      if (!this.isLoaded){
        return
      }      
      await this.reloadRealEstateListing()
    },
    async publishStatus(newStatus, oldStatus){
      if (!this.isLoaded){
        return
      }
      if (this.isOverwritingPublishStatus){
        this.isOverwritingPublishStatus = false
        this.localRealEstateListing.publish_status = newStatus
        this.remoteRealEstateListing.publish_status = newStatus
        return
      }
      await this.saveRealEstateListingField("publishStatus", "Veröffentlichungsstatus", oldStatus, newStatus)
    },
    async channelImmoscout24(newValue, oldValue){
      if (!this.isLoaded){
        return
      }
      await this.saveRealEstateListingField("channelImmoscout24", "Kanal (Immoscout24)", oldValue, newValue)  
    },
    async channelBookoo(newValue, oldValue){
      if (!this.isLoaded){
        return
      }
      if (this.isOverwritingChannelBookoo){
        this.isOverwritingChannelBookoo = false
        return
      }
      await this.saveRealEstateListingField("channelBookoo", "Kanal (Bookoo)", oldValue, newValue)  
    }
  },
  methods:{
    async reloadRealEstateListing(){
      this.isLoaded = false
      this.resetAllRealEstateListingDocuments()
      await this.loadRealEstateListing(this.realEstateListingUUID)
      this.isLoaded = true
    },
    async loadRealEstateListing(realEstateListingUUID){
      await Promise.all([
        this.readRealEstateListingObject(realEstateListingUUID),
        this.readAllRealEstateListingSyncStatuses(realEstateListingUUID),
        this.loadTags(realEstateListingUUID),
      ])     
      if (this.currentSubView != ''){
        await this.loadRealEstateListingDocumentsForSubView(this.currentSubView)
      }
    },
    focusBookooTitleField(){
      if (this.isDisplayModeGrid){        
        this.focusBookooTitleFieldForModeGrid()
        return;
      }
      this.focusBookooTitleFieldForModeList()
    },
    async focusBookooTitleFieldForModeGrid(){
      const bookooGridElem = document.getElementById("field-group-channels")
      if (bookooGridElem){
        bookooGridElem.click()
        await this.$nextTick()
      }
      const bookooSectionElem = document.getElementById("grid-fields-bookoo")
      if (bookooSectionElem){
        this.showSection("grid-fields-bookoo")
        bookooSectionElem.scrollIntoView()
      }
      const titleInputElem = document.getElementById("grid-bookooTitle-input")
      if (titleInputElem){
        titleInputElem.focus()
      }
    },
    focusBookooTitleFieldForModeList(){
      const bookooSectionElem = document.getElementById("list-fields-bookoo")
      if (bookooSectionElem){
        this.showSection("list-fields-bookoo")
        bookooSectionElem.scrollIntoView()
      }
      const titleInputElem = document.getElementById("list-bookooTitle-input")
      if (titleInputElem){
        titleInputElem.focus()
      }
    },
    showSection(sectionKey){
      const sectionControlElem = document.getElementById(sectionKey + "-control")
      sectionControlElem.classList.remove("collapsed")

      const sectionBodyElem = document.getElementById(sectionKey + "-body")
      sectionBodyElem.classList.add("show")
    },
    toggleChannelBookoo(){
      if (this.currentSubView != '' && this.isEditingDocument){
        return
      }
      if (this.abortMessageBookoo){
        alert(this.abortMessageBookoo)
        this.focusBookooTitleField()
        return
      }
      const isConfirmed = confirm(this.confirmMessageBookoo)
      if (isConfirmed){
        this.channelBookoo = !this.channelBookoo
      }
    },
    toggleChannelImmoscout24(){
      if (this.currentSubView != '' && this.isEditingDocument){
        return
      }
      const isConfirmed = confirm(this.confirmMessageImmoscout24)
      if (isConfirmed){
        this.channelImmoscout24 = !this.channelImmoscout24
      }
    },
    async loadTags(realEstateListingUUID){      
      await this.readAllTagOptions()
      await this.readAllRealEstateListingTags(realEstateListingUUID)
    },
    async reloadAllRealEstateListingDocuments(){      
      await this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
    },
    toggleDisplayMode(){
      this.$refs.toggleDisplayModeButton.blur()

      if (this.displayMode == 'List'){
        this.displayMode = 'Grid'
        return
      }
      this.displayMode = 'List'
    },
    async toggleSubView(selectedSubView){
      if (this.currentSubView == selectedSubView.value){
        this.currentSubView = ''
        return
      }
      await this.loadRealEstateListingDocumentsForSubView(selectedSubView.value)
    },
    async uploadDocumentFileBatches(documentType, fileList, batchSize = 3){
      const fileUploadEndpointURL = this.getFileUploadEndpointURLByDocumentType(documentType)

      let formData = new FormData()
      let allErrors = []
      let allQueries = []
      for (let fileIdx = 0; fileIdx < fileList.length; fileIdx++) {
        const file = fileList[fileIdx]
        formData.append("files", file)         
        if (fileIdx % batchSize === 0 || fileIdx === fileList.length-1){          
          try{
            allQueries.push(this.uploadAndRefreshDocumentFormData(fileUploadEndpointURL, formData, this.realEstateListingUUID))
          } catch(requestErr) {
            allErrors.push(requestErr)
          }
          formData = new FormData()
        }
      }

      const formKeys = formData.keys()
      if (formKeys && formKeys.length > 0) {      
        try{
          allQueries.push(this.uploadAndRefreshDocumentFormData(fileUploadEndpointURL, formData, this.realEstateListingUUID))
        } catch(requestErr) {
          allErrors.push(requestErr)
        }
      }
      
      const allResults = await Promise.allSettled(allQueries)
      for (let resultIdx = 0; resultIdx < allResults.length; resultIdx++) {
        const queryResult = allResults[resultIdx]
        if (queryResult.status !== "rejected"){
          continue
        }
        const queryErrBody = (((queryResult || {}).reason || {}).response || {}).data
        if (!queryErrBody){
          continue
        }
        const queryErr = services.$err.new(queryErrBody.code, queryErrBody.message)
        allErrors.push(queryErr)        
      }
      return allErrors
    },
    async uploadAndRefreshDocumentFormData(uploadURL, formData, realEstateListingUUID){
      await services.$http.post(process.env.VUE_APP_CLIENT_NAME, uploadURL, formData)
      await this.readAllRealEstateListingDocuments(realEstateListingUUID)
    },
    async createRealEstateListingDocuments(documentType, fileList){
      const messageTarget = `createRealEstateListingDocuments.${documentType}`
      this.messageStore.flushTarget(messageTarget)
      
      this.messageStore.pending('', 'Der Upload wird bearbeitet. Es könnte noch einen Moment dauern... (Browser-Tab nicht schließen)', messageTarget)
      const uploadErrors = await this.uploadDocumentFileBatches(documentType, fileList)
      if (uploadErrors.length > 0){
        let firstErrCode = ''
        let allErrMessages = []
        for (let errIdx = 0; errIdx < uploadErrors.length; errIdx++) {
          const uploadError = uploadErrors[errIdx]
          if (!firstErrCode && uploadError.code){
            firstErrCode = uploadError.code
          }
          allErrMessages.push(uploadError.message)
        }
        this.messageStore.error(firstErrCode, allErrMessages.join(' '), messageTarget)
        await this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
        return
      }
      let label = 'Dokumente'
      if (documentType === 'image'){
        label = 'Bilder'
      }
      if (documentType === 'floorPlan'){
        label = 'Grundrisse'
      }
      this.messageStore.success('', label + " erfolgreich hochgeladen", messageTarget)
      await this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
    },
    async readRealEstateListingObject(realEstateListingUUID){
      const messageTarget = `readRealEstateListingObject`
      this.messageStore.flushTarget(messageTarget)

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0021", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      await this.loadRealEstateListingData(response.data.real_estate_listing)
      return true
    },
    async updateRealEstateListingCoverImageDocument(coverImageUUID){
      const messageTarget = `updateRealEstateListingCoverImageDocument`
      this.messageStore.flushTarget(messageTarget)

      try{
        await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + this.remoteRealEstateListing.uuid + "/images/cover/"+coverImageUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0009", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Titelbild erfolgreich geändert", messageTarget)
      this.remoteRealEstateListing.cover_image_uuid = coverImageUUID
      this.localRealEstateListing.cover_image_uuid = coverImageUUID

      this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
    },
    async sortRealEstateListingDocumentsByPrefix(documentType){
      const messageTarget = `sortRealEstateListingDocumentsByPrefix`
      this.messageStore.flushTarget(messageTarget)

      const sortByPrefixEndpointURL = this.getSortByPrefixEndpointURLByDocumentType(documentType)
      this.messageStore.pending('', 'Elemente werden sortiert...', messageTarget)
      this.isDocumentTypeLoaded['image'] = false
      try{
        await services.$http.get(process.env.VUE_APP_CLIENT_NAME, sortByPrefixEndpointURL)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0176", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Elemente erfolgreich sortiert", messageTarget)

      this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
    },
    readAllRealEstateListingDocuments(realEstateListingUUID){      
      this.readRealEstateListingImages(realEstateListingUUID)
      this.readRealEstateListingFloorPlans(realEstateListingUUID)
      this.readRealEstateListingDocuments(realEstateListingUUID)
    },
    async loadRealEstateListingDocumentsForSubView(subViewValue){      
      await this.readAllDocumentChannelStatistics()

      if (subViewValue == 'Bilder'){
        this.localRealEstateListingImages = []
        await this.readRealEstateListingImages(this.realEstateListingUUID)
        return
      }
      if (subViewValue == 'Grundrisse'){
        this.localRealEstateListingFloorPlans = []
        await this.readRealEstateListingFloorPlans(this.realEstateListingUUID)
        return
      }
      if (subViewValue == 'Dokumente'){
        this.localRealEstateListingDocuments = []
        await this.readRealEstateListingDocuments(this.realEstateListingUUID)
        return
      }
    },
    resetAllRealEstateListingDocuments(){
        this.localRealEstateListingImages = []
        this.localRealEstateListingFloorPlans = []
        this.localRealEstateListingDocuments = []
    },
    async readAllTagOptions(){
      const messageTarget = `readAllTagOptions`
      this.messageStore.flushTarget(messageTarget)

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/tag/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0022", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.localTagOptions = []
        return
      }
      this.localTagOptions = response.data.all_tags
      return true
    },    
    async readAllRealEstateListingTags(realEstateListingUUID){
      const messageTarget = `readAllRealEstateListingTags`
      this.messageStore.flushTarget(messageTarget)

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID + "/tag/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0022", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.localRealEstateListingTags = []
        return
      }
      this.localRealEstateListingTags = response.data.all_real_estate_listing_tags
      return true
    },
    async readAllRealEstateListingSyncStatuses(realEstateListingUUID){
      const messageTarget = `readAllRealEstateListingSyncStatuses`
      this.messageStore.flushTarget(messageTarget)

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID + "/sync-status")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0038", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.channelImmoscout24SyncStatus = ''
        this.channelBookooSyncStatus = ''
        return
      }
      this.channelImmoscout24SyncStatus = response.data.channel_immoscout24
      this.channelBookooSyncStatus = response.data.channel_bookoo
      return true
    },
    async createTag(tagName){
      const messageTarget = `createTag.${tagName}`
      this.messageStore.flushTarget(messageTarget)

      const tagModel = new Tag()
      tagModel.fields.name.value = tagName
      
      try{
        await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/tag/create", tagModel.marshalJSON())
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0026", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }      
      this.messageStore.success('', "Objekt-Attribut erfolgreich angelegt", messageTarget)
      await this.loadTags(this.realEstateListingUUID)
    },
    async updateTag(tagJSON){
      const messageTarget = `updateTag.${tagJSON.uuid}`
      this.messageStore.flushTarget(messageTarget)

      const tagModel = new Tag()
      tagModel.unmarshalJSON(tagJSON)

      try{
        await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/tag/" + tagJSON.uuid, tagModel.marshalJSON())
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0027", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }      
      this.messageStore.success('', "Objekt-Attribut erfolgreich gespeichert", messageTarget)
      await this.loadTags(this.realEstateListingUUID)
    },
    async deleteTag(tagJSON){
      const messageTarget = `deleteTag.${tagJSON.uuid}`
      this.messageStore.flushTarget(messageTarget)

      if (!tagJSON.uuid){
        this.messageStore.error("MOC0024", "Etwas ist schiefgelaufen. Das ausgewählte Attribut konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0024", "delete tag cannot be executed, invalid uuid")
        return false
      }
      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/tag/" + tagJSON.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0025", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      this.messageStore.success('', "Das Objekt-Attribut wurde erfolgreich für alle Objekte gelöscht.", messageTarget)
      await this.loadTags(this.realEstateListingUUID)
      return true
    },
    async attachRealEstateListingTag(tagJSON){
      const messageTarget = `attachRealEstateListingTag.${tagJSON.uuid}`
      this.messageStore.flushTarget(messageTarget)

      const tagModel = new Tag()
      tagModel.unmarshalJSON(tagJSON)
      
      const realEstateListingTagModel = new RealEstateListingTag()
      realEstateListingTagModel.fields.tagUUID.value = tagModel.fields.uuid.value
      realEstateListingTagModel.fields.tenantUUID.value = tagModel.fields.tenantUUID.value
      realEstateListingTagModel.fields.name.value = tagModel.fields.name.value
      realEstateListingTagModel.fields.color.value = tagModel.fields.color.value
      realEstateListingTagModel.fields.realEstateListingUUID.value = this.realEstateListingUUID

      try{
        await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + this.realEstateListingUUID + "/tag", realEstateListingTagModel.marshalJSON())
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0028", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }      
      this.messageStore.success('', "Objekt-Attribut erfolgreich gespeichert", messageTarget)
      await this.loadTags(this.realEstateListingUUID)
    },
    async detachRealEstateListingTag(tagJSON){
      const messageTarget = `detachRealEstateListingTag.${tagJSON.uuid}`
      this.messageStore.flushTarget(messageTarget)

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + this.realEstateListingUUID + "/tag/" + tagJSON.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0025", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      this.messageStore.success('', "Objekt-Attribut erfolgreich entfernt", messageTarget)
      await this.loadTags(this.realEstateListingUUID)
    },    
    async updateLocalDocument(documentJSON){
      await this.readAllDocumentChannelStatistics()

      if (documentJSON.type == 'image'){
        for (let oldDocIdx = 0; oldDocIdx < this.localRealEstateListingImages.length; oldDocIdx++) {
          if (this.localRealEstateListingImages[oldDocIdx].uuid !== documentJSON.uuid){
            continue
          }
          this.localRealEstateListingImages[oldDocIdx] = documentJSON          
        }
        return
      }
      if (documentJSON.type == 'floorPlan'){
        for (let oldDocIdx = 0; oldDocIdx < this.localRealEstateListingFloorPlans.length; oldDocIdx++) {
          if (this.localRealEstateListingFloorPlans[oldDocIdx].uuid !== documentJSON.uuid){
            continue
          }
          this.localRealEstateListingFloorPlans[oldDocIdx] = documentJSON          
        }
        return        
      }
      if (documentJSON.type == 'document'){
        for (let oldDocIdx = 0; oldDocIdx < this.localRealEstateListingDocuments.length; oldDocIdx++) {
          if (this.localRealEstateListingDocuments[oldDocIdx].uuid !== documentJSON.uuid){
            continue
          }
          this.localRealEstateListingDocuments[oldDocIdx] = documentJSON          
        }
        return        
      }
    },
    deleteLocalDocument(documentJSON){
      if (documentJSON.type == 'image'){
        for (let oldDocIdx = 0; oldDocIdx < this.localRealEstateListingImages.length; oldDocIdx++) {
          if (this.localRealEstateListingImages[oldDocIdx].uuid !== documentJSON.uuid){
            continue
          }
          this.localRealEstateListingImages.splice(oldDocIdx, 1)
        }
        return
      }
      if (documentJSON.type == 'floorPlan'){
        for (let oldDocIdx = 0; oldDocIdx < this.localRealEstateListingFloorPlans.length; oldDocIdx++) {
          if (this.localRealEstateListingFloorPlans[oldDocIdx].uuid !== documentJSON.uuid){
            continue
          }
          this.localRealEstateListingFloorPlans.splice(oldDocIdx, 1)     
        }
        return        
      }
      if (documentJSON.type == 'document'){
        for (let oldDocIdx = 0; oldDocIdx < this.localRealEstateListingDocuments.length; oldDocIdx++) {
          if (this.localRealEstateListingDocuments[oldDocIdx].uuid !== documentJSON.uuid){
            continue
          }
          this.localRealEstateListingDocuments.splice(oldDocIdx, 1)        
        }
        return        
      }
    },
    async saveDocument(documentJSON){
      const messageTarget = `saveDocument.${documentJSON.uuid}`
      this.messageStore.flushTarget(messageTarget)

      try{
        await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/document/" + documentJSON.uuid, documentJSON)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0033", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      if (documentJSON.channel_order == 1 && documentJSON.type == 'image'){
        this.localRealEstateListing.cover_image_uuid = documentJSON.uuid
        this.remoteRealEstateListing.cover_image_uuid = documentJSON.uuid
      }
      
      this.messageStore.success('', "Das Dokument wurde erfolgreich gespeichert.", messageTarget)
      await this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
      return true
    },
    async deleteDocument(documentUUID){
      const messageTarget = `saveDocument.${documentUUID}`
      this.messageStore.flushTarget(messageTarget)

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/document/" + documentUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0019", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }

      this.messageStore.success('', "Das Dokument wurde erfolgreich gelöscht.", messageTarget)
      if (this.localRealEstateListing.cover_image_uuid == documentUUID){
        this.localRealEstateListing.cover_image_uuid = ''
        this.remoteRealEstateListing.cover_image_uuid = ''
      }
      await this.readAllRealEstateListingDocuments(this.realEstateListingUUID)
      return true
    },    
    async readAllDocumentChannelStatistics(){
      const messageTarget = `readAllDocumentChannelStatistics`
      this.messageStore.flushTarget(messageTarget)

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/"+ this.realEstateListingUUID +"/statistics/channel-documents")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0052", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.localRealEstateListingDocumentChannelStatistics = []
        return true
      }
      this.localRealEstateListingDocumentChannelStatistics = response.data
      return true
    },  
    async readRealEstateListingImages(realEstateListingUUID){
      const messageTarget = `readRealEstateListingImages`
      this.messageStore.flushTarget(messageTarget)

      this.isDocumentTypeLoaded['image'] = false

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID + "/images")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0016", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.localRealEstateListingImages = []
        this.isDocumentTypeLoaded['image'] = true
        return
      }
      let imageDocs = response.data.all_documents
      imageDocs = this.sortDocumentsByChannelOrder(imageDocs)
      this.localRealEstateListingImages = imageDocs      
      this.isDocumentTypeLoaded['image'] = true
      if (!imageDocs.length){
        return true
      }
      const currentCoverImageUUID = this.localRealEstateListing.cover_image_uuid
      if (!currentCoverImageUUID || currentCoverImageUUID !== imageDocs[0].uuid){
        this.localRealEstateListing.cover_image_uuid = imageDocs[0].uuid
        this.remoteRealEstateListing.cover_image_uuid = imageDocs[0].uuid
      }
      return true
    },
    async readRealEstateListingFloorPlans(realEstateListingUUID){
      const messageTarget = `readRealEstateListingFloorPlans`
      this.messageStore.flushTarget(messageTarget)

      this.isDocumentTypeLoaded['floorPlan'] = false
      
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID + "/floor-plans")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0017", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.isDocumentTypeLoaded['floorPlan'] = true
        this.localRealEstateListingFloorPlans = []
        return
      }
      let floorPlanDocs = response.data.all_documents
      floorPlanDocs = this.sortDocumentsByChannelOrder(floorPlanDocs)
      this.isDocumentTypeLoaded['floorPlan'] = true
      this.localRealEstateListingFloorPlans = floorPlanDocs
      return true
    },
    async readRealEstateListingDocuments(realEstateListingUUID){
      const messageTarget = `readRealEstateListingDocuments`
      this.messageStore.flushTarget(messageTarget)

      this.isDocumentTypeLoaded['document'] = false
      
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID + "/documents")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0015", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return false
      }
      if (response.status === 204){
        this.isDocumentTypeLoaded['document'] = true
        this.localRealEstateListingDocuments = []
        return
      }
      let documentDocs = response.data.all_documents
      documentDocs = this.sortDocumentsByChannelOrder(documentDocs)
      this.isDocumentTypeLoaded['document'] = true
      this.localRealEstateListingDocuments = documentDocs
      return true
    },
    async loadRealEstateListingData(realEstateJSON){
      this.remoteRealEstateListing = realEstateJSON
      this.localRealEstateListing = realEstateJSON
      if (realEstateJSON.publish_status){
        this.publishStatus = realEstateJSON.publish_status
      }
      let channelImmoscout24 = false
      if (realEstateJSON.channel_immoscout24 == true){
        channelImmoscout24 = true
      }
      this.channelImmoscout24 = channelImmoscout24

      let channelBookoo = false
      if (realEstateJSON.channel_bookoo == true){
        channelBookoo = true
      }
      this.channelBookoo = channelBookoo

      await this.$nextTick()      
      this.areAllRemoteFieldsValid = this.areAllLocalFieldsValid 
    },
    loadBookooFieldData(savedListingModel){
      for (const [fieldName, savedField] of Object.entries(savedListingModel.fields)){
        if (fieldName.indexOf('bookoo') != 0){
          continue
        }        
        const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
        this.localRealEstateListing[jsonFieldName] = savedField.value
        this.remoteRealEstateListing[jsonFieldName] = savedField.value
      }
    },
    sortDocumentsByChannelOrder(documents){
      return documents.sort(function(first, second){
        if (first.channel_order < second.channel_order){
          return -1
        }
        if (first.channel_order > second.channel_order){
          return 1
        }
        return 0
      })
    },
    async triggerSyncForChannel(channelLabel, channelID){
      const messageTarget = `triggerSyncForChannel.${channelID}`
      this.messageStore.flushTarget(messageTarget)

      this.messageStore.pending('', "Objekt wird mit Kanal '"+channelLabel+"' synchronisiert, bitte warten.", messageTarget)

      let syncRealEstateListingMsg = new SyncSingleRealEstateListing(this.realEstateListingUUID, channelID)
      try{
        await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/sync/real-estate-listing", syncRealEstateListingMsg.marshalJSON())
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0026", requestErr)
        await this.reloadRealEstateListing()
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Objekt wurde mit Kanal '"+channelLabel+"' erfolgreich synchronisiert.", messageTarget)
      await this.reloadRealEstateListing()
    },
    updateLocalRealEstateListing(realEstate){
      if (JSON.stringify(this.localRealEstateListing) === JSON.stringify(realEstate)){
        return
      }
      this.localRealEstateListing = realEstate
    },
    cancelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      if (this.currentSubView != '' && this.isEditingDocument){
        return
      }
      this.cancelEdit(e)
    },
    cancelEdit(e){
      if (e){
        e.preventDefault()
      }
      
      this.$emit('cancelled:modelEdit')
    },
    prevModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      if (this.currentSubView != '' && this.isEditingDocument){
        return
      }
      this.prevModelEdit()
    },
    prevModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.prevModelUUID){
        return
      }      
      this.$emit('navigate:model', this.prevModelUUID)
    },
    nextModelEditShortcut(e){
      if (e){
        e.preventDefault()
      }
      if (this.currentSubView != '' && this.isEditingDocument){
        return
      }
      this.nextModelEdit()
    },
    nextModelEdit(e){
      if (e){
        e.preventDefault()
      }
      if (!this.nextModelUUID){
        return
      }      
      this.$emit('navigate:model', this.nextModelUUID)
    },
    async deleteRealEstateListing(e){
      const messageTarget = `deleteRealEstateListing.${this.localRealEstateListing.uuid}`
      this.messageStore.flushTarget(messageTarget)

      if (e){
        e.preventDefault()
      }

      if (!this.localRealEstateListing.uuid){
        this.messageStore.error("MOC0010", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0010", "delete real estate cannot be executed, invalid uuid")
        return
      }
      const identifier = this.realEstateIdentifier
      const isConfirmed = confirm("Sicher, dass das Objekt '"+ identifier +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + this.localRealEstateListing.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0011", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Objekt '"+identifier+"' wurde erfolgreich gelöscht.", messageTarget)
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete')
    },

    async createSuccessorRealEstateListing(e){
      const messageTarget = `createSuccessorRealEstateListing.${this.localRealEstateListing.uuid}`
      this.messageStore.flushTarget(messageTarget)

      if (e){
        e.preventDefault()
      }
      
      if (!this.localRealEstateListing.uuid){
        this.messageStore.error("MOC0177", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.", messageTarget)
        services.$log.fatal("MOC0177", "delete real estate cannot be executed, invalid uuid")
        return
      }
      const identifier = this.realEstateIdentifier
      const isConfirmed = confirm("Sicher, dass das Objekt '"+ identifier +"' in eine Nachfolgeanzeige übertragen werden soll?")
      if (!isConfirmed){
        return
      }

      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + this.localRealEstateListing.uuid + "/successor")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0178", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Anzeige '"+identifier+"' wurde erfolgreich in eine Nachfolge-Anzeige übertragen. Leite weiter...", messageTarget)
      await services.$sleep.seconds(2)
      this.$emit('navigate:model', response.data.uuid)
    },

    async saveRealEstateListingFieldGrid(fieldName, fieldLabel, oldValue, newValue){
      if (this.isDisplayModeList){
        return
      }
      return this.saveRealEstateListingField(fieldName, fieldLabel, oldValue, newValue)
    },
    async saveRealEstateListingFieldList(fieldName, fieldLabel, oldValue, newValue){
      if (this.isDisplayModeGrid){
        return
      }
      return this.saveRealEstateListingField(fieldName, fieldLabel, oldValue, newValue)
    },
    async saveRealEstateListingField(fieldName, fieldLabel, oldValue, newValue){
      if (!this.isLoaded){
        return
      }
      if (this.currentSubView != '' && this.isEditingDocument){
        return
      }
      this.messageStore.pending('', `Objekt-Feld '${fieldLabel}' wird gespeichert...`, fieldName)

      const reqBody = {
        uuid: this.localRealEstateListing.uuid,
        field_name: fieldName,
        old_value: oldValue,
        new_value: newValue,
      }

      let response = {}
      try{
        response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + this.localRealEstateListing.uuid +"/field", reqBody)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0056", requestErr)
        this.messageStore.error(parsedError.code, `Objekt-Feld '${fieldLabel}': ${ parsedError.message }`, fieldName)
        return
      }
      this.messageStore.success('', `Objekt-Feld '${fieldLabel}' erfolgreich gespeichert`, fieldName)
      this.$emit('success:modelEdit')
      
      await this.$nextTick()

      const jsonFieldName = services.$strcase.convertToSnakeCase(fieldName)
      const savedListing = new RealEstateListing()
      savedListing.unmarshalJSON(response.data.real_estate_listing)
      const savedValue = savedListing.fields[fieldName].value

      if (fieldName.indexOf('bookoo') == 0){
        this.localRealEstateListing['bookoo_description'] = savedListing.fields['bookooDescription'].value
        this.remoteRealEstateListing['bookoo_description'] = savedListing.fields['bookooDescription'].value
      } else {
        this.loadBookooFieldData(savedListing)
      }
      if (fieldName.indexOf('channel') == 0 ){
        const savedPublishStatus = savedListing.fields['publishStatus'].value
        this.localRealEstateListing['publish_status'] = savedPublishStatus
        this.remoteRealEstateListing['publish_status'] = savedPublishStatus
      }

      if (savedValue != newValue){
        this.localRealEstateListing[jsonFieldName] = savedValue
      }
      this.remoteRealEstateListing[jsonFieldName] = savedValue
      this.areAllRemoteFieldsValid = this.areAllLocalFieldsValid
    },
    getSortByPrefixEndpointURLByDocumentType(documentType){      
      const baseURL = "/real-estate-listing/" + this.realEstateListingUUID + "/"
      if (documentType == 'document'){
        return baseURL + 'documents/prefix-sort'
      }
      if (documentType == 'image'){
        return baseURL + 'images/prefix-sort'
      }
      if (documentType == 'floorPlan'){
        return baseURL + 'floor-plans/prefix-sort'
      }
      return 'invalid-type'
    },
    getFileUploadEndpointURLByDocumentType(documentType){      
      const baseURL = "/real-estate-listing/" + this.realEstateListingUUID + "/"
      if (documentType == 'document'){
        return baseURL + 'documents'
      }
      if (documentType == 'image'){
        return baseURL + 'images'
      }
      if (documentType == 'floorPlan'){
        return baseURL + 'floor-plans'
      }
      return 'invalid-type'
    },
    modelFieldsErrorHandler(code, message){
      const messageTarget = `modelFieldsErrorHandler`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.error(code, message, messageTarget)
    },
    setDocumentPendingMessage(pendingBody){      
      const messageTarget = `setDocumentPendingMessage`
      this.messageStore.flushTarget(messageTarget)

      this.messageStore.pending('', pendingBody, messageTarget)
    },
    setDocumentSuccessMessage(successBody){
      const messageTarget = `setDocumentSuccessMessage`
      this.messageStore.flushTarget(messageTarget)

      this.messageStore.success('', successBody, messageTarget)      
    },
    setDocumentErrorMessage(contextCode, errorBody){
      const messageTarget = `setDocumentErrorMessage`
      this.messageStore.flushTarget(messageTarget)

      this.messageStore.error(contextCode, errorBody, messageTarget)
    },
    getRealEstateListingAddressLine(){
      let addrPieces = []
      if (this.remoteRealEstateListing.street_name){
        addrPieces.push(this.remoteRealEstateListing.street_name)
      }
      if (this.remoteRealEstateListing.street_number){
        addrPieces.push(this.remoteRealEstateListing.street_number)
      }
      if (this.remoteRealEstateListing.zip_code){
        addrPieces.push(this.remoteRealEstateListing.zip_code)
      }
      if (this.remoteRealEstateListing.city){
        addrPieces.push(this.remoteRealEstateListing.city)
      }
      return addrPieces.join(" ")
    },
    isSyncPossibleImmoscout24(){
      // Update
      if (this.remoteRealEstateListing.immoscout24_id){ 
        return true
      }
      // Create
      if (this.remoteRealEstateListing.publish_status != 'Active'){
        return false
      }
      return this.channelImmoscout24
    },
    isSyncPossibleBookoo(){
      // Update
      if (this.remoteRealEstateListing.bookoo_id){ 
        return true
      }
      // Create
      if (this.remoteRealEstateListing.publish_status != 'Active'){
        return false
      }
      return this.channelBookoo
    },
    isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    }
  }, 
  components:{
    RealEstateListingFields,
    RadioButtonInput,
    CheckboxButtonInput,
    SelectButtonInput,
    TagsInput,
    DocumentGrid,
    SyncStatusIndicator,
    GridIcon,
    ListColumnsIcon,
    FormMessages,
  }
}
</script>

<style>
.sync-status-bar{
  display: inline-block;
  margin-left: 5px;
}

.channels-container{
  padding: 0px;
}
@media (min-width: 992px) { /* lg */  
  .sync-container,
  .channels-container,
  .publish-status-container{
    margin-top: 5rem;
  }
}
</style>