<template>
    <div>
      <FormMessages
        :allMessages="messageStore.messages"
      />
      <nav 
        id="renter-search-bar"
        class="col-12 col-lg-10"
        v-show="currentComponent === 'RealEstateRenterOverview' && contextUUID === ''"
      >
        <div class="row gx-1 gx-lg-2 position-relative">
            <div class="col-7 col-sm-8 col-xl-6">
              <StringInput
                  name="searchText" 
                  label="Hinweis"
                  class="form-control"
                  placeholder="Herr ..."
                  v-model="searchText"
                  @valid:modelValue="searchTextValid = true"
                  @invalid:modelValue="searchTextValid = false"
                  @reset:modelValue="searchTextValid = null"
                  :maxLength="100"
              />
            </div>
            <div class="col-1 col-xl-3 btn-icon-container">
              <button
                class="btn btn-sec form-control show-xl"
                type="button"
                @click="displayFilters = !displayFilters"
              >
                Filtern
              </button>
              <button
                class="btn btn-icon-sec form-control hide-xl"
                type="button"
                @click="displayFilters = !displayFilters"
              >
                <FunnelIcon />
              </button>
            </div>
            <div class="col-1 col-xl-3 btn-icon-container">
              <button
                class="btn btn-warn form-control show-xl"
                type="button"
                @click="resetSearchFilterCriteria"
              >
                Zurücksetzen
              </button>
              <button
                class="btn btn-icon-warn form-control hide-xl"
                type="button"
                @click="resetSearchFilterCriteria"
              >
                <UndoArrowIcon />
              </button>
            </div>
            <h6
              v-show="displayFilters"
              class="mt-1"
            >Filter</h6>
            <RealEstateRenterFilters
              v-show="displayFilters"
              class="col-8 col-md-6 col-xxl-4"
              :filterValues="filterValues"
              @update:filterValues="updateFilterValues"
            />
        </div>
      </nav>
      <div
        v-if="currentComponent === 'RealEstateRenterOverview'"
      >
        <div class="my-2" v-if="contextType === 'RealEstateUnit'">
          <div
            class="btn btn-brand"
            title="Neuen Mieter erstellen"
            role="button"
            @click="createRealEstateRenter"
          >
            Neuen Mieter erstellen
          </div>
        </div>
        <RealEstateRenterTable 
          id="real-estate-renters"
          :realEstateUnitUUID="realEstateUnitUUID"
          :realEstateRenters="displayRealEstateRenters"
          :isLoaded="isLoaded"
          @triggered:modelEdit="selectRealEstateRenterForEdit"
          @triggered:modelDetach="detachRealEstateRenter"
        />
        <div
          class="my-2 row"
          v-if="isContextGiven"
          v-show="isLoaded"
        >
          <div class="col-8 col-md-8 my-1">
            <SelectAutocompleteInput
                name="attachRenter"
                label="Zusatztyp"
                id="attachRenter"
                class="form-control" 
                v-model="attachRealEstateRenterUUID"
                :options="unattachedRealEstateRenterOptions"
            />
          </div>
          <div class="col-8 col-md-4 my-1">
            <button
              class="btn btn-sec w-100"
              title="Neuen Mieter hinzufügen"
              role="button"
              @click="attachRealEstateRenter"
              :disabled="attachRealEstateRenterUUID == ''"
            >
              Mieter hinzuf.
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="currentComponent === 'RealEstateRenterEditForm'"
      >
        <RealEstateRenterEditForm
          class="col-12"
          @cancelled:modelEdit="exitToRelatedContextOverview"
          @success:modelDelete="exitToRelatedContextOverview"
          @navigate:model="selectRealEstateRenterForEdit"
          :realEstateRenterUUID="currentRealEstateRenterUUID"
          :prevModelUUID="prevRealEstateRenterUUID"
          :nextModelUUID="nextRealEstateRenterUUID"
          :contextType="contextType"
          :contextUUID="contextUUID"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { services } from '@digiscape/js-core'
  
  import RealEstateRenter from '@/diaspora/models/real-estate-renter.js'
  import { readAllRealEstateRenters, readAllRealEstateRentersByRelated, searchRealEstateRenters, createRealEstateRenter, attachRealEstateRenter, detachRealEstateRenter } from '@/diaspora/models/queries/real-estate-renter.js'
  
  import RealEstateRenterTable from '@/components/tables/RealEstateRenterTable.vue'
  import RealEstateRenterEditForm from '@/components/forms/RealEstateRenterEditForm.vue'
  
  import SelectAutocompleteInput from '@/components/inputs/SelectAutocompleteInput.vue'
  import StringInput from  '@/components/inputs/StringInput.vue'
  
  import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'
  import FunnelIcon from  '@/components/icons/FunnelIcon.vue'
  
  import FormMessages from '@/components/forms/messages/FormMessages.vue'
  import MessageStore from '@/components/forms/messages/message-store.js'
  
  import RealEstateRenterFilters from '@/components/control/RealEstateRenterFilters.vue'
  
  export default {
    name: 'RealEstateRenterCollection',
    data(){
      return {      
        messageStore: new MessageStore(),
        allRealEstateRenters: [],
        contextRealEstateRenters: [],
        searchText: '',
        searchTextValid: false,
        displayFilters: false,
        isLoaded: false,
        currentComponent: 'RealEstateRenterOverview',
        currentRealEstateRenterUUID: '',
        attachRealEstateRenterUUID: '',
        filterValues: {
          department: '',
        },
      }
    },
    props:{
      isSubView: {
        type: Boolean,
      },
      realEstateUnitUUID: {
        type: String,
      }
    },
    computed:{
      userDepartmentPrefilter(){
        const department = this.$store.getters.userDepartment
        if (department === 'Alle'){
          return ''
        }
        return department
      },
      contextType(){
        if (this.realEstateUnitUUID){
          return 'RealEstateUnit'
        }
        return ''
      }, 
      contextUUID(){
        if (this.realEstateUnitUUID){
          return this.realEstateUnitUUID
        }
        return ''
      }, 
      isContextGiven(){
        return this.contextType != '' && this.contextUUID != ''
      },
      displayRealEstateRenters(){
        let realEstateRenterSubset = this.contextRealEstateRenters
        if (!realEstateRenterSubset){
          return []
        }
        realEstateRenterSubset = this.cloneArray(this.contextRealEstateRenters)
        realEstateRenterSubset = this.filterRealEstateRentersByDepartment(realEstateRenterSubset)
        return realEstateRenterSubset.sort(this.realEstateRenterNameComparator)
      },
      sortedUnattachedRealEstateRenters(){
        const sorted = this.cloneArray(this.unattachedRealEstateRenters)
        return sorted.sort(this.realEstateRenterNameComparator)
      },
      unattachedRealEstateRenters(){
        const unattachedRenters = [] 
        for (let renterIdx = 0; renterIdx < this.allRealEstateRenters.length; renterIdx++) {
          const renter = this.allRealEstateRenters[renterIdx];
          if (this.isDisplayRealEstateRenter(renter.uuid)){
            continue
          }
          unattachedRenters.push(renter)
        }
        return unattachedRenters
      },
      unattachedRealEstateRenterOptions(){
        const unattachedRenterOptions = []
        for (let renterIdx = 0; renterIdx < this.sortedUnattachedRealEstateRenters.length; renterIdx++) {
          const renter = this.sortedUnattachedRealEstateRenters[renterIdx];
          const renterOption = {
            label: this.getRenterOptionLabel(renter), 
            value: renter.uuid,
          }
          if (renterOption.label === '' || !renterOption.label){
            continue
          }
          unattachedRenterOptions.push(renterOption)
        }
        return unattachedRenterOptions
      },
      urlRealEstateRenterUUID(){
        return this.$route.params.realEstateRenterUUID
      },    
      prevRealEstateRenterUUID(){
        if (!this.currentRealEstateRenterUUID){
          return ''
        }
        let prevRealEstateRenter = undefined
        for (let realEstateRenterIdx = 0; realEstateRenterIdx < this.displayRealEstateRenters.length; realEstateRenterIdx++) {
          const realEstateRenter = this.displayRealEstateRenters[realEstateRenterIdx]
          if (realEstateRenter.uuid !== this.currentRealEstateRenterUUID){
            prevRealEstateRenter = realEstateRenter
            continue
          }
          if (prevRealEstateRenter){
            return prevRealEstateRenter.uuid
          }
          return ''
        }
        return ''
      },
      nextRealEstateRenterUUID(){
        if (!this.currentRealEstateRenterUUID){
          return ''
        }
        let nextRealEstateRenter = undefined
        for (let realEstateRenterIdx = this.displayRealEstateRenters.length - 1; realEstateRenterIdx >= 0; realEstateRenterIdx--) {
          const realEstateRenter = this.displayRealEstateRenters[realEstateRenterIdx]
          if (realEstateRenter.uuid !== this.currentRealEstateRenterUUID){
            nextRealEstateRenter = realEstateRenter
            continue
          }
          if (nextRealEstateRenter){
            return nextRealEstateRenter.uuid
          }
          return ''
        }
        return ''
      },
    },
    async mounted(){
      if (this.userDepartmentPrefilter){
        this.filterValues.department = this.userDepartmentPrefilter
      }
      if (this.urlRealEstateRenterUUID && !this.isSubView){
        this.showRealEstateRenterEditForm(this.urlRealEstateRenterUUID)
      }
      await this.loadRealEstateRenterData()
    },
    watch:{
      async searchText(){
        await this.loadRealEstateRenterData()
      },
      urlRealEstateRenterUUID(newUUID){
        if (newUUID){
          this.showRealEstateRenterEditForm(newUUID)
          return
        }
        this.showRealEstateRenterOverview()
      },
      async contextUUID(newUUID){
        if (!newUUID){
          this.allRealEstateRenters = []
          this.contextRealEstateRenters = []
          return
        }
        
        await this.loadRealEstateRenterData()
      },
    },
    methods:{
      async loadRealEstateRenterData(){      
        this.isLoaded = false
        await Promise.all([
          this.loadContextRealEstateRenters(),
          this.loadAllRealEstateRenters()
        ])
        this.attachRealEstateRenterUUID = ''
        this.isLoaded = true
      },
      async loadContextRealEstateRenters(){
        if (this.searchText){
          this.contextRealEstateRenters = await this.searchRealEstateRenters()
          return
        }
        if (this.isContextGiven) {
          this.contextRealEstateRenters = await this.readRelatedRealEstateRenters()
          return
        }
        this.contextRealEstateRenters = await this.readAllRealEstateRenters()
      },
      async loadAllRealEstateRenters(){
        if (!this.isContextGiven) {
          return
        }
        this.allRealEstateRenters = await this.readAllRealEstateRenters()
      },
      async searchRealEstateRenters(){
        const messageTarget = `searchRealEstateRenters`
        this.messageStore.flushTarget(messageTarget)
  
        let resultRealEstateRenters = []
        try{
          resultRealEstateRenters = await searchRealEstateRenters(this.searchText)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0093", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message)
          return []
        }
        return resultRealEstateRenters      
      },
      async readRelatedRealEstateRenters(){
      if (!this.isContextGiven){
        services.$log.warn("MOC0103", "read all real estate renters cannot be executed, invalid related uuid / type, skipping")
        return
      }
        const messageTarget = `readRelatedRealEstateRenters`
        this.messageStore.flushTarget(messageTarget)
  
        let relatedRealEstateRenters = []
        try{
          relatedRealEstateRenters = await readAllRealEstateRentersByRelated(this.contextType, this.contextUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0088", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message)
          return []
        }
        return relatedRealEstateRenters      
      },
      async readAllRealEstateRenters(){
        const messageTarget = `readAllRealEstateRenters`
        this.messageStore.flushTarget(messageTarget)
  
        let allRealEstateRenters = []
        try{
          allRealEstateRenters = await readAllRealEstateRenters()
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0089", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message)
          return []
        }
        return allRealEstateRenters
      },
      async createRealEstateRenter(e){
        if (e){
          e.preventDefault()
        }
  
        const messageTarget = `createRealEstateRenter`
        this.messageStore.flushTarget(messageTarget)
        this.messageStore.pending('', 'Erstelle einen neuen Mieter...', messageTarget)
  
        const newRealEstateRenter = new RealEstateRenter()
        const newRealEstateRenterJSON = newRealEstateRenter.marshalJSON()
        let createdUUID = ''
        try{
          createdUUID = await createRealEstateRenter(newRealEstateRenterJSON, this.contextType, this.contextUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0086", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Mieter erfolgreich erstellt.", messageTarget)
        this.selectRealEstateRenterForEdit(createdUUID)
      },
      async attachRealEstateRenter(e){
        if (e){
          e.preventDefault()
        }
  
        const messageTarget = `attachRealEstateRenter`
        this.messageStore.flushTarget(messageTarget)
        this.messageStore.pending('', 'Füge Mieter hinzu...', messageTarget)
  
        if (!this.attachRealEstateRenterUUID){
          this.messageStore.error("MOC0090", "Etwas ist schiefgelaufen. Der ausgewählte Mieter kann nicht identifiziert werden.", messageTarget)
          return
        }
  
        try{
          await attachRealEstateRenter(this.contextType, this.contextUUID, this.attachRealEstateRenterUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0086", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Mieter erfolgreich hinzugefügt.", messageTarget)
        await this.loadRealEstateRenterData()
      },
      async detachRealEstateRenter(detachRealEstateRenterUUID){
        const messageTarget = `detachRealEstateRenter`
        this.messageStore.flushTarget(messageTarget)
        
        const isConfirmed = confirm("Sicher, dass der Mieter entfernt werden soll?")
        if (!isConfirmed){
          return
        }
  
        this.messageStore.pending('', 'Entferne Mieter...', messageTarget)
  
        if (!detachRealEstateRenterUUID){
          this.messageStore.error("MOC0091", "Etwas ist schiefgelaufen. Der ausgewählte Mieter kann nicht identifiziert werden.", messageTarget)
          return
        }
  
        try{
          await detachRealEstateRenter(this.contextType, this.contextUUID, detachRealEstateRenterUUID)
        } catch(requestErr) {
          const parsedError = services.$err.parseRequestError("MOC0092", requestErr)
          this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
          return
        }
        this.messageStore.success('', "Mieter erfolgreich entfernt.", messageTarget)
        await this.loadRealEstateRenterData()
      },
      updateFilterValues(newValues){
        for (const filterName in newValues) {
          const newFilterValue = newValues[filterName]
          this.filterValues[filterName] = newFilterValue
        }
      },
      filterRealEstateRentersByDepartment(realEstateRenterSubset){      
        const departmentFilter = this.filterValues.department
        if (!departmentFilter || departmentFilter === 'Alle'){
          return realEstateRenterSubset
        }
        const filteredRealEstateRenters = realEstateRenterSubset.filter(function(realEstateRenter){
          if (realEstateRenter.department === '' || realEstateRenter.department === 'Alle'){
            return true
          }
          if (!realEstateRenter.department){
            return false
          }
          return realEstateRenter.department === departmentFilter
        })
        return filteredRealEstateRenters
      },
      isDisplayRealEstateRenter(renterUUID){
        for (let renterIdx = 0; renterIdx < this.contextRealEstateRenters.length; renterIdx++) {
          const displayRenter = this.contextRealEstateRenters[renterIdx];
          if (displayRenter.uuid === renterUUID){
            return true
          }
        }
        return false
      },
      getRenterOptionLabel(renterJSON){
        let renterLabel = ""
        if (renterJSON.salutation || renterJSON.first_name || renterJSON.last_name){
          renterLabel += (renterJSON.salutation || '') + " " + (renterJSON.first_name || '') + " " + (renterJSON.last_name || '')
        }
        if (renterJSON.company_name){
          renterLabel += " / " + renterJSON.company_name
        }
        return renterLabel
      },
      selectRealEstateRenterForEdit(realEstateRenterUUID){
        this.showRealEstateRenterEditForm(realEstateRenterUUID)
        this.navigateToRealEstateRenter(realEstateRenterUUID)
      },
      exitToRelatedContextOverview(){
        this.showRealEstateRenterOverview()
        this.navigateToRelatedContextFromRealEstateRenter()
      },
      navigateToRelatedContextFromRealEstateRenter(){
        const newRoute = '/real-estate-renter'
        this.$router.push(newRoute)
      },
      navigateToRealEstateRenter(newUUID){
        if (this.contextType === 'RealEstateUnit'){
          const newRoute = '/real-estate-unit/' + this.contextUUID + '/real-estate-renter/' + newUUID
          this.$router.push(newRoute)
          return
        }
        const newRoute = '/real-estate-renter/' + newUUID
        this.$router.push(newRoute)
      },
      async resetSearchFilterCriteria(){
        this.filterValues.department = ''
        this.searchText = ''
      },
      showRealEstateRenterEditForm(realEstateRenterUUID){
        if (!realEstateRenterUUID){
          services.$log.warnWrap('showRealEstateRenterEditForm', 'MOC0087', 'no real estate renter uuid provided or nested, showing overview instead')
          this.showRealEstateRenterOverview()
          return
        }
        this.currentRealEstateRenterUUID = realEstateRenterUUID
        this.currentComponent = 'RealEstateRenterEditForm'
      },
      async showRealEstateRenterOverview(){
        this.currentRealEstateRenterUUID = ''
        this.currentComponent = 'RealEstateRenterOverview'
  
        await this.loadRealEstateRenterData()
      },
      realEstateRenterNameComparator(first, second) {
        const orderFirstBeforeSecond = -1
        const orderSecondBeforeFirst = 1
        const orderEqual = 0
  
        if (!first.name){
          return orderFirstBeforeSecond
        }
        if (!second.name){
          return orderSecondBeforeFirst
        }
        if (first.name < second.name){
          return orderFirstBeforeSecond
        }
        if (first.name > second.name){
          return orderSecondBeforeFirst
        }
        return orderEqual
      },
      cloneArray(cloneSource){
        return [...cloneSource]
      }
    },
    components:{
      RealEstateRenterTable,
      RealEstateRenterEditForm,
      UndoArrowIcon,
      StringInput,
      SelectAutocompleteInput,
      FormMessages,
      FunnelIcon,
      RealEstateRenterFilters,
    }
  }
  </script>
  