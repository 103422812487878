import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field'

class RealEstateListingTag extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                  new UUIDField('uuid', 'UUID'),
            tenantUUID:            new UUIDField('tenantUUID', 'Kunden-UUID'),
            realEstateListingUUID: new UUIDField('realEstateListingUUID', 'Objekt-UUID', true),
            tagUUID:               new UUIDField('tagUUID', 'Attribut-UUID'),
            name:                  new StringField('name', 'Name', true),
            color:                 new StringField('color', 'Farbe'),
        }
    }
}

export default RealEstateListingTag