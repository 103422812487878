<template>
  <div id="login">
    <h2>Anmelden</h2>
    <form id="user-fields">
      <div class="form-group">
        <label for="userEmail">E-Mail Adresse</label>        
        <StringInput
          name="userEmail" 
          id="userEmail"
          label="E-Mail"
          class="form-control" 
          placeholder="max@mustermann.de"
          v-model="fields.email.value"
          @valid:modelValue="fields.email.isValid = true"
          @invalid:modelValue="fields.email.isValid = false"
          @reset:modelValue="fields.email.isValid = null"
          :min-length="3"
          format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
        />
      </div>
      <div class="form-group">
        <label for="userPassword">Passwort</label>
        <PasswordInput
          name="userPassword" 
          id="userPassword"
          label="Passwort"
          class="form-control" 
          v-model="fields.password.value"
          @valid:modelValue="fields.password.isValid = true"
          @invalid:modelValue="fields.password.isValid = false"
          @reset:modelValue="fields.password.isValid = null"
          :min-length="3"
        />
      </div>
      <button class="btn btn-brand form-control" type="button" @click="loginUser">Anmelden</button>
    </form>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'
import { reactive } from 'vue'

import StringInput from  '@/components/inputs/StringInput.vue'
import PasswordInput from  '@/components/inputs/PasswordInput.vue'

export default {
  name: 'LoginForm',
  data(){
    return {
      fields: reactive({
        email: {
          value: '',
          isValid: false
        },
        password: {
          value: '',
          isValid: false
        }
      }),
      errorCode: '',
      errorMessage: ''
    }
  },
  methods:{
    async loginUser(e){
      e.preventDefault()

      this.errorCode = ''
      this.errorMessage = ''

      const userData = {
        email: this.fields.email.value,
        password: this.fields.password.value
      }
      services.$log.info("loginUser", userData.email)
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/login", userData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0001", requestErr)
        this.errorCode = parsedError.code
        this.errorMessage = parsedError.message
        return
      }
      const token = response.data.token
      this.$store.commit({
        type: 'updateUser',
        token: token,
        email: userData.email,
      })
      services.$http.storeAuthHeaderToken(process.env.VUE_APP_CLIENT_NAME, token)
      if(this.$route.params.nextUrl){
        this.$router.push(this.$route.params.nextUrl)
        return
      }
      this.$router.push('/')
    }
  },
  components:{
    StringInput,
    PasswordInput
  }
}
</script>

<style scoped>
#user-fields .form-group{
  margin-top:2em;
}
#user-fields .btn{
  margin-top:4em;
}
#user-fields .form-group label{
  display: block;
  text-align: left;
  margin: 5px 0px;
}
</style>
