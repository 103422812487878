<template>
  <div>
    <FormMessages
      :allMessages="messageStore.messages"
    />
    <div
      v-if="currentComponent === 'BankAccountOverview'"
    >
      <BankAccountTable 
        id="bankAccounts"
        :bankAccounts="sortedBankAccounts"
        :isLoaded="isLoaded"
        @triggered:modelEdit="selectBankAccountForEdit"
        @triggered:modelCreate="createBankAccount"
        @triggered:modelDetach="detachBankAccount"
        :isAccountableContextGiven="isAccountableContextGiven"
        :contextType="contextType"
        :contextUUID="contextUUID"
      />
      <div
        class="my-2 row"
        v-if="isAccountableContextGiven"
        v-show="isLoaded"
      >
        <div class="col-8 col-md-8 my-1">
          <SelectAutocompleteInput
              name="attachBankAccount"
              label="Verkn. Konto"
              id="attachBankAccount"
              class="form-control" 
              v-model="attachBankAccountUUID"
              :options="unattachedRelatedBankAccountsOptions"
          />
        </div>
        <div class="col-8 col-md-4 my-1">
          <button
            class="btn btn-sec w-100"
            title="Neues Konto hinzufügen"
            role="button"
            @click="attachBankAccount"
            :disabled="attachBankAccountUUID == ''"
          >
            Konto hinzuf.
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="currentComponent === 'BankAccountEditForm'"
    >
      <BankAccountEditForm
        class="col-12"
        @cancelled:modelEdit="exitToOverview"
        @success:modelDelete="exitToOverview"
        :bankAccountUUID="currentBankAccountUUID"
      />
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import { readAllBankAccountsByRelated, readAllBankAccountsByRelatedAccountable, createBankAccount, attachBankAccount, detachBankAccount } from '@/diaspora/models/queries/bank-account.js'

import BankAccountTable from '@/components/tables/BankAccountTable.vue'
import BankAccountEditForm from '@/components/forms/BankAccountEditForm.vue'

import SelectAutocompleteInput from '@/components/inputs/SelectAutocompleteInput.vue'

import FormMessages from '@/components/forms/messages/FormMessages.vue'
import MessageStore from '@/components/forms/messages/message-store.js'

export default {
  name: 'BankAccountCollection',
  data(){
    return {      
      attachBankAccountUUID: '',
      messageStore: new MessageStore(),
      contextBankAccounts: [],
      contextRelatedBankAccounts: [],
      isLoaded: false,
      currentComponent: 'BankAccountOverview',
      currentBankAccountUUID: '',
    }
  },
  props:{
    isSubView: {
      type: Boolean,
    },
    realEstateUUID: {
      type: String,
    },
    realEstateOwnerUUID: {
      type: String,
    },
    realEstateRenterUUID: {
      type: String,
    },
    serviceProviderUUID: {
      type: String,
    },
  },
  computed:{
    contextType(){
      if (this.realEstateUUID){ // Accountable
        return 'RealEstate'
      }
      if (this.realEstateOwnerUUID){
        return 'RealEstateOwner'
      }
      if (this.realEstateRenterUUID){
        return 'RealEstateRenter'
      }
      if (this.serviceProviderUUID){
        return 'ServiceProvider'
      }
      return ''
    }, 
    contextUUID(){
      if (this.realEstateUUID){ // Accountable
        return this.realEstateUUID
      }
      if (this.realEstateOwnerUUID){
        return this.realEstateOwnerUUID
      }
      if (this.realEstateRenterUUID){
        return this.realEstateRenterUUID
      }
      if (this.serviceProviderUUID){
        return this.serviceProviderUUID
      }
      return ''
    }, 
    isAccountableContextGiven(){
      return this.isContextGiven && this.contextType == 'RealEstate'
    },
    isContextGiven(){
      return this.contextType != '' && this.contextUUID != ''
    },
    sortedBankAccounts(){
      const sorted = this.cloneArray(this.contextBankAccounts)
      return sorted.sort(this.bankAccountIBANComparator)
    }, 
    unattachedRelatedBankAccounts(){
      const unattachedAccounts = [] 
      for (let accountIdx = 0; accountIdx < this.contextRelatedBankAccounts.length; accountIdx++) {
        const account = this.contextRelatedBankAccounts[accountIdx];
        if (this.isContextBankAccount(account.uuid)){
          continue
        }
        unattachedAccounts.push(account)
      }
      return unattachedAccounts
    },
    sortedUnattachedRelatedBankAccounts(){
      const sorted = this.cloneArray(this.unattachedRelatedBankAccounts)
      return sorted.sort(this.bankAccountIBANComparator)
    },
    unattachedRelatedBankAccountsOptions(){
      const unattachedAccountOptions = []
      for (let accountIdx = 0; accountIdx < this.sortedUnattachedRelatedBankAccounts.length; accountIdx++) {
        const account = this.sortedUnattachedRelatedBankAccounts[accountIdx];
        const accountOption = {
          label: this.getBankAccountOptionLabel(account), 
          value: account.uuid,
        }
        if (accountOption.label === '' || !accountOption.label){
          continue
        }
        unattachedAccountOptions.push(accountOption)
      }
      return unattachedAccountOptions
    },
  },
  async mounted(){
    await this.loadBankAccountData()
  },
  watch:{
    async contextUUID(newUUID){
      if (!newUUID){
        this.contextBankAccounts = []
        return
      }
      
      await this.showBankAccountOverview()
      await this.loadBankAccountData()
    },
  },
  methods:{
    async loadBankAccountData(){
      this.isLoaded = false  
      await Promise.all([
        this.loadContextBankAccounts(),
        this.loadAccountableContextRelatedBankAccounts()
      ])
      this.attachBankAccountUUID = ''
      this.isLoaded = true
    },
    async loadContextBankAccounts(){
      this.contextBankAccounts = await this.readRelatedBankAccounts()
    },
    async loadAccountableContextRelatedBankAccounts(){
      if (!this.isAccountableContextGiven) {
        return
      }
      this.contextRelatedBankAccounts = await this.readAccountableRelatedBankAccounts()
    },
    async readRelatedBankAccounts(){
      if (!this.isContextGiven){
        services.$log.warn("MOC0170", "read all bank accounts cannot be executed, invalid related uuid / type, skipping")
        return []
      }
      const messageTarget = `readRelatedBankAccounts`
      this.messageStore.flushTarget(messageTarget)

      let relatedBankAccounts = []
      try{
        relatedBankAccounts = await readAllBankAccountsByRelated(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0171", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedBankAccounts
    },
    async readAccountableRelatedBankAccounts(){
      if (!this.isAccountableContextGiven){
        services.$log.warn("MOC0174", "read all accountable related bank accounts cannot be executed, invalid related uuid / type, skipping")
        return []
      }
      const messageTarget = `readRelatedBankAccounts`
      this.messageStore.flushTarget(messageTarget)

      let relatedBankAccounts = []
      try{
        relatedBankAccounts = await readAllBankAccountsByRelatedAccountable(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0175", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedBankAccounts
    },
    async readRelatedBankAccountOptions(){
      const messageTarget = `readRelatedBankAccountOptions`
      this.messageStore.flushTarget(messageTarget)

      let relatedBankAccounts = []
      try{
        relatedBankAccounts = await readAllBankAccountsByRelated(this.contextType, this.contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0171", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message)
        return []
      }
      return relatedBankAccounts
    },
    async attachBankAccount(e){
      if (e){
        e.preventDefault()
      }
      if (!this.isAccountableContextGiven){
        return 
      }
      const messageTarget = `attachBankAccount`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Füge Konto hinzu...', messageTarget)

      if (!this.attachBankAccountUUID){
        this.messageStore.error("MOC0142", "Etwas ist schiefgelaufen. Das ausgewählte Konto kann nicht identifiziert werden.", messageTarget)
        return
      }

      try{
        await attachBankAccount(this.contextType, this.contextUUID, this.attachBankAccountUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0143", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Konto erfolgreich hinzugefügt.", messageTarget)
      await this.loadBankAccountData()
    },
    async detachBankAccount(detachBankAccountUUID){
      if (!this.isAccountableContextGiven){
        return 
      }
      const messageTarget = `detachBankAccount`
      this.messageStore.flushTarget(messageTarget)
      
      const isConfirmed = confirm("Sicher, dass das Konto entfernt werden soll?")
      if (!isConfirmed){
        return
      }

      this.messageStore.pending('', 'Entferne Konto...', messageTarget)

      if (!detachBankAccountUUID){
        this.messageStore.error("MOC0144", "Etwas ist schiefgelaufen. Das ausgewählte Konto kann nicht identifiziert werden.", messageTarget)
        return
      }

      try{
        await detachBankAccount(this.contextType, this.contextUUID, detachBankAccountUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0145", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Konto erfolgreich entfernt.", messageTarget)
      await this.loadBankAccountData()
    },
    async createBankAccount(bankAccountJSON, contextType, contextUUID){
      const messageTarget = `createBankAccount`
      this.messageStore.flushTarget(messageTarget)
      this.messageStore.pending('', 'Erstelle ein neues Konto...', messageTarget)
      if (!contextType){
        contextType = this.contextType
      }
      if (!contextUUID){
        contextUUID = this.contextUUID
      }
      try{
        await createBankAccount(bankAccountJSON, contextType, contextUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0172", requestErr)
        this.messageStore.error(parsedError.code, parsedError.message, messageTarget)
        return
      }
      this.messageStore.success('', "Konto erfolgreich erstellt.", messageTarget)
      
      await this.loadBankAccountData()
    },
    selectBankAccountForEdit(bankAccountUUID){
      this.showBankAccountEditForm(bankAccountUUID)
    },
    async exitToOverview(){
      await this.showBankAccountOverview()
    },
    showBankAccountEditForm(bankAccountUUID){
      if (!bankAccountUUID){
        services.$log.warnWrap('showBankAccountEditForm', 'MOC0173', 'no real estate uuid provided, showing overview instead')
        this.showBankAccountOverview()
        return
      }
      this.currentBankAccountUUID = bankAccountUUID
      this.currentComponent = 'BankAccountEditForm'
    },
    async showBankAccountOverview(){
      this.currentBankAccountUUID = ''
      this.currentComponent = 'BankAccountOverview'

      await this.loadBankAccountData()
    },
    isContextBankAccount(accountUUID){
      for (let accountIdx = 0; accountIdx < this.contextBankAccounts.length; accountIdx++) {
        const displayOwner = this.contextBankAccounts[accountIdx];
        if (displayOwner.uuid === accountUUID){
          return true
        }
      }
      return false
    },
    getBankAccountOptionLabel(accountJSON){
      let accountLabel = ""
      if (accountJSON.name){
        accountLabel += accountJSON.name
      }
      if (accountJSON.iban){
        accountLabel += " / " + accountJSON.iban
      }
      if (accountJSON.account_holder_name){
        accountLabel += " / " + accountJSON.account_holder_name
      }
      return accountLabel
    },
    bankAccountIBANComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (!first.iban){
        return orderFirstBeforeSecond
      }
      if (!second.iban){
        return orderSecondBeforeFirst
      }
      if (first.iban < second.iban){
        return orderFirstBeforeSecond
      }
      if (first.iban > second.iban){
        return orderSecondBeforeFirst
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
  },
  components:{
    BankAccountTable,
    BankAccountEditForm,
    FormMessages,
    SelectAutocompleteInput,
  }
}
</script>
