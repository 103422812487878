import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'
import BoolField from '@/diaspora/fields/bool_field.js'
import DateField from '@/diaspora/fields/date_field'

class TechnicalInstallation extends Model{
    constructor(facilityType = '') {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            facilityType:           new StringField('facilityType', 'Typ'),
            facilityUUID:           new UUIDField('facilityUUID', 'UUID (Obj.)'),
            type:                   new StringField('type', 'Typ', true, [
                new FieldOption('Rauchmelder',      'Rauchmelder'),
                new FieldOption('Rolltor',          'Rolltor'),
                new FieldOption('Hebeanlage',       'Hebeanlage'),
                new FieldOption('Therme',           'Therme'),
                new FieldOption('Aufzug',           'Aufzug'),
                new FieldOption('Klimaanlage',      'Klimaanlage'),
                new FieldOption('Türsprechanlage',  'Türsprechanlage'),
            ]),
            installationDate:       new DateField('installationDate', 'Installiert am'),
            description:            new StringField('description', 'Beschreibung'),
            manufacturer:           new StringField('manufacturer', 'Hersteller'),
            model:                  new StringField('model', 'Modell'),
            serialNumber:           new StringField('serialNumber', 'Seriennummer'),
            maintenanceInterval:    new StringField('maintenanceInterval', 'Wartungsintervall'),
            isMaintainable:         new BoolField('isMaintainable', 'Wartbar', false, [
                new FieldOption('Nein', false),
                new FieldOption('Ja',   true, true),
            ]),
        }
        if (facilityType){
            this.fields.facilityType.value = facilityType
        }
    }

    getIdentifierString(){
        const type = this.fields.type.value
        if (!type){
            return this.fields.uuid.value
        }
        const serialNumber = this.fields.serialNumber.value
        if (!serialNumber) {
            return type
        }
        return type + " (" + serialNumber + ")"
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }
    
    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}

export default TechnicalInstallation