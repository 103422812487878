import { services } from '@digiscape/js-core'
import { isProxy, toRaw } from 'vue';

class Marshallable {
    constructor() {
        this.fields = {}
    }

    marshalJSON(){
        const exportData = {}
        for (const fieldName in this.fields) {
            const exportFieldName = services.$strcase.convertToSnakeCase(fieldName)
            const fieldRef = this.fields[fieldName]
            if (fieldRef.isMandatoryField){
                exportData[exportFieldName] = fieldRef.value
                continue
            }
            if (fieldRef.isZeroValue() || !fieldRef.value){
                continue
            }
            exportData[exportFieldName] = fieldRef.value
        }
        return exportData
    }

    unmarshalJSON(importData){
        var rawImportData = importData
        if (isProxy(importData)){
            rawImportData = toRaw(importData)
        }
        for (const fieldName in this.fields) {
            const fieldRef = this.fields[fieldName]
            const importFieldName = services.$strcase.convertToSnakeCase(fieldName)
            if (!Object.prototype.hasOwnProperty.call(rawImportData, importFieldName)) {
                fieldRef.resetValueToZero()
                continue
            }
            if (typeof rawImportData[importFieldName] == undefined){
                continue
            }
            fieldRef.value = rawImportData[importFieldName]
        }
    }
    
    areFieldsValid(){
        for (const fieldName in this.fields) {
            const fieldRef = this.fields[fieldName]
            if (fieldRef.isValid === false) {
                return false
            }
            if (fieldRef.isMandatory === true && !fieldRef.isValid) {
                return false
            }
        }
        return true
    }

    registerEventHandlerForAllFields(eventName, eventHandlerFn){
        for (const fieldRef of Object.values(this.fields)) {
            fieldRef.subscribe(eventName, eventHandlerFn);
        }
    }
}

export default Marshallable