class ShortcutManager{
    // See: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
    constructor(shortcutHandlers) {
        this.cooldownPeriodMs = 200
        this.isCooldownActive = false
        this.shortcutHandlers = shortcutHandlers
    }

    listen(){
        this.listener = this.handleKeyboardShortcuts.bind(this)
        document.addEventListener('keydown', this.listener)
    }

    destroy(){
        document.removeEventListener('keydown', this.listener)
    }

    async handleKeyboardShortcuts(e){
        if (e.key == 'Control' || e.key == 'Home' || e.key == 'Alt' || e.key == 'Shift'){
            return
        }
        if (this.isCooldownActive){
            return
        }
        const pressedShortcutPieces = this.aggregateEventShortcutKeyPieces(e)        
        const shortcutKey = pressedShortcutPieces.join(" + ")

        if (!(shortcutKey in this.shortcutHandlers)){
            return
        }

        const handler = this.shortcutHandlers[shortcutKey]
        await handler()
        this.isCooldownActive = true
        setTimeout(this.deactivateCooldown.bind(this), this.cooldownPeriodMs)
        e.preventDefault()
    }

    aggregateEventShortcutKeyPieces(e){
        let pressedShortcutPieces = []
        if (e.ctrlKey){
            pressedShortcutPieces.push("CTRL")
        }
        if (e.altKey){
            pressedShortcutPieces.push("ALT")
        }
        if (e.shiftKey){
            pressedShortcutPieces.push("SHIFT")
        }
        pressedShortcutPieces.push(e.key.toUpperCase())
        return pressedShortcutPieces
    }

    deactivateCooldown(){
        this.isCooldownActive = false
    }
}

export default ShortcutManager