import { services } from '@digiscape/js-core'


async function createMeterReading(meterUUID, newMeterReadingJSON){
    if (meterUUID == ''){
        throw new Error('meterUUID is empty')
    }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/meter/"+meterUUID+"/meter-reading/create", newMeterReadingJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readAllMeterReadings(meterUUID){
    if (meterUUID == ''){
        throw new Error('meterUUID is empty')
    }
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/meter/"+meterUUID+"/meter-reading/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_meter_readings
    return responseBody
}


async function deleteMeterReading(meterReadingUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/meter-reading/" + meterReadingUUID)
}

export {
    createMeterReading,
    readAllMeterReadings,
    deleteMeterReading
}