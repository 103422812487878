<template>
    <div class="container">
      <h2>Dienstleister</h2>
      <ServiceProviderCollection
        :realEstateUUID="urlRealEstateUUID"
        :realEstateUnitUUID="urlRealEstateUnitUUID"
        :realEstateOwnerUUID="urlRealEstateOwnerUUID"
        :isSubView="false"
      />
    </div>
  </template>
  
  <script>
  import ServiceProviderCollection from '@/components/collections/ServiceProviderCollection.vue'
  
  export default {
    name: 'ServiceProvider',
    computed:{
      urlRealEstateUUID(){
        return this.$route.params.realEstateUUID
      },
      urlRealEstateUnitUUID(){
        return this.$route.params.realEstateUnitUUID
      },
      urlRealEstateOwnerUUID(){
        return this.$route.params.realEstateOwnerUUID
      },
    },
    components:{
      ServiceProviderCollection,
    }
  }
  </script>
  
  <style>
  </style>
  