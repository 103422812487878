<template>
<div>
    <div class="row g-1 p-1">
        <div class="col-6">
            <RadioButtonInput
                name="departmentFilter" 
                label="Abteilung"
                id="departmentFilter"
                v-model="filterFields.department.value"
                :options="filterFields.department.options"
                @select:option="toggleFilterOptionDepartment"
                centered
            />
        </div>
        <div class="col-6">
            <RadioButtonInput
                name="typeFilter" 
                label="Typ"
                id="typeFilter"
                v-model="filterFields.type.value"
                :options="filterFields.type.options"
                @select:option="toggleFilterOptionType"
                centered
            />
        </div>
    </div>
</div>
</template>

<script>
import FieldOption  from '@/diaspora/fields/field_option.js'
import StringField  from '@/diaspora/fields/string_field.js'

import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'

export default {
    name: 'RealEstateFilters',
    emits: [
        'update:filterValues',
    ],
    data(){
        return {            
            filterFields: this.newFilterFields(),
        }
    },
    props:{
        filterValues:{
            Type: Object,
            mandatory: true
        },
    },
    computed:{
        departmentValue(){
            return this.filterFields.department.value
        },
        typeValue(){
            return this.filterFields.type.value
        },
    },
    watch:{
        departmentValue(){
            const newValues = {
                department: this.departmentValue,
                type: this.typeValue,
            }
            this.$emit('update:filterValues', newValues)
        },
        typeValue(){
            const newValues = {
                department: this.departmentValue,
                type: this.typeValue,
            }
            this.$emit('update:filterValues', newValues)
        },
        filterValues:{
            deep: true,
            handler(){
                this.filterFields.department.value = this.filterValues.department
                this.filterFields.type.value = this.filterValues.type
            }
        },
    },
    methods:{
        toggleFilterOptionDepartment(filterOption){
            if (this.filterFields.department.value !== filterOption.value){
                return
            }
            this.filterFields.department.value = ''
        },
        toggleFilterOptionType(filterOption){
            if (this.filterFields.type.value !== filterOption.value){
                return
            }
            this.filterFields.type.value = ''
        },
        newFilterFields(){
            const filterFields = {
                department: new StringField('department', 'Abteilung', false, [
                        new FieldOption('Vermarktung', 'Vermarktung'),
                        new FieldOption('Verwaltung', 'Verwaltung'),
                    ]
                ),
                type: new StringField('type', 'Typ', false, [
                        new FieldOption('Wohnung', 'Wohnung'),
                        new FieldOption('Haus', 'Haus'),
                    ]
                ),
            }
            return filterFields
        }
    },
    components:{
        RadioButtonInput,
    }
}
</script>

<style>

</style>