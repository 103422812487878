class SwipeManager{

    constructor(swipableElement, swipeActionLeft = () => {}, swipeActionRight = () => {}, minSwipeDistancePx = 50) {
        if (!swipableElement){
            return
        }
        this.swipableElement = swipableElement;
        this.swipeActionLeft = swipeActionLeft
        this.swipeActionRight = swipeActionRight
        this.minSwipeDistancePx = minSwipeDistancePx
        this.touchStartX = 0;

        swipableElement.addEventListener(
            "touchstart",
            this.startSwipe.bind(this),
            { passive: true }
        );

        swipableElement.addEventListener(
            "touchend",
            this.endSwipe.bind(this),
            { passive: true }
        );
    }
    
    startSwipe(event) {
        this.touchStartX = event.pageX || event.changedTouches[0].screenX;
    }

    endSwipe(event) {
        const touchStartX = this.touchStartX;
        if (!touchStartX) {
            return;
        }
        this.touchStartX = 0;

        const touchEndX = event.pageX || event.changedTouches[0].screenX;
        const touchDelta = touchStartX - touchEndX;
        const touchMoveMin = this.minSwipeDistancePx;
        if (Math.abs(touchDelta) < touchMoveMin || touchDelta == 0) {
            return;
        }

        this.swipableElement.focus();

        if (touchDelta < 0) {
            this.swipeActionLeft();
            return;
        }
        this.swipeActionRight();
    }
}

export default SwipeManager