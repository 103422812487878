<template>
    <div class="container">
        <h2>Immobilien-Mieter</h2>
        <RealEstateRenterCollection
          :realEstateUnitUUID="urlRealEstateUnitUUID"
          :isSubView="false"
        />
    </div>
</template>

<script>
import RealEstateRenterCollection from '@/components/collections/RealEstateRenterCollection.vue'

export default {
  name: 'RealEstateRenter',
  computed:{
    urlRealEstateUnitUUID(){
      return this.$route.params.realEstateUnitUUID
    },
  },
  components:{
    RealEstateRenterCollection,
  }
}
</script>

<style>
</style>
