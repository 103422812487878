<template>
    <fieldset>
      <section
        :id="id+'-fields-main'" 
      >
          <h5
            class="collapsable"
            data-bs-toggle="collapse" 
            :data-bs-target="`#${id}-fields-main-body`"
            :aria-controls="`#${id}-fields-main-body`"
            aria-expanded="true"
          >
              Objektdaten
          </h5>
          <div
              :id="id+'-fields-main-body'"
              class="collapse show"
          >
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-type'"
                    class="col-sm-4 col-form-label"
                    label="Abteilung"
                    :validationState="realEstateModel.getFieldValidityIndicatorClass('department')"
                  />
                  <div class="col-sm-8">
                      <RadioButtonInput
                        :name="id+'-department'" 
                        label="Abteilung"
                        :id="id+'-department'"
                        v-model="fields.department.value"
                        @set:modelValue="fields.department.isValid = true"
                        :options="fields.department.options"
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-type'"
                    class="col-sm-4 col-form-label"
                    label="Typ"
                    :validationState="realEstateModel.getFieldValidityIndicatorClass('type')"
                    mandatory
                  />
                  <div class="col-sm-8">
                      <RadioButtonInput
                        :name="id+'-type'" 
                        label="Typ"
                        :id="id+'-type'"
                        v-model="fields.type.value"
                        @set:modelValue="fields.type.isValid = true"
                        :options="fields.type.options"
                        mandatory
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-subType'"
                    class="col-sm-4 col-form-label"
                    label="Zusatztyp"
                    :validationState="realEstateModel.getFieldValidityIndicatorClass('subType')"
                  />
                  <div class="col-sm-8">            
                      <SelectInput
                        :name="id+'-subType'"
                        label="Zusatztyp"
                        :id="id+'-subType'"
                        class="form-control" 
                        v-model="fields.subType.value"
                        @valid:modelValue="fields.subType.isValid = true"
                        @invalid:modelValue="fields.subType.isValid = false"
                        @reset:modelValue="fields.subType.isValid = null"
                        :options="fields.subType.options"
                        :optionGroup="fields.type.value"
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                    :for="id+'-shortCode'"
                    class="col-sm-4 col-form-label"
                    label="Objekt-Nr"
                    :validationState="realEstateModel.getFieldValidityIndicatorClass('shortCode')"
                  />
                  <div class="col-sm-8">            
                      <StringInput
                          :name="id+'-shortCode'"
                          label="Objekt-Nr"
                          class="form-control"
                          placeholder="50"
                          v-model="fields.shortCode.value"
                          @valid:modelValue="fields.shortCode.isValid = true"
                          @invalid:modelValue="fields.shortCode.isValid = false"
                          @reset:modelValue="fields.shortCode.isValid = null"
                      />
                  </div>
              </div>
          </div>
      </section>
      <section
          :id="id+'-fields-address'"
      >
          <h5
              class="collapsable"
              data-bs-toggle="collapse" 
              :data-bs-target="`#${id}-fields-address-body`"
              :aria-controls="`#${id}-fields-address-body`"
              aria-expanded="true"
          >
              Adresse
          </h5>
          <div
              :id="id+'-fields-address-body'"
              class="collapse show"
          >
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-streetName'"
                      class="col-sm-4 col-form-label"
                      label="Straße"
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('streetName')"
                      mandatory
                  />  
                  <div class="col-sm-8">
                      <StringInput
                          :name="id+'-streetName'"
                          label="Straße"
                          class="form-control"
                          placeholder="Musterstr."
                          v-model="fields.streetName.value"
                          @valid:modelValue="fields.streetName.isValid = true"
                          @invalid:modelValue="fields.streetName.isValid = false"
                          @reset:modelValue="fields.streetName.isValid = null"
                          :min-length="3"
                          mandatory
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-streetNumber'"
                      class="col-sm-4 col-form-label"
                      label="Haus-Nr."
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('streetNumber')"
                      mandatory
                  />
                  <div class="col-sm-8">
                      <StringInput
                          :name="id+'-streetNumber'"
                          label="Haus-Nr."
                          class="form-control"
                          placeholder="2b"
                          v-model="fields.streetNumber.value"
                          @valid:modelValue="fields.streetNumber.isValid = true"
                          @invalid:modelValue="fields.streetNumber.isValid = false"
                          @reset:modelValue="fields.streetNumber.isValid = null"
                          :min-length="1"
                          mandatory
                      />
                  </div>
              </div>
              <div class="row mb-3">                
                  <FieldLabel
                      :for="id+'-zipCode'"
                      class="col-sm-4 col-form-label"
                      label="PLZ"
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('zipCode')"
                      mandatory
                  />
                  <div class="col-sm-8">
                      <StringInput
                          :name="id+'-zipCode'"
                          label="PLZ"
                          class="form-control"
                          placeholder="85325"
                          v-model="fields.zipCode.value"
                          @valid:modelValue="fields.zipCode.isValid = true"
                          @invalid:modelValue="fields.zipCode.isValid = false"
                          @reset:modelValue="fields.zipCode.isValid = null"
                          :min-length="3"
                          :max-length="5"
                          mandatory
                      />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-city'"
                      class="col-sm-4 col-form-label"
                      label="Ort"
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('city')"
                      mandatory
                  />
                  <div class="col-sm-8">
                      <StringInput
                          :name="id+'-city'"
                          label="Ort"
                          class="form-control"
                          placeholder="Musterstadt"
                          v-model="fields.city.value"
                          @valid:modelValue="fields.city.isValid = true"
                          @invalid:modelValue="fields.city.isValid = false"
                          @reset:modelValue="fields.city.isValid = null"
                          :min-length="3"
                          mandatory
                      />
                  </div>
              </div>
          </div>
      </section>      
      <section :id="id+'-fields-key-data'">
          <h5
              class="collapsable"
              data-bs-toggle="collapse" 
              :data-bs-target="`#${id}-fields-key-data-body`"
              :aria-controls="`#${id}-fields-key-data-body`"
              aria-expanded="true"
          >
            Eckdaten
          </h5>
          <div
            :id="id+'-fields-key-data-body'"
            class="collapse show"
          >
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-spaceLiving'"
                      class="col-sm-4 col-form-label"
                      label="Wohnfläche"
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('spaceLiving')"
                      mandatory
                  />
                  <div class="col-sm-8 input-container">
                      <FloatInput
                          :name="id+'-spaceLiving'"
                          label="Wohnfläche"
                          class="form-control"
                          placeholder="80,00"
                          v-model="fields.spaceLiving.value"
                          @valid:modelValue="fields.spaceLiving.isValid = true"
                          @invalid:modelValue="fields.spaceLiving.isValid = false"
                          @reset:modelValue="fields.spaceLiving.isValid = null"
                          mandatory
                      />
                      <UnitLabel
                          class="input-placeholder-unit"
                          type="sqm"
                      />
                  </div>
              </div>
              <div class="row mb-3" v-if="isRealEstateTypeHouse">
                  <FieldLabel
                      :for="id+'-spaceProperty'"
                      class="col-sm-4 col-form-label"
                      label="Grundstücksfl."
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('spaceProperty')"
                      mandatory
                  />
                  <div class="col-sm-8 input-container">
                  <FloatInput
                      :name="id+'-spaceProperty'"
                      label="Grundstücksfl."
                      class="form-control"
                      placeholder="125,00"
                      v-model="fields.spaceProperty.value"
                      @valid:modelValue="fields.spaceProperty.isValid = true"
                      @invalid:modelValue="fields.spaceProperty.isValid = false"
                      @reset:modelValue="fields.spaceProperty.isValid = null"
                  />
                  <UnitLabel
                      class="input-placeholder-unit"
                      type="sqm"
                  />
                  </div>
              </div>
              <div class="row mb-3">
                  <FieldLabel
                      :for="id+'-spaceUsable'"
                      class="col-sm-4 col-form-label"
                      label="Nutzfläche"
                      :validationState="realEstateModel.getFieldValidityIndicatorClass('spaceUsable')"
                  />
                  <div class="col-sm-8 input-container">
                      <FloatInput
                          :name="id+'-spaceUsable'"
                          label="Nutzfläche"
                          class="form-control"
                          placeholder="25,00"
                          v-model="fields.spaceUsable.value"
                          @valid:modelValue="fields.spaceUsable.isValid = true"
                          @invalid:modelValue="fields.spaceUsable.isValid = false"
                          @reset:modelValue="fields.spaceUsable.isValid = null"
                      />
                      <UnitLabel
                          class="input-placeholder-unit"
                          type="sqm"
                      />
                  </div>
              </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberTotalRooms'"
              class="col-sm-4 col-form-label"
              label="Anz. Zimmer"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('numberTotalRooms')"
              mandatory
            />
            <div class="col-sm-8">
                <IntegerInput
                  :name="id+'-numberTotalRooms'"
                  label="Anz. Zimmer"
                  class="form-control"
                  placeholder="5"
                  v-model="fields.numberTotalRooms.value"
                  @valid:modelValue="fields.numberTotalRooms.isValid = true"
                  @invalid:modelValue="fields.numberTotalRooms.isValid = false"
                  @reset:modelValue="fields.numberTotalRooms.isValid = null"
                  mandatory
                />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberBathrooms'"
              class="col-sm-4 col-form-label"
              label="Anz. Badezimmer"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('numberBathrooms')"
            />
            <div class="col-sm-8">
                <IntegerInput
                  :name="id+'-numberBathrooms'"
                  label="Anz. Badezimmer"
                  class="form-control"
                  placeholder="2"
                  v-model="fields.numberBathrooms.value"
                  @valid:modelValue="fields.numberBathrooms.isValid = true"
                  @invalid:modelValue="fields.numberBathrooms.isValid = false"
                  @reset:modelValue="fields.numberBathrooms.isValid = null"
                />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberBedrooms'"
              class="col-sm-4 col-form-label"
              label="Anz. Schlafzimmer"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('numberBedrooms')"
            />
            <div class="col-sm-8">
              <IntegerInput
                :name="id+'-numberBedrooms'"
                label="Anz. Schlafzimmer"
                class="form-control" 
                placeholder="2"
                v-model="fields.numberBedrooms.value"
                @valid:modelValue="fields.numberBedrooms.isValid = true"
                @invalid:modelValue="fields.numberBedrooms.isValid = false"
                @reset:modelValue="fields.numberBedrooms.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-numberFloors'"
              class="col-sm-4 col-form-label"
              label="Anz. Etagen"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('numberFloors')"
            />
            <div class="col-sm-8 input-container">
              <IntegerInput
                :name="id+'-numberFloors'"
                label="Anz. Etagen"
                class="form-control"
                placeholder="4"
                v-model="fields.numberFloors.value"
                @valid:modelValue="fields.numberFloors.isValid = true"
                @invalid:modelValue="fields.numberFloors.isValid = false"
                @reset:modelValue="fields.numberFloors.isValid = null"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="isRealEstateTypeApartment">
            <FieldLabel
              :for="id+'-floor'"
              class="col-sm-4 col-form-label"
              label="Etage"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('floor')"
            />
            <div class="col-sm-8 input-container">
              <IntegerInput
                :name="id+'-floor'"
                label="Etage"
                class="form-control"
                placeholder="2"
                v-model="fields.floor.value"
                @valid:modelValue="fields.floor.isValid = true"
                @invalid:modelValue="fields.floor.isValid = false"
                @reset:modelValue="fields.floor.isValid = null"
              />
            </div>
          </div>    
        </div>  
      </section>      
      <section :id="id+'-fields-details'">
        <h5
          class="collapsable"
          data-bs-toggle="collapse" 
          :data-bs-target="`#${id}-fields-details-body`"
          :aria-controls="`#${id}-fields-details-body`"
          aria-expanded="true"
        >
          Details
        </h5>
        <div
          :id="id+'-fields-details-body'"
          class="collapse show"
        >
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-condition'"
              class="col-sm-4 col-form-label"
              label="Zustand"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('condition')"
            />
            <div class="col-sm-8">
              <SelectInput
                :name="id+'-condition'"
                label="Zustand"
                class="form-control" 
                v-model="fields.condition.value"
                @valid:modelValue="fields.condition.isValid = true"
                @invalid:modelValue="fields.condition.isValid = false"
                @reset:modelValue="fields.condition.isValid = null"
                :options="fields.condition.options"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-yearOfConstruction'"
              class="col-sm-4 col-form-label"
              label="Baujahr"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('yearOfConstruction')"
            />
            <div class="col-sm-8">
              <IntegerInput
                :name="id+'-yearOfConstruction'"
                label="Baujahr"
                class="form-control" 
                placeholder="2005"
                v-model="fields.yearOfConstruction.value"
                @valid:modelValue="fields.yearOfConstruction.isValid = true"
                @invalid:modelValue="fields.yearOfConstruction.isValid = false"
                @reset:modelValue="fields.yearOfConstruction.isValid = null"
                :min-length="4"
                :max-length="4"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-phaseOfConstruction'"
              class="col-sm-4 col-form-label"
              label="Bauphase"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('phaseOfConstruction')"
            />
            <div class="col-sm-8">
              <SelectInput
                :name="id+'-phaseOfConstruction'"
                label="Bauphase"
                class="form-control" 
                v-model="fields.phaseOfConstruction.value"
                @valid:modelValue="fields.phaseOfConstruction.isValid = true"
                @invalid:modelValue="fields.phaseOfConstruction.isValid = false"
                @reset:modelValue="fields.phaseOfConstruction.isValid = null"
                :options="fields.phaseOfConstruction.options"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-lastRenovationYear'"
              class="col-sm-4 col-form-label"
              label="Saniert"
              :validationState="realEstateModel.getFieldValidityIndicatorClass('lastRenovationYear')"
            />
            <div class="col-sm-8">
              <IntegerInput
                :name="id+'-lastRenovationYear'"
                label="Saniert"
                class="form-control" 
                placeholder="2010"
                v-model="fields.lastRenovationYear.value"
                @valid:modelValue="fields.lastRenovationYear.isValid = true"
                @invalid:modelValue="fields.lastRenovationYear.isValid = false"
                @reset:modelValue="fields.lastRenovationYear.isValid = null"
                :min-length="4"
                :max-length="4"
              />
            </div>
          </div>
          <div class="row mb-3">
            <FieldLabel
              :for="id+'-energyCertificateAvailability'"
              class="col-sm-4 col-form-label"
              label="Energieausweis Verf."
              :validationState="realEstateModel.getFieldValidityIndicatorClass('energyCertificateAvailability')"
            />
            <div class="col-sm-8">
              <SelectInput
                :name="id+'-energyCertificateAvailability'"
                label="Energieausweis Verf."
                class="form-control" 
                v-model="fields.energyCertificateAvailability.value"
                @valid:modelValue="fields.energyCertificateAvailability.isValid = true"
                @invalid:modelValue="fields.energyCertificateAvailability.isValid = false"
                @reset:modelValue="fields.energyCertificateAvailability.isValid = null"
                :options="fields.energyCertificateAvailability.options"
              />
            </div>
          </div>
        </div>
      </section>
    </fieldset>
</template>

<script>
import FieldLabel from  '@/components/labels/FieldLabel.vue'
import StringInput from  '@/components/inputs/StringInput.vue'
import IntegerInput from  '@/components/inputs/IntegerInput.vue'
import FloatInput from  '@/components/inputs/FloatInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import UnitLabel from  '@/components/labels/UnitLabel.vue'

import RealEstate from '@/diaspora/models/real-estate.js'

export default {
  name: 'RealEstateFields',
  emits: [
    'update:modelValue', 
    'update:fieldValue', 
    'valid:modelFields', 
    'invalid:modelFields',
    'error:modelFields'
  ],
  data(){
    return {
      realEstateModel: new RealEstate(),
    }
  },
  props:{
    id: {
      type: String,
    },
    realEstate:{
      type: Object
    },
  },
  async created(){
    if (this.realEstate){
        this.realEstateModel.unmarshalJSON(this.realEstate)
    }
    this.emitModelFieldValidity()
    
    this.realEstateModel.registerEventHandlerForAllFields('update:fieldValue', this.emitUpdateFieldValue)
  },
  computed:{
    fields(){
      return this.realEstateModel.fields
    },
    isRealEstateTypeApartment(){
      return this.fields.type.value === 'Wohnung'
    },
    isRealEstateTypeHouse(){
      return this.fields.type.value === 'Haus'
    },
  },
  watch:{
    fields: {
      deep:true,
      handler(){
          this.emitModelFieldValidity()
          this.$emit('update:modelValue', this.realEstateModel.marshalJSON())
      }
    },
    realEstate: {
        deep: true,
        handler(){
            if (this.realEstate){
              this.realEstateModel.unmarshalJSON(this.realEstate)
            }
        }
    },
    isRealEstateTypeApartment(){
      this.resetRealEstateTypeSpecificFields()
    },
    isRealEstateTypeHouse(){
      this.resetRealEstateTypeSpecificFields()
    },
  },
  methods:{
    async emitUpdateFieldValue(fieldName, oldValue, newValue){
      await this.$nextTick()      
      const isValid = this.fields[fieldName].isValid;
      const fieldLabel = this.fields[fieldName].displayName;
      this.$emit('update:fieldValue', fieldName, fieldLabel, oldValue, newValue, isValid);
    },
    emitModelFieldValidity(){        
      const areAllFieldsValid = this.realEstateModel.areFieldsValid()
      if (areAllFieldsValid === true) {
        this.$emit('valid:modelFields')
        return
      }
      this.$emit('invalid:modelFields')
    },
    resetRealEstateTypeSpecificFields(){
      if (this.isRealEstateTypeHouse){
        return
      }
      this.fields.spaceProperty.value = 0.0
    },
  },
  components:{
    FieldLabel,
    StringInput,
    IntegerInput,
    FloatInput,
    SelectInput,
    RadioButtonInput,
    UnitLabel,
  }
}
</script>