<template>
  <div>
    <div class="pending-container" v-if="pendingMessage">
      <span class="pending-message">
        <div class="spinner-grow" role="status"></div>
        {{pendingMessage}}
      </span>
    </div>
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <nav 
      id="object-search-bar"
      class="col-12 col-lg-10"
      v-show="currentComponent === 'RealEstateListingGrid'"
    >
      <div class="row gx-1 gx-lg-2 position-relative">
          <div class="col-7 col-sm-8 col-xl-6">
            <StringInput
                name="searchText" 
                label="Hinweis"
                class="form-control"
                placeholder="EFH Kaiserslautern ..."
                v-model="searchText"
                @valid:modelValue="searchTextValid = true"
                @invalid:modelValue="searchTextValid = false"
                @reset:modelValue="searchTextValid = null"
                :maxLength="100"
            />
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-sec form-control show-xl"
              type="button"
              @click="displayFilters = !displayFilters"
            >
              Filtern
            </button>
            <button
              class="btn btn-icon-sec form-control hide-xl"
              type="button"
              @click="displayFilters = !displayFilters"
            >
              <FunnelIcon />
            </button>
          </div>
          <div class="col-1 col-xl-3 btn-icon-container">
            <button
              class="btn btn-warn form-control show-xl"
              type="button"
              @click="resetSearchFilterCriteria"
            >
              Zurücksetzen
            </button>
            <button
              class="btn btn-icon-warn form-control hide-xl"
              type="button"
              @click="resetSearchFilterCriteria"
            >
              <UndoArrowIcon />
            </button>
          </div>
          <h6
            v-show="displayFilters"
            class="mt-1"
          >Filter</h6>
          <RealEstateListingFilters
              v-show="displayFilters"
              class="col-12 col-md-8 col-lg-12 col-xl-10 col-xxl-8"
              :filterValues="filters"
              :filterTagUUIDs="filterTagUUIDs"
              :tagOptions="tagOptions"
              @update:filterValues="updateFilterValues"
              @update:filterTagUUIDs="updateFilterTagUUIDs"
            />
      </div>
    </nav>
    <RealEstateListingGrid
      v-if="currentComponent === 'RealEstateListingGrid'"
      class="col-12"
      @triggered:modelCreation="createRealEstateListing"
      @triggered:modelEdit="showRealEstateListingEditForm"
      :realEstateListings="displayRealEstateListings"
      :createAllowed="createAllowed"
    />
    <RealEstateListingEditForm
      v-if="currentComponent === 'RealEstateListingEditForm'"
      class="col-12"
      @cancelled:modelEdit="navigateToRealEstateListingGridFromRealEstateListing"
      @success:modelDelete="navigateToRealEstateListingGridFromRealEstateListing"
      @navigate:model="selectRealEstateListingForEdit"
      :realEstateListingUUID="currentRealEstateListingUUID"
      :prevModelUUID="prevRealEstateListingUUID"
      :nextModelUUID="nextRealEstateListingUUID"
    />
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import RealEstateListingGrid from '@/components/grids/RealEstateListingGrid.vue'
import RealEstateListingEditForm from '@/components/forms/RealEstateListingEditForm.vue'
import FormMessages from '@/components/forms/features/form-messages.js'

import RealEstateListingFilters from '@/components/control/RealEstateListingFilters.vue'

import StringInput from  '@/components/inputs/StringInput.vue'

import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'
import FunnelIcon from  '@/components/icons/FunnelIcon.vue'

import RealEstateListing from '@/diaspora/models/real-estate-listing.js'

export default {
  name: 'RealEstateListingCollection',
  data(){
    return {
      currentComponent: 'RealEstateListingGrid',
      currentRealEstateListingUUID: '',
      allRealEstateListings: [],
      searchText: '',
      searchTextValid: false,
      displayFilters: false,
      filters: {
        contractType: '',
        type: '',
      },
      filterTagUUIDs: {},
      allRealEstateListingCoverImages: {},
      tagOptions: [],
      messages: new FormMessages(),
    }
  },
  props:{
    createAllowed:{
      type: Boolean,
      default: true
    },
    prefilterFields:{ // Ex.: { 'publishStatus': 'Active' }
      type: Object
    },
  },
  computed:{
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    displayRealEstateListings(){
      let realEstateSubset = this.allRealEstateListings
      if (!this.allRealEstateListings){
        return []
      }
      realEstateSubset = this.filterRealEstateListingsByContractType(realEstateSubset)
      realEstateSubset = this.filterRealEstateListingsByType(realEstateSubset)
      realEstateSubset = this.filterRealEstateListingsByTags(realEstateSubset)
      realEstateSubset = this.sortRealEstateListingsByUUID(realEstateSubset)
      return realEstateSubset
    },
    prevRealEstateListingUUID(){
      if (!this.currentRealEstateListingUUID){
        return ''
      }
      let prevRealEstateListing = undefined
      for (let realEstateIdx = 0; realEstateIdx < this.displayRealEstateListings.length; realEstateIdx++) {
        const realEstate = this.displayRealEstateListings[realEstateIdx]
        if (realEstate.uuid !== this.currentRealEstateListingUUID){
          prevRealEstateListing = realEstate
          continue
        }
        if (prevRealEstateListing){
          return prevRealEstateListing.uuid
        }
        return ''
      }
      return ''
    },
    nextRealEstateListingUUID(){
      if (!this.currentRealEstateListingUUID){
        return ''
      }
      let nextRealEstateListing = undefined
      for (let realEstateIdx = this.displayRealEstateListings.length - 1; realEstateIdx >= 0; realEstateIdx--) {
        const realEstate = this.displayRealEstateListings[realEstateIdx]
        if (realEstate.uuid !== this.currentRealEstateListingUUID){
          nextRealEstateListing = realEstate
          continue
        }
        if (nextRealEstateListing){
          return nextRealEstateListing.uuid
        }
        return ''
      }
      return ''
    },
    urlRealEstateListingUUID(){
      return this.$route.params.realEstateListingUUID
    },
    isGridActive(){
      return this.currentComponent === 'RealEstateListingGrid'
    }
  },
  async created(){
    if (this.urlRealEstateListingUUID){
      this.showRealEstateListingEditForm(this.urlRealEstateListingUUID)
      await this.readAllRealEstateListingObjects()
      return
    }
    await this.readAllRealEstateListingObjects()
    await this.readAllTagOptions()
  },
  watch:{
    async searchText(newText, oldText){
      if (newText === '' && oldText !== ''){
        await this.readAllRealEstateListingObjects()
        return
      }

      await this.searchForRealEstateListings()
    },
    currentRealEstateListingUUID(newUUID){
      if (!newUUID){
        this.navigateToRealEstateListingGridFromRealEstateListing()
        return
      }
      const oldUUID = this.urlRealEstateListingUUID
      if (!oldUUID){
        this.navigateToRealEstateListingFromRealEstateListingGrid(newUUID)
        return
      }
      this.navigateToOtherRealEstateListingFromRealEstateListing(oldUUID, newUUID)
    },
    urlRealEstateListingUUID(newUUID, oldUUID){
      if (!newUUID && oldUUID){
        this.showRealEstateListingGrid()
        return
      }
    }
  },
  methods:{
    navigateToRealEstateListingGridFromRealEstateListing(){
      if (this.isGridActive){
        return
      }
      const newRoutePieces = this.$route.path.split('/')
      newRoutePieces.pop()
      const newRoute = newRoutePieces.join('/')
      this.$router.push(newRoute)
    },
    navigateToRealEstateListingFromRealEstateListingGrid(newUUID){
      const newRoute = this.$route.path + '/' + newUUID
      this.$router.push(newRoute)
    },
    navigateToOtherRealEstateListingFromRealEstateListing(oldUUID, newUUID){
      const newRoute = this.$route.path.replace(oldUUID, newUUID)
      this.$router.push(newRoute)
    },
    async resetSearchFilterCriteria(){
      await this.readAllTagOptions()
      this.filters.contractType = ''
      this.filters.type = ''
      this.filterTagUUIDs = {}
      this.searchText = ''
    },
    async readAllRealEstateListingObjects(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing-meta/all", this.prefilterFields)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0004", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      if (response.status === 204){
        this.allRealEstateListings = []
        return true
      }
      this.allRealEstateListings = response.data.all_real_estate_listings
      return true
    },
    async reloadAllRealEstateListings(){
      const searchText = this.searchText
      const searchFilters = this.filters
      const searchFilterTags = this.filterTagUUIDs
      if (!searchText && !searchFilters.contractType && !searchFilters.type && (!searchFilterTags || Object.keys(searchFilterTags).length == 0)){
        await this.readAllRealEstateListingObjects()
        return
      }
      await this.searchForRealEstateListings()
    },
    async readAllTagOptions(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/tag/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0022", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      if (response.status === 204){
        this.tagOptions = []
        return true
      }
      this.tagOptions = response.data.all_tags
      this.tagOptions = this.sortTagsByName(this.tagOptions)
      return true
    },
    async readRealEstateListingTags(realEstateListingUUID){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/" + realEstateListingUUID + "/tag/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0023", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return []
      }
      if (response.status === 204){
        return []
      }
      return response.data.all_real_estate_listing_tags
    },
    async searchForRealEstateListings(){
      if (!this.searchTextValid){
        return
      }
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing-meta/search", { search_term: this.searchText }, this.prefilterFields)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0014", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      if (response.status === 204){
        this.allRealEstateListings = []
        return true
      }
      this.allRealEstateListings = response.data.result_real_estate_listings
      return true
    },
    updateFilterValues(newValues){
      for (const filterName in newValues) {
        const newFilterValue = newValues[filterName]
        this.filters[filterName] = newFilterValue
      }
    },
    updateFilterTagUUIDs(activeTagFlags){
      this.filterTagUUIDs = activeTagFlags
    },
    filterRealEstateListingsByContractType(realEstateSubset){      
      const contractTypeFilter = this.filters.contractType
      if (!contractTypeFilter){
        return realEstateSubset
      }
      const filteredRealEstateListings = realEstateSubset.filter(function(realEstate){
        if (!realEstate.contract_type){
          return false
        }
        return realEstate.contract_type === contractTypeFilter
      })
      return filteredRealEstateListings
    },
    filterRealEstateListingsByType(realEstateSubset){
      const typeFilter = this.filters.type
      if (!typeFilter){
        return realEstateSubset
      }
      const filteredRealEstateListings = realEstateSubset.filter(function(realEstate){
        if (!realEstate.type){
          return false
        }
        return realEstate.type === typeFilter
      })
      return filteredRealEstateListings
    },
    filterRealEstateListingsByTags(realEstateSubset){
      const activeTagFlagUUIDs = Object.keys(this.filterTagUUIDs)
      if (!activeTagFlagUUIDs || activeTagFlagUUIDs.length === 0){
        return realEstateSubset
      }
      const self = this
      const filteredRealEstateListings = realEstateSubset.filter(function(realEstate){
        if (!realEstate.uuid){
          return false
        }
        const realEstateListingTags = realEstate.real_estate_listing_tags
        if (!realEstateListingTags){
          return false
        }
        for (let tagUUIDIdx = 0; tagUUIDIdx < activeTagFlagUUIDs.length; tagUUIDIdx++) {
          const tagUUID = activeTagFlagUUIDs[tagUUIDIdx]
          if (!self.isRealEstateListingTagged(tagUUID, realEstateListingTags)){
            return false
          }
        }
        return true
      })
      return filteredRealEstateListings
    },
    isRealEstateListingTagged(tagUUID, realEstateListingTags){      
      for (let realEstateListingTagIdx = 0; realEstateListingTagIdx < realEstateListingTags.length; realEstateListingTagIdx++) {
        const realEstateListingTag = realEstateListingTags[realEstateListingTagIdx]
        if (tagUUID === realEstateListingTag.tag_uuid){
          return true
        }
      }
      return false
    },
    sortRealEstateListingsByUUID(realEstateSubset){
      return realEstateSubset.sort(function(first, second){
        if (first.uuid > second.uuid){
          return -1
        }
        if (first.uuid < second.uuid){
          return 1
        }
        return 0
      })
    },
    sortTagsByName(tagSubset){
      return tagSubset.sort(function(first, second){
        if (first.name > second.name){
          return 1
        }
        if (first.name < second.name){
          return -1
        }
        return 0
      })
    },
    async createRealEstateListing(e){
      if (e){
        e.preventDefault()
      }

      this.messages.reset()
      this.messages.pending("Erstelle eine neue Immobilien-Anzeige...")

      const newRealEstateListing = new RealEstateListing()
      const newRealEstateListingJSON = newRealEstateListing.marshalJSON()
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-listing/create", newRealEstateListingJSON)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0012", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      this.messages.success("Immobilien-Anzeige erfolgreich erstellt")
      this.showRealEstateListingEditForm(response.data.uuid)
    },
    showRealEstateListingEditForm(realEstateListingUUID){
      if (!realEstateListingUUID){
        services.$log.warnWrap('showRealEstateListingEditForm', 'MOC0050', 'no real estate uuid provided, showing grid instead')
        this.showRealEstateListingGrid()
        return
      }
      this.currentRealEstateListingUUID = realEstateListingUUID
      this.currentComponent = 'RealEstateListingEditForm'
    },
    async showRealEstateListingGrid(){
      this.currentRealEstateListingUUID = ''
      this.currentComponent = 'RealEstateListingGrid'

      await this.reloadAllRealEstateListings()
    },
    selectRealEstateListingForEdit(realEstateListingUUID){
      this.currentRealEstateListingUUID = realEstateListingUUID
    },
  },
  components:{
    RealEstateListingGrid,
    RealEstateListingEditForm,
    RealEstateListingFilters,
    StringInput,
    UndoArrowIcon,
    FunnelIcon
  }
}
</script>

<style scoped>
</style>
