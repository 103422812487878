<template>
  <div class="autocomplete-container">
    <input
      :name="name"
      :placeholder="placeholder"
      :class="[ this.class, {'input-valid': isValid === true, 'input-invalid': isValid === false }]"
      v-model="searchLabel"
      autocomplete="off"
      :mandatory="mandatory"
      :disabled="disabled"
      :id="baseID + '-input'"
      @focusin="isInputFocused = true"
      @keydown.tab="(e) => { e.preventDefault() }"
      @keyup.tab="visitOptionByTabHandler"

      @keyup.enter="selectVisitedOption"

      @keydown.arrow-down="(e) => { e.preventDefault() }"
      @keyup.arrow-down="visitNextOptionHandler"

      @keydown.arrow-up="(e) => { e.preventDefault() }"
      @keyup.arrow-up="visitPreviousOptionHandler"
    >
    <ul
      v-show="isInputFocused && localOptions && localOptions.length > 0"
      :id="baseID+ '-options'"
      class="autocomplete-options form-control"
      :class="{ 'above': moveOptionsAboveContainer }"
    >
      <li
        v-for="(option, optionIdx) in localOptions"
        :key="option.label"
        :class="{ selected: selectedLabel == option.label, visited: visitedOptionIdx == optionIdx }"
        @click="selectOptionClick(option)"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import FieldOption  from '@/diaspora/fields/field_option.js'

var stringSimilarity = require("string-similarity");

export default {
  name: 'SelectAutocompleteInput',
  data(){
    return {
      localElementBottom: 0,
      localResizeOberver: null,
      localValue: '',
      searchLabel: '',
      selectedLabel: '',
      visitedOptionIdx: -1,
      isValid: null,
      isInputFocused: false
    }
  },
  props:{
    modelValue:{
      required: true
    },
    options:{
      type: Array,
      required: true
    },
    minSimilarity:{
      type: Number,
      default: 0.5
    },
    optionGroup: {
      type: String
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    id:{
      type: String
    },
    class:{
      type: String
    },
    placeholder:{
      type: String,
      default: "n.v.",
    },
    mandatory:{
      type: Boolean
    },
    disabled:{
      type: Boolean
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue'
  ],
  computed:{
    baseID(){
      if (this.id){
        return this.id
      }
      return this.name
    },
    moveOptionsAboveContainer(){
      if (!this.localElementBottom){
        return false
      }
      const containerBottom = this.localElementBottom
      const pageBottom = document.documentElement.getBoundingClientRect().bottom
      const pageBottomMargin = pageBottom * 0.1
      return containerBottom > pageBottom - pageBottomMargin
    },
    defaultOption(){
      if (!this.options || this.options.length < 1){
        return new FieldOption('n.v.', '')
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        if (option.default === true){
          return option
        }
      }
      if (this.mandatory === true){
        return this.options[0]
      }
      return new FieldOption('n.v.', '')
    },
    localOptions(){
      if (!this.defaultOption){
        return []
      }
      if (!this.selectableOptions){
        return [this.defaultOption]
      }
      if (this.defaultOption.value !== ''){
        return this.selectableOptions
      }
      return [this.defaultOption, ...this.selectableOptions]
    },
    selectableOptions(){
      const searchLabel = this.searchLabel
      const currentGroup = this.optionGroup
      if (!currentGroup && !searchLabel){
        return this.options
      }
      let options = this.options
      options = options.filter(this.optionGroupFilter(currentGroup))
      if (!searchLabel){
        return options
      }
      options = options.filter(this.optionLabelSubsumptionFilter(searchLabel))
      options = options.sort(this.optionSimilarityComparator(searchLabel))
      return options
    },
    selectedOptionIdx(){
      if (this.selectedLabel == ''){
        return -1
      }
      for (let optionIdx = 0; optionIdx < this.localOptions.length; optionIdx++) {
        const option = this.localOptions[optionIdx];
        if (option.label == this.selectedLabel){
          return optionIdx
        }
      }
      return -1
    },
    isValidLocalValueForGroup(){
      if (!this.localValue || !this.optionGroup){
        return true
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        if (option.value !== this.localValue){
          continue
        }
        return option.group === this.optionGroup
      }
      return true
    }
  },
  watch:{
    modelValue:{
      immediate:true,
      handler(value){
        if (!value && (this.localValue || this.searchLabel)){
          this.localValue = ''
          this.searchLabel = ''
          return
        }
        this.localValue = value
        if (this.localValue == this.defaultOption.value){
          this.$emit('valid:modelValue')
        }
      }
    },
    defaultOption(){
      if (this.defaultOption && this.defaultOption.value && !this.modelValue){
        this.localValue = this.defaultOption.value
      }
    },
    optionGroup(newValue, oldValue){
      if (!oldValue || newValue === oldValue || this.isValidLocalValueForGroup){
        return
      }
      this.localValue = this.defaultOption.value
    },
    localValue() {
      if (this.mandatory === true && !this.localValue){
        this.isValid = false
        this.$emit('invalid:modelValue', "Das Feld '" + this.label +"' ist ein Pflichtfeld und muss ausgewählt werden.")
        return
      }
      if (this.searchLabel != "" && this.searchLabel != this.selectedLabel){
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    selectableOptions:{
      deep: true,
      handler(){
        if ((!this.selectedOptionIdx || this.selectedOptionIdx < 0) && this.selectedLabel){
          this.localValue = ''
          this.selectedLabel = ''
        }
      }
    },
    searchLabel(newValue, oldValue) {
      if (oldValue && !newValue && this.localValue){
        this.localValue = ''
        this.selectedLabel = ''
        return
      }
      if (oldValue && !newValue && !this.localValue){
        this.selectedLabel = ''
        this.isValid = true
        this.$emit('valid:modelValue')
        return
      }
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000)
        return
      }
    }
  },
  methods:{
    getExactLabelMatch(searchLabel){
      if (!searchLabel){
        return null
      }
      for (let optionIdx = 0; optionIdx < this.selectableOptions.length; optionIdx++) {
        const option = this.selectableOptions[optionIdx];
        if (!option.label){
          continue
        }
        if (option.label.toLowerCase() === searchLabel.toLowerCase()){
          return option
        }
      }
      return null
    },
    updateLocalElementBottom(){
      if (!this.$el){
        return
      }
      const localElementBottom = this.$el.getBoundingClientRect().bottom
      this.localElementBottom = localElementBottom
    },
    selectOptionClick(option){
      this.selectOption(option)
      this.isInputFocused = false
    },
    selectOption(option){
      if (option.label == this.defaultOption.label){
        this.searchLabel = ''
        this.selectedLabel = ''
        this.localValue = ''
        return
      }
      this.searchLabel = option.label
      this.selectedLabel = option.label
      this.localValue = option.value
    },
    visitPreviousOptionHandler(e){
      if (e){
        e.preventDefault()
      }
      this.visitPreviousOptionIndex()
    },
    visitNextOptionHandler(e){
      if (e){
        e.preventDefault()
      }
      this.visitNextOptionIndex()
    },
    visitOptionByTabHandler(e){
      if (e){
        e.preventDefault()
      }
      if (e.shiftKey){
        this.visitPreviousOptionIndex()
        return
      }
      this.visitNextOptionIndex()
    },
    visitPreviousOptionIndex(){
      let currentOptionIdx = this.visitedOptionIdx
      if (currentOptionIdx == -1 && this.selectedOptionIdx >= 0){
        currentOptionIdx = this.selectedOptionIdx
      }
      if (currentOptionIdx == -1){
        return
      }
      this.visitedOptionIdx = Math.max(currentOptionIdx - 1, 0)
    },
    visitNextOptionIndex(){
      this.visitedOptionIdx = Math.min(this.visitedOptionIdx + 1, this.localOptions.length - 1)
    },
    selectVisitedOption(e){
      if (e){
        e.preventDefault()
      }
      const option = this.localOptions[this.visitedOptionIdx]
      if (!option){
        this.finalizeSearchLabelValue()
        return
      }
      this.selectOption(option)
    },
    resetIsValid(){
      if (this.isValid === false){
        return
      }
      this.isValid = null
    },
    optionGroupFilter(currentGroup){
      return function(option){
        if (!option.group){
          return true
        }
        if (!currentGroup){
          return true
        }
        return option.group === currentGroup
      }
    },
    optionLabelSubsumptionFilter(searchLabel){
      const minSimilarity = this.minSimilarity
      return function(option){
        const lowerLabelValue = option.label.toLowerCase()
        const lowerSearchValue = searchLabel.toLowerCase()
        if (lowerLabelValue.includes(lowerSearchValue)){
          return true
        }
        if (stringSimilarity.compareTwoStrings(lowerLabelValue, lowerSearchValue) > minSimilarity){
          return true
        }
        return false
      }
    },
    optionSimilarityComparator(searchLabel){
      return function(first, second) {
        const orderFirstBeforeSecond = -1
        const orderSecondBeforeFirst = 1
        const orderEqual = 0
        
        const firstSim = stringSimilarity.compareTwoStrings(searchLabel, first.label);
        const secondSim = stringSimilarity.compareTwoStrings(searchLabel, second.label);
        if (firstSim > secondSim){
          return orderFirstBeforeSecond
        }
        if (firstSim < secondSim){
          return orderSecondBeforeFirst
        }
        return orderEqual
      }
    },
    unfocusElementHandler(e){
      if (this.$el.contains(e.target)){
        return
      }
      this.isInputFocused = false
      this.visitedOptionIdx = -1
      
      this.finalizeSearchLabelValue()
    },
    finalizeSearchLabelValue(){
      const exactMatchOption = this.getExactLabelMatch(this.searchLabel)
      if (exactMatchOption){
        this.selectOption(exactMatchOption)
        return
      }

      if (this.searchLabel){
        this.localValue = ''
        this.selectedLabel = ''
        this.isValid = false
        this.$emit('invalid:modelValue', "Das Feld '" + this.label +"' enthält keinen gültigen Wert.")
        return
      }
    }
  },
  mounted(){
    if (this.defaultOption && this.defaultOption.value && !this.modelValue){
      this.localValue = this.defaultOption.value
    }
    
    document.body.addEventListener('click', this.unfocusElementHandler)
    this.localResizeOberver = new ResizeObserver(this.updateLocalElementBottom)
    this.localResizeOberver.observe(this.$el)
  },
  unmounted(){
    document.body.removeEventListener('click', this.unfocusElementHandler)
    this.localResizeOberver.disconnect()
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>