import Model from '@/diaspora/model.js'

import FieldOption  from '@/diaspora/fields/field_option.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'
import DateField from '@/diaspora/fields/date_field.js'
import FloatField from '@/diaspora/fields/float_field.js'
import BoolField from '@/diaspora/fields/bool_field.js'

class RentalContract extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField('uuid', 'UUID'),
            tenantUUID:             new UUIDField('tenantUUID', 'Kunden-UUID'),
            unitUUID:               new UUIDField('unitUUID', 'Einheit-UUID'),
            isSubTenant:            new BoolField('isSubTenant', 'Untermieter', false, [
                new FieldOption('Nein', false, true),
                new FieldOption('Ja',   true),
            ]),
            moveInAt:               new DateField('moveInAt', 'Einzug am'),
            moveOutAt:              new DateField('moveOutAt', 'Auszug am'),
            securityDeposit:        new FloatField('securityDeposit', 'Kaution'),
            monthlyRentCold:        new FloatField('monthlyRentCold', 'Kaltmiete'),
            contractStartAt:        new DateField('contractStartAt', 'Vertragsbeginn'),
            contractEndAt:          new DateField('contractEndAt', 'Vertragsende'),
            cancellationAt:         new DateField('cancellationAt', 'Gekündigt zum'),
            cancellationPeriod:     new DateField('cancellationPeriod', 'Kündigungsfrist'),
        }
    }

    getFieldValidityIndicatorClass(fieldName){
        const field = this.fields[fieldName];
        if (this.isFieldValid(fieldName, field)){
            return "success"
        }
        if (this.isFieldInvalid(fieldName, field)){
            return "error"
        }
        return "inactive"
    }

    isFieldValid(fieldName, field){
        return field.value && field.isValid
    }

    isFieldInvalid(fieldName, field){
        if (field.isValid === false){
            return true
        }
        if (!field.isMandatory){
            return false
        }
        return !field.value || !field.isValid
    }
}
export default RentalContract