<template>
  <input 
    type="text" 
    :name="name" 
    :placeholder="placeholder"
    :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
    v-model.lazy="localValue"
  >
</template>

<script>
import { services } from '@digiscape/js-core'

export default {
  name: 'FloatInput',
  data(){
    return {
      localValue: '',
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: Number,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "100,50",
    },
    decimalPlaces:{
      type: Number,
      default: 2
    },
    minLength:{
      type: Number
    },
    maxLength:{
      type: Number
    }
  },
  emits: ['update:modelValue', 'invalid:modelValue', 'valid:modelValue', 'reset:modelValue'],  
  watch:{
    modelValue:{
      immediate:true,
      handler(value){
        if (value === 0){
          this.localValue = ''
          return
        }
        this.localValue = services.$format.float(value, this.decimalPlaces)
      }
    },
    localValue(){
      const validateErr = this.validateInput(this.localValue)
      if (validateErr != ""){
        this.isValid = false
        this.$emit('invalid:modelValue', validateErr)
        return
      }
      if (!this.localValue){
        this.isValid = null
        this.$emit('update:modelValue', this.localizedStringToNumber(this.localValue))
        this.$emit('reset:modelValue')
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localizedStringToNumber(this.localValue))
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  methods:{
    validateInput(value){
      if (!value){
        return ""
      }
      let numberValue = this.localizedStringToNumber(value)
      if (isNaN(numberValue)) {
        return "Das Feld '" + this.label +"' darf nur Zahlen enthalten."
      }
      if (this.minLength && value.length < this.minLength){
        return "Das Feld '"+ this.label +"' muss mindestens "+this.minLength+" Zeichen lang sein."
      }
      if (this.maxLength && value.length > this.maxLength){
        return "Das Feld '"+ this.label +"' darf höchstens "+this.maxLength+" Zeichen lang sein."
      }
      return ""
    },
    localizedStringToNumber(value){
      return Number(value.replace(/\./gi, '').replace(/,/, '.'))
    },
    resetIsValid(){
      this.isValid = null
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>