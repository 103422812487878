<template>
  <div>
    <div
      v-show="!isLoaded"
      class="m-1"
    >
      <div class="spinner-grow spinner co-hl" role="status"></div>
      <h5 class="spinner-label spinner-label co-sec">Einheiten werden geladen...</h5>
    </div>
    <table
      id="real-estate-units"
      class="table table-sm table-striped"
      v-show="isLoaded"
    >
      <thead
        class="thead-dark show-md"
      >
        <tr class="text-nowrap">
          <th>Bez.</th>
          <th>Typ</th>
          <th style="width:190px">Zusatztyp</th>
          <th style="width:100px">Wohnfl.</th>
          <th style="width:100px">Grundst.</th>
          <th style="width:100px">Nutzfl.</th>
          <th style="width:50px"></th>
          <th
            style="width:50px" 
            v-if="isDetachAllowed"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="realEstateUnit in realEstateUnits"
          :key="realEstateUnit.uuid"
        >
          <td
            label="Name"
          >
            {{ realEstateUnit.name }}
          </td>
          <td
            label="Typ"
          >
            {{ realEstateUnit.type }}
          </td>
          <td
            label="Zusatztyp"
          >
            {{ realEstateUnit.sub_type }}
          </td>
          <td
            label="Wohnfläche"
          >
            {{ formatSpace(realEstateUnit.space_living) }} 
            <UnitLabel
              v-if="realEstateUnit.space_living"
              type="sqm"
            />
          </td>
          <td
            label="Grundstück"
          >
            {{ formatSpace(realEstateUnit.space_property) }}
            <UnitLabel
              v-if="realEstateUnit.space_property"
              type="sqm"
            />
          </td>
          <td
            label="Nutzfläche"
          >
            {{ formatSpace(realEstateUnit.space_usable) }}
            <UnitLabel
              v-if="realEstateUnit.space_usable"
              type="sqm"
            />
          </td>
          <td>
            <div
              class="btn btn-sec hide-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditRealEstateUnit($event, realEstateUnit)"
            >
              Bearbeiten
            </div>
            <div
              class="btn-icon show-md"
              title="Bearbeiten"
              role="button"
              @click="triggerEditRealEstateUnit($event, realEstateUnit)"
            >
              <PencilIcon />
            </div>
          </td>
          <td
            v-if="isDetachAllowed"
          >
            <div
              class="btn btn-warn hide-md"
              title="Entfernen"
              role="button"
              @click="triggerDetachRealEstateUnit($event, realEstateUnit)"
            >
              Entfernen
            </div>
            <div
            class="btn-icon-warn show-md"
            title="Entfernen"
            role="button"
            @click="triggerDetachRealEstateUnit($event, realEstateUnit)"
            >
              <XIcon />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import UnitLabel from  '@/components/labels/UnitLabel.vue'

import PencilIcon from  '@/components/icons/PencilIcon.vue'
import XIcon from  '@/components/icons/XIcon.vue'

export default {
  name: 'RealEstateUnitTable',
  emits: [
    'triggered:modelEdit',
    'triggered:modelDetach',
  ],
  props:{
    contextType: {
      type: String,
    },
    contextUUID:{
      type: String,
    },
    realEstateUnits:{
      type: Array,
      required: true
    },
    isLoaded:{
      type: Boolean,
      required: true
    }
  },
  computed:{
    isDetachAllowed(){
      return this.contextUUID && (this.contextType == 'ServiceProvider' || this.contextType == 'RealEstateOwner')
    }
  },
  methods:{
    triggerEditRealEstateUnit(e, realEstateUnit){
      this.$emit('triggered:modelEdit', realEstateUnit.uuid)
    },
    triggerDetachRealEstateUnit(e, realEstateUnit){
      this.$emit('triggered:modelDetach', realEstateUnit.uuid)
    },
    formatSpace(spaceValue){
      if (!spaceValue){
        return ''
      }
      let formattedValue = services.$format.float(spaceValue, 2)
      return formattedValue
    }
  },
  components:{
    UnitLabel,
    PencilIcon,
    XIcon,
  },
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  table thead {
    display: none;
  }
  table tr{
    margin: 5px 0;
  }
  table td {
    display: flex;
  }  
  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }  
  table td .btn{
    width: 100%;
  }
}
</style>