import { services } from '@digiscape/js-core'

async function createRealEstateRenter(newRealEstateRenterJSON, relatedType, relatedUUID){
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/real-estate-renter/create", newRealEstateRenterJSON)
        const createdUUID = response.data.uuid
        return createdUUID
    }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/create", newRealEstateRenterJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readAllRealEstateRenters(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_real_estate_renters
    return responseBody
}

async function readAllRealEstateRentersByRelated(relatedType, relatedUUID){
    if (relatedType === 'RealEstateUnit'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + relatedUUID+ "/real-estate-renter/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estate_renters
        return responseBody
    }
    return []
}

async function searchRealEstateRenters(searchText){
    const requestBody = { "search_term": searchText }
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/search", requestBody)
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.result_real_estate_renters
    return responseBody
}
async function readRealEstateRenter(realEstateRenterUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + realEstateRenterUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.real_estate_renter
    return responseBody
}

async function saveRealEstateRenterField(realEstateRenterUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: realEstateRenterUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + realEstateRenterUUID +"/field", reqBody)
    const responseBody = response.data.real_estate_renter
    return responseBody
}

async function deleteRealEstateRenter(realEstateRenterUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + realEstateRenterUUID)
}

async function attachRealEstateRenter(relatedType, relatedUUID, realEstateRenterUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + realEstateRenterUUID + "/attach", requestBody)
}

async function detachRealEstateRenter(relatedType, relatedUUID, realEstateRenterUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + realEstateRenterUUID + "/detach", requestBody)
}

export {
    createRealEstateRenter,
    readAllRealEstateRenters,
    readAllRealEstateRentersByRelated,
    searchRealEstateRenters,
    readRealEstateRenter,
    saveRealEstateRenterField,
    deleteRealEstateRenter,
    attachRealEstateRenter,
    detachRealEstateRenter,
}