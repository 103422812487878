import { services } from '@digiscape/js-core'

async function createRealEstateUnit(realEstateUUID, newRealEstateUnitJSON){
    const response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + realEstateUUID+ "/real-estate-unit/create", newRealEstateUnitJSON)
    const createdUUID = response.data.uuid
    return createdUUID
}

async function readRelatedRealEstateUnits(contextType, contextUUID){
    if (contextType  == 'RealEstateRenter'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-renter/" + contextUUID+ "/real-estate-unit/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estate_units
        return responseBody
    }
    if (contextType  == 'ServiceProvider'){
        const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/service-provider/" + contextUUID+ "/real-estate-unit/all")
        if (response.status === 204){
            return []
        }
        const responseBody = response.data.all_real_estate_units
        return responseBody
    }

    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate/" + contextUUID+ "/real-estate-unit/all")
    if (response.status === 204){
        return []
    }
    const responseBody = response.data.all_real_estate_units
    return responseBody
}

async function readRealEstateUnit(realEstateUnitUUID){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + realEstateUnitUUID)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.real_estate_unit
    return responseBody
}

async function saveRealEstateUnitField(realEstateUnitUUID, fieldName, oldValue, newValue){
    const reqBody = {
      uuid: realEstateUnitUUID,
      field_name: fieldName,
      old_value: oldValue,
      new_value: newValue,
    }

    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + realEstateUnitUUID +"/field", reqBody)
    const responseBody = response.data.real_estate_unit
    return responseBody
}

async function deleteRealEstateUnit(realEstateUnitUUID){
    await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + realEstateUnitUUID)
}

async function detachRealEstateUnit(relatedType, relatedUUID, realEstateUnitUUID){
    const requestBody = {
        related_type: relatedType,
        related_uuid: relatedUUID,
    }
    await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/real-estate-unit/" + realEstateUnitUUID + "/detach", requestBody)
}

export { createRealEstateUnit, readRelatedRealEstateUnits, readRealEstateUnit, saveRealEstateUnitField, deleteRealEstateUnit, detachRealEstateUnit }