import Message from '@/diaspora/message.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class SyncSingleRealEstateListing extends Message{
    constructor(realEstateListingUUID, channelID) {
        super() 
        this.fields = {
            realEstateListingUUID: new UUIDField(),
            channelID:             new StringField(true),
        }
        if (realEstateListingUUID){
            this.fields.realEstateListingUUID.value = realEstateListingUUID
        }
        if (channelID){
            this.fields.channelID.value = channelID
        }
    }
}

export default SyncSingleRealEstateListing