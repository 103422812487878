<template>
<div>
    <h5 style="display:flex">
      <a   
        class="btn btn-icon-sec small" 
        style="margin: 2px;" 
        role="button"
        :href="documentURLPath(documentModel.fields.relativePath.value)"
        target="_blank"
        download
      >
        <FileIcon />
      </a>
      <span class="text-limit-3 fst-italic txt-sec full-width" style="margin-left: 3px;">{{ documentName }} </span>
    </h5>
    <h6>
      <span class="txt-sec">({{ documentFileType(documentModel.fields.fileType.value) }}, {{ documentFileSize(documentModel.fields.fileSize.value) }})</span>
    </h6>
    <form @submit="saveDocument">
        <div class="row mb-3 gx-1">
          <div class="col-6">
            <button
              class="btn btn-warn form-control"
              type="button"
              @click="deleteDocument"
            >
                Löschen
            </button>
          </div>
          <div class="col-6">
            <button
              v-if="fields.type.value === 'image'"
              class="btn btn-sec form-control"
              type="button"
              @click="triggerUpdateCoverImage"
            >
                Titelbild
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <CheckboxButtonInput
            id="channelImmoscout24-checkbox"
            name="channelImmoscout24"
            label="I24"
            :labelsResponsive="{ 'md': 'Immoscout24', 'lg': 'I24', 'xl': 'Immoscout24' }"
            class="col-5 d-inline-block"
            v-model="fields.channelImmoscout24.value"
            @valid:modelValue="fields.channelImmoscout24.isValid = true"
            @invalid:modelValue="fields.channelImmoscout24.isValid = false"
            buttonStyle="suc"
            centered
          />
          <CheckboxButtonInput
            id="channelBookoo-checkbox"
            v-if="isSupportedBookooFileFormat"
            name="channelBookoo"
            label="B"
            :labelsResponsive="{ 'md': 'Bookoo', 'lg': 'B', 'xl': 'Bookoo' }"
            class="col-4 d-inline-block"
            v-model="fields.channelBookoo.value"
            @valid:modelValue="fields.channelBookoo.isValid = true"
            @invalid:modelValue="fields.channelBookoo.isValid = false"
            buttonStyle="suc"
            centered
            :disabled="isBookooDisabled"
          />
        </div>
        <div
          class="row"
        >
          <div
            class="col-12 order-3 order-lg-2"
          >
            <div class="row mb-3">
                <label for="name" class="col-sm-3 col-form-label">Name<span class="highlight">*</span></label>  
                <div class="col-sm-9">
                    <StringInput
                        name="name" 
                        label="Name"
                        class="form-control"
                        placeholder="Keller"
                        v-model="fields.name.value"
                        @valid:modelValue="fields.name.isValid = true"
                        @invalid:modelValue="fields.name.isValid = false"
                        @reset:modelValue="fields.name.isValid = null"
                        id="document-edit-name"
                        :maxLength="30"
                        mandatory
                    />
                </div>
            </div>
            <div class="row mb-3">
                <div class="offset-sm-3 col-sm-9">                  
                    <RadioButtonInput
                        name="nameSuggestionButton" 
                        label="Name"
                        id="nameSuggestionButton"
                        v-model="fields.name.value"
                        :options="fields.name.options"
                        shrink
                        small
                    />
                </div>
            </div>
            <div class="row mb-3 gx-1">
              <div class="col-6">
                <button
                  class="btn btn-brand form-control"
                  type="button"
                  @click="cancelEdit"
                >
                  Abbrechen
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-brand form-control"
                  type="button"
                  @click="saveDocument"
                  :disabled="!areAllFieldsValid"
                >
                  Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
    </form>
</div>
</template>

<script>
import { services } from '@digiscape/js-core'

import ShortcutManager from '@/events/shortcut-manager.js'

import StringInput from  '@/components/inputs/StringInput.vue'
import RadioButtonInput from  '@/components/inputs/RadioButtonInput.vue'
import CheckboxButtonInput from  '@/components/inputs/CheckboxButtonInput.vue'

import FileIcon from  '@/components/icons/FileIcon.vue'

import FormMessages from '@/components/forms/features/form-messages.js'

import Document from '@/diaspora/models/document.js'

export default {
  name: 'DocumentEditForm',
  emits: [
    'navigate:nextModel',
    'navigate:previousModel',
    'success:modelEdit',
    'success:modelDelete',
    'cancelled:modelEdit',
    'triggered:updateCoverImage',
    'success:message',
    'error:message',
    'pending:message',
  ],
  data(){
    return {
      areAllFieldsValid: false,
      remoteDocument: {},
      documentModel: new Document(),
      messages: new FormMessages(),
      shortcutHandlers: {
        "CTRL + I": this.toggleChannelImmoscout24.bind(this),
        "CTRL + B": this.toggleChannelBookoo.bind(this),
        "CTRL + DELETE": this.deleteDocument.bind(this),
        "CTRL + ENTER": this.saveDocument.bind(this),
        "ESCAPE": this.triggerCancelEdit.bind(this),
        "CTRL + ARROWLEFT": this.triggerNavigatePreviousModel.bind(this),
        "CTRL + ARROWRIGHT": this.triggerNavigateNextModel.bind(this),
      },
      shortcutManager: new ShortcutManager(),
    }
  },
  computed:{
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    },
    fields(){
      return this.documentModel.fields
    },
    documentName(){
      if (!this.documentModel.fields.name.value){
        return ''
      }
      return this.documentModel.fields.name.value
    },
    isSupportedBookooFileFormat(){
      const fileType = this.documentModel.fields.fileType.value 
      return fileType != 'pdf'
    },
    isBookooDisabled(){
      return this.bookooLimitReached && this.fields.channelBookoo.value == false && this.remoteDocument.channel_bookoo !== true
    }
  },
  props:{
      documentUUID:{
        type: String,
        required: true,
      },
      bookooLimitReached:{
        type: Boolean,
      }
  },
  async created(){
    await this.loadDocument(this.documentUUID)
    document.getElementById("document-edit-name-input").select()
  },
  mounted(){    
    this.shortcutManager = new ShortcutManager(this.shortcutHandlers)
    this.shortcutManager.listen()
  },
  beforeUnmount(){
    this.shortcutManager.destroy()
  },
  watch:{
    async documentUUID(newUUID) {
      document.getElementById("document-edit-name-input").blur()
      await this.loadDocument(newUUID)
      this.$nextTick(() => {
        document.getElementById("document-edit-name-input").select()
      })
    },
    fields: {
      deep:true,
      handler(){
        this.areAllFieldsValid = this.documentModel.areFieldsValid()
      }
    },
    successMessage(){
      if (!this.successMessage){
        return
      }
      this.$emit('success:message', this.successMessage)      
    },
    errorMessage(){
      if (!this.errorMessage){
        return
      }
      this.$emit('error:message', this.errorCode, this.errorMessage)      
    },
    pendingMessage(){
      if (!this.pendingMessage){
        return
      }
      this.$emit('pending:message', this.pendingMessage)      
    }
  },
  methods:{
    async loadDocument(documentUUID){
      this.messages.reset()
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/document/" + documentUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0029", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.remoteDocument = response.data.document
      this.documentModel.unmarshalJSON(response.data.document)
      return true
    },
    cancelEdit(e){
      e.preventDefault()

      this.triggerCancelEdit()
    },
    async saveDocument(e){
      if(e){
        e.preventDefault()
      }
      this.messages.reset()

      const documentJSON = this.documentModel.marshalJSON()

      try{
        await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/document/" + this.documentUUID, documentJSON)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0030", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      this.messages.success("Dokument erfolgreich gespeichert")
      await services.$sleep.seconds(1)
      this.$emit('success:modelEdit', documentJSON)
    },
    async deleteDocument(e){
      if(e){
        e.preventDefault()
      }

      if (!this.documentUUID){
        this.messages.error("MOC0031", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("MOC0031", "delete document cannot be executed, invalid uuid")
        return
      }
      const nameLine = this.documentName
      const isConfirmed = confirm("Sicher, dass das Dokument '"+ nameLine +"' gelöscht werden soll?")
      if (!isConfirmed){
        return
      }

      const documentJSON = this.documentModel.marshalJSON()

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/document/" + this.documentUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("MOC0032", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      this.messages.success("Dokument '"+nameLine+"' wurde erfolgreich gelöscht.")     
      await services.$sleep.seconds(1)
      this.$emit('success:modelDelete', documentJSON)
    },
    documentFileSize(fileSizeBytes){
        const conversionFactor = 0.0009765625 // 1 / 1024
        if (fileSizeBytes > 1000 && fileSizeBytes < 999999){
            return Math.round(fileSizeBytes * conversionFactor) + " KB"
        }
        if (fileSizeBytes > 999999 && fileSizeBytes < 999999999){
            return Math.round(fileSizeBytes * conversionFactor * 0.001) + " MB"
        }
        if (fileSizeBytes > 999999999 && fileSizeBytes < 999999999999){
            return Math.round(fileSizeBytes * conversionFactor * 0.000001) + " GB"
        }
        return Math.round(fileSizeBytes) + " B"
    },
    documentFileType(fileType){
        return fileType.toUpperCase()
    },
    toggleChannelImmoscout24(){
      this.fields.channelImmoscout24.value = !this.fields.channelImmoscout24.value
    },
    toggleChannelBookoo(){
      if (this.isBookooDisabled){
        return
      }
      this.fields.channelBookoo.value = !this.fields.channelBookoo.value
    },
    triggerUpdateCoverImage(e){
      e.preventDefault()
      this.$emit('triggered:updateCoverImage', this.documentUUID)
    },
    triggerCancelEdit(){
      this.$emit('cancelled:modelEdit')
    },
    triggerNavigatePreviousModel(e){
      if(e){
        e.preventDefault()
      }
      this.$emit('navigate:previousModel')
    },
    triggerNavigateNextModel(e){
      if(e){
        e.preventDefault()
      }
      this.$emit('navigate:nextModel')
    },
    documentURLPath(relativePath){
        return process.env.VUE_APP_API_BASE_URL + '/files' + relativePath
    },
  },
  components:{
    RadioButtonInput,
    StringInput,
    CheckboxButtonInput,
    FileIcon,
  }
}
</script>