import { services } from '@digiscape/js-core'

class Field {
    constructor(fieldName = '', displayName = '', zeroValue, defaultValue = zeroValue, isMandatory = false){
        this.fieldName = fieldName
        this.displayName = displayName
        this.zeroValue = zeroValue
        this.defaultValue = defaultValue
        this.isMandatory = isMandatory
        this.isValid = (this.defaultValue == this.zeroValue) ? null : true
        this.eventHandlers = { 'update:fieldValue': [] }
        this._value = defaultValue
    }

    isEqual(otherField){
        const properties = [
            'fieldName', 
            'displayName', 
            'zeroValue',
            'defaultValue',
            'isMandatory',
            'isValid',
            '_value',
        ]
        for (let propertyIdx = 0; propertyIdx < properties.length; propertyIdx++) {
            const property = properties[propertyIdx];
            if (this[property] != otherField[property]){
                return false
            }
        }
        return true
    }

    clone(){
        const clone = Object.create(this.constructor.prototype);

        clone.fieldName = this.fieldName
        clone.displayName = this.displayName
        clone.zeroValue = this.zeroValue
        clone.defaultValue = this.defaultValue
        clone.isMandatory = this.isMandatory
        clone.isValid = this.isValid
        clone.eventHandlers = { 'update:fieldValue': [] }
        clone._value = this._value

        return clone
    }

    subscribe(eventName, handlerFn){
        if (! this.isEventRegistered(eventName)){
            services.$log.warnWrap(`subscribe(${this.fieldName})`,"MOC0053", `unknown event name '${eventName}' for field '${this.fieldName}', lazy initializing`)
            this.eventHandlers[eventName] = [ handlerFn ]
            return
        }
        this.eventHandlers[eventName].push(handlerFn);
    }

    emit(eventName, ...params){
        if (!this.isEventRegistered(eventName)){
            services.$log.fatalWrap(`emit(${this.fieldName})`, "MOC0054", `cannot emit for unregistered event '${eventName}'`)
        }
        for (let handlerFnIdx = 0; handlerFnIdx < this.eventHandlers[eventName].length; handlerFnIdx++) {
            const handlerFn = this.eventHandlers[eventName][handlerFnIdx];
            handlerFn(...params);
        }
    }

    isEventRegistered(eventName){
        return Object.prototype.hasOwnProperty.call(this.eventHandlers, eventName);
    }

    set value(newValue){
        const oldValue = this._value;
        this._value = newValue;

        if (oldValue == newValue){
            return;
        }
        this.emit('update:fieldValue', this.fieldName, oldValue, newValue);
    }

    get value(){
        return this._value;
    }

    isZeroValue(){
        return this.value == this.zeroValue
    }

    resetValueToDefault(){
        this.value = this.defaultValue
    }
    resetValueToZero(){
        this.value = this.zeroValue
    }
}

export default Field